<div class="quotations-cntr">

  <ng-container *ngIf="items.length > 0; else emptyQuotationsTemplate">

    <ng-container *ngIf="items.length >= 5">

      <ct-search-filters
        [configuration]="filtersConfiguration"
        (filter)="filter($event)"
      ></ct-search-filters>

    </ng-container>

    <app-cart-order
      *ngFor="let order of items"
      [configuration]="order"
      (refresh)="fetch()"
    ></app-cart-order>

  </ng-container>

  <ng-template #emptyQuotationsTemplate>
    <p class="text-center">Non ci sono preventivi da approvare.</p>
  </ng-template>


</div>
