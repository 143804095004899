import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ValidationMarkComponent } from './validation-mark.component';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';

@NgModule({
  declarations: [ValidationMarkComponent],
  imports: [CommonModule, MatIconModule, MatButtonModule],
  exports: [ValidationMarkComponent],
})
export class ValidationMarkModule {}
