import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-html-warning-modal",
  template: `
    <h2>Attenzione!</h2>
    <p>Probabilmente hai appena inserito il contenuto di un file proveniente da Microsoft Word o Microsoft Office: il
      testo copiato e incollato in questo modo potrebbe generare un'anteprima pdf non corretta.</p>
    <p>Verifica attentamente che il testo in anteprima pdf sia corretto!</p>
    <p>
      Per evitare problematiche di questo tipo incolla il testo su un editor come Notepad, dopodichè incollalo sul
      nostro editor web applicando manualmente le formattazioni (grassetto, tipologia font ecc).</p>
    <button mat-button [mat-dialog-close] color="primary">Chiudi</button>
  `
})
export class HtmlWarningModalComponent {
}
