export class User {

  Oid: number | null = null;
  GroupCode: string | null = null;
  GroupName: string | null = null;

  constructor(
    public RoleCode: string,
    public GroupOid: number,
    public Name: string,
    public Surname: string,
    public UserEmail: string,
    public PhoneNumber: string,
    public Password: string
  ) {
  }

}
