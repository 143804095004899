import { Component, Inject } from "@angular/core";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { Flow } from "../../../core/classes/flow";
import { CartOrderConfiguration } from "../../../_components/cart-order/cart-order.configuration";

@Component({
  selector: "app-order-detail",
  templateUrl: "./order-detail.component.html",
  styleUrls: ["./order-detail.component.scss"]
})
export class OrderDetailComponent {

  configuration: CartOrderConfiguration | null = null;

  constructor(
    private _dialogRef: MatDialogRef<OrderDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Flow) {

    this.configuration = CartOrderConfiguration.create()
      .setFlow(data)
      .setExpanded(true)
      .setDisable(true)
      .setHasDialogCallback(true)
      .setTrackingRedirectEnabled(true);

  }

  back() {

    this._dialogRef.close();

  }

}
