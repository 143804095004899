<div class="d-flex space-between">

  <h1 mat-dialog-title>I tuoi modelli</h1>
  <button mat-icon-button color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

</div>

<div mat-dialog-content class="templates-manager-cntr">

  <div
    class="template-selection-list"
    [class.empty]="!loading && !list.length">

    <ng-container
      *ctSkeleton="loading;
           repeat: 4;
           className: 'templates-skeleton';">

      <ng-container *ngIf="list.length; else emptyTemplate">

        <mat-list role="list">

          <ng-container *ngFor="let item of list; let last = last;">

            <mat-list-item role="listitem">

              <mat-icon matListItemIcon color="primary">edit_square</mat-icon>

              <div mat-line>

                <div>

                  <b> {{item.Name}} </b>

                </div>
                <div *ngIf="item.Description">

                  {{item.Description}}

                </div>

              </div>

              <div mat-line fxLayout="row wrap" fxLayoutAlign="space-between center">

                <div>

                  <small>Creato il: {{ item.CreateAt  | date:'dd/MM/yy' }}</small>

                </div>

                <div fxLayout="row wrap" fxLayoutAlign="space-between center">

                  <ng-container *ngIf="isOwner(item)">

                    <button mat-icon-button color="primary" (click)="onEdit($event, item)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="warn" (click)="onDelete($event, item)">
                      <mat-icon>delete</mat-icon>
                    </button>

                    <mat-divider vertical style="height: 30px;margin-right: 10px;"></mat-divider>

                  </ng-container>

                  <button mat-button color="accent" (click)="onSelect(item)">Usa modello</button>

                </div>

              </div>

            </mat-list-item>

            <mat-divider class="m-b-5" *ngIf="!last"></mat-divider>

          </ng-container>

        </mat-list>

      </ng-container>

      <ng-template #emptyTemplate>

        <p> Non hai ancora salvato nessun modello. Per salvare un modello, compila il contenuto della comunicazione
          che desideri inviare nell'editor. <br/>Una volta che il contenuto è pronto, fai clic su 'Modelli' e
          successivamente
          su 'Salva modello'. </p>

      </ng-template>

    </ng-container>

  </div>

</div>
