<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<!--
<ng-container *ngIf="roleService.isCompanyGroupAdmin()">

  <app-group-budget-massive-management (refresh)="fetch()"></app-group-budget-massive-management>

</ng-container>
-->

<mat-card>

  <mat-card-content>

    <ng-container *ngIf="hasAdministrativeAccess">

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="newGroup()">Nuovo gruppo
        </button>
      </mat-card-actions>

    </ng-container>

    <ng-container *ngIf="group">

      <ct-datatable
        [configuration]="group"
        (reload)="fetch($event)"
        (sort)="onSort($event)"
        (onEdit)="detail($event)"
        (onDelete)="delete($event)"
      ></ct-datatable>

    </ng-container>

  </mat-card-content>

</mat-card>

<ng-template #budgetCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <ng-container *ngIf="value; else emptyTemplate">

      <p> {{ getBudgetLabel(row) }}</p>

    </ng-container>

    <ng-template #emptyTemplate>

      <p> NON DISPONIBILE </p>

    </ng-template>

  </div>

</ng-template>
