import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DemoMaterialModule } from "../../demo-material-module";
import { CtCardModule } from "@ctsolution/ct-framework";
import { RouterModule } from "@angular/router";
import { ShipmentComponent } from "./shipment.component";
import { SendFlowModule } from "./send-flow/send-flow.module";
import { SendFlowComponent } from "./send-flow/send-flow.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { FLOW_TYPE } from "../../core/enums/flow-type.enum";
import { SendFlowAlertModule } from "./send-flow/send-flow-alert/send-flow-alert.module";
import { StandaloneSingleFlowComponent } from "./standalone-single-flow/standalone-single-flow.component";
import { StandaloneSingleFlowModule } from "./standalone-single-flow/standalone-single-flow.module";

@NgModule({
  imports: [
    CommonModule,
    CtCardModule,
    DemoMaterialModule,
    SendFlowModule,
    StandaloneSingleFlowModule,
    FlexLayoutModule,
    SendFlowAlertModule,
    RouterModule.forChild([
      {
        path: "singola",
        component: SendFlowComponent,
        data: {
          title: "Invio singolo",
          urls: [
            { title: "Invio singolo", url: "/nuova-spedizione/singola" },
            { title: "Invio singolo" }
          ],
          FlowDraftType: FLOW_TYPE.SINGLE_RECIPIENT
        }
      },
      {
        path: "standalone",
        component: StandaloneSingleFlowComponent,
        data: {
          title: "Invio singolo",
          urls: [{ title: "Invio singolo", url: "/nuova-spedizione/standalone" }]
        }
      },
      {
        path: "multipla",
        component: SendFlowComponent,
        data: {
          title: "Invio multiplo",
          urls: [
            { title: "Invio multiplo", url: "/nuova-spedizione/multipla" },
            { title: "Invio multiplo" }
          ],
          FlowDraftType: FLOW_TYPE.MULTIPLE_RECIPIENT
        }
      },
      {
        path: "",
        component: ShipmentComponent,
        data: {
          title: "Nuova spedizione",
          description: "Crea documenti online e spediscili in formato cartaceo o digitale.",
          urls: [
            { title: "Nuova spedizione", url: "/nuova-spedizione" },
            { title: "Nuova spedizione" }
          ]
        }
      }
    ])
  ],
  declarations: [ShipmentComponent]
})
export class ShipmentModule {
}
