<mat-card-content class="step4-container">
  <div class="m-b-15">
    <mat-card-title class="step-title"> COMPLETA ORDINE</mat-card-title>
    <app-letter-additions #additions></app-letter-additions>
    <mat-divider></mat-divider>
  </div>

  <div>
    <mat-card-title> ANTEPRIMA</mat-card-title>
    <app-document-preview #preview></app-document-preview>
  </div>
</mat-card-content>
