import { Injectable } from "@angular/core";
import { ActivatedRoute, NavigationExtras, Router } from "@angular/router";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root"
})
export class GeneralService {
  constructor(
    private router: Router,
    private location: Location,
    private route: ActivatedRoute) {
  }

  /* A function that navigates to a route. */
  navigateTo = (commands: string[], navigateOnActivatedRoute: boolean = false) => {
    const extras: NavigationExtras = {} as NavigationExtras;

    if (navigateOnActivatedRoute) {
      extras.relativeTo = this.route;
    }

    return this.router.navigate(commands, extras);
  };

  /* A function that navigates to the root route. */
  navigateToRoot = () => this.navigateTo(["/"]);

  /* A function that navigates to the previous route. */
  back = () => this.location.back();

  scrollToFirstInvalidControl() {

    const firstElementWithError = document.querySelector(".ng-invalid");

    if (firstElementWithError) {

      firstElementWithError.scrollIntoView({ behavior: "smooth" });

    }

  }

}
