<form [formGroup]="form">

  <mat-form-field>

    <mat-label>Nome</mat-label>

    <input
      matInput
      formControlName="Name" />

    <mat-error *ngIf="form.get('Name')?.hasError('required')"> * Campo obbligatorio</mat-error>

  </mat-form-field>

  <mat-form-field>

    <mat-label>Descrizione</mat-label>

    <textarea
      matInput
      formControlName="Description"
      cdkTextareaAutosize></textarea>

    <mat-error *ngIf="form.get('Description')?.hasError('required')"> * Campo obbligatorio</mat-error>

  </mat-form-field>

  <mat-form-field *ngIf="form.get('SendCategory')">

    <mat-label>Prodotto</mat-label>
    <mat-select formControlName="SendCategory">
      <mat-option *ngFor="let product of productTemplateTypes" [value]="product">
        {{ SendTypeSendCategory2LabelMapping[product] }}
      </mat-option>
    </mat-select>

    <mat-error *ngIf="form.get('SendCategory')?.hasError('required')"> * Campo obbligatorio</mat-error>

  </mat-form-field>

  <mat-form-field *ngIf="form.get('ApiCode')">

    <mat-label>Codice</mat-label>

    <input
      matInput
      formControlName="ApiCode" />

  </mat-form-field>

  <mat-checkbox [hidden]="!isOwner" formControlName="Shared">Condividi (rendi disponibile questo modello anche agli
    altri membri del tuo
    gruppo)
  </mat-checkbox>

</form>
