import { ChangeDetectorRef, Component, OnDestroy, TemplateRef, ViewChild } from "@angular/core";
import {
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CtDatatableResult,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration
} from "@ctsolution/ct-framework";
import { UserListFilters, UserListParameter } from "../../../core/classes/user-list.parameter";
import { UserController } from "../../../core/controllers/user.controller";
import { TableColumn } from "@swimlane/ngx-datatable";
import { CompanyUiSettings } from "../../../core/lib/company-ui/company.ui-settings";
import {
  CompanyUiCustomizationService,
  DEFAULT_LABEL
} from "../../../core/lib/company-ui/company.ui-customization.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { User } from "../../../core/classes/user";
import { UserDetailComponent } from "./user-detail/user-detail/user-detail.component";
import { UserDeleteComponent } from "./user-detail/user-delete/user-delete.component";
import { RoleService } from "../../../core/lib/role.service";
import { RoleType2LabelMapping } from "../../../core/enums/roles.enum";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"],
  providers: [UserController]
})
export class UserComponent implements OnDestroy {

  @ViewChild("cdcCellTemplate") public cdcCellTemplate!: TemplateRef<any>;
  @ViewChild("roleCellTemplate") public roleCellTemplate!: TemplateRef<any>;

  users!: CtDatatableConfiguration;

  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  filtersConfiguration: CtSearchFiltersConfiguration = CtSearchFiltersConfiguration.create().setFilters(UserListFilters);

  private params: UserListParameter = new UserListParameter();

  hasAdministrativeAccess: boolean = false;

  public RoleType2LabelMapping = RoleType2LabelMapping;

  constructor(
    private userController: UserController,
    private cdr: ChangeDetectorRef,
    private companyUiCustomizationService: CompanyUiCustomizationService,
    private roleService: RoleService,
    private _dialog: MatDialog) {
  }

  ngAfterViewInit() {

    this.setup();
  }

  private async setup() {

    this.hasAdministrativeAccess = await this.roleService.hasAdministrativeAccess();

    this.setTable();

  }

  /**
   * It creates a new instance of the CtDatatableConfiguration class, sets the externalPaging property to true, sets the
   * columns property to the columns array and assigns the result to the users property
   * Called in afterViewInit because needs to wait custom cell templates load
   */
  private setTable() {

    this.companyUiCustomizationService
      .getSettings()
      .then(async (company: CompanyUiSettings | undefined) => {

        const columns: TableColumn[] = [
          { prop: "UserEmail", name: "E-mail", width: 250 },
          {
            prop: "GetFullName", name: "Nome utente",
            width: 250
          },
          {
            prop: "RoleCode", name: "Ruolo",
            cellTemplate: this.roleCellTemplate,
            width: 250
          },
          {
            prop: "GroupCode",
            name: company?.getGroupLabel() ?? DEFAULT_LABEL.GROUP,
            cellTemplate: this.cdcCellTemplate,
            width: 400
          }
        ];

        this.users = CtDatatableConfiguration.create()
          .setExternalPaging(true)
          .setScrollbarH(true)
          .setRowHeight(65)
          .setColumns(columns)
          .setController('users');

        if (this.hasAdministrativeAccess) {

          this.users
            .setEditingActionEnabled(true)
            .setDeleteActionEnabled(true);
        }

        this.cdr.detectChanges();

      });

  }

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter) {

    this.users.setLoadingIndicator(true);

    this.userController
      .list(this.params, pagination)
      .then((result: CtDatatableResult<User>) => {

        this.users
          .setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

  newUser() {

    this.detail({} as User);

  }

  detail(user: User) {

    this._dialog
      .open(UserDetailComponent, {
        data: user,
        disableClose: true,
        maxWidth: 650
      }).afterClosed()
      .subscribe((result: boolean | undefined) => {

        if (result) {

          this.fetch();

        }

      });

  }

  delete(user: User) {

    if (!user.Oid) return;

    this._dialog
      .open(UserDeleteComponent, {
        data: user,
        disableClose: true,
        maxWidth: 650
      }).afterClosed()
      .subscribe((result: boolean | undefined) => {

        if (result) {

          this.userController
            .delete(user.Oid!)
            .then(() => {

              this.fetch();

            });

        }

      });

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
