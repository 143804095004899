import { Component, Input } from "@angular/core";
import { CartOrderConfiguration } from "../cart-order.configuration";
import { PriceEstimateController } from "../../../core/controllers/price-estimate.controller";
import { FlowPricingDetailResult } from "../../../core/interfaces/flow-pricing-detail-result";

@Component({
  selector: "app-estimated-price",
  templateUrl: "./estimated-price.component.html",
  styleUrls: ["./estimated-price.component.scss"],
  providers: [PriceEstimateController]
})
export class EstimatedPriceComponent {
  @Input() configuration: CartOrderConfiguration | undefined;

  constructor(private _controller: PriceEstimateController) {
  }

  ngAfterViewInit() {
    if (this.configuration?.flow.TotalAmount && this.configuration?.flow.TotalAmount > 0) return;
    this.getEstimatedPrice();
  }

  getEstimatedPrice() {
    this._controller.getFlowPriceEstimate(this.configuration?.flow.Oid!).then((result) => {
      if (result) {
        this.updateTotalPrice(result.TotalAmount);
      }
    });
  }

  private updateTotalPrice = (TotalPrice: number) => this.configuration?.flow.setTotalAmount(TotalPrice);

}
