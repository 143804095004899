import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { CtAuthenticationService, SignInParameter } from "@ctsolution/ct-authentication";
import { GeneralService } from "../../core/lib/general.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit {

  public form: UntypedFormGroup = Object.create(null);

  private redirectUrl: string | null = null;

  constructor(
    private fb: UntypedFormBuilder,
    private general: GeneralService,
    private _auth: CtAuthenticationService,
    private route: ActivatedRoute,
    private router: Router
  ) {

    if (this._auth.userValue) {

      this.general.navigateToRoot();

    } else {

      this.route
        .queryParams
        .subscribe(qp => {

          if (qp["returnUrl"]) {

            this.redirectUrl = qp["returnUrl"];

          }

        });

    }

  }

  ngOnInit(): void {

    this.form = this.fb.group({
      Email: [null, Validators.compose([Validators.required])],
      Password: [null, Validators.compose([Validators.required])],
      RememberMe: [false]
    });

  }

  onSubmit(): void {

    if (this.form.valid) {

      this._auth
        .signIn(this.form.value as SignInParameter)
        .subscribe(() => {

          if (this.redirectUrl) {

            this.router
              .navigateByUrl(this.redirectUrl);

          } else {

            this.general.navigateToRoot();

          }

        });

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

}
