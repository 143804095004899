import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { PagesRoutes } from "./pages.routing";
import { HomeModule } from "./home/home.module";
import { OrdersModule } from "./orders/orders.module";
import { DemoMaterialModule } from "../demo-material-module";
import { TrackingModule } from "./tracking/tracking.module";
import { CartModule } from "./cart/cart.module";
import { GroupModule } from "./admin/group/group.module";
import { QuotationsModule } from "./quotations/quotations.module";

@NgModule({
  imports: [
    CommonModule,
    DemoMaterialModule,
    HomeModule,
    OrdersModule,
    QuotationsModule,
    TrackingModule,
    CartModule,
    GroupModule,
    RouterModule.forChild(PagesRoutes)
  ]
})
export class PagesModule {
}
