<mat-card class="template-detail-container">

  <mat-card-content>

    <div fxLayout="row wrap">

      <div
        class="m-b-10"
        fxFlex.gt-sm="100"
        [fxFlex.gt-md]="viewModel.fakeFlow.letterStandaloneChannelSetupEnabled ? 80 : 100">

        <app-template-save-form
          #form
          *ngIf="viewModel.templateSaveFormConfiguration"
          [configuration]="viewModel.templateSaveFormConfiguration"></app-template-save-form>

      </div>

      <div
        fxFlex="100"
        fxFlex.gt-sm="100"
        [fxFlex.gt-md]="viewModel.fakeFlow.letterStandaloneChannelSetupEnabled ? 20 : 100"
        class="template-letter-specifications"
        *ngIf="letterSpecificationsEnabled">

        <app-letter-specifications
          [flow]="viewModel?.fakeFlow"
          #specifications></app-letter-specifications>

      </div>

    </div>

    <ng-container *ngIf="viewModel.fakeFlow?.smsStandaloneChannelSetupEnabled; else defaultTemplate">

      <app-sms-editor #SMS_EDITOR></app-sms-editor>

    </ng-container>
    <ng-template #defaultTemplate>

      <div class="editor-panel"
           [class]="viewModel?.fakeFlow?.SendTypeConfigurationSendTypeSendCategory">
        <app-editor #TINY_EDITOR></app-editor>
      </div>

    </ng-template>

    <mat-card-actions
      fxLayout="row"
      fxLayoutAlign="space-between center">

      <div>

        <ct-button [configuration]="cancelButton"></ct-button>

      </div>

      <div>

        <ct-button class="m-r-15" [configuration]="variableFieldsButton"></ct-button>

        <ct-button [configuration]="submitButton"></ct-button>

      </div>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
