import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CTDatatablePaginationParameter, CtDatatableResult } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { FlowRecipientListParameter } from "../classes/flow-recipient-list.parameter";
import { RecipientData } from "../interfaces/recipient-data";

@Injectable()
export class FlowRecipientController {

  private controller: string = "/FlowRecipient/";

  constructor(private _webapi: CtWebapiService) {
  }

  list(parameter: FlowRecipientListParameter, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<RecipientData>> {

    return new Promise<CtDatatableResult<RecipientData>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}FlowRecipientList`)
        .setQueryParams({
          ...parameter,
          ...pagination
        });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<CtDatatableResult<RecipientData>>) => resolve(r.Result));

    });

  }

  export(parameter: FlowRecipientListParameter) {

    const request: DataRequest = new DataRequest(`${this.controller}FlowRecipientExport`)
      .setQueryParams(parameter)
      .setResponseType("blob");

    this._webapi
      .get(request)
      .subscribe((result: any) => {

        const blob = new Blob([result], { type: "text/csv" });
        const fileURL = URL.createObjectURL(blob);

        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = `PP_Tracking${new Date().getTime()}`;
        fileLink.click();

      });

  }

}
