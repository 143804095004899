import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { CartOrderConfiguration } from "./cart-order.configuration";
import { FlowController } from "../../core/controllers/flow.controller";
import { Flow } from "../../core/classes/flow";
import { FlowDetailRequest } from "../../core/classes/flow-detail-request";

@Component({
  selector: "app-cart-order",
  templateUrl: "./cart-order.component.html",
  styleUrls: ["./cart-order.component.scss"],
  providers: [FlowController]
})
export class CartOrderComponent {

  @Input() configuration: CartOrderConfiguration = CartOrderConfiguration.create();
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(private flowController: FlowController) {
  }

  onOpen() {

    if (!this.configuration.flowData) {

      const request: FlowDetailRequest = new FlowDetailRequest(this.configuration.flow.Oid);

      this.flowController
        .detail(request)
        .then((result: Flow) => {

          this.configuration
            .setFlowData(result);

        });

    }

  }

}
