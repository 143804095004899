import { IValidationResult } from '../../../../core/interfaces/address-validation';
import { FlowRecipientValidation } from "../../../../core/classes/flow-recipient-validation";

export class AddressValidatorCache {
  results: AddressValidatorCacheObject[] = [];

  constructor() {}

  save = (parameter: FlowRecipientValidation, validation: IValidationResult) =>
    this.results.push(new AddressValidatorCacheObject(JSON.stringify(parameter), validation));

  get = (parameter: FlowRecipientValidation): IValidationResult | undefined =>
    this.results.find((e) => e.key === JSON.stringify(parameter))?.value;
}

class AddressValidatorCacheObject {
  constructor(public key: string, public value: IValidationResult) {}
}
