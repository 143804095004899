import { Component, ViewChild } from "@angular/core";
import { MatLegacyDialog, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { CtFileUploaderComponent, CtFileUploaderConfiguration, SnackbarService } from "@ctsolution/ct-framework";
import { Validators } from "@angular/forms";
import { TemplateController } from "../../../../core/controllers/template.controller";
import {
  ImportGlobalVariableFieldsSaveParameter
} from "../../../../core/classes/import-global-variable-fields-save.parameter";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import {
  GlobalVariableFieldsImportOutputComponent
} from "./global-variable-fields-import-output/global-variable-fields-import-output.component";

@Component({
  selector: "app-global-variable-fields-import",
  templateUrl: "./global-variable-fields-import.component.html"
})
export class GlobalVariableFieldsImportComponent {

  @ViewChild("Uploader") fileUploader!: CtFileUploaderComponent;
  configuration: CtFileUploaderConfiguration = CtFileUploaderConfiguration
    .create()
    .setTitle(null)
    .setLimit(1)
    .setAcceptedFormats([
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"
    ])
    .setControlValidators(Validators.required);

  createIfNotExist: boolean = false;

  constructor(
    private dialogRef: MatLegacyDialogRef<GlobalVariableFieldsImportComponent>,
    private templateController: TemplateController,
    private dialog: MatLegacyDialog) {
  }

  cancel() {

    this.dialogRef
      .close(null);

  }

  submit() {

    if (this.fileUploader.configuration.control.invalid) return;

    const files = this.fileUploader
      .configuration
      .control
      .value ?? [];

    const file = files[0] ?? null;

    if (!file) return;

    const parameter = ImportGlobalVariableFieldsSaveParameter
      .create()
      .setFile(file)
      .setCreateIfNotExist(this.createIfNotExist);

    this.templateController
      .importGlobalVariableFields(parameter)
      .subscribe((response: CtWebapiGenericResponse<string | null>) => {


        this.dialogRef
          .afterClosed()
          .subscribe(() => {

            if (response.Result) {

              this.dialog
                .open(GlobalVariableFieldsImportOutputComponent, { data: response.Result });

            }

          });

        this.dialogRef
          .close();

      });

  }

  downloadTemplate() {

    let link = document.createElement("a");
    link.setAttribute("type", "hidden");
    link.href = "/assets/templates/polipush-global-variable-fields-template.xlsx";
    link.download = `polipush-global-variable-fields-template`;
    document.body.appendChild(link);
    link.click();
    link.remove();

  }

}
