import { Routes } from "@angular/router";

import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgottenComponent } from "./forgotten/forgotten.component";

export const AuthenticationRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "accesso",
        component: LoginComponent
      },
      {
        path: "registrazione",
        component: RegisterComponent
      },
      {
        path: "recupero-password",
        component: ForgottenComponent
      },
      {
        path: "selezione-compagnia",
        loadChildren: () => import("../authentication/company-selection/company-selection.module").then(m => m.CompanySelectionModule)
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "accesso"
      }
    ]
  }
];
