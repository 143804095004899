import { AddressData } from "./address-data";

export class GroupSender extends AddressData {
  constructor(public AutoFillSenderData: boolean, AddressData: AddressData) {
    super(
      AddressData.Name,
      AddressData.Address,
      AddressData.ZipCode,
      AddressData.City,
      AddressData.Province,
      AddressData.Country,
      AddressData.Email,
      AddressData.Phone
    );
  }
}
