import { Component, Input } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { productTemplateTypes } from "src/app/core/classes/template-list.parameter";
import {
  SendTypeSendCategory2LabelMapping,
  SendTypeSendCategoryEnum
} from "src/app/core/interfaces/send-type-configuration";
import { TemplateManagerConfiguration } from "../../template-manager.configuration";
import { JwtService } from "../../../../../core/lib/jwt.service";

export interface TemplateSaveForm {

  Name: string | null;
  Description: string | null;
  Shared: boolean;
  SendCategory?: SendTypeSendCategoryEnum | null;
  ApiCode: string | null;

}

@Component({
  selector: "app-template-save-form",
  templateUrl: "./template-save-form.component.html",
  styles: [`::ng-deep .mat-checkbox-layout {
    white-space: normal !important;
  }
  `]
})
export class TemplateSaveFormComponent {

  form: FormGroup;
  isOwner: boolean = false;

  @Input() configuration: TemplateManagerConfiguration | null = null;

  constructor(
    private jwt: JwtService,
    private fb: FormBuilder) {

    this.form = this.fb.group({
      Name: new FormControl(null, Validators.required),
      Description: new FormControl(null, Validators.required),
      Shared: new FormControl(false)
    });

  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    if (!this.configuration) return;

    const userInfoOid = await this.jwt.getUserInfoOid();

    this.isOwner = !this.configuration.parameter?.UserInfoOid || userInfoOid === this.configuration.parameter?.UserInfoOid;

    if (!this.configuration.parameter?.SendCategory) {

      this.form
        .addControl("SendCategory", new FormControl(null, Validators.required));

    }

    const advancedTemplateManagementEnabled = await this.jwt.getAdvancedTemplateManagement();

    if (advancedTemplateManagementEnabled) {

      this.form
        .addControl("ApiCode", new FormControl(null));

    }

    this.form.patchValue(<any>this.configuration.parameter);

  }

  protected readonly SendTypeSendCategory2LabelMapping = SendTypeSendCategory2LabelMapping;
  protected readonly productTemplateTypes = productTemplateTypes;

}
