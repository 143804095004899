import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormControl,
} from '@angular/forms';
import { GeneralService } from "../../core/lib/general.service";

const password = new UntypedFormControl('', Validators.required);

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent implements OnInit {
  public form: UntypedFormGroup = Object.create(null);

  constructor(private fb: UntypedFormBuilder, private _general: GeneralService) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required])],
      // tslint:disable-next-line - Disables all
      password: password,
      // tslint:disable-next-line - Disables all
      confirmPassword: password,
    });
  }

  onSubmit(): void {
    this._general.navigateToRoot();
  }
}
