import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PersonSpecificationsComponent } from './person-specifications.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { AddressValidatorModule } from '../../address-validator/address-validator.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ValidationMarkModule } from './validation-mark/validation-mark.module';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';

@NgModule({
  declarations: [PersonSpecificationsComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AddressValidatorModule,
    MatAutocompleteModule,
    FlexLayoutModule,
    MatCardModule,
    ValidationMarkModule,
  ],
  exports: [PersonSpecificationsComponent],
})
export class PersonSpecificationsModule {}
