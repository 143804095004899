import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ProductSelectComponent } from "./product-select.component";
import { CtControlModule } from "@ctsolution/ct-framework";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [ProductSelectComponent],
  imports: [CommonModule, CtControlModule, ReactiveFormsModule],
  exports: [ProductSelectComponent]
})
export class ProductSelectModule {
}
