<div class="step3-cntr">

  <ng-container *ngIf="flowService?.activeFlow?.smsStandaloneChannelSetupEnabled; else defaultTemplate">

    <app-sms-editor #SMS_EDITOR></app-sms-editor>

  </ng-container>
  <ng-template #defaultTemplate>

    <mat-tab-group
      [hidden]="!viewModel?.editorEnabled ||flowService?.activeFlow?.emailStandaloneChannelSetupEnabled "
      dynamicHeight
      mat-stretch-tabs
      [(selectedIndex)]="viewModel.selectedTabIndex"
      (selectedTabChange)="onTabChange()">

      <mat-tab>

        <ng-template mat-tab-label>
          <mat-icon>upload_file</mat-icon>
          Caricamento File
        </ng-template>

      </mat-tab>

      <mat-tab>

        <ng-template mat-tab-label>
          <mat-icon>draw</mat-icon>
          Composizione online
        </ng-template>

      </mat-tab>

    </mat-tab-group>

    <div
      [@expandCollapse]="isEditorTabActive ? 'expanded' : 'collapsed'"
      class="editor-panel"
      [class]="flowService?.activeFlow?.SendTypeConfigurationSendTypeSendCategory">
      <app-editor #TINY_EDITOR></app-editor>
    </div>

    <app-flow-file-uploader
      *ngIf="fileUploaderContainerConfiguration?.ctFileUploaderConfiguration"
      #FILE_UPLOADER
      [configuration]="fileUploaderContainerConfiguration"
      (onFlowFilesUpdate)="refreshUploaderFilesAfterFileDelete($event)">
    </app-flow-file-uploader>

  </ng-template>

</div>
