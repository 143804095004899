<mat-action-row>

  <ng-container
    *ngIf="configuration.flow.Type === FLOW_TYPE.SINGLE_RECIPIENT">

    <button mat-button (click)="download()">
      Anteprima
      <mat-icon>file_download</mat-icon>
    </button>

  </ng-container>

  <ng-container *ngIf="configuration.flow.digitalSendingStandaloneChannelSetupEnabled">

    <app-cart-file-list-download-button [data]="configuration.flowData"></app-cart-file-list-download-button>

  </ng-container>

  <span fxFlex></span>

  <ng-container *ngIf="configuration.trackingRedirectEnabled">

    <button
      mat-button color="primary" (click)="tracking()">Tracking
    </button>

  </ng-container>

  <button
    cdkFocusInitial
    mat-button color="warn" (click)="cancel()">
    {{ cancelButtonLabel() }}
  </button>

  <ng-container *ngIf="!configuration.hasDialogCallback">
    <button
      mat-button
      color="primary"
      [disabled]="configuration.estimatedPriceManagementEnabled && !hasAvailableTotalPrice"
      (click)="accept()"
    >
      {{ acceptButtonLabel() }}
    </button>
  </ng-container>
</mat-action-row>
