import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-send-flow-alert',
  templateUrl: './send-flow-alert.component.html',
  styleUrls: ['./send-flow-alert.component.scss'],
})
export class SendFlowAlertComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
