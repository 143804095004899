import { CTBase } from "@ctsolution/ct-base";

export class TemplateVariableField extends CTBase<TemplateVariableField> {

  Name: string | null = null;
  Mandatory: boolean = false;
  DefaultValue: string | null = null;

  private constructor();
  private constructor(model?: TemplateVariableField);
  private constructor(model?: TemplateVariableField) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: TemplateVariableField) => new TemplateVariableField(model);

  setName(value: string | null) {

    this.Name = value;
    return this;

  }

  setMandatory(value: boolean) {

    this.Mandatory = value;
    return this;

  }

  setDefaultValue(value: string | null) {

    this.DefaultValue = value;
    return this;

  }

}
