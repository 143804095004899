<div fxLayout="row wrap" class="send-flow-cntr">
  <div fxFlex.gt-sm="100%" fxFlex="100">

    <mat-card>

      <mat-card-content>

        <mat-stepper
          #Stepper
          [linear]="true"
          [orientation]="mobileQuery.matches ? 'horizontal' : 'vertical'">
          <!--#region Mat Stepper Icons -->

          <ng-template matStepperIcon="firstStep">
            <mat-icon>ads_click</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="secondStep">
            <mat-icon>edit_note</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="thirdStep">
            <mat-icon>send_and_archive</mat-icon>
          </ng-template>

          <ng-template matStepperIcon="fourthStep">
            <mat-icon>task_alt</mat-icon>
          </ng-template>

          <!--#endregion -->

          <mat-step state="firstStep">

            <ng-template matStepLabel> Selezione prodotto</ng-template>

            <app-step1 #STEP_ONE (onSuccess)="updateProgress($event)"></app-step1>

          </mat-step>

          <mat-step state="secondStep">

            <ng-template matStepLabel> Specifiche</ng-template>

            <app-step2 #STEP_TWO (onSuccess)="updateProgress($event)"></app-step2>

          </mat-step>

          <mat-step state="thirdStep">

            <ng-template matStepLabel> File Upload</ng-template>

            <app-step3 #STEP_THREE (onSuccess)="updateProgress($event)"></app-step3>

          </mat-step>

          <mat-step state="fourthStep">

            <ng-template matStepLabel> Completa ordine</ng-template>

            <app-step4 #STEP_FOUR (onSuccess)="updateProgress($event)"></app-step4>

          </mat-step>

        </mat-stepper>

        <app-stepper-actions
          [currentIndex]="Stepper.selectedIndex"
          (back)="back()"
          (next)="next()"></app-stepper-actions>

      </mat-card-content>

    </mat-card>

  </div>

</div>
