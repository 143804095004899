import { Injectable } from "@angular/core";
import { CTDatatablePaginationParameter } from "@ctsolution/ct-framework";

@Injectable()
export class ListService {

  constructor() {
  }

  getFilteredData<T>(list: Array<T>, params: any = {}, pagination?: CTDatatablePaginationParameter) {

    const pageIndex = pagination?.Page ? pagination.Page - 1 : 0;
    const pageSize = pagination?.ElementsForPage || 10;

    const startIndex = pageIndex * pageSize;
    const endIndex = startIndex + pageSize;

    const filteredData = list
      .filter(current => {

        for (const key in params) {

          const paramValue = (<any>params)[key]?.toLowerCase();
          const userValue = (<any>current)[key]?.toLowerCase();

          if (paramValue && !userValue) return false;

          if (paramValue && userValue && !userValue.includes(paramValue)) return false;

        }

        return true;

      });

    return filteredData.slice(startIndex, endIndex);

  }

}
