<div class="d-flex space-between">

  <h1 mat-dialog-title>Attenzione</h1>
  <button mat-icon-button color="warn" mat-dialog-close>
    <mat-icon>close</mat-icon>
  </button>

</div>

<div mat-dialog-content>
  <p class="text-center">
    Proseguendo cancellerai l'ultima spedizione in composizione. <br />
    Sei sicuro di voler proseguire?
  </p>
</div>

<div mat-dialog-actions class="d-flex space-between">
  <button mat-raised-button color="warn" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
    Annulla spedizione
  </button>

  <button mat-raised-button color="primary" [mat-dialog-close]="false">
    Continua spedizione
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
