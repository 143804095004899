<mat-card>
  <mat-card-header>
    <mat-card-title-group>
      <mat-card-title>Impostazioni UI</mat-card-title>
      <mat-card-subtitle>Gestisci le impostazioni grafiche del portale</mat-card-subtitle>
    </mat-card-title-group>
  </mat-card-header>
  <mat-card-content>

    <div>

      <mat-slide-toggle>Dark Mode</mat-slide-toggle>

    </div>

  </mat-card-content>
</mat-card>
