<mat-card class="m-b-15 sms-editor-cntr">

  <mat-card-header>
    <div mat-card-avatar class="d-flex">
      <mat-icon class="m-auto" color="primary"> sms</mat-icon>
    </div>
    <mat-card-subtitle><p>Scrivi nel paragrafo sottostante la comunicazione da spedire</p></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <div class="m-b-10" align="end" [hidden]="!dynamicFieldsList.length">

      <button mat-stroked-button [matMenuTriggerFor]="menu">Campi dinamici
      </button>
      <mat-menu #menu="matMenu">
        <button
          mat-menu-item
          *ngFor="let field of dynamicFieldsList"
          (click)="insertField(field)"> {{ field }}
        </button>
      </mat-menu>

      <ng-container *ngIf="viewModel.templateManagementEnabled">

        <button mat-stroked-button class="m-l-15" [matMenuTriggerFor]="templatesMenu">Modelli
        </button>
        <mat-menu #templatesMenu="matMenu">

          <button mat-menu-item (click)="templatesList()">I tuoi modelli</button>
          <button mat-menu-item (click)="templateSave()">Salva modello</button>

        </mat-menu>

      </ng-container>

    </div>

    <div>

      <mat-form-field appearance="fill">
        <mat-label>Messaggio</mat-label>
        <textarea
          matInput
          [formControl]="control"
          cdkTextareaAutosize
          cdkAutosizeMinRows="8"></textarea>

        <mat-hint align="end">{{ control.value?.length || 0 }}/{{ viewModel.maxLength }}</mat-hint>

        <mat-error *ngIf="control?.hasError('required')"> * Campo obbligatorio</mat-error>
        <mat-error *ngIf="control?.hasError('maxlength')"> * Campo non valido</mat-error>

      </mat-form-field>

    </div>

  </mat-card-content>

</mat-card>
