import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendFlowAlertComponent } from './send-flow-alert.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
  declarations: [SendFlowAlertComponent],
  imports: [CommonModule, MatDialogModule, MatButtonModule, MatIconModule],
})
export class SendFlowAlertModule {}
