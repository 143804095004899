import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { debounceTime, Observable, startWith } from "rxjs";
import { distinctUntilChanged, map } from "rxjs/operators";
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { Province } from "../interfaces/province";
import { Municipality } from "../interfaces/municipality";
import { PersonSpecificationsControls } from "../interfaces/person-specifications.controls";

const CountriesJsonPath: string = "/assets/json/countries.json";
const ProvincesJsonPath: string = "/assets/json/provinces.json";
const MunicipalitiesJsonPath: string = "/assets/json/municipalities.json";

function numericCapValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const cap = control.value;

    if (cap && isNaN(Number(cap))) {
      return { numericOnly: true, message: "Il CAP deve contenere solo valori numerici." };
    }

    return null;
  };
}

export const zipCodeValidators = () => [Validators.required, Validators.maxLength(10)];
export const localeZipCodeValidators = () => [...zipCodeValidators(), Validators.minLength(5), numericCapValidator()];
export const provinceValidators = () => [Validators.required, Validators.maxLength(2), Validators.minLength(2)];

@Injectable()
export class LocalizationService {

  countries: string[] | undefined = [];
  private provinces: Province[] | undefined = [];
  private municipalities: Municipality[] | undefined = [];

  filteredZipCodes: Observable<string[]> | undefined;
  filteredCities: Observable<string[]> | undefined;
  filteredProvinces: Observable<string[]> | undefined;
  filteredCountries: Observable<string[]> | undefined;

  constructor(private http: HttpClient) {
  }

  async loadConfig(controls?: PersonSpecificationsControls | undefined): Promise<void> {

    this.countries = await this.http.get<string[] | undefined>(CountriesJsonPath).toPromise();
    this.provinces = await this.http.get<Province[] | undefined>(ProvincesJsonPath).toPromise();
    this.municipalities = await this.http.get<Municipality[] | undefined>(MunicipalitiesJsonPath).toPromise();

    if (controls) {

      this.setupControls(controls);

    }

  }

  private setupControls(controls: PersonSpecificationsControls) {

    if (controls.ZipCodeControl) {

      let uniqueCaps = (this.municipalities ?? [])
        .reduce((accumulator: string[], element) => accumulator.concat(element.cap), [])
        .filter((cap, index, self) => self.indexOf(cap) === index)
        .sort((prev, current) => prev.localeCompare(current));

      this.filteredZipCodes = this.setupFilter(controls.ZipCodeControl, uniqueCaps);

    }

    if (controls.CityControl) {

      this.filteredCities = this.setupFilter(controls.CityControl, (this.municipalities ?? []).map(element => element.nome));

    }

    if (controls.ProvinceControl) {

      this.filteredProvinces = this.setupFilter(controls.ProvinceControl, (this.provinces ?? []).map(element => element.sigla));

    }

    if (controls.CountriesControl) {

      this.filteredCountries = this.setupFilter(controls.CountriesControl, (this.countries ?? []));

    }

  }

  private _filter(name: string, container: string[]): string[] {

    const filterValue = name.toLowerCase();
    return container.filter((option: string) => option.toLowerCase().includes(filterValue));

  }

  private _filterMapping(value: string, container: string[]) {

    const name = value;
    return name ? this._filter(name as string, container) : container.slice(0, 10);

  }

  setupFilter(control: AbstractControl<any, any> | null, data: string[]) {

    return control
      ?.valueChanges
      .pipe(
        startWith(""),
        debounceTime(400),
        distinctUntilChanged(),
        map(value => this._filterMapping(value, data)));

  }

  autocompleteStringValidator(validOptions: Array<string>): ValidatorFn {

    return (control: AbstractControl): { [key: string]: any } | null => {
      if (validOptions.indexOf(control.value) !== -1) {
        return null;  /* valid option selected */
      }
      return { "invalidAutocompleteString": { value: control.value } };
    };

  }

}
