import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartTotalComponent } from './cart-total.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FlexLayoutModule } from "@angular/flex-layout";

@NgModule({
  declarations: [CartTotalComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, MatButtonModule, FlexLayoutModule],
  exports: [CartTotalComponent],
})
export class CartTotalModule {}
