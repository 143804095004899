import { Injectable } from "@angular/core";
import { VariableFieldsManagerComponent } from "./variable-fields-manager.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { TemplateVariableField } from "../../../../core/classes/template-variable-field";

@Injectable()
export class VariableFieldsManagerService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(value: TemplateVariableField[]) {

    return this.dialog
      .open(VariableFieldsManagerComponent, { width: "90vw", maxWidth: "900px", data: value });

  }

  extractVariableFields(text: string): TemplateVariableField[] {

    const regex = /\{\{\{([^{}]+?)\}\}\}/g;

    const result: TemplateVariableField[] = [];
    let match;

    while ((match = regex.exec(text)) !== null) {

      const campoVariabile = match[1].trim();

      if (!result.some((field) => field.Name === campoVariabile)) {
        result.push(
          TemplateVariableField.create().setName(campoVariabile)
        );
      }

    }

    return result;
  }

}
