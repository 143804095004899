import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import {
  SmartDeliveryConfigurationController
} from "../../../../core/controllers/smart-delivery-configuration.controller";
import { SnackbarService } from "@ctsolution/ct-framework";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { CtFileManagementService } from "@ctsolution/ct-file-management";

@Component({
  selector: "app-get-smart-delivery-file",
  templateUrl: "./get-smart-delivery-file.component.html",
  providers: [SmartDeliveryConfigurationController],
  styles: [
    `.smart-delivery-file-container {
      .blank-container-box {
        max-width: 450px !important;
      }
    }`
  ]
})
export class GetSmartDeliveryFileComponent {

  viewModel: any = {
    id: null
  };

  constructor(
    private router: Router,
    private _snackbar: SnackbarService,
    public _auth: CtAuthenticationService,
    private smartDeliveryConfigurationController: SmartDeliveryConfigurationController,
    private fileManager: CtFileManagementService,
    private route: ActivatedRoute) {

    this.route
      .queryParams
      .subscribe(
        qp => {

          setTimeout(async () => { // just to simulate delay

            const { id } = qp;

            this.viewModel.id = id;

            await this.download();

          }, 1500);
        }
      );

  }

  async download(id: string | null = this.viewModel.id) {

    if (!id) this.errorSnackbar();

    try {

      const response = await this.smartDeliveryConfigurationController.getRowFile(<string>id).toPromise();

      const fileName = this.fileManager.getFileNameFromResponseContentDisposition(response);

      const fileURL = URL.createObjectURL(response.body);
      const fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.download = fileName ?? "attachment";
      fileLink.click();

    } catch (error) {

      // this.errorSnackbar();

    }

  }

  errorSnackbar() {

    const reference = this._snackbar.open("An error occurred while file fetching. Try again.", "OK", 3500);

    reference.afterOpened().subscribe(() => this.router.navigate(["/"]));

  }

}
