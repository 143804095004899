import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { CartPersonConfiguration } from "./cart-person.configuration";
import { RecipientData } from "../../../../core/interfaces/recipient-data";
import {
  PERSON_SPECIFICATION_TYPE
} from "../../../../pages/shipment/send-flow/step2/person-specifications/person-specifications.configuration";

@Component({
  selector: "app-cart-person-specifications",
  templateUrl: "./cart-person-specifications.component.html",
  styleUrls: ["./cart-person-specifications.component.css"]
})
export class CartPersonSpecificationsComponent {

  @Input() configuration?: CartPersonConfiguration;
  user?: RecipientData | null;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    switch (this.configuration?.personType) {

      case PERSON_SPECIFICATION_TYPE.SENDER:

        if ((this.configuration?.flow?.FlowSenders ?? []).length) {

          this.user = <RecipientData>this.configuration!.flow!.FlowSenders![0];

        }

        break;

      case PERSON_SPECIFICATION_TYPE.RECIPIENT:

        if ((this.configuration?.flow?.Recipients ?? []).length) {

          this.user = this.configuration!.flow!.Recipients![0];

        }

        break;

    }

    this.cdr.detectChanges();

  }

}
