import { Injectable } from "@angular/core";
import { GroupBudget } from "../classes/group-budget";
import { Group } from "../interfaces/group";

@Injectable({
  providedIn: "root"
})
export class GroupService {

  constructor() {
  }

  getBudgetResidueLabel(userGroup: Group): string {

    if (userGroup.InfiniteBudget) {

      return "ILLIMITATO";

    }

    let currentGroupBudget: GroupBudget | null | undefined = null;

    if (userGroup.BudgetList && userGroup.BudgetList.length > 0) {

      currentGroupBudget = userGroup.BudgetList.find((budget: GroupBudget) => budget.Year === new Date().getFullYear());

      return (currentGroupBudget ? Math.round((currentGroupBudget.Amount ?? 0) - (currentGroupBudget.Expense ?? 0)) : 0).toString();

    }

    return "NON DISPONIBILE";

  };

}
