<div class="product-list-container text-center" [class.d-flex]="loadingList">

  <ng-container
    *ctSkeleton="loadingList;
           repeat: 3;
           className: 'product-card-skeleton';">

    <ng-container *ngIf="SEND_PRODUCTS.length > 0; else noProductAvailable">

      <div fxLayout="row wrap">

        <app-product-card
          fxFlex="100"
          [fxFlex.gt-sm]="100"
          [fxFlex.gt-md]="LETTER_PRODUCT_CARD_MD_FLEX"
          *ngFor="let product of LETTER_PRODUCTS"
          [product]="product"
          [selected]="control?.value === product"
          [invalid]="control.touched && control.invalid"
          (onSelect)="setValue($event)"
        ></app-product-card>

      </div>

      <ng-container *ngIf="DIGITAL_PRODUCTS.length">

        <div fxLayout="row wrap">

          <app-product-card
            fxFlex="100"
            [fxFlex.gt-md]="DIGITAL_PRODUCT_CARD_MD_FLEX"
            *ngFor="let product of DIGITAL_PRODUCTS"
            [product]="product"
            [selected]="control?.value === product"
            [invalid]="control.touched && control.invalid"
            (onSelect)="setValue($event)"
          ></app-product-card>

        </div>

      </ng-container>

    </ng-container>

    <ng-template #noProductAvailable>

      <p class="text-center"> Non ci sono prodotti attivi per la tua utenza. </p>

    </ng-template>

  </ng-container>

  <ng-container *ngIf="control.touched && control.invalid">

    <p><small style="color: red">* Selezionare un prodotto</small></p>

  </ng-container>

</div>
