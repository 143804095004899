<form [formGroup]="form" fxLayout="row wrap" class="group-data-cntr">
  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <section>
      <mat-checkbox class="checkbox-margin" formControlName="AutoFillSenderData">
        Compila automaticamente i dati del mittente
      </mat-checkbox>
    </section>
  </div>

  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <mat-divider></mat-divider>
  </div>

  <app-person-specifications
    [configuration]="personSpecificationsComponentConfiguration"
    #AddressData></app-person-specifications>

</form>
