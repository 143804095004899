import { FlowStep } from "./flow-step";
import { AddressData } from "../address-data";
import { COLOR_MODE } from "../../enums/color-mode.enum";
import { PRINT_MODE } from "../../enums/print-mode.enum";
import { RecipientAddress } from "../recipient-address";
import { FlowSendAreaEnum } from "../../enums/flow-send-area.enum";

export class FlowStep2 extends FlowStep {

  SingleRecipientData?: RecipientAddress;
  RecipientFile?: File;
  AutoCorrectValidationResult?: boolean;

  constructor(
    public SendArea: FlowSendAreaEnum,
    public SenderData: AddressData,
    public ColorMode: COLOR_MODE | null = null,
    public PrintMode: PRINT_MODE | null = null,
    public SendSubject: string | null = null
  ) {

    super();

  }

}
