import { CtSearchFilterControlConfiguration, SEARCH_FILTER_CONTROL_TYPE } from "@ctsolution/ct-framework";
import { CompanyCodeFilter, GroupCodeFilter } from "./flow-common.parameter";

export class GroupListParameter {

  CompanyCode?: string;
  Code?: string;
  Name?: string;
  Active?: boolean;
  ExpenseYear?: number;

  OrderByField?: "Expense" | "Name" | "Code" | any; // name by default
  OrderByDescending?: boolean; // true se decrescente

  constructor() {
  }

}

export const NameFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("Name")
    .setLabel("Nome");

export const ActiveFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("Active")
    .setType(SEARCH_FILTER_CONTROL_TYPE.ENUMERABLE)
    .setValues([
      { key: "Attivo", value: true },
      { key: "Non attivo", value: false }
    ])
    .setLabel("Stato");

export const GroupListFilters: CtSearchFilterControlConfiguration[] = [GroupCodeFilter, NameFilter, ActiveFilter];
