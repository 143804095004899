import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GroupModule } from "./group/group.module";
import { UserModule } from "./user/user.module";
import { RouterModule } from "@angular/router";
import { GroupComponent } from "./group/group.component";
import { UserComponent } from "./user/user.component";
import { DEFAULT_LABEL } from "../../core/lib/company-ui/company.ui-customization.service";
import { SettingsModule } from "./settings/settings.module";
import { SettingsComponent } from "./settings/settings/settings.component";
import { TemplateModule } from "./template/template.module";
import { SmartDeliveryModule } from "./smart-delivery/smart-delivery.module";

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    GroupModule,
    UserModule,
    SettingsModule,
    TemplateModule,
    RouterModule.forChild([
      {
        path: DEFAULT_LABEL.GROUPS.toLowerCase(),
        component: GroupComponent,
        data: {
          title: DEFAULT_LABEL.GROUPS,
          description: `Da qui puoi gestire i tuoi ${DEFAULT_LABEL.GROUPS.toLowerCase()}, assegnare il loro budget annuale e vedere il loro budget già impegnato`,
          urls: [
            {
              title: DEFAULT_LABEL.GROUPS,
              url: `/amministrazione/${DEFAULT_LABEL.GROUPS.toLowerCase()}`
            },
            { title: DEFAULT_LABEL.GROUPS }
          ]
        }
      },
      {
        path: "centri-di-costo",
        component: GroupComponent,
        data: {
          title: "Centri di Costo",
          description:
            "Da qui puoi gestire i tuoi centri di costo, assegnare il loro budget annuale e vedere il loro budget già impegnato",
          urls: [
            { title: "Centri di Costo", url: "/amministrazione/centri-di-costo" },
            { title: "Centri di Costo" }
          ]
        }
      },
      {
        path: "utenti",
        component: UserComponent,
        data: {
          title: "Utenti",
          description: "Da qui puoi gestire i tuoi utenti, assegnarli ad un gruppo e reimpostare la loro password",
          urls: [{ title: "Utenti", url: "/amministrazione/utenti" }, { title: "Utenti" }]
        }
      },
      {
        path: "impostazioni",
        component: SettingsComponent,
        data: {
          title: "Impostazioni",
          description: "Da qui puoi gestire le impostazioni del tuo profilo",
          urls: [{ title: "Impostazioni", url: "/amministrazione/impostazioni" }, { title: "Impostazioni" }]
        }
      },
      {
        path: "modelli",
        loadChildren: () => TemplateModule
      },
      {
        path: "smart-delivery",
        loadChildren: () => SmartDeliveryModule
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard"
      }
    ])
  ]
})
export class AdminModule {
}
