import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CTDatatablePaginationParameter, CtDatatableResult } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { Flow } from "../classes/flow";
import { FlowListParameter } from "../classes/flow-list.parameter";
import { FlowDetailRequest } from "../classes/flow-detail-request";
import { FlowPurchaseRequest } from "../classes/flow-purchase-request";
import { FLOW_STATE } from "../enums/flow-state";
import { PurchaseService } from "../lib/purchase.service";

@Injectable({
  providedIn: "root"
})
export class FlowController {

  private controller: string = "/Flow/";

  constructor(private _webapi: CtWebapiService, private purchaseService: PurchaseService) {
  }

  list(parameter: FlowListParameter, pagination?: CTDatatablePaginationParameter): Promise<CtDatatableResult<Flow>> {

    return new Promise<CtDatatableResult<Flow>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}FlowList`, {
        ...parameter,
        ...(pagination ?? {})
      });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<CtDatatableResult<Flow>>) => resolve(r.Result));

    });

  }

  detail(request: FlowDetailRequest): Promise<Flow> {

    return new Promise<Flow>((resolve) => {
      this._webapi
        .post(new DataRequest(`${this.controller}FlowDetail`), request)
        .subscribe((r: CtWebapiGenericResponse<Flow>) => resolve(r.Result));
    });

  }

  deleteDraft(): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}FlowDraftDelete`);

      this._webapi
        .delete(request)
        .subscribe(() => resolve(true));

    });

  }

  delete(flowOid: number): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      this._webapi
        .delete(new DataRequest(`${this.controller}FlowDelete`, { flowOid }))
        .subscribe(() => resolve(true));

    });

  }

  purchase(request: FlowPurchaseRequest): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      this._webapi
        .post(new DataRequest(`${this.controller}Purchase`), request)
        .subscribe((response: CtWebapiGenericResponse<any>) => {

          const errorsOccurred: boolean = response.Result.Errors && response.Result.Errors.length > 0;

          if (errorsOccurred) {

            this.purchaseService.purchaseErrorsDialog(response.Result.Errors);

          }

          resolve(!errorsOccurred);

        });

    });

  }

  flowCount(flowStates: FLOW_STATE[]): Promise<number> {

    return new Promise<number>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}FlowCount`, { flowStates });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<number>) => resolve(r.Result ?? 0));

    });

  }

}
