import { TemplateParameter } from "../../../core/classes/template-parameter";

export class TemplateManagerConfiguration {

  parameter: TemplateParameter | null = null;

  constructor() {
  }

  public static create = (): TemplateManagerConfiguration => new TemplateManagerConfiguration();

  setParameter(value: TemplateParameter | null) {

    this.parameter = value;
    return this;

  }

}
