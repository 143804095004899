import { Component, Input, OnInit } from "@angular/core";
import { Flow } from "../../../../core/classes/flow";
import { PRINT_MODE } from "../../../../core/enums/print-mode.enum";
import { COLOR_MODE } from "../../../../core/enums/color-mode.enum";
import {
  CompanyUiCustomizationService,
  DEFAULT_LABEL
} from "../../../../core/lib/company-ui/company.ui-customization.service";
import { CompanyUiSettings } from "../../../../core/lib/company-ui/company.ui-settings";
import { TableColumn } from "@swimlane/ngx-datatable";

@Component({
  selector: "app-cart-product-specifications",
  templateUrl: "./cart-product-specifications.component.html",
  styleUrls: ["./cart-product-specifications.component.css"]
})
export class CartProductSpecificationsComponent implements OnInit {

  @Input() data!: Flow;

  viewModel = {

    groupLabel: DEFAULT_LABEL.GROUP

  };

  constructor(private companyUiCustomizationService: CompanyUiCustomizationService) {
  }

  ngOnInit() {

    this.companyUiCustomizationService
      .getSettings()
      .then((company: CompanyUiSettings | undefined) => {

        this.viewModel.groupLabel = company?.getGroupLabel() ?? DEFAULT_LABEL.GROUP;

      });

  }

  getPrintOption(): string {

    switch (this.data.PrintMode) {

      case PRINT_MODE.FRONT:
        return "Solo fronte";
      case PRINT_MODE.FRONT_BACK:
        return "Fronte/Retro";
      default:
        return "No option available";

    }

  }

  getColorOption(): string {

    switch (this.data.ColorMode) {

      case COLOR_MODE.COLOR:
        return "A colori";
      case COLOR_MODE.BW:
        return "Bianco e nero";
      default:
        return "No option available";

    }

  }

}
