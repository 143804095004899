import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { PersonSpecificationsModule } from './person-specifications/person-specifications.module';
import { LetterSpecificationsModule } from './letter-specifications/letter-specifications.module';
import { RecipientListUploadModule } from './recipient-list-upload/recipient-list-upload.module';
import { Step2Component } from './step2.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DigitalChannelDescriptionModule } from "./digital-channel-description/digital-channel-description.module";

@NgModule({
  declarations: [Step2Component],
  imports: [
    CommonModule,
    MatCardModule,
    PersonSpecificationsModule,
    LetterSpecificationsModule,
    RecipientListUploadModule,
    ReactiveFormsModule,
    FormsModule,
    FlexLayoutModule,
    DigitalChannelDescriptionModule
  ],
  exports: [Step2Component],
})
export class Step2Module {}
