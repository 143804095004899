import { CTBase } from "@ctsolution/ct-base";
import { SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";
import {
  TemplateSaveForm
} from "../../_components/flow-components/editor/template-save/template-save-form/template-save-form.component";
import {
  LetterSpecifications
} from "src/app/pages/shipment/send-flow/step2/letter-specifications/letter-specifications.component";
import { TemplateVariableField } from "./template-variable-field";
import { COLOR_MODE } from "../enums/color-mode.enum";
import { PRINT_MODE } from "../enums/print-mode.enum";

export class TemplateParameter extends CTBase<TemplateParameter> {

  Oid: number | null = null;
  UserInfoOid: number | null = null;
  SendCategory: SendTypeSendCategoryEnum | null = null;
  Name: string | null = null;
  Description: string | null = null;
  Shared: boolean | null = false;
  CreateAt: string | null = null;
  UpdateAt: string | null = null;
  Content: string | null = null;
  ApiCode: string | null = null;
  Fields: Array<TemplateVariableField> = [];
  PrintColor: COLOR_MODE | null = null;
  PrintMode: PRINT_MODE | null = null;
  Subject: string | null = null;

  override createListElementClassByProperty(value: any, attribute: string): TemplateVariableField | null {

    switch (attribute) {

      case "Fields":
        return TemplateVariableField.create(value);
      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: TemplateParameter);
  private constructor(model?: TemplateParameter) {

    super();

    if (model) {

      this.getClass(model);

    }

  }

  public static create = (model?: TemplateParameter): TemplateParameter => new TemplateParameter(model);

  setOid(value: number | null) {

    this.Oid = value;
    return this;

  }

  setName(value: string | null) {

    this.Name = value;
    return this;

  }

  setDescription(value: string | null) {

    this.Description = value;
    return this;

  }

  setShared(value: boolean | null) {

    this.Shared = value;
    return this;

  }

  setSendCategory(value: SendTypeSendCategoryEnum | null) {

    this.SendCategory = value;
    return this;

  }

  setContent(value: string | null) {

    this.Content = value;
    return this;

  }

  setLetterSpecifications(value: LetterSpecifications | null) {

    this
      .setSubject(value?.SendSubject ?? null)
      .setPrintMode(value?.PrintMode ?? null)
      .setPrintColor(value?.ColorMode ?? null);

    return this;

  }

  setApiCode(value: string | null) {

    this.ApiCode = value;
    return this;

  }

  setFields(value: TemplateVariableField[] = []) {

    this.Fields = value;
    return this;

  }

  setUserInfoOid(value: number | null) {

    this.UserInfoOid = value;
    return this;

  }

  setPrintMode(value: PRINT_MODE | null) {

    this.PrintMode = value;
    return this;

  }

  setPrintColor(value: COLOR_MODE | null) {

    this.PrintColor = value;
    return this;

  }

  setSubject(value: string | null) {

    this.Subject = value;
    return this;

  }

  setFromTemplateSaveForm(value: TemplateSaveForm) {

    this
      .setName(value.Name)
      .setDescription(value.Description)
      .setShared(value.Shared);

    if (value.SendCategory) {

      this.setSendCategory(value.SendCategory);

    }

    if (value.ApiCode) this.setApiCode(value.ApiCode);


    return this;

  }

}
