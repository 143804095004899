<mat-card class="editor-cntr" [class]="configuration?.sendTypeSendCategory">
  <mat-card-header>
    <div mat-card-avatar class="d-flex" *ngIf="configuration?.icon">
      <mat-icon class="m-auto" color="primary"> {{ configuration?.icon }}</mat-icon>
    </div>
    <mat-card-title *ngIf="configuration?.title"> {{ configuration?.title }}</mat-card-title>
    <mat-card-subtitle *ngIf="configuration?.description"><p [innerHtml]="configuration?.description"></p>
    </mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

    <ng-container
      *ctSkeleton="!editorConfiguration;
           repeat: 1;
           className: 'editor-skeleton';">

      <div class="mat-form-field-wrapper">

        <editor
          [apiKey]="PPTinyMCEApiKey"
          [formControl]="control"
          [init]="editorConfiguration"
        ></editor>

        <div class="mat-form-field-subscript-wrapper">

          <mat-error *ngIf="control.touched && control.hasError('required')">
            * Campo obbligatorio
          </mat-error>

        </div>

      </div>

    </ng-container>

  </mat-card-content>

</mat-card>
