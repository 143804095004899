import { Component } from '@angular/core';

@Component({
  selector: 'app-smart-delivery-change-confirm',
  template: `
    <h1 mat-dialog-title>Attenzione</h1>
    <div mat-dialog-content>
      <p>
        La modifica alla regola di Smart Delivery avrà effetto immediato e influenzerà anche i flussi in esecuzione.
        <br /> Sei sicuro di voler procedere?
      </p>
    </div>
    <div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">
      <div>
        <button mat-raised-button color="warn" [mat-dialog-close]="false">
          <mat-icon>close</mat-icon>
          Annulla
        </button>
      </div>
      <div>
        <button mat-raised-button color="primary" [mat-dialog-close]="true">
          Procedi
        </button>
      </div>

    </div>
  `
})
export class SmartDeliveryChangeConfirmComponent {

}
