import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SettingsComponent } from "./settings/settings.component";
import { UiSettingsComponent } from "./settings/ui-settings/ui-settings.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacySlideToggleModule as MatSlideToggleModule } from "@angular/material/legacy-slide-toggle";
import { MatDividerModule } from "@angular/material/divider";
import { ShortcutsAreaComponent } from "./settings/shortcuts-area/shortcuts-area.component";
import { FlexLayoutModule } from "@angular/flex-layout";
import { CtCardModule } from "@ctsolution/ct-framework";
import { ChangePasswordDialogModule } from "./settings/change-password-dialog/change-password-dialog.module";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [
    SettingsComponent,
    UiSettingsComponent,
    ShortcutsAreaComponent
  ],
  imports: [
    CommonModule,
    MatCardModule,
    MatSlideToggleModule,
    MatDividerModule,
    FlexLayoutModule,
    CtCardModule,
    ChangePasswordDialogModule,
    MatDialogModule
  ]
})
export class SettingsModule {
}
