import { SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";

/*
  * TODO: E' SBAGLIATO CHE NEL CANALE EMAIL STANDALONE CI SIA PEC; UN TEMPO E' STATO UN WORKAROUND, MA ORA POTREBBE GENERARE CASINI.
  * Bisogna fare un check dove viene utilizzato questo canale e utilizzare PEC_STANDALONE_SEND_CATEGORY separatamente
  */
export const EMAIL_STANDALONE_SEND_CATEGORY: SendTypeSendCategoryEnum[] = [SendTypeSendCategoryEnum.Email, SendTypeSendCategoryEnum.PEC];

export const SMS_STANDALONE_SEND_CATEGORY: SendTypeSendCategoryEnum[] = [SendTypeSendCategoryEnum.SMS];

export const OTP_STANDALONE_SEND_CATEGORY: SendTypeSendCategoryEnum[] = [SendTypeSendCategoryEnum.OTPLetter];

export const PEC_STANDALONE_SEND_CATEGORY: SendTypeSendCategoryEnum[] = [SendTypeSendCategoryEnum.PEC];

export const DIGITAL_CHANNELS: SendTypeSendCategoryEnum[] = [...SMS_STANDALONE_SEND_CATEGORY, ...EMAIL_STANDALONE_SEND_CATEGORY, ...OTP_STANDALONE_SEND_CATEGORY];
