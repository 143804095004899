<mat-card class="change-password-container">
  <mat-card-header>
    <mat-card-title>Cambio Password</mat-card-title>
    <mat-card-subtitle>Inserisci la password attuale, conferma quella nuova e seleziona "Cambia password".
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <form [formGroup]="form">

      <p>
        <mat-form-field style="width: 100%">
          <mat-label>Password attuale</mat-label>
          <input matInput type="password" formControlName="Password"
                 (keyup)="form.get('NewPassword')?.touched && form.get('NewPassword')?.updateValueAndValidity()">
          <mat-error *ngIf="form.get('Password')?.hasError('required')">
            * Campo obbligatorio
          </mat-error>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="width: 100%">
          <mat-label>Nuova Password</mat-label>
          <input matInput type="password" formControlName="NewPassword"
                 (keyup)="form.get('ConfirmPassword')?.touched && form.get('ConfirmPassword')?.updateValueAndValidity()">
          <mat-hint>La password deve contenere almeno due dei seguenti elementi: numeri, lettere minuscole, lettere
            maiuscole o caratteri speciali.
          </mat-hint>
          <mat-error *ngIf="form.get('NewPassword')?.hasError('required')">
            * Campo obbligatorio
          </mat-error>
          <mat-error
            *ngIf="!form.get('NewPassword')?.hasError('required') && (form.get('NewPassword')?.hasError('minlength') || form.get('NewPassword')?.hasError('passwordStrength') || form.get('NewPassword')?.hasError('notEqualTo'))">
            * Campo non valido, la password deve contenere almeno due dei seguenti elementi: numeri, lettere minuscole,
            lettere
            maiuscole o caratteri speciali.
          </mat-error>
        </mat-form-field>
      </p>

      <p>
        <mat-form-field style="width: 100%">
          <mat-label>Conferma Nuova Password</mat-label>
          <input matInput type="password" formControlName="ConfirmPassword">
          <mat-error *ngIf="form.get('ConfirmPassword')?.hasError('required')">
            * Campo obbligatorio
          </mat-error>
          <mat-error
            *ngIf="!form.get('ConfirmPassword')?.hasError('required') && form.get('ConfirmPassword')?.hasError('equalTo')">
            * Campo non valido
          </mat-error>
        </mat-form-field>
      </p>

    </form>

  </mat-card-content>
  <div class="p-10 d-flex space-between">
    <button mat-button mat-dialog-close>Annulla</button>
    <button mat-button color="primary" (click)="changePassword()">Cambia password</button>
  </div>
</mat-card>
