import { Injectable } from "@angular/core";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { CompanyCustomConfiguration } from "../interfaces/company-custom-configuration";

@Injectable({
  providedIn: "root"
})
export class JwtService {

  constructor(private _authentication: CtAuthenticationService) {
  }

  //#region Other getters

  /* A getter that returns the value of the key `UserName` in the JWT token. */
  async getUserName() {

    await this._authentication.claimsUploaded();

    const value: string | null = this._authentication.getJwtValueByContainedKey("UserName");

    return value && value.length > 0 ? value : null;

  };

  async getCompanyCode() {

    await this._authentication.claimsUploaded();

    return this._authentication.getJwtValueByContainedKey("CompanyCode");

  }

  async getCompanyMacroCode() {

    await this._authentication.claimsUploaded();

    return this._authentication.getJwtValueByContainedKey("CompanyMacroCode");

  }

  async getUserRole() {

    await this._authentication.claimsUploaded();

    return this._authentication.getJwtValueByContainedKey("role");

  };

  async getAutoAcceptPrintQuote() {

    await this._authentication.claimsUploaded();

    return this._authentication.getJwtValueByContainedKey("AutoAcceptPrintQuote") === "True";

  };

  //#endregion Other getters

  //#region Number values

  /* A helper method that casts the value to a number. */
  private validateNumberValue = (value: any | null) => (!!value ? +value : 0);

  /* A method that returns a number. */
  async getFlowDraftOid() {

    await this._authentication.claimsUploaded();

    const flowDraftOid = this._authentication.getJwtValueByContainedKey("FlowDraftOid");

    return this.validateNumberValue(flowDraftOid);

  }

  async getGroupOid() {

    await this._authentication.claimsUploaded();

    const groupOid = this._authentication.getJwtValueByContainedKey("GroupOid");

    return this.validateNumberValue(groupOid);

  }

  async getUserInfoOid() {

    await this._authentication.claimsUploaded();

    const userInfoOid = this._authentication.getJwtValueByContainedKey("UserInfoOid");

    return this.validateNumberValue(userInfoOid);

  }

  //#endregion Helpers

  async getCompanyCustomConfiguration() {

    await this._authentication.claimsUploaded();

    const value: string | null = this._authentication.getJwtValueByContainedKey("CustomConfiguration");

    if (!value) return null;

    return JSON.parse(value) as CompanyCustomConfiguration;

  }

  async userHasExportEnabled(): Promise<boolean> {

    const configuration = await this.getCompanyCustomConfiguration();

    return !!configuration?.ExportEnabled;

  }

  async userHasPrintInternationalEnabled(): Promise<boolean | null> {

    const configuration = await this.getCompanyCustomConfiguration();

    return configuration?.PrintInternationalEnabled ?? null; // se nullo è abilitato

  }

  async getAdvancedTemplateManagement(): Promise<boolean | null> {

    const configuration = await this.getCompanyCustomConfiguration();

    return configuration?.AdvancedTemplateManagement ?? null;

  };

}
