<h1 mat-dialog-title style="margin: 0px !important">Gestione Campi Variabili</h1>
<p>Qui hai la possibilità di gestire i tuoi campi variabili</p>

<mat-dialog-content>

  <p-table
    [value]="data"
    dataKey="Name"
    editMode="row">

    <ng-template pTemplate="header">
      <tr>
        <th></th>
        <th>Valore Default</th>
        <th style="width: 15%">Obbligatorio</th>
      </tr>
    </ng-template>
    <ng-template pTemplate="body" let-field let-editing="editing" let-ri="rowIndex">
      <tr [pEditableRow]="field">
        <td>
          {{ field.Name }}
        </td>
        <td>
          <p-cellEditor>
            <ng-template pTemplate="input">
              <input style="width: 100%" pInputText type="text" [(ngModel)]="field.DefaultValue">
            </ng-template>
            <ng-template pTemplate="output">
              {{ field.DefaultValue }}
            </ng-template>
          </p-cellEditor>
        </td>
        <td class="centered">
          <p-cellEditor>
            <ng-template pTemplate="input">
              <mat-checkbox
                [(ngModel)]="field.Mandatory">
              </mat-checkbox>
            </ng-template>
            <ng-template pTemplate="output">
              <mat-icon> {{ field.Mandatory ? 'check' : 'close_small' }}</mat-icon>
            </ng-template>
          </p-cellEditor>
        </td>
      </tr>
    </ng-template>
  </p-table>

</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">
  <div>
    <button mat-raised-button color="warn" (click)="cancel()">
      Annulla
    </button>
  </div>
  <div>
    <button mat-raised-button color="primary" (click)="submit()">
      Aggiorna
    </button>
  </div>
</div>
