export enum ROLES {

  COMPANY_GROUP_ADMIN = "companygroupadmin",
  COMPANY_ADMIN = "companyadmin",
  GROUP_ADMIN = "groupadmin",
  OPERATOR = "operator",
  CUSTOMER_CARE = "customercare"

}

export const RoleType2LabelMapping: Record<ROLES, string> = {

  [ROLES.COMPANY_GROUP_ADMIN]: "Company Group Admin",
  [ROLES.COMPANY_ADMIN]: "Company Admin",
  [ROLES.GROUP_ADMIN]: "Group Admin",
  [ROLES.OPERATOR]: "Operator",
  [ROLES.CUSTOMER_CARE]: "Customer Care"

};
