<mat-expansion-panel class="group-budget-massive-management-container" #expansionPanel>

  <mat-expansion-panel-header>

    <mat-panel-title>
      Importazione massiva budget
    </mat-panel-title>
    <mat-panel-description>
      <span></span>
      <mat-icon>account_balance</mat-icon>
    </mat-panel-description>

  </mat-expansion-panel-header>

  <div class="import-area">

    <app-flow-file-uploader #Uploader [configuration]="groupBudgetFileUploaderConfiguration">
    </app-flow-file-uploader>

  </div>

  <mat-action-row>
    <button mat-button color="accent" (click)="export()">Esporta
      <mat-icon>file_download</mat-icon>
    </button>
    <button mat-button color="primary" (click)="import()">Importa
      <mat-icon>file_upload</mat-icon>
    </button>
  </mat-action-row>

</mat-expansion-panel>
