import { Component, Input } from "@angular/core";
import { SendTypeSendCategoryEnum } from "../../../../../core/interfaces/send-type-configuration";

@Component({
  selector: "app-digital-channel-description",
  templateUrl: "./digital-channel-description.component.html",
  styleUrls: ["./digital-channel-description.component.css"]
})
export class DigitalChannelDescriptionComponent {

  @Input() SendTypeSendCategory?: SendTypeSendCategoryEnum;

  SEND_CATEGORY = SendTypeSendCategoryEnum;

  constructor() {
  }

}
