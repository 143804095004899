import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SmartDeliveryDetailComponent } from "./smart-delivery-detail.component";
import { SmartDeliveryDetailService } from "./smart-delivery-detail.service";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { FlexModule } from "@angular/flex-layout";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { DragDropModule } from "@angular/cdk/drag-drop";
import {
  SmartDeliveryPriorityRuleComponent
} from "./smart-delivery-priority-rule/smart-delivery-priority-rule.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { CtControlModule, CtFileUploaderModule } from "@ctsolution/ct-framework";
import { SendTypeController } from "../../../../core/controllers/send-type.controller";
import {
  SmartDeliveryChangeConfirmComponent
} from "./smart-delivery-change-confirm/smart-delivery-change-confirm.component";
import {
  SmartDeliveryRuleConditionsComponent
} from "./smart-delivery-rule-conditions/smart-delivery-rule-conditions.component";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";
import { DropdownModule } from "primeng/dropdown";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";

@NgModule({
  declarations: [
    SmartDeliveryDetailComponent,
    SmartDeliveryPriorityRuleComponent,
    SmartDeliveryChangeConfirmComponent,
    SmartDeliveryRuleConditionsComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    MatLegacyDialogModule,
    FlexModule,
    MatIconModule,
    MatLegacyButtonModule,
    FormsModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    ReactiveFormsModule,
    MatLegacyCheckboxModule,
    DragDropModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    CtControlModule,
    TableModule,
    InputTextModule,
    DropdownModule,
    MatLegacyCardModule,
    ButtonModule,
    RippleModule,
    CtFileUploaderModule
  ],
  providers: [SmartDeliveryDetailService, SendTypeController]
})
export class SmartDeliveryDetailModule {
}
