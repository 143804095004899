<ng-container *ngIf="groupInfoCardParameters">
  <div fxLayout="row wrap">
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
      <ct-card [parameters]="groupInfoCardParameters"></ct-card>
    </div>
  </div>
</ng-container>

<div fxLayout="row wrap">
  <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
    <ct-card [parameters]="sendLettersCardParameters"></ct-card>
  </div>
</div>

<div fxLayout="row wrap">
  <div fxFlex.gt-sm="33.33" fxFlex.gt-xs="100" fxFlex="100" *ngFor="let widget of widgets">
    <ct-widget [configuration]="widget"></ct-widget>
  </div>
</div>


<ng-container *ngIf="administrativeWidgets.length > 0">

  <mat-divider></mat-divider>

  <div fxLayout="row wrap">
    <div
      fxFlex.gt-sm="33.33"
      fxFlex.gt-xs="100"
      fxFlex="100"
      *ngFor="let widget of administrativeWidgets"
    >
      <ct-widget [configuration]="widget"></ct-widget>
    </div>
  </div>


</ng-container>
