import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { LogoutComponent } from "./logout/logout.component";
import { BperLogoutComponent } from "./bper-logout/bper-logout.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";

@NgModule({
  declarations: [LogoutComponent, BperLogoutComponent],
  imports: [
    CommonModule,
    MatCardModule,
    RouterModule.forChild([
      {
        path: "bper-group",
        component: BperLogoutComponent
      },
      {
        path: "",
        component: LogoutComponent
      }
    ])
  ]
})
export class SignOutModule {
}
