import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { CtAuthenticationGuard } from "@ctsolution/ct-authentication";
import { RoleService } from "./role.service";

@Injectable({
  providedIn: "root"
})
export class CustomerCareRoleGuard {

  constructor(private authenticationGuard: CtAuthenticationGuard, private roleService: RoleService, private router: Router) {
  }

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean | UrlTree> {

    // Chiamiamo il canActivate del CtAuthenticationGuard
    if (!this.authenticationGuard.canActivate(next, state)) return false;

    const isCustomerCare = await this.roleService.isCustomerCare();

    if (isCustomerCare) {

      if (!state.url.toLowerCase().startsWith("/tracking")) return this.router.createUrlTree(["/tracking"]);

    }

    return true;

  }

}
