import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(private _snackbar: MatSnackBar) {}

  /* A function that takes in a message, action, and duration. It then calls the open function on the snackbar service. */
  open = (message: string, action: string = 'X', duration: number = 2000) =>
    this._snackbar.open(message, action, {
      duration,
      panelClass: 'generic-snackbar',
    });
}
