import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GlobalVariableFieldsImportComponent } from "./global-variable-fields-import.component";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { GlobalVariableFieldsImportService } from "./global-variable-fields-import.service";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { CtFileUploaderModule } from "@ctsolution/ct-framework";
import { MatIconModule } from "@angular/material/icon";
import { TemplateController } from "../../../../core/controllers/template.controller";
import {
  GlobalVariableFieldsImportOutputComponent
} from "./global-variable-fields-import-output/global-variable-fields-import-output.component";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";


@NgModule({
  declarations: [
    GlobalVariableFieldsImportComponent,
    GlobalVariableFieldsImportOutputComponent
  ],
  imports: [
    CommonModule,
    MatLegacyDialogModule,
    FlexModule,
    MatLegacyButtonModule,
    CtFileUploaderModule,
    MatIconModule,
    MatLegacyCheckboxModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [GlobalVariableFieldsImportService, TemplateController]
})
export class GlobalVariableFieldsImportModule {
}
