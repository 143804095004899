export class ImportGlobalVariableFieldsSaveParameter {

  File: File | null = null;
  CreateIfNotExist: boolean = false;

  private constructor() {
  }

  public static create = (): ImportGlobalVariableFieldsSaveParameter => new ImportGlobalVariableFieldsSaveParameter();

  setFile(value: File | null): ImportGlobalVariableFieldsSaveParameter {

    this.File = value;
    return this;

  }

  setCreateIfNotExist(value: boolean) {

    this.CreateIfNotExist = value;
    return this;

  }

}
