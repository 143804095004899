import { Injectable } from "@angular/core";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { GlobalVariableFieldsImportComponent } from "./global-variable-fields-import.component";

@Injectable()
export class GlobalVariableFieldsImportService {

  constructor(private dialog: MatLegacyDialog) {
  }


  open() {

    return this.dialog
      .open(GlobalVariableFieldsImportComponent);

  }

}
