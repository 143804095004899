import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupComponent } from './group.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CtDatatableModule, CtSearchFiltersModule } from "@ctsolution/ct-framework";
import { GroupDetailModule } from './group-detail/group-detail.module';
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";
import {
  GroupBudgetMassiveManagementModule
} from "./group-budget-massive-management/group-budget-massive-management.module";

@NgModule({
  declarations: [GroupComponent],
  imports: [CommonModule, MatCardModule, CtDatatableModule, GroupDetailModule, CtSearchFiltersModule, MatButtonModule, MatIconModule, GroupBudgetMassiveManagementModule]
})
export class GroupModule {}
