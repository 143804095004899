import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDescriptionComponent } from "./order-description.component";
import { CtControlModule } from "@ctsolution/ct-framework";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [OrderDescriptionComponent],
  imports: [CommonModule, CtControlModule, ReactiveFormsModule],
  exports: [OrderDescriptionComponent]
})
export class OrderDescriptionModule {}
