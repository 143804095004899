import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCheckboxModule as MatCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlexLayoutModule } from "@angular/flex-layout";

import { AuthenticationRoutes } from "./authentication.routing";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { ForgottenComponent } from "./forgotten/forgotten.component";
import {
  ForgottenConfirmDialogComponent
} from "./forgotten/forgotten-confirm-dialog.component";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(AuthenticationRoutes),
    MatIconModule,
    MatCardModule,
    MatInputModule,
    MatCheckboxModule,
    MatButtonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    MatLegacyDialogModule
  ],
  declarations: [LoginComponent, RegisterComponent, ForgottenComponent, ForgottenConfirmDialogComponent]
})
export class AuthenticationModule {
}
