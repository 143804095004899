import {
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { LoaderService } from "@ctsolution/ct-webapi";

@Component({
  selector: "app-spinner",
  template: `
    <div class="preloader fade-in" [hidden]="!isSpinnerVisible">
      <img src="/assets/images/logo/logo.svg" class="spinner bounce-top" />
    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class SpinnerComponent implements OnDestroy {

  public isSpinnerVisible = true;

  constructor(
    private loaderService: LoaderService,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {
    
    this.loaderService
      .isLoading
      .subscribe((v) => {

        this.isSpinnerVisible = v;
        this.cdr.detectChanges();

      });

  }

  ngOnDestroy(): void {

    this.isSpinnerVisible = false;

  }

}
