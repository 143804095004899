<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<mat-card>

  <mat-card-content>

    <ng-container *ngIf="orders">

      <ct-datatable
        [configuration]="orders"
        (reload)="fetch($event)"
        (onDetail)="detail($event)"
      ></ct-datatable>

    </ng-container>

  </mat-card-content>

</mat-card>

<ng-template #sendDateTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ (row.SendDate ?? row.EstimateAcceptDate ?? row.CreationDate) | date }}</p>

  </div>

</ng-template>

<ng-template #cdcCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value ?? '' }} - {{ row.GroupName ?? '' }}</p>
  </div>

</ng-template>

<ng-template #createdByCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ row.UserInfoName ?? '' }} {{ row.UserInfoSurname ?? '' }}</p>

  </div>

</ng-template>
