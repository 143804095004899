import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { User } from "../../../../../core/classes/user";

@Component({
  selector: "app-user-delete",
  templateUrl: "./user-delete.component.html",
  styleUrls: ["./user-delete.component.css"]
})
export class UserDeleteComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: User) {
  }

  ngOnInit(): void {
  }
}
