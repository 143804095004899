import { MAT_LEGACY_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Component, Inject } from "@angular/core";

@Component({
  selector: "app-global-variable-fields-import-output",
  template: `
    <h1 mat-dialog-title style="margin: 0px !important">Importazione avvenuta con successo!</h1>
    <mat-dialog-content>

      <p>
        {{ response }}
      </p>

    </mat-dialog-content>

    <div mat-dialog-actions class="d-flex space-between">
      <button mat-button mat-dialog-close color="primary">Chiudi</button>
    </div>`
})
export class GlobalVariableFieldsImportOutputComponent {

  constructor(
    @Inject(MAT_LEGACY_DIALOG_DATA) public response: string | null = null) {
  }

}
