import { DEFAULT_LABEL } from "../../core/lib/company-ui/company.ui-customization.service";

export enum MENU_ITEM_MACRO_TYPE {

  ESTIMATE_PRICE_ITEM = "ESTIMATE_PRICE_ITEM",
  GROUP_ITEM = "GROUP_ITEM",
  USER_ITEM = "USER_ITEM",
  SETTINGS_ITEM = "SETTINGS_ITEM"

}

export const logoutMenuItem = {
  state: "logout",
  name: "Logout",
  type: "link",
  icon: "logout"
};

export const dashboardMenuItem = {
  state: "dashboard",
  name: "Dashboard",
  type: "link",
  icon: "dashboard"
};

export const groupMenuItem = {
  separator: "amministrazione",
  state: DEFAULT_LABEL.GROUPS.toLowerCase(),
  name: DEFAULT_LABEL.GROUPS,
  type: "link",
  icon: "group",
  macroType: MENU_ITEM_MACRO_TYPE.GROUP_ITEM
};

export const userMenuItem = {
  separator: "amministrazione",
  state: "utenti",
  name: "Utenti",
  type: "link",
  icon: "person",
  macroType: MENU_ITEM_MACRO_TYPE.USER_ITEM
};

export const settingsMenuItem = {
  separator: "amministrazione",
  state: "impostazioni",
  name: "Impostazioni",
  type: "link",
  icon: "settings",
  macroType: MENU_ITEM_MACRO_TYPE.SETTINGS_ITEM
};

export const estimatedPriceMenuItem = {
  state: "preventivi",
  name: "Preventivi",
  type: "link",
  icon: "fact_check",
  macroType: MENU_ITEM_MACRO_TYPE.ESTIMATE_PRICE_ITEM
};

export const cartMenuItem: any = {
  state: "carrello",
  name: "Carrello",
  type: "link",
  icon: "shopping_cart",
  badge: [{ type: "warning", value: 0 }]
};

export const trackingMenuItem: any = {
  state: "tracking",
  name: "Tracking",
  type: "link",
  icon: "local_shipping"
};

export const shipmentSeparator: any = {
  state: "",
  name: "Spedizioni",
  type: "separator"
};

export const DASHBOARD_MENU = [
  {
    state: "",
    name: "", //     name: "Dashboard",
    type: "separator"
  },
  dashboardMenuItem
];

export const SHIPMENT_MENU = [
  shipmentSeparator,
  {
    state: "nuova-spedizione",
    name: "Nuova spedizione",
    type: "link",
    icon: "local_post_office"
  },
  trackingMenuItem,
  {
    state: "ordini",
    name: "Ordini",
    type: "link",
    icon: "list_alt"
  },
  cartMenuItem
];

export const ADMINISTRATION_MENU = [
  {
    state: "",
    name: "Amministrazione",
    type: "separator"
  }
];
