<ng-container *ngIf="(data?.flowPdfAttachments() ?? []).length">

  <button mat-button [matMenuTriggerFor]="menu">Allegati
    <mat-icon>attachment</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <button
      mat-menu-item
      *ngFor="let file of data.flowPdfAttachments()"
      (click)="downloadFile(file)"> {{ file.Name }}</button>
  </mat-menu>

</ng-container>
