import { FlowStep } from "./flow-step";
import { FLOW_TYPE } from "../../enums/flow-type.enum";

export class FlowStep1 extends FlowStep {

  SendTypeConfigurationOid: number | null = null;

  constructor(
    public SendTypeOid: number,
    public FlowType: FLOW_TYPE) {

    super();

  }

  setSendTypeConfigurationOid(value: number | null) {

    this.SendTypeConfigurationOid = value;
    return this;

  }

}
