import { Component } from '@angular/core';

@Component({
  selector: 'app-tracking-order-detail-exit-confirm',
  template: `
    <h1 mat-dialog-title>Attenzione!</h1>
    <div mat-dialog-content>
      <p>Stai visualizzando il tracking specifico di un ordine, se continui verrai reindirizzato al tracking generico
        del portale.</p>
    </div>
    <div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">
      <button mat-raised-button color="warn" [mat-dialog-close]="false">
        <mat-icon>close</mat-icon>
        Annulla
      </button>
      <button mat-raised-button color="primary" [mat-dialog-close]="true">
        Prosegui
      </button>

    </div>`
})
export class TrackingOrderDetailExitConfirmComponent {

}
