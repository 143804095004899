import { SendTypeSendCategoryEnum } from "../../../core/interfaces/send-type-configuration";
import { ValidatorFn } from "@angular/forms";
import { FlowFile } from "../../../core/interfaces/flow-file";

export class TinyMceSetupConfiguration {

  initialValue: string | null = null;
  validators: ValidatorFn | ValidatorFn[] | null = null;
  file: FlowFile | null = null;
  templateManagementEnabled: boolean = true;

  private constructor(public sendTypeSendCategoryEnum: SendTypeSendCategoryEnum | null = null) {
  }

  public static create = (sendTypeSendCategoryEnum: SendTypeSendCategoryEnum | null = null): TinyMceSetupConfiguration => new TinyMceSetupConfiguration(sendTypeSendCategoryEnum);

  setSendTypeSendCategoryEnum(value: SendTypeSendCategoryEnum | null): TinyMceSetupConfiguration {

    this.sendTypeSendCategoryEnum = value;
    return this;

  }

  setTemplateManagementEnabled(value: boolean) {

    this.templateManagementEnabled = value;
    return this;

  }

  setInitialValue(value: string | null): TinyMceSetupConfiguration {

    this.initialValue = value;
    return this;

  }

  setValidators(value: ValidatorFn | ValidatorFn[] | null): TinyMceSetupConfiguration {

    this.validators = value;
    return this;

  }

  setFile(value: FlowFile | null): TinyMceSetupConfiguration {

    this.file = value;
    return this;

  }

}
