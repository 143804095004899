<h1 mat-dialog-title>{{ data.Oid ? 'Modifica' : 'Creazione' }} utente</h1>

<div mat-dialog-content class="user-detail-cntr">

  <form [formGroup]="form" fxLayout="row wrap" class="user-data-cntr" autocomplete="off">

    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Nome</mat-label>
        <input matInput placeholder="Nome" formControlName="Name" />
        <mat-error *ngIf="form.get('Name')?.hasError('required')"> * Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Cognome</mat-label>
        <input matInput placeholder="Cognome" formControlName="Surname" />
        <mat-error *ngIf="form.get('Surname')?.hasError('required')"> * Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>E-mail</mat-label>
        <input matInput placeholder="E-mail" formControlName="UserEmail" />
        <mat-error *ngIf="form.get('UserEmail')?.hasError('required')"> * Campo obbligatorio</mat-error>
        <mat-error *ngIf="form.get('UserEmail')?.hasError('email')"> * Campo non valido</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Numero di telefono</mat-label>
        <input matInput placeholder="Numero di telefono" formControlName="PhoneNumber" />
        <mat-error *ngIf="form.get('PhoneNumber')?.hasError('required')"> * Campo obbligatorio</mat-error>
        <mat-error *ngIf="form.get('PhoneNumber')?.hasError('pattern')"> * Campo non valido</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label> {{ data.Oid ? 'Reset' : ''}} Password</mat-label>
        <input type="password" matInput placeholder="Password" formControlName="Password" />
        <mat-error *ngIf="form.get('Password')?.hasError('required')"> * Campo obbligatorio</mat-error>
        <mat-error *ngIf="form.get('Password')?.hasError('passwordStrength')">La password deve essere composta da
          almeno 6 caratteri, contenere
          almeno una lettera minuscola, una lettera maiuscola e un numero
        </mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
      <mat-form-field>
        <mat-label>Ruolo</mat-label>
        <mat-select formControlName="RoleCode">
          <mat-option *ngFor="let role of roleTypes" [value]="role">
            {{ RoleType2LabelMapping[role] }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form.get('RoleCode')?.hasError('required')"> * Campo obbligatorio</mat-error>
      </mat-form-field>
    </div>

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">

      <ct-control [configuration]="groupLookupControl"></ct-control>

    </div>

  </form>

</div>

<div mat-dialog-actions class="d-flex space-between">
  <button mat-button mat-dialog-close color="primary">Annulla</button>
  <button mat-button cdkFocusInitial color="accent" (click)="submit()">Salva</button>
</div>
