import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { FlowPricingDetailResult } from "../interfaces/flow-pricing-detail-result";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";

@Injectable()
export class PriceEstimateController {
  private controller: string = "/PriceEstimate/";

  constructor(private _webapi: CtWebapiService) {
  }

  getFlowPriceEstimate(flowOid: number): Promise<FlowPricingDetailResult> {

    return new Promise<FlowPricingDetailResult>((resolve) => {

      this._webapi
        .get(new DataRequest(`${this.controller}GetFlowPriceEstimate`, { flowOid }))
        .subscribe((r: CtWebapiGenericResponse<FlowPricingDetailResult>) => resolve(r.Result));

    });

  }

  acceptFlowPriceEstimate = (flowOid: number) => this._webapi.post(new DataRequest(`${this.controller}AcceptFlowPriceEstimate`), flowOid);

}
