import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { Step3Component } from "./step3.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatDividerModule } from "@angular/material/divider";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FlowFileUploaderModule } from "../../../../_components/flow-components/file-uploader/flow-file-uploader.module";
import { MatLegacyTabsModule as MatTabsModule } from "@angular/material/legacy-tabs";
import { MatIconModule } from "@angular/material/icon";
import { EditorModule } from "../../../../_components/flow-components/editor/editor.module";
import { SmsEditorModule } from "./sms-editor/sms-editor.module";
import { CheckBraceMatchingAlertComponent } from './check-brace-matching-alert/check-brace-matching-alert.component';
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [Step3Component, CheckBraceMatchingAlertComponent],
  imports: [
    CommonModule,
    MatCardModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    FlowFileUploaderModule,
    MatTabsModule,
    MatIconModule,
    EditorModule,
    SmsEditorModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule
  ],
  exports: [Step3Component]
})
export class Step3Module {
}
