import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupBudgetMassiveManagementComponent } from './group-budget-massive-management.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { FlowFileUploaderModule } from '../../../../_components/flow-components/file-uploader/flow-file-uploader.module';
import { GroupBudgetMassiveManagementConfirmComponent } from './group-budget-massive-management-confirm/group-budget-massive-management-confirm.component';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [
    GroupBudgetMassiveManagementComponent,
    GroupBudgetMassiveManagementConfirmComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    FlowFileUploaderModule,
    MatDialogModule
  ],
  exports: [GroupBudgetMassiveManagementComponent],
})
export class GroupBudgetMassiveManagementModule {}
