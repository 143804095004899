import { MediaMatcher } from "@angular/cdk/layout";
import { Router } from "@angular/router";
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { Direction } from "@angular/cdk/bidi";
import { CompanyUiCustomizationService } from "../../core/lib/company-ui/company.ui-customization.service";
import { Subscription } from "rxjs";
import { CompanyUiSettings } from "../../core/lib/company-ui/company.ui-settings";
import { SnackbarService } from "../../core/lib/snackbar.service";
import { MatLegacySnackBarRef as MatSnackBarRef } from "@angular/material/legacy-snack-bar";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { FlowService } from "../../core/lib/flow.service";
import { JwtService } from "../../core/lib/jwt.service";
import { groupMenuItem } from "../../shared/menu/menu.items";
import {
  CompanyCodeFilter,
  GroupCodeFilter
} from "../../core/classes/flow-common.parameter";
import { RoleService } from "../../core/lib/role.service";
import { GroupListFilters } from "../../core/classes/group-list.parameter";
import { FlowListFilters } from "../../core/classes/flow-list.parameter";
import { FlowRecipientFilters } from "../../core/classes/flow-recipient-list.parameter";

/** @title Responsive sidenav */
@Component({
  selector: "app-full-layout",
  templateUrl: "full.component.html",
  styleUrls: [],
  providers: [FlowService]
})
export class FullComponent implements OnInit {

  mobileQuery: MediaQueryList;
  dir = "ltr" as Direction;
  sidebarOpened = true;

  // tslint:disable-next-line - Disables all
  private readonly _mobileQueryListener: () => void;

  company = {
    logoSrc: "assets/images/logo/logo-extended.png"
  };

  snackbar?: MatSnackBarRef<any>;

  private dateFromFilterValueSubscription: Subscription | null = null;

  constructor(
    public router: Router,
    private companyUiService: CompanyUiCustomizationService,
    private _authentication: CtAuthenticationService,
    private _snackbar: SnackbarService,
    private changeDetectorRef: ChangeDetectorRef,
    private flowService: FlowService,
    private _jwt: JwtService,
    private role: RoleService,
    media: MediaMatcher
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 1100px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();

    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {

    this.setup();

  }

  private async setup() {

    const companyCode: string | null = await this._jwt.getCompanyCode();

    if (!companyCode) {

      this._snackbar.open("Il tuo utente risulta senza ragione sociale associata, contattare l'assistenza.", "X", 3000);

      this._authentication
        .signOut();

      return;

    }

    this.flowService
      .getCartCount()
      .then();

    const flowDraftOid = await this._jwt.getFlowDraftOid();

    if (flowDraftOid) {

      this.openFlowDraftSnackbar();

    }

    this.setupCompany()
      .then();

  }

  async setupCompany() {

    this.companyUiService
      .getSettings()
      .then(async (company: CompanyUiSettings | undefined) => {

        if (!company) {

          const companyMacroCode: string | null = await this._jwt.getCompanyMacroCode();

          if (companyMacroCode) company = new CompanyUiSettings(companyMacroCode);

        }

        if (company) {

          company
            .getLogo()
            .then(data => {

              this.company.logoSrc = data.src;

              if (!data.default) {

                // Aggiungi la classe 'customer' al div 'main-container'
                const mainContainer = document.querySelector(".main-container");

                if (mainContainer) mainContainer.classList.add("customer", company!.MacroCode.toUpperCase());

              }

            });

          groupMenuItem.name = company.getGroupsLabel();
          groupMenuItem.state = company.getGroupsPath();

          CompanyCodeFilter.setLabel(company.getCompanyCodeLabel());
          GroupCodeFilter.setLabel(company.getGroupCodeLabel());

        }

        this.changeDetectorRef.detectChanges();

      });

    const BPERGroupUser = await this.companyUiService.BPERGroupUser();

    if (BPERGroupUser) {

      this.setBPERGroupUserCustomizations();

    }

    const isCompanyGroupAdmin = await this.role.isCompanyGroupAdmin();

    if (isCompanyGroupAdmin) {

      this.setCompanyGroupAdminCustomizations();

    }

  }

  private setBPERGroupUserCustomizations() {

    this.companyUiService.setBPERGroupLogoutRedirect();

  }

  private setCompanyGroupAdminCustomizations() {

    if (GroupListFilters.indexOf(CompanyCodeFilter) < 0) {

      GroupListFilters.push(CompanyCodeFilter);

    }

    if (FlowListFilters.indexOf(CompanyCodeFilter) < 0) {

      FlowListFilters.push(CompanyCodeFilter);

    }

    if (FlowRecipientFilters.indexOf(CompanyCodeFilter) < 0) {

      FlowRecipientFilters.push(CompanyCodeFilter);

    }

  }

  openFlowDraftSnackbar() {

    this.snackbar = this._snackbar.open("Hai 1 spedizione in stato bozza.", ">");
    this.snackbar
      .onAction()
      .subscribe(() => this.flowService.continueFlow());

  }

  ngOnDestroy(): void {

    this.snackbar?.dismiss();

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

    this.dateFromFilterValueSubscription?.unsubscribe();

  }

}
