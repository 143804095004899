import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CTDatatablePaginationParameter, CtDatatableResult } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { Group } from "../interfaces/group";
import { GroupListParameter } from "../classes/group-list.parameter";

@Injectable()
export class GroupController {

  private controller: string = "/Group/";

  constructor(private _webapi: CtWebapiService) {
  }

  list(parameter: GroupListParameter, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<Group>> {

    return new Promise<CtDatatableResult<Group>>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}GroupList`)
        .setQueryParams({
          ...parameter,
          ...pagination
        });

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<CtDatatableResult<Group>>) => resolve(r.Result));

    });

  }

  /**
   * Restituisce il risultato della GroupDetail filtrata per il gruppo associato all'utente
   * */
  userGroup(): Promise<Group> {

    return new Promise<Group>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}UserGroup`, {});

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<Group>) => resolve(r.Result));

    });

  }

  detail(GroupId: number): Promise<Group> {

    return new Promise<Group>((resolve) => {

      this._webapi
        .get(new DataRequest(`${this.controller}GroupDetail`, { GroupId }))
        .subscribe((r: CtWebapiGenericResponse<Group>) => resolve(r.Result));

    });

  }

  save(group: Group): Promise<any> {

    return new Promise<any>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}Save`);

      return this._webapi
        .put(request, group)
        .subscribe((r: CtWebapiGenericResponse<any>) => resolve(r.Result));

    });

  }

  delete(groupOid: number): Promise<any> {

    return new Promise<any>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}Delete`, { groupOid });

      return this._webapi
        .delete(request)
        .subscribe((r: CtWebapiGenericResponse<any>) => resolve(r.Result));

    });

  }


  exportGroupBudget() {

    const request: DataRequest = new DataRequest(`${this.controller}ExportGroupBudget`);
    request.setResponseType("blob");

    this._webapi
      .post(request, {})
      .subscribe((result: any) => {

        const blob = new Blob([result], { type: "text/csv" });
        const fileURL = URL.createObjectURL(blob);

        const fileLink = document.createElement("a");
        fileLink.href = fileURL;
        fileLink.download = "ExportBudgets";
        fileLink.click();

      });

  }

  importGroupBudget(file: File): Promise<boolean> {

    return new Promise<boolean>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ImportGroupBudget`);

      const formData: FormData = new FormData();
      formData.append("File", file, file.name);

      this._webapi
        .post(request, formData)
        .subscribe({
          next: () => resolve(true),
          error: () => resolve(false)
        });

    });

  }

}
