<mat-card class="product-card" [class.popular-plan]="selected ?? false" [class.invalid]="invalid">
  <mat-card-content>
    <app-product-card-icon
      [productCode]="product"
    ></app-product-card-icon>
    <mat-card-title> {{ product.SendTypeName }} </mat-card-title>
    <mat-card-subtitle class="mat-text-primary">
      {{ product.SendTypeDescription }}
    </mat-card-subtitle>
    <h1>
      <sup>Da</sup> {{ product.BasePrice | currency:'EUR':'symbol':'1.0-3' }}
    </h1>
    <button mat-raised-button [color]="selected ? 'primary' : undefined" (click)="onSelect.emit(product)">
      {{ selected ? 'Selezionato' : 'Seleziona' }}
    </button>
  </mat-card-content>
</mat-card>
