import { Flow } from "./flow";
import { RecipientData } from "../interfaces/recipient-data";

export class BaseFlowFileParameter {

  rowNumber: number = 1;

  constructor(public flowOid: number) {
  }

  public static create = (flowOid: number): BaseFlowFileParameter => new BaseFlowFileParameter(flowOid);

  setFlowOid(value: number): BaseFlowFileParameter {

    this.flowOid = value;
    return this;

  }

  setRowNumber(value: number | null): BaseFlowFileParameter {

    this.rowNumber = value ?? 1;
    return this;

  }

}

// TODO: Aggiungere costruttore al Flow che utilizza RecipientData e passare al component flow preview una classe di configurazione
export class RecipientFlowPreviewParameter {

  flow: Flow | null = null;
  recipient: RecipientData | null = null;

  public static create = (): RecipientFlowPreviewParameter => new RecipientFlowPreviewParameter();

  setFlow(value: Flow | null): RecipientFlowPreviewParameter {

    this.flow = value;
    return this;

  }

  setRecipient(value: RecipientData | null): RecipientFlowPreviewParameter {

    this.recipient = value;

    if (!this.flow) {

      this.setFlow(Flow
        .create(<Flow>{
          Oid: this.recipient?.FlowOid,
          SendTypeConfigurationSendTypeSendCategory: this.recipient?.FlowSendTypeConfigurationSendTypeSendCategory
        }));

    }

    return this;

  }

}
