import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThankYouComponent } from './thank-you.component';
import { RouterModule } from '@angular/router';


import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";


@NgModule({
  imports: [
    CommonModule,
    MatButtonModule,
  ],
  declarations: [ThankYouComponent],
  exports: [ThankYouComponent]
})
export class ThankYouModule { }
