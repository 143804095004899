import {
  PERSON_SPECIFICATION_TYPE
} from "../../../../pages/shipment/send-flow/step2/person-specifications/person-specifications.configuration";
import { Flow } from "../../../../core/classes/flow";

export class CartPersonConfiguration {

  get flow(): Flow | null {

    return this._flow;

  }

  private set flow(value: Flow | null) {

    this._flow = value;

  }

  get personType(): PERSON_SPECIFICATION_TYPE {

    return this._personType;

  }

  private set personType(value: PERSON_SPECIFICATION_TYPE) {

    this._personType = value;

  }

  private _personType = PERSON_SPECIFICATION_TYPE.SENDER;
  private _flow: Flow | null = null;

  constructor() {
  }

  setPersonType(value: PERSON_SPECIFICATION_TYPE): CartPersonConfiguration {

    this.personType = value;
    return this;

  }

  setFlow(value: Flow): CartPersonConfiguration {

    this.flow = value;
    return this;

  }

}
