import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TemplateSaveComponent } from "./template-save.component";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { TemplateSaveService } from "./template-save.service";
import { TemplateController } from "../../../../core/controllers/template.controller";
import { TemplateSaveFormModule } from "./template-save-form/template-save-form.module";


@NgModule({
  declarations: [
    TemplateSaveComponent
  ],
  imports: [
    CommonModule,
    MatDialogModule, MatButtonModule, MatIconModule, TemplateSaveFormModule
  ],
  providers: [TemplateSaveService, TemplateController]
})
export class TemplateSaveModule {
}
