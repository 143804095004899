<h1 mat-dialog-title style="margin: 0px !important">Importa i valori predefiniti globali da Excel</h1>
<p>Aggiungi rapidamente i valori predefiniti dei campi dinamici globali importando un file Excel.<br />Semplifica il
  processo
  di creazione dei modelli utilizzando una colonna per il nome e una colonna per il valore.</p>
<mat-dialog-content>

  <ct-file-uploader #Uploader [configuration]="configuration"></ct-file-uploader>

  <section class="m-b-10">

    <mat-checkbox
      class="checkbox-margin"
      [(ngModel)]="createIfNotExist"> Crea automaticamente le variabili non riconosciute durante l'importazione
      dell'Excel.
    </mat-checkbox>

  </section>

</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between" style="margin-bottom: -9px;">
  <div>
    <button mat-raised-button color="warn" (click)="cancel()">
      Annulla
    </button>
  </div>
  <div>
    <button mat-raised-button (click)="downloadTemplate()">
      Template
      <mat-icon>download</mat-icon>
    </button>
    <button mat-raised-button color="primary" (click)="submit()">
      Salva
    </button>
  </div>
</div>
