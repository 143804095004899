import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step4Component } from './step4.component';
import { LetterAdditionsModule } from './letter-additions/letter-additions.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DocumentPreviewModule } from './document-preview/document-preview.module';

@NgModule({
  declarations: [Step4Component],
  imports: [
    CommonModule,
    LetterAdditionsModule,
    MatCardModule,
    MatDividerModule,
    ReactiveFormsModule,
    FormsModule,
    DocumentPreviewModule,
  ],
  exports: [Step4Component],
})
export class Step4Module {}
