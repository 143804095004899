import { Injectable } from "@angular/core";
import { CtSerializerHelperService, CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { Observable } from "rxjs";
import { SmartDeliveryConfigurationListParameter } from "../classes/smart-delivery-configuration-list.parameter";
import {
  SmartDeliveryConfigurationRowFileParameter,
  SmartDeliveryConfigurationSaveParameter
} from "../classes/smart-delivery-configuration-save.parameter";

@Injectable()
export class SmartDeliveryConfigurationController {

  private controller: string = "SmartDeliveryConfiguration";

  constructor(
    private _webapi: CtWebapiService,
    private serializerHelperService: CtSerializerHelperService) {
  }

  list(parameter: SmartDeliveryConfigurationListParameter | null = null): Observable<CtWebapiGenericResponse<Array<SmartDeliveryConfigurationSaveParameter>>> {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("SmartDeliveryConfigurationList")
      .setQueryParams(parameter);

    return this._webapi
      .get(request);

  }

  save(parameter: SmartDeliveryConfigurationSaveParameter) {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("Save")
      .setBody(parameter);

    return this._webapi
      .post(request);

  }

  delete(parameter: SmartDeliveryConfigurationSaveParameter) {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("Delete")
      .setQueryParams({ Oid: parameter.Oid });

    return this._webapi
      .delete(request);

  }

  addRowFile(parameter: SmartDeliveryConfigurationRowFileParameter) {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("AddRowFile")
      .setBody(this.serializerHelperService.objectToFormData(parameter));

    return this._webapi
      .post(request);

  }

  deleteRowFile(parameter: SmartDeliveryConfigurationRowFileParameter) {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("DeleteRowFile")
      .setBody(parameter);

    return this._webapi
      .post(request);

  }

  getRowFile(id: string) {

    const request: DataRequest = DataRequest
      .create()
      .setController([this.controller])
      .setAction("GetRowFile")
      .setQueryParams({ id });

    request
      .setResponseType("blob" as "json")
      .setObserve("response");

    return this._webapi
      .get(request);

  }

}



