import { DEFAULT_LABEL } from "./company.ui-customization.service";

export class CompanyUiSettings {

  constructor(
    public MacroCode: string,
    public Assets: CompanyAsset[] = [],
    public Dictionary: Dictionary<string> = {}
  ) {
  }

  getLogo(): Promise<{ src: string, default?: boolean }> {

    return new Promise(async (resolve) => {

      const jsonLogoAssetSrc = this.Assets.find((a) => a.type === ASSET_TYPE.LOGO)?.src;

      if (jsonLogoAssetSrc) {

        // Verifica che la chiamata a quella source non vada in 404
        try {

          const response = await fetch(jsonLogoAssetSrc, { cache: "no-cache" });

          if (response.status === 200 && response.headers.get("Content-Type")?.startsWith("image/")) {
            resolve({ src: jsonLogoAssetSrc });
            return;
          }

        } catch (error) {
          console.error(error);
        }

      }

      // Arrivato a questo punto, vuol dire che il logo non è stato aggiunto nel json degli assets,
      // allora provo a vedere se lo trovo con questa path
      const defaultSrc = `assets/companies/${this.MacroCode.toUpperCase()}/logo.png`;

      // Verifico che la chiamata alla defaultSrc non vada in 404 (quindi che il logo sia stato caricato correttamente)
      try {
        const response = await fetch(defaultSrc, { cache: "no-cache" });

        if (response.status === 200 && response.headers.get("Content-Type")?.startsWith("image/")) {
          resolve({ src: defaultSrc });
          return;
        }

      } catch (error) {
        console.error(error);
      }

      // Se non è stato possibile ottenere il logo personalizzato, ritorno il logo di default di Polipush
      resolve({ src: `assets/images/logo/logo-extended.png`, default: true });

    });

  }

  /* A getter function that returns the value of the key passed as argument. */
  getCustomLabel = (k: COMPANY_CUSTOM_LABEL): string | undefined => this.Dictionary[k];

  /* A getter function that returns the value of the key passed as argument. */
  getGroupLabel = () => this.getCustomLabel(COMPANY_CUSTOM_LABEL.GROUP) ?? DEFAULT_LABEL.GROUP;

  /* A getter function that returns the value of the key passed as argument. */
  getGroupsLabel = () => this.getCustomLabel(COMPANY_CUSTOM_LABEL.GROUPS) ?? DEFAULT_LABEL.GROUPS;

  getGroupCodeLabel = () => this.getCustomLabel(COMPANY_CUSTOM_LABEL.GROUP_CODE) ?? DEFAULT_LABEL.GROUP_CODE;

  /* A getter function that returns the value of the key passed as argument. */
  getGroupsPath = () => this.getGroupsLabel().toLowerCase().replace(/ /g, "-");

  getCompanyCodeLabel = () => this.getCustomLabel(COMPANY_CUSTOM_LABEL.COMPANY_CODE) ?? DEFAULT_LABEL.COMPANY_CODE;

}

class CompanyAsset {
  constructor(public type: ASSET_TYPE, public src: string) {
  }
}

enum ASSET_TYPE {
  LOGO = "logo",
}

enum COMPANY_CUSTOM_LABEL {
  GROUP = "GROUP",
  GROUPS = "GROUPS",
  COMPANY_CODE = "COMPANY_CODE",
  GROUP_CODE = "GROUP_CODE"
}

interface Dictionary<T> {
  [Key: string]: T;
}
