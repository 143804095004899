import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OneClickPurchaseComponent } from "./one-click-purchase.component";
import { ReactiveFormsModule } from "@angular/forms";
import { CtControlModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [OneClickPurchaseComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    CtControlModule
  ],
  exports: [
    OneClickPurchaseComponent
  ]
})
export class OneClickPurchaseModule {
}
