import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { Group } from "../../../../../core/interfaces/group";

@Component({
  selector: "app-group-delete",
  templateUrl: "./group-delete.component.html",
  styleUrls: ["./group-delete.component.css"]
})
export class GroupDeleteComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: Group
  ) {
  }

  ngOnInit(): void {
  }
}
