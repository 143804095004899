import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TemplateDetailComponent } from "./template-detail.component";
import { SmsEditorModule } from "../../../shipment/send-flow/step3/sms-editor/sms-editor.module";
import { EditorModule } from "../../../../_components/flow-components/editor/editor.module";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { CtButtonModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import {
  VariableFieldsManagerModule
} from "../../../../_components/flow-components/editor/variable-fields-manager/variable-fields-manager.module";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { PaginatorModule } from "primeng/paginator";
import { ReactiveFormsModule } from "@angular/forms";
import { TextFieldModule } from "@angular/cdk/text-field";
import {
  TemplateSaveFormModule
} from "../../../../_components/flow-components/editor/template-save/template-save-form/template-save-form.module";
import {
  LetterSpecificationsModule
} from "../../../shipment/send-flow/step2/letter-specifications/letter-specifications.module";

@NgModule({
  declarations: [
    TemplateDetailComponent
  ],
  imports: [
    CommonModule,
    SmsEditorModule,
    EditorModule,
    MatLegacyCardModule,
    CtButtonModule,
    FlexModule,
    VariableFieldsManagerModule,
    MatIconModule,
    MatLegacyCheckboxModule,
    MatLegacyDialogModule,
    PaginatorModule,
    ReactiveFormsModule,
    TextFieldModule,
    TemplateSaveFormModule,
    LetterSpecificationsModule,
    FlexLayoutModule
  ]
})
export class TemplateDetailModule {
}
