export class FlowDetailRequest {

  LoadFileData: boolean = false;

  /** @deprecated
   * Abbiamo deciso con Fra che non è più necessario
   * Sarà l'API direttamente a passarmi recipients valorizzato se e solo se il flusso è di tipo SINGOLO
   * In caso contrario, si tratta di lista di dati che verranno visualizzati diversamente
   * */
  LoadRecipientsData: boolean = false;

  constructor(public FlowOID: number) {
  }

}
