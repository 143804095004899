import { BaseFlowFileParameter } from "./base-flow-file-parameter";
import { FLOW_FILE_TYPE } from "../enums/flow-file-type.enum";

export class GetFlowFileParameter extends BaseFlowFileParameter {

  flowFileOid: number | null = null;
  fileType: FLOW_FILE_TYPE | null = null;

  constructor(flowOid: number) {
    super(flowOid);
  }

  public static create = (flowOid: number, rowNumber: number | null = null): GetFlowFileParameter => {

    let toReturn = new GetFlowFileParameter(flowOid);

    toReturn
      .setRowNumber(rowNumber);

    return toReturn;

  };

  setFlowFileOid(value: number | null): GetFlowFileParameter {

    this.flowFileOid = value;
    return this;

  }

  setFileType(value: FLOW_FILE_TYPE | null): GetFlowFileParameter {

    this.fileType = value;
    return this;

  }

}
