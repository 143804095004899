import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-group-budget-massive-management-confirm',
  templateUrl: './group-budget-massive-management-confirm.component.html',
  styleUrls: ['./group-budget-massive-management-confirm.component.css'],
})
export class GroupBudgetMassiveManagementConfirmComponent {
  constructor() {}
}
