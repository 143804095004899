import { Component, OnInit, ViewChild } from "@angular/core";
import { CompanyUiCustomizationService } from "../../../core/lib/company-ui/company.ui-customization.service";
import { GeneralService } from "../../../core/lib/general.service";
import { FormBuilder, FormGroup } from "@angular/forms";
import {
  CtButtonConfiguration,
  CtCardConfiguration,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import { Step2Component } from "../send-flow/step2/step2.component";
import { Router } from "@angular/router";
import { ProductSelectComponent } from "./product-select/product-select.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { OrderDescriptionComponent } from "./order-description/order-description.component";
import { FlowStep1 } from "../../../core/classes/flow-steps/flow-step1";
import { FLOW_TYPE } from "../../../core/enums/flow-type.enum";
import { FlowStepsController, StepSaveResult } from "../../../core/controllers/flow-steps.controller";
import { LetterSpecifications } from "../send-flow/step2/letter-specifications/letter-specifications.component";
import { FlowStep2 } from "../../../core/classes/flow-steps/flow-step2";
import { AddressData } from "../../../core/classes/address-data";
import { AddressValidatorService } from "../send-flow/address-validator/address-validator.service";
import { FlowStep3 } from "../../../core/classes/flow-steps/flow-step3";
import { FlowStep4 } from "../../../core/classes/flow-steps/flow-step4";
import { JwtService } from "../../../core/lib/jwt.service";
import { SnackbarService } from "../../../core/lib/snackbar.service";
import { FlowService } from "../../../core/lib/flow.service";
import { OneClickPurchaseComponent } from "./one-click-purchase/one-click-purchase.component";
import { FlowPurchaseRequest } from "../../../core/classes/flow-purchase-request";
import { FlowController } from "../../../core/controllers/flow.controller";
import { Group } from "../../../core/interfaces/group";
import { GroupController } from "../../../core/controllers/group.controller";
import { GroupService } from "../../../core/lib/group.service";

@Component({
  selector: "app-standalone-single-flow",
  templateUrl: "./standalone-single-flow.component.html",
  styleUrls: ["./standalone-single-flow.component.scss"],
  providers: [FlowStepsController, FlowController, GroupController]
})
export class StandaloneSingleFlowComponent implements OnInit {

  @ViewChild("Step1") step1!: ProductSelectComponent;
  @ViewChild("Step2") step2!: Step2Component;
  @ViewChild("Step3") step3!: FileUploadComponent;
  @ViewChild("Step4") step4!: OrderDescriptionComponent;

  @ViewChild("OneClickPurchase") oneClickPurchase!: OneClickPurchaseComponent;

  GROUP_INFO: CtCardConfiguration = CtCardConfiguration.create()
    .setClass("group-info-cntr");

  form: FormGroup;

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Indietro")
    .setAction(() => this.onCancel())
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Conferma")
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  constructor(
    private companyUiCustomizationService: CompanyUiCustomizationService,
    private general: GeneralService,
    private formBuilder: FormBuilder,
    private router: Router,
    private _controller: FlowStepsController,
    private _validator: AddressValidatorService,
    private _general: GeneralService,
    private _jwt: JwtService,
    private _snackbar: SnackbarService,
    private flowService: FlowService,
    private flowController: FlowController,
    private groupController: GroupController,
    private groupService: GroupService
  ) {

    this.form = this.formBuilder.group({});

  }

  ngOnInit(): void {

    this.setup();

  }

  private async setup() {

    const BPERGroupUser = await this.companyUiCustomizationService.BPERGroupUser();

    if (!BPERGroupUser) {


      this.general
        .navigateTo(["/", "nuova-spedizione"]);

      return;

    }

    this.setGroupData();

  }

  ngAfterViewInit() {

    this.form.addControl("Step1", this.step1.form);
    this.form.addControl("Step2", this.step2.form);
    this.form.addControl("Step3", this.step3.form);
    this.form.addControl("Step4", this.step4.form);
    this.form.addControl("OneClickPurchase", this.oneClickPurchase.form);

    this.step2
      .fetch();

  }

  setGroupData() {

    this.groupController
      .userGroup()
      .then((userGroup: Group) => {

        const groupResidueBudget: string = this.groupService.getBudgetResidueLabel(userGroup);

        this.GROUP_INFO
          .setTitle(`${userGroup.Code} - ${userGroup.Name}`)
          .setDescription(`Budget residuo: ${isNaN(+groupResidueBudget) ? groupResidueBudget : (groupResidueBudget + " €")}`);

      });

  }

  onCancel() {

    this.router.navigate(["/", "nuova-spedizione"]);

  }

  onSubmit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const Step1Parameter: FlowStep1 = new FlowStep1(this.form.value.Step1.SendType, FLOW_TYPE.SINGLE_RECIPIENT);

      this._controller
        .step1Save(Step1Parameter)
        .then((ResultStep1: StepSaveResult) => {

          this.flowService.updateCurrentFlowValues(ResultStep1);

          const specifications: LetterSpecifications = new LetterSpecifications(this.form.value.Step2.specifications);

          const Step2Parameter: FlowStep2 = new FlowStep2(
            specifications.Destination,
            this._validator.handleDefaultLocalCountryValue(<AddressData>this.form.value.Step2.sender),
            specifications.ColorMode,
            specifications.PrintMode,
            specifications.SendSubject
          );

          this.step2
            .setupSingleRecipientDataParameter(Step2Parameter)
            .then((parameter: FlowStep2 | null) => {

              if (!parameter) return;

              this._controller
                .step2Save(Step2Parameter)
                .then((ResultStep2: StepSaveResult) => {

                  this.flowService.updateCurrentFlowValues(ResultStep2);

                  const Step3Parameter: FlowStep3 = new FlowStep3([this.form.value.Step3.File], null);
                  //passo null come content perché non c'è l'editor sulla standalone

                  this._controller
                    .step3Save(Step3Parameter)
                    .then((ResultStep3: StepSaveResult) => {

                      this.flowService.updateCurrentFlowValues(ResultStep3);

                      const Step4Parameter: FlowStep4 = new FlowStep4(this.form.value.Step4.OrderDescription);

                      this._controller
                        .step4Save(Step4Parameter)
                        .then((ResultStep4: StepSaveResult) => {

                          if (this.form.value.OneClickPurchase.OneClickPurchase) {

                            const purchaseRequest: FlowPurchaseRequest = new FlowPurchaseRequest(false, [ResultStep4.FlowOid]);

                            this.flowController
                              .purchase(purchaseRequest)
                              .then((success: boolean) => {

                                if (success) {

                                  this.flowService
                                    .getCartCount().then(() => {

                                    this._snackbar.open("Ordine inviato!", "X");

                                  });

                                }

                              });

                          } else {

                            this._snackbar.open("Ordine completato con successo!");

                          }

                          this.reset();

                        });

                    });

                });

            });

        });

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

  private reset() {

    this.flowService.resetFlow();

    this.step1.form.reset();

    this.step2
      .recipient
      .form
      .reset();

    this.step3
      .form
      .reset();

    this.step4
      .form
      .reset();

  }

}
