<div fxLayout="row wrap" class="cart-order-detail-cntr">

  <div
    *ngIf="flow"
    [fxFlex.gt-sm]=" multiple ? 50 : 33.33"
    fxFlex.gt-xs="100"
    fxFlex="100">

    <mat-card>

      <mat-card-header>

        <mat-icon mat-card-avatar color="primary">dashboard</mat-icon>
        <mat-card-title>Specifiche</mat-card-title>

      </mat-card-header>

      <mat-card-content>

        <app-cart-product-specifications [data]="flow"></app-cart-product-specifications>

      </mat-card-content>

    </mat-card>

  </div>

  <div
    *ngIf="flow.FlowSenders && flow.FlowSenders.length > 0"
    [fxFlex.gt-sm]=" multiple ? 50 : 33.33"
    fxFlex.gt-xs="100"
    fxFlex="100">

    <mat-card>

      <mat-card-header>

        <mat-icon mat-card-avatar color="primary">extension</mat-icon>
        <mat-card-title>Mittente</mat-card-title>

      </mat-card-header>

      <mat-card-content>

        <app-cart-person-specifications
          *ngIf="senderCartPersonConfiguration"
          [configuration]="senderCartPersonConfiguration"></app-cart-person-specifications>

      </mat-card-content>

    </mat-card>

  </div>

  <ng-container *ngIf="multiple; else singleRecipientTemplate">

    <!-- Datatable multiple recipient -->
    <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">

      <mat-card>

        <mat-card-header>

          <mat-icon mat-card-avatar color="primary">send_time_extension</mat-icon>
          <mat-card-title>Informazioni destinatari</mat-card-title>

        </mat-card-header>

        <mat-card-content>

          <app-cart-multiple-recipients-list [data]="flow"></app-cart-multiple-recipients-list>

        </mat-card-content>

      </mat-card>

    </div>

  </ng-container>

  <ng-template #singleRecipientTemplate>

    <div
      *ngIf="flow.Recipients && flow.Recipients.length > 0"
      fxFlex.gt-sm="33.33" fxFlex.gt-xs="100" fxFlex="100">

      <mat-card>

        <mat-card-header>

          <mat-icon mat-card-avatar color="primary">send_time_extension</mat-icon>
          <mat-card-title>Destinatario</mat-card-title>

        </mat-card-header>

        <mat-card-content>

          <app-cart-person-specifications
            *ngIf="recipientCartPersonConfiguration"
            [configuration]="recipientCartPersonConfiguration"></app-cart-person-specifications>

        </mat-card-content>

      </mat-card>

    </div>

  </ng-template>

</div>
