import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  /**
   * It takes a string as an argument, and returns a sanitized version of that string
   * @param {string} url - The URL you want to bypass security for.
   * @returns A SafeResourceUrl object that can be used by the DOM sanitizer.
   */
  transform(url: string) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
