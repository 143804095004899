import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TemplateComponent } from "./template.component";
import { CtDatatableModule, CtSearchFiltersModule } from "@ctsolution/ct-framework";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { RouterModule } from "@angular/router";
import { TemplateDetailComponent } from "./template-detail/template-detail.component";
import { TemplateDetailModule } from "./template-detail/template-detail.module";
import {
  GlobalVariableFieldsImportModule
} from "../../../_components/flow-components/editor/global-variable-fields-import/global-variable-fields-import.module";

@NgModule({
  declarations: [
    TemplateComponent
  ],
  imports: [
    CommonModule,
    CtSearchFiltersModule,
    MatLegacyCardModule,
    CtDatatableModule,
    MatLegacyButtonModule,
    GlobalVariableFieldsImportModule,
    TemplateDetailModule,
    RouterModule
      .forChild([
        {
          path: "gestione-modello",
          component: TemplateDetailComponent,
          data: {
            title: "Gestione Modello",
            description: "Modifica e visualizza il tuo modello"
          }
        },
        {
          path: "",
          component: TemplateComponent,
          data: {
            title: "Modelli",
            description: "Visualizza e gestisci i tuoi modelli",
            urls: [{ title: "Modelli", url: "/amministrazione/gestione-modelli" }, { title: "Modelli" }]
          }
        }
      ])
  ]
})
export class TemplateModule {
}
