import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import {
  FlowComponentCardContainerConfiguration
} from "../../../../../_components/flow-components/flow-component-card-container.configuration";
import { CtFileUploaderConfiguration } from "@ctsolution/ct-framework";
import { Validators } from "@angular/forms";
import {
  FlowFileUploaderComponent
} from "../../../../../_components/flow-components/file-uploader/flow-file-uploader.component";
import { FlowFile } from "../../../../../core/interfaces/flow-file";

@Component({
  selector: "app-recipient-list-upload",
  templateUrl: "./recipient-list-upload.component.html",
  styleUrls: ["./recipient-list-upload.component.scss"]
})
export class RecipientListUploadComponent {

  @ViewChild("Uploader") fileUploader!: FlowFileUploaderComponent;
  @Output() updateFlowFileList: EventEmitter<FlowFile[]> = new EventEmitter<FlowFile[]>();

  ctFileUploaderConfiguration: CtFileUploaderConfiguration = CtFileUploaderConfiguration.create()
    .setLimit(1)
    .setControlValidators(Validators.required)
    .setAcceptedFormats([
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"
    ]);

  flowFileUploaderDescriptionWarning: string = `<small>Attenzione: ricordati che il successivo upload è disponibile solo per formati .csv o .xlsx</small>`;
  flowFileTemplateDownloadHtml: string = `<a href="/assets/templates/polipush-multiple-recipient-template.xlsx" download="polipush-multiple-recipient-template.xlsx">template</a>`;
  flowFileUploaderDescription: string = `Scarica il ${this.flowFileTemplateDownloadHtml} e compilalo con la tua lista di destinatari.`;

  flowFileUploaderConfiguration: FlowComponentCardContainerConfiguration =
    FlowComponentCardContainerConfiguration.create()
      .setTitle("I destinatari")
      .setDescription(`${this.flowFileUploaderDescription}<br/>${this.flowFileUploaderDescriptionWarning}`)
      .setIcon("group")
      .setCtFileUploaderConfiguration(this.ctFileUploaderConfiguration);

  constructor() {
  }

  setData(files: FlowFile[]) {

    this.fileUploader.setFiles(files);

  }

  updateFlowList = (event: FlowFile[]) => this.updateFlowFileList.emit(event);

  reset = () => this.fileUploader.reset();
}
