import { Flow } from "../../../../core/classes/flow";
import { CompanyCustomConfiguration } from "../../../../core/interfaces/company-custom-configuration";

export const fileUploaderTabIndex: number = 0;
export const editorTabIndex: number = 1;

export class Step3Model {

  get maxAttachmentsCount(): number | null {
    return this._maxAttachmentsCount;
  }

  private set maxAttachmentsCount(value: number | null) {
    this._maxAttachmentsCount = value;
  }

  get selectedTabIndex(): number {
    return this._selectedTabIndex;
  }

  private set selectedTabIndex(value: number) {
    this._selectedTabIndex = value;
  }

  get editorEnabled(): boolean | null {
    return this._editorEnabled;
  }

  private set editorEnabled(value: boolean | null) {
    this._editorEnabled = value;
  }

  private _editorEnabled: boolean | null = false;
  private _selectedTabIndex: number = fileUploaderTabIndex;
  private _maxAttachmentsCount: number | null = 1;

  private constructor(private flow: Flow | null = null) {

    if (!!flow) {

      if (flow.emailStandaloneChannelSetupEnabled) {

        this
          .setSelectedTabIndex(editorTabIndex)
          .setEditorEnabled(false);

      }

    }

  }

  public static create = (flow: Flow | null = null): Step3Model => new Step3Model(flow);

  setEditorEnabled(value: boolean | null): Step3Model {

    this.editorEnabled = value;
    return this;

  }

  setMaxAttachmentsAccount(value: number | null): Step3Model {

    this.maxAttachmentsCount = value;
    return this;

  }

  setSelectedTabIndex(value: number): Step3Model {

    this.selectedTabIndex = this.flow?.emailStandaloneChannelSetupEnabled ? editorTabIndex : value;
    return this;

  }

  setupByConfiguration(value: CompanyCustomConfiguration | null): Step3Model {

    this
      .setMaxAttachmentsAccount(value?.MaxAttachmentsCount ?? 1)
      .setEditorEnabled(value?.EditorEnabled ?? false);

    return this;

  }

}
