import { Component } from "@angular/core";

export enum TemplateSaveMode {

  insert = 1,
  update = 2

}

@Component({
  selector: "app-template-edit-confirm",
  template: `
    <h1 mat-dialog-title>Conferma Salva Modello</h1>
    <div mat-dialog-content>
      <p>
        Attenzione: hai modificato il contenuto di un modello salvato in precedenza.<br />
        Per continuare scegli se aggiornare il modello esistente o salvarne uno nuovo.
      </p>
    </div>
    <div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">
      <div>
        <button mat-raised-button color="warn" [mat-dialog-close]="false">
          <mat-icon>close</mat-icon>
          Annulla
        </button>
      </div>
      <div>
        <button mat-raised-button color="accent" [mat-dialog-close]="TemplateSaveMode.update">
          Salva e Sovrascrivi
        </button>
        <button mat-raised-button color="primary" [mat-dialog-close]="TemplateSaveMode.insert">
          Salva come Nuovo Modello
        </button>
      </div>

    </div>`
})
export class TemplateEditConfirmComponent {

  protected readonly TemplateSaveMode = TemplateSaveMode;

}
