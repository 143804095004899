<mat-card
  [formGroup]="form"
  class="standalone-single-flow-cntr">

  <mat-card-content>

    <mat-card-title> Scelta di invio</mat-card-title>
    <mat-card-subtitle> Seleziona il tipo di spedizione</mat-card-subtitle>

    <app-product-select #Step1></app-product-select>

  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-card-title> Informazioni di Spedizione</mat-card-title>
    <mat-card-subtitle>Fornisci le informazioni necessarie per il tuo invio</mat-card-subtitle>

    <app-step2 #Step2></app-step2>

  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-card-title> Carica il tuo file PDF</mat-card-title>
    <mat-card-subtitle> Carica il file PDF della tua lettera o documento</mat-card-subtitle>

    <app-file-upload #Step3></app-file-upload>

  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-card-title> Dettagli dell'Ordine</mat-card-title>
    <mat-card-subtitle> Utilizza il campo di testo sottostante per fornire maggiori dettagli sul tuo ordine
    </mat-card-subtitle>

    <app-order-description #Step4></app-order-description>

  </mat-card-content>

  <mat-divider></mat-divider>

  <mat-card-content>

    <mat-card-title> Acquisto diretto</mat-card-title>
    <mat-card-subtitle> Seleziona la checkbox e invia il tuo ordine direttamente, senza passare dal carrello
    </mat-card-subtitle>

    <app-one-click-purchase #OneClickPurchase></app-one-click-purchase>

  </mat-card-content>

  <mat-divider></mat-divider>

  <ct-card [configuration]="GROUP_INFO"></ct-card>

  <mat-card-content>

    <mat-card-actions
      fxLayout="row"
      fxLayoutAlign="space-between center">

      <ct-button [configuration]="cancelButton"></ct-button>

      <ct-button [configuration]="submitButton"></ct-button>

    </mat-card-actions>

  </mat-card-content>

</mat-card>
