import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GetSmartDeliveryFileComponent } from "./get-smart-delivery-file.component";
import { RouterModule } from "@angular/router";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { FlexModule } from "@angular/flex-layout";

@NgModule({
  declarations: [
    GetSmartDeliveryFileComponent
  ],
  imports: [
    CommonModule,
    RouterModule
      .forChild([
        {
          path: "",
          component: GetSmartDeliveryFileComponent
        }
      ]),
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    MatLegacyCardModule,
    FlexModule
  ]
})
export class GetSmartDeliveryFileModule {
}
