import { CtSearchFilterControlConfiguration } from "@ctsolution/ct-framework";
import { GroupCodeFilter } from "./flow-common.parameter";

export class UserListParameter {

  GroupCode?: string;
  Email?: string;
  NameSurname?: string;
  Username?: string;

  constructor() {
  }

}

const EmailFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("Email")
    .setLabel("E-mail");

const NameSurnameFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("NameSurname")
    .setLabel("Nome utente");

export const UserListFilters: CtSearchFilterControlConfiguration[] = [GroupCodeFilter, EmailFilter, NameSurnameFilter];
