import { Component, OnInit, ViewChild } from "@angular/core";
import { Flow } from "../../core/classes/flow";
import { CartOrderConfiguration } from "../../_components/cart-order/cart-order.configuration";
import { FlowController } from "../../core/controllers/flow.controller";
import { FLOW_STATE } from "../../core/enums/flow-state";
import { FlowListFilters, FlowListParameter } from "../../core/classes/flow-list.parameter";
import {
  CtDatatableResult,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration
} from "@ctsolution/ct-framework";

@Component({
  selector: "app-cart",
  templateUrl: "./cart.component.html",
  styleUrls: ["./cart.component.scss"],
  providers: [FlowController]
})
export class CartComponent implements OnInit {

  items: CartOrderConfiguration[] = [];

  filtersConfiguration: CtSearchFiltersConfiguration =
    CtSearchFiltersConfiguration.create()
      .setFilters(FlowListFilters);

  params: FlowListParameter = new FlowListParameter();
  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  createCartOrder = (flow: Flow) => CartOrderConfiguration
    .create()
    .setFlow(flow);

  constructor(private FlowController: FlowController) {
  }

  get cartTotalAmount() {

    return this.items
      .map((item: CartOrderConfiguration) => item.flow.TotalAmount ?? 0)
      .reduce((prev, next) => (prev ?? 0) + (next ?? 0));

  }

  ngOnInit() {

    this.fetch();

  }

  /**
   * The function fetches the active cart and then fetches the cart items
   */
  fetch() {

    this.params.FlowStates = [FLOW_STATE.CART];

    this.FlowController
      .list(this.params)
      .then((result: CtDatatableResult<Flow>) => {

        this.items = result.Items.map((flow: Flow) => this.createCartOrder(flow));

      });

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
