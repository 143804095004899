import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DigitalChannelDescriptionComponent } from "./digital-channel-description.component";

@NgModule({
  declarations: [DigitalChannelDescriptionComponent],
  imports: [CommonModule],
  exports: [
    DigitalChannelDescriptionComponent
  ]
})
export class DigitalChannelDescriptionModule {}
