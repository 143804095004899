import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { ChangePasswordParameter, CtProfileService } from "@ctsolution/ct-authentication";
import { SnackbarService } from "../../../../../core/lib/snackbar.service";
import { MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { equalToValidator, notEqualToValidator, passwordStrengthValidator } from "@ctsolution/ct-framework";

@Component({
  selector: "app-change-password-dialog",
  templateUrl: "./change-password-dialog.component.html",
  styleUrls: ["./change-password-dialog.component.css"]
})
export class ChangePasswordDialogComponent {

  form: FormGroup;

  constructor(
    private profile: CtProfileService,
    private snackbar: SnackbarService,
    private fb: FormBuilder,
    private dialog: MatDialogRef<ChangePasswordDialogComponent>) {

    this.form = this.fb.group({
      Password: new FormControl(null, [Validators.required]),
      NewPassword: new FormControl(null, [Validators.required, Validators.minLength(6), passwordStrengthValidator, notEqualToValidator("Password")]),
      ConfirmPassword: new FormControl(null, [Validators.required, equalToValidator("NewPassword")])
    });

  }

  changePassword() {

    if (this.form.valid) {

      const parameter = new ChangePasswordParameter(this.form.value.Password, this.form.value.NewPassword);

      this.profile.changePassword(parameter)
        .subscribe(() => {

          this.snackbar.open("Password modificata con successo!");
          this.dialog.close();

        });

    }

  }

}
