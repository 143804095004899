<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<mat-card>
  <mat-card-content>

    <mat-card-actions align="end">
      <button mat-raised-button
              color="accent"
              *ngIf="viewModel.isCompanyAdminOrCompanyGroupAdmin"
              (click)="globalVariableFields()">Campi variabili globali
      </button>
      <button mat-raised-button color="primary" (click)="newTemplate()">Nuovo modello</button>
    </mat-card-actions>

    <ct-datatable
      *ngIf="templates"
      [configuration]="templates"
      (reload)="fetch($event)"
      (onEdit)="detail($event)"
      (onDelete)="delete($event)"
    ></ct-datatable>
  </mat-card-content>
</mat-card>
