import { Component, Inject } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  SmartDeliveryConfigurationRow,
  SmartDeliveryConfigurationSaveParameter
} from "../../../../../core/classes/smart-delivery-configuration-save.parameter";
import {
  ISendTypeConfiguration
} from "../../../../../core/interfaces/send-type-configuration";
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import {
  CtControlConfiguration,
  CtControlTypes, CtControlValidator,
  CtSelectControlOptions, CtSelectControlValue,
  CtThemeTypes,
  MaterialControlOptions
} from "@ctsolution/ct-framework";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { SendTypeController } from "../../../../../core/controllers/send-type.controller";

@Component({
  selector: "app-smart-delivery-priority-rule",
  templateUrl: "./smart-delivery-priority-rule.component.html"
})
export class SmartDeliveryPriorityRuleComponent {

  form: FormGroup;

  templateLookupControl: CtControlConfiguration = CtControlConfiguration
    .create()
    .setName("Template")
    .setControl(new FormControl(null))
    .setLabel("Modello")
    .setType(CtControlTypes.LOOKUP)
    .setRemoteValueOptionsEndpoint(DataRequest
      .create()
      .setController(["Template"])
      .setAction("TemplateList")
      .setMethod(MethodEnum.GET)
      .setQueryParams({ hideSpinner: true }))
    .setTheme(CtThemeTypes.MATERIAL)
    .setMaterialOptions(MaterialControlOptions.create().setAppearance("legacy"))
    .setValidators([CtControlValidator.create().setName("required")])
    .configureOptions<CtSelectControlOptions>(options => {

      if (!options) options = CtSelectControlOptions.create();

      options
        .setLookupResponseMapper(response => {

          return response.Result
            .map((elm: any) => CtSelectControlValue
              .create()
              .setLabel(elm.Name)
              .setValue(elm));

        })
        .setLookupFilter((value?: string | number | null) => {
          return {
            Name: value,
            SendCategory: this.sendTypeSendCategoryFilter,
            Page: 1,
            ElementsForPage: 10
          };
        });

    });

  sendTypeList: ISendTypeConfiguration[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private sendTypeController: SendTypeController,
    private dialogRef: MatLegacyDialogRef<SmartDeliveryPriorityRuleComponent, SmartDeliveryConfigurationRow>,
    @Inject(MAT_LEGACY_DIALOG_DATA) public data: SmartDeliveryConfigurationSaveParameter | null) {

    this.form = this.formBuilder.group({
      Priority: new FormControl(null),
      SendTypeConfiguration: new FormControl(null, Validators.required)
    });

    this.templateLookupControl
      ?.setFormParent(this.form)
      ?.setDisabled(true);

    const sendTypeConfigurationControl = this.form.get("SendTypeConfiguration");

    sendTypeConfigurationControl
      ?.valueChanges
      .subscribe(() => {

        const isValid = sendTypeConfigurationControl?.valid;

        this.templateLookupControl
          .setDisabled(!isValid);

        // TODO: il blocco successivo non è che mi piaccia tantissimo eh! Serve a triggerare l'aggiornamento dei valori di lookup
        this.templateLookupControl.setValue(null);
        setTimeout(() => this.templateLookupControl.setValue(""));

      });

  }

  ngOnInit() {

    this.sendTypeController
      .getSendTypeConfigurationList()
      .then(response => {

        // const sendTypeIdsInUse = new Set(this.data?.Rows.map(elm => elm.SendTypeConfiguration?.Oid));
        this.sendTypeList = response;//.filter(elm => !sendTypeIdsInUse.has(elm.Oid)); // essendoci ora le condizioni di attivazione, non è più richiesto questo filtro, posso inserire più volte lo stesso template

      });

  }

  get sendTypeSendCategoryFilter() {

    let sendTypeSendCategory = this.form.value?.SendTypeConfiguration?.SendTypeSendCategory;

    if (sendTypeSendCategory && sendTypeSendCategory.toLowerCase() === "pec") sendTypeSendCategory += ",Email";

    return sendTypeSendCategory ?? null;

  }

  cancel() {

    this.dialogRef
      .close();

  }

  submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value as SmartDeliveryConfigurationRow;

      if ((<any>value.Template)?.value) value.Template = (<any>value.Template)?.value;

      this.dialogRef
        .close(SmartDeliveryConfigurationRow.create(value));

    }

  }

}
