import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CancelOrderSubmissionComponent } from "./cancel-order-submission.component";
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";

@NgModule({
  declarations: [
    CancelOrderSubmissionComponent
  ],
  imports: [
    CommonModule,
    MatProgressBarModule,
    MatButtonModule
  ],
  exports: [
    CancelOrderSubmissionComponent
  ]
})
export class CancelOrderSubmissionModule { }
