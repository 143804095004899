<div class="cart-product-specifications-container">

  <p> Data: {{ (data.SendDate ?? data.EstimateAcceptDate ?? data.CreationDate) | date }}</p>
  <p> Creato da: {{ data.UserInfoName ?? '' }} {{ data.UserInfoSurname ?? '' }} </p>
  <p> Prodotto: {{ data.SendTypeConfigurationSendTypeName ?? '' }}</p>

  <div [hidden]="data?.digitalSendingChannelSetupEnabled">

    <p> Stampa: {{ getColorOption() }}, {{ getPrintOption() }} </p>

  </div>

  <p> Codice ordine: {{ data.Oid }}</p>
  <p *ngIf="data.GroupCode"> {{viewModel.groupLabel}}: {{ data.GroupCode ?? '' }} - {{ data.GroupName ?? '' }}</p>

</div>
