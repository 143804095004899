import { DEFAULT_COUNTRY } from "../enums/destination.enum";
import { CTBase } from "@ctsolution/ct-base";

/**
 * Tutti i campi sono obbligatori.
 * @{Name} maxLength: 100
 * @{Address} maxLength: 100
 * @{ZipCode} maxLength: 10
 * @{City} maxLength: 100
 * @{Province} maxLength: 2
 * @{Country} maxLength: 100
 * */
export class AddressData extends CTBase<AddressData> {

  constructor(
    public Name: string | null = null,
    public Address: string | null = null,
    public ZipCode: string | null = null,
    public City: string | null = null,
    public Province: string | null = null,
    public Country: string = DEFAULT_COUNTRY,
    public Email: string | null = null,
    public Phone: string | null = null
  ) {

    super();

  }

  public static create = (model: AddressData): AddressData => {

    const returnValue: AddressData = new AddressData();
    returnValue.getClass(model);

    return returnValue;

  };

}
