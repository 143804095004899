import { Component } from "@angular/core";
import { CtControlConfiguration, CtThemeTypes } from "@ctsolution/ct-framework";
import { FormBuilder, FormGroup } from "@angular/forms";

const OrderDescriptionConfiguration: CtControlConfiguration = {
  name: "OrderDescription",
  label: "Descrizione ordine",
  theme: CtThemeTypes.MATERIAL,
  validators: [
    { name: "required" }
  ]
} as CtControlConfiguration;

@Component({
  selector: "app-order-description",
  templateUrl: "./order-description.component.html",
  styleUrls: ["./order-description.component.css"]
})
export class OrderDescriptionComponent {

  form: FormGroup;

  controlConfiguration: CtControlConfiguration = CtControlConfiguration
    .create(OrderDescriptionConfiguration);

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.controlConfiguration
      .setFormParent(this.form);

  }

}
