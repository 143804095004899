export enum SmartDeliveryActivationConditionOperator {

  EqualTo = 0,
  DifferentFrom = 1,
  BeginsWith = 50,
  EndsWith = 51,
  Contains = 52

}

export const SmartDeliveryActivationCondition2LabelMapping: Record<SmartDeliveryActivationConditionOperator, string> = {

  [SmartDeliveryActivationConditionOperator.EqualTo]: "Uguale a",
  [SmartDeliveryActivationConditionOperator.DifferentFrom]: "Diverso da",
  [SmartDeliveryActivationConditionOperator.BeginsWith]: "Inizia con",
  [SmartDeliveryActivationConditionOperator.EndsWith]: "Finisce con",
  [SmartDeliveryActivationConditionOperator.Contains]: "Contiene"

};

