import { Component, Input } from "@angular/core";
import { RecipientData } from "../../../core/interfaces/recipient-data";

@Component({
  selector: "app-barcode-cell-template",
  templateUrl: "./barcode-cell-template.component.html",
  styleUrls: ["./barcode-cell-template.component.scss"]
})
export class BarcodeCellTemplateComponent {

  @Input() flow: RecipientData | null = null;

}
