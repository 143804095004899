import { Injectable } from "@angular/core";
import { CtSerializerHelperService, CtWebapiService, DataRequest, MethodEnum } from "@ctsolution/ct-webapi";
import { Observable } from "rxjs";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { TemplateListParameter } from "../classes/template-list.parameter";
import { TemplateParameter } from "../classes/template-parameter";
import { TemplateLiteResponse } from "../interfaces/template-lite-response";
import { TemplateDetailParameter } from "../classes/template-detail-parameter";
import { TemplateVariableField } from "../classes/template-variable-field";
import {
  ImportGlobalVariableFieldsSaveParameter
} from "../classes/import-global-variable-fields-save.parameter";

@Injectable()
export class TemplateController {

  private controller: string = "/Template/";

  constructor(private _webapi: CtWebapiService, private serializerHelperService: CtSerializerHelperService) {
  }

  templateList(parameter: TemplateListParameter | null = null): Observable<CtWebapiGenericResponse<Array<TemplateLiteResponse>>> {

    const request: DataRequest = new DataRequest(`${this.controller}TemplateList`);

    if (parameter) {

      request
        .setQueryParams(parameter);

    }

    return this._webapi
      .get(request);

  }

  templateContent(parameter: TemplateParameter): Observable<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}TemplateContent`)
      .setQueryParams(parameter);

    return this._webapi
      .get(request);

  }

  templateDetail(parameter: TemplateDetailParameter): Observable<CtWebapiGenericResponse<any>> {

    const request: DataRequest = new DataRequest(`${this.controller}Detail`)
      .setQueryParams(parameter);

    return this._webapi
      .get(request);

  }

  templateVariableFields(parameter: TemplateParameter): Observable<CtWebapiGenericResponse<TemplateVariableField[]>> {

    const request: DataRequest = new DataRequest(`${this.controller}TemplateVariableFields`)
      .setQueryParams(parameter);

    return this._webapi
      .get(request);

  }

  importGlobalVariableFields(parameter: ImportGlobalVariableFieldsSaveParameter) {

    const request: DataRequest = new DataRequest(`${this.controller}ImportGlobalVariableFields`)
      .setMethod(MethodEnum.POST)
      .setBody(this.serializerHelperService.objectToFormData(parameter));

    return this._webapi
      .request(request);

  }

  save(parameter: TemplateParameter) {

    const request: DataRequest = new DataRequest(`${this.controller}Save`)
      .setBody(parameter);

    return this._webapi
      .post(request);

  }

  delete(parameter: TemplateParameter) {

    const request: DataRequest = new DataRequest(`${this.controller}Delete`)
      .setQueryParams(parameter);

    return this._webapi
      .delete(request);

  }

}



