import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TemplateSaveFormComponent } from "./template-save-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { TextFieldModule } from "@angular/cdk/text-field";

@NgModule({
  declarations: [
    TemplateSaveFormComponent
  ],
  exports: [
    TemplateSaveFormComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    MatLegacyCheckboxModule,
    MatLegacyFormFieldModule,
    MatLegacyInputModule,
    MatLegacyOptionModule,
    MatLegacySelectModule,
    ReactiveFormsModule,
    TextFieldModule
  ]
})
export class TemplateSaveFormModule {
}
