import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartOrderDetailComponent } from './cart-order-detail.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { CartPersonSpecificationsComponent } from './cart-person-specifications/cart-person-specifications.component';
import { CartProductSpecificationsComponent } from './cart-product-specifications/cart-product-specifications.component';
import { MatIconModule } from '@angular/material/icon';
import { CartMultipleRecipientsListComponent } from './cart-multiple-recipients-list/cart-multiple-recipients-list.component';
import { CtDatatableModule } from "@ctsolution/ct-framework";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import {
  CartFileListDownloadButtonModule
} from "./cart-file-list-download-button/cart-file-list-download-button.module";

@NgModule({
  declarations: [
    CartOrderDetailComponent,
    CartPersonSpecificationsComponent,
    CartProductSpecificationsComponent,
    CartMultipleRecipientsListComponent
  ],
  imports: [CommonModule, FlexLayoutModule, MatCardModule, MatIconModule, CtDatatableModule, MatIconModule, MatButtonModule, CartFileListDownloadButtonModule],
  exports: [CartOrderDetailComponent],
})
export class CartOrderDetailModule {}
