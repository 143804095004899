import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TemplateManagerConfiguration } from "../template-manager.configuration";
import { DeleteConfirmService } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { TemplateSaveService } from "../template-save/template-save.service";
import { JwtService } from "../../../../core/lib/jwt.service";
import { TemplateListParameter } from "../../../../core/classes/template-list.parameter";
import { TemplateController } from "../../../../core/controllers/template.controller";
import { TemplateLiteResponse } from "../../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../../core/classes/template-parameter";

@Component({
  selector: "app-templates-manager",
  templateUrl: "./templates-manager.component.html",
  styleUrls: ["./templates-manager.component.scss"],
  providers: [TemplateController, DeleteConfirmService]
})
export class TemplatesManagerComponent implements OnInit {

  list: Array<TemplateLiteResponse> = new Array<TemplateLiteResponse>();
  loading: boolean = true;
  userInfoOid: number | null = null;

  constructor(
    private dialogRef: MatLegacyDialogRef<TemplatesManagerComponent>,
    private templateController: TemplateController,
    private deleteConfirm: DeleteConfirmService,
    private jwt: JwtService,
    private templateSaveService: TemplateSaveService,
    @Inject(MAT_DIALOG_DATA) public data: TemplateManagerConfiguration) {
  }

  ngOnInit() {

    this.setup()
      .then(() => this.get());

  }

  async setup() {

    this.userInfoOid = await this.jwt.getUserInfoOid();

  }

  get() {

    this.loading = true;

    if (!this.data.parameter?.SendCategory) {

      this.loading = false;
      return;

    }

    this.templateController
      .templateList(
        TemplateListParameter
          .create()
          .setSendCategory(this.data.parameter.SendCategory))
      .subscribe({
        next: (response: any) => {

          this.list = response.Result ?? [];
          this.loading = false;

        },
        error: () => this.loading = false
      });


  }

  onEdit(event: any, item: TemplateLiteResponse) {

    event.stopPropagation();

    if (!this.data.parameter) return;

    const data: TemplateManagerConfiguration = TemplateManagerConfiguration
      .create()
      .setParameter(TemplateParameter.create(item as TemplateParameter));

    this.templateSaveService
      .create(data)
      .afterClosed()
      .subscribe(result => {

        if (result) this.get();

      });

  }

  isOwner = (item: TemplateLiteResponse): boolean => this.userInfoOid === item.UserInfoOid;

  onDelete(event: any, item: TemplateLiteResponse) {

    event.stopPropagation();

    if (!this.data.parameter) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          this.data
            .parameter
            ?.setOid(item.Oid);

          this.templateController
            .delete(this.data.parameter!)
            .subscribe(() => this.get());

        }

      });

  }

  onSelect(template: TemplateLiteResponse) {

    const parameter: TemplateParameter = TemplateParameter.create(template as TemplateParameter);

    this.templateController
      .templateContent(parameter)
      .subscribe((response: CtWebapiGenericResponse<any>) => {

        this.dialogRef
          .close({ content: response.Result, data: template });

      });

  }

}
