import { Component, OnInit } from "@angular/core";
import { CtButtonParameters, CtCardParameters, CtWidgetConfiguration } from "@ctsolution/ct-framework";
import {
  CompanyUiCustomizationService, DEFAULT_LABEL
} from "../../core/lib/company-ui/company.ui-customization.service";
import { GeneralService } from "../../core/lib/general.service";
import { GroupController } from "../../core/controllers/group.controller";
import { GroupService } from "../../core/lib/group.service";
import { RoleService } from "../../core/lib/role.service";
import { groupMenuItem } from "../../shared/menu/menu.items";
import { JwtService } from "../../core/lib/jwt.service";
import { Group } from "../../core/interfaces/group";
import { CompanyCustomConfiguration } from "../../core/interfaces/company-custom-configuration";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [GroupController]
})
export class HomeComponent implements OnInit {

  sendLettersButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this._general.navigateTo(["nuova-spedizione"]);

  })
    .setMaterialIcon("arrow_forward")
    .setContainerClass("text-right");

  sendLettersCardParameters: CtCardParameters = CtCardParameters.create(() => {
  })
    .setTitle("Nuova spedizione")
    .setText("Crea documenti online e spediscili in formato cartaceo o digitale.")
    .setMaterialIcon("local_post_office")
    .setBackgroundClass("bg-warning")
    .setTextColor("text-white")
    .setCtButton(this.sendLettersButtonParameters);

  ordersWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["/ordini"]))
    .setTitle("Ordini")
    .setText("Visualizza gli ordini effettuati.")
    .setBackgroundClass("bg-success")
    .setMaterialIcon("list_alt");

  trackingWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["/tracking"]))
    .setTitle("Tracking")
    .setText("Segui lo stato delle spedizioni")
    .setBackgroundClass("bg-purple")
    .setMaterialIcon("local_shipping");

  quotationsWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["/preventivi"]))
    .setTitle("Preventivi")
    .setText("Gestisci i tuoi preventivi.")
    .setBackgroundClass("bg-megna")
    .setMaterialIcon("fact_check");

  widgets: CtWidgetConfiguration[] = [this.ordersWidget, this.trackingWidget];

  groupWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["/amministrazione", DEFAULT_LABEL.GROUPS.toLowerCase()]))
    .setTitle(DEFAULT_LABEL.GROUPS)
    .setText(`Gestisci i tuoi ${DEFAULT_LABEL.GROUPS.toLowerCase()}`)
    .setBackgroundClass("bg-danger")
    .setMaterialIcon("group");

  usersWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["amministrazione", "utenti"]))
    .setTitle("Utenti")
    .setText(`Gestisci i tuoi utenti`)
    .setBackgroundClass("bg-olive")
    .setMaterialIcon("person");

  settingsWidget: CtWidgetConfiguration = CtWidgetConfiguration.create(() => this._general.navigateTo(["amministrazione", "impostazioni"]))
    .setTitle("Impostazioni")
    .setText(`Gestisci le impostazioni del tuo profilo`)
    .setBackgroundClass("bg-info")
    .setMaterialIcon("admin_panel_settings");

  administrativeWidgets: CtWidgetConfiguration[] = [];

  groupInfoCardParameters?: CtCardParameters;

  constructor(
    private _general: GeneralService,
    private companyUiService: CompanyUiCustomizationService,
    private groupController: GroupController,
    private groupService: GroupService,
    private roleService: RoleService,
    private _jwt: JwtService
  ) {

  }

  ngOnInit() {

    this.setupAdministrativeWidgets()
      .then(() => this.setupQuotationsWidget()
        .then(() => this.setGroupData()));

  }

  setGroupData() {

    this.groupController
      .userGroup()
      .then((userGroup: Group) => {

        const groupResidueBudget: string = this.groupService.getBudgetResidueLabel(userGroup);

        this.groupInfoCardParameters = CtCardParameters
          .create(() => {
          })
          .setTitle(`${userGroup.Code} - ${userGroup.Name}`)
          .setText(`Budget residuo: ${isNaN(+groupResidueBudget) ? groupResidueBudget : (groupResidueBudget + " €")}`);

        this.groupWidget
          .setTitle(groupMenuItem.name)
          .setText(`Gestisci i tuoi ${groupMenuItem.name}`)
          .setAction(() => this._general.navigateTo(["/amministrazione", groupMenuItem.state]));

      });

  }

  private async setupAdministrativeWidgets() {

    const companyConfiguration: CompanyCustomConfiguration | null = await this._jwt.getCompanyCustomConfiguration();

    const hasAdministrativeAccess: boolean = await this.roleService.hasAdministrativeAccess();

    if (hasAdministrativeAccess) {

      this.administrativeWidgets = [this.groupWidget];

      if (companyConfiguration?.UserManagementEnabled) {

        this.administrativeWidgets.push(this.usersWidget);

      }

    }

    if (companyConfiguration?.SettingsManagementEnabled) {

      this.administrativeWidgets.push(this.settingsWidget);

    }

  }

  private async setupQuotationsWidget() {

    const autoAcceptPrintQuote: boolean = await this._jwt.getAutoAcceptPrintQuote();

    if (autoAcceptPrintQuote && this.widgets.includes(this.quotationsWidget)) {

      this.widgets.splice(this.widgets.indexOf(this.quotationsWidget), 1);

    } else if (!autoAcceptPrintQuote && !this.widgets.includes(this.quotationsWidget)) {

      this.widgets.push(this.quotationsWidget);

    }

  }

}
