<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<mat-card>
  <mat-card-content>

    <mat-card-actions align="end">
      <button mat-raised-button color="primary" (click)="manage()">Nuova regola</button>
    </mat-card-actions>

    <ct-datatable
      *ngIf="rules"
      [configuration]="rules"
      (reload)="fetch($event)"
      (onEdit)="manage($event)"
      (onDelete)="delete($event)"
    ></ct-datatable>

  </mat-card-content>
</mat-card>
