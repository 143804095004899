export enum FLOW_STATE {

  STEP_1 = 1,
  STEP_2 = 2,
  STEP_3 = 3,
  STEP_4 = 4,
  PRICE_ESTIMATE = 50, // preventivo
  CART = 60,
  PROCESSING = 70,
  SENT = 99,
  SENT_WITH_ERROR = 0

}
