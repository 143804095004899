import { FLOW_STATE } from "../enums/flow-state";
import { FlowCommon, FlowCommonFilters } from "./flow-common.parameter";
import { CtSearchFilterControlConfiguration } from "@ctsolution/ct-framework";

const DescriptionFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration.create("Description")
    .setLabel("Descrizione");

export class FlowListParameter extends FlowCommon {

  FlowStates?: FLOW_STATE[];
  Description?: string;

  constructor() {
    super();
  }

}

export const FlowListFilters: CtSearchFilterControlConfiguration[] = [...FlowCommonFilters, DescriptionFilter];
