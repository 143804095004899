import { Component } from '@angular/core';

@Component({
  selector: 'app-alert-both-addresses-invalid',
  templateUrl: './alert-both-addresses-invalid.component.html',
  styleUrls: ['./alert-both-addresses-invalid.component.scss'],
})
export class AlertBothAddressesInvalidComponent {
  constructor() {}
}
