import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UserDetailComponent } from "./user-detail/user-detail.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatDividerModule } from "@angular/material/divider";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { UserDeleteComponent } from "./user-delete/user-delete.component";
import { MatIconModule } from "@angular/material/icon";
import { CtControlModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [UserDetailComponent, UserDeleteComponent],
  imports: [CommonModule, MatDialogModule, FlexLayoutModule, MatIconModule, MatInputModule, MatButtonModule, FormsModule, ReactiveFormsModule, MatFormFieldModule, MatDividerModule, MatSelectModule, MatAutocompleteModule, CtControlModule]
})
export class UserDetailModule {
}
