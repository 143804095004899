import { Component, Inject } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-check-brace-matching-alert",
  template: `
    <h1 mat-dialog-title>Attenzione</h1>

    <div mat-dialog-content>

      Nel contenuto sono stati utilizzati dei campi variabili
      ma <b>{{ count }}</b> {{ count > 1 ? 'non sono stati compilati' : 'non è stato compilato' }} correttamente.
      <br /> I
      campi variabili devono essere sempre inclusi dentro tre parentesi graffe (esempio:
      <code>&lbrace;&lbrace;&lbrace;</code> Name <code>&rbrace;&rbrace;&rbrace;</code> )

    </div>

    <div mat-dialog-actions align="end">
      <button mat-raised-button color="warn" mat-dialog-close="">
        Chiudi
      </button>
    </div>
  `
})
export class CheckBraceMatchingAlertComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public count: number) {
  }

}
