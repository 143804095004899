import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SendFlowComponent } from './send-flow.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatStepperModule } from '@angular/material/stepper';
import { MatIconModule } from '@angular/material/icon';
import { Step1Module } from './step1/step1.module';
import { Step3Module } from './step3/step3.module';
import { Step4Module } from './step4/step4.module';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { Step2Module } from './step2/step2.module';
import { StepperActionsModule } from './stepper-actions/stepper-actions.module';
import { FlowController } from "../../../core/controllers/flow.controller";
import { FlowService } from "../../../core/lib/flow.service";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MatStepperModule,
    MatIconModule,
    MatCardModule,
    Step1Module,
    Step2Module,
    Step3Module,
    Step4Module,
    StepperActionsModule,
  ],
  declarations: [SendFlowComponent],
  exports: [SendFlowComponent],
  providers: [FlowController, FlowService]
})
export class SendFlowModule {}
