import { FlowStep } from "./flow-step";
import { FlowFile } from "../../interfaces/flow-file";

export class FlowStep3 extends FlowStep {

  constructor(public Attachments: Array<FlowFile | File>,public Content : FlowFile | File | null) {

    super();

  }

}
