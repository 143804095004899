import { Component } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";

const OneClickPurchaseControlConfiguration: CtControlConfiguration = {
  name: "OneClickPurchase",
  label: "Acquisto diretto",
  theme: CtThemeTypes.MATERIAL,
  type: CtControlTypes.CHECKBOX
} as CtControlConfiguration;

@Component({
  selector: "app-one-click-purchase",
  templateUrl: "./one-click-purchase.component.html",
  styleUrls: ["./one-click-purchase.component.css"]
})
export class OneClickPurchaseComponent {

  form: FormGroup;

  controlConfiguration: CtControlConfiguration = CtControlConfiguration
    .create(OneClickPurchaseControlConfiguration);

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.controlConfiguration
      .setFormParent(this.form);

  }

}
