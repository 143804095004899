<mat-card-content>
  <mat-card-title class="text-uppercase" *ngIf="configuration.title">
    DATI {{ configuration.title }}
    <app-validation-mark
      *ngIf="configuration.verified !== null"
      [verified]="configuration.verified ?? false"
      (verify)="validate()"
    ></app-validation-mark>
  </mat-card-title>

  <form [formGroup]="form">

    <div fxLayout="row wrap">

      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
        <mat-form-field>
          <input
            matInput
            placeholder="Nominativo/Ragione Sociale" formControlName="Name"
            (change)="specialCharsService.replaceControlSpecialChars(form.get('Name'))" />
          <mat-error *ngIf="form.get('Name')?.hasError('required')"> * Campo obbligatorio</mat-error>

          <mat-error
            *ngIf="form.get('Name')?.hasError('pattern') && configuration.errors?.pattern; else wrongMessageDefaultTemplate">
            {{ configuration.errors.pattern }}
          </mat-error>

          <ng-template #wrongMessageDefaultTemplate>
            <mat-error
              *ngIf="form.get('Name')?.hasError('minlength') || form.get('Name')?.hasError('maxlength') || form.get('Name')?.hasError('pattern')">
              * Campo non valido
            </mat-error>
          </ng-template>

        </mat-form-field>
      </div>

    </div>

    <div fxLayout="row wrap"
         [hidden]="configuration.flow?.digitalSendingStandaloneChannelSetupEnabled">

      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
        <mat-form-field>
          <input matInput placeholder="Indirizzo" formControlName="Address" />

          <mat-error *ngIf="form.get('Address')?.hasError('required')"> * Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('Address')?.hasError('maxlength')"> * La lunghezza del campo non può superare
            i {{ form.get('Address')?.getError('maxlength').requiredLength }} caratteri
          </mat-error>

        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">

        <mat-form-field>
          <mat-label>Città</mat-label>
          <input type="text" matInput formControlName="City" [matAutocomplete]="cityAuto">
          <mat-autocomplete #cityAuto="matAutocomplete">
            <mat-option
              *ngFor="let city of localizationService.filteredCities | async"
              [value]="city"> {{ city }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('City')?.hasError('required')"> * Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('City')?.hasError('maxlength')"> * Campo non valido</mat-error>
        </mat-form-field>

      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10">
        <mat-form-field>

          <mat-label>CAP</mat-label>
          <input type="text" matInput formControlName="ZipCode" [matAutocomplete]="zipCodeAuto">
          <mat-autocomplete #zipCodeAuto="matAutocomplete">
            <mat-option
              *ngFor="let zipCode of localizationService.filteredZipCodes | async"
              [value]="zipCode"> {{ zipCode }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('ZipCode')?.hasError('required')">
            * Campo obbligatorio
          </mat-error>
          <mat-error
            *ngIf="form.get('ZipCode')?.hasError('maxlength') || form.get('ZipCode')?.hasError('minlength') || form.get('ZipCode')?.hasError('numericOnly')">
            * Campo non valido.
          </mat-error>
        </mat-form-field>
      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10">

        <mat-form-field>
          <mat-label>Provincia</mat-label>
          <input type="text" matInput formControlName="Province" [matAutocomplete]="provinceAuto" maxlength="2">
          <mat-autocomplete #provinceAuto="matAutocomplete">
            <mat-option *ngFor="let province of localizationService.filteredProvinces | async"
                        [value]="province"> {{ province }}</mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="form.get('Province')?.hasError('required')"> * Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('Province')?.hasError('maxlength')"> * Campo non valido</mat-error>
        </mat-form-field>

      </div>

      <ng-container *ngIf="configuration.externalAreaConfiguration">

        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">

          <mat-form-field>
            <mat-label>Nazione</mat-label>
            <input type="text" matInput formControlName="Country" [matAutocomplete]="countryAuto">
            <mat-autocomplete #countryAuto="matAutocomplete">
              <mat-option *ngFor="let country of localizationService.filteredCountries | async"
                          [value]="country"> {{ country }}</mat-option>
            </mat-autocomplete>

            <mat-error
              *ngIf="form.get('Country')?.hasError('required')">
              * Campo obbligatorio
            </mat-error>
            <mat-error
              *ngIf="!form.get('Country')?.hasError('required') && (form.get('Country')?.hasError('maxlength') || form.get('Country')?.hasError('invalidAutocompleteString'))">
              * Campo non valido
            </mat-error>

          </mat-form-field>
        </div>

      </ng-container>

    </div>

    <div fxLayout="row wrap" [hidden]="!configuration.flow?.digitalSendingChannelSetupEnabled">

      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10"
           [hidden]="!emailControlEnabled">

        <mat-form-field>

          <mat-label>E-mail</mat-label>

          <input matInput placeholder="E-mail" formControlName="Email" type="email" />

          <span *ngIf="configuration?.emailProvider" matSuffix>{{ configuration?.emailProvider }}</span>

          <mat-error *ngIf="form.get('Email')?.hasError('required')"> * Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('Email')?.hasError('email') || form.get('Email')?.hasError('pattern')"> * Campo non
            valido
          </mat-error>

        </mat-form-field>

      </div>

      <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10"
           [hidden]="!phoneControlEnabled">

        <mat-form-field>

          <mat-label>Numero di telefono</mat-label>

          <input matInput placeholder="Telefono" formControlName="Phone" type="tel" />

          <mat-error *ngIf="form.get('Phone')?.hasError('required')"> * Campo obbligatorio</mat-error>
          <mat-error *ngIf="form.get('Phone')?.hasError('pattern')"> * Campo non valido</mat-error>

        </mat-form-field>

      </div>

    </div>

  </form>

</mat-card-content>
