import { Component, ElementRef, Inject, Input, Optional, ViewChild } from "@angular/core";
import { Flow } from "../../../../../core/classes/flow";
import { FileService } from "../../../../../core/lib/file.service";
import { SendTypeSendCategoryEnum } from "../../../../../core/interfaces/send-type-configuration";
import { FlowFile } from "../../../../../core/interfaces/flow-file";
import {
  RecipientFlowPreviewParameter
} from "../../../../../core/classes/base-flow-file-parameter";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-document-preview",
  templateUrl: "./document-preview.component.html",
  styleUrls: ["./document-preview.component.scss"]
})
export class DocumentPreviewComponent {

  @ViewChild("iframe") iframe!: ElementRef;
  previewParameter: RecipientFlowPreviewParameter;
  isDialog: boolean = false;

  constructor(
    private fileHelper: FileService,
    @Optional() @Inject(MAT_DIALOG_DATA) parameter: RecipientFlowPreviewParameter | null = null) {

    this.previewParameter = parameter ?? RecipientFlowPreviewParameter.create();
    this.isDialog = !!parameter;

  }

  ngAfterViewInit() {

    if (this.isDialog) this.setFlowPreviewFrame();

  }

  setup(flow: Flow) {

    this.previewParameter
      .setFlow(flow);

    this.setFlowPreviewFrame();

  }

  setFlowPreviewFrame(parameter: RecipientFlowPreviewParameter = this.previewParameter) {

    if (!parameter?.flow?.Oid && !parameter?.recipient?.FlowOid) return;

    const {
      flowPreviewParameter,
      isFlowDraftDigitalChannel,
      isRecipientDigitalChannel
    } = this.fileHelper.getFlowPreviewParameterFromRecipient(parameter);

    if (isFlowDraftDigitalChannel || isRecipientDigitalChannel) {

      this.fileHelper
        .getStringContentFromFlowFile(flowPreviewParameter)
        .then(result => {

          if (!result) return;

          this.iframe.nativeElement.srcdoc = <string>result;

        });

      return;

    }

    this.fileHelper
      .getFlowPreviewBlobUrl(flowPreviewParameter)
      .subscribe(blob => this.iframe.nativeElement.src = blob);

  }

  downloadFile(file: FlowFile) {

    if (!file) return;

    this.fileHelper.downloadFlowFile(file);

  }

  protected getIcon(): string {

    switch (this.previewParameter.flow?.SendTypeConfigurationSendTypeSendCategory) {

      case SendTypeSendCategoryEnum.SMS:
        return "sms";

      case SendTypeSendCategoryEnum.Email:
        return "alternate_email";

      case SendTypeSendCategoryEnum.PEC:
        return "mark_email_read";

      default:
        return "picture_as_pdf";

    }

  }

  protected getTitle(): string {

    switch (this.previewParameter.flow?.SendTypeConfigurationSendTypeSendCategory) {

      case SendTypeSendCategoryEnum.SMS:
        return "Visualizza l'anteprima del messaggio che hai creato.";

      case SendTypeSendCategoryEnum.Email:
        return "Visualizza l'anteprima della mail che hai creato.";

      case SendTypeSendCategoryEnum.PEC:
        return "Visualizza l'anteprima della PEC che hai creato.";

      default:
        return "Visualizza l'anteprima della lettera che hai creato.";

    }

  }


}
