/**
 * @deprecated now use FlowSendAreaEnum
 */
export enum DESTINATION {
  ITALIA = 'ITALIA',
  ESTERO = 'ESTERO',
}

export const DEFAULT_COUNTRY = 'ITALIA';
export const FOREIGN_PROVINCE = 'EE';
