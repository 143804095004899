import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";

import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarService } from "../../../../core/lib/snackbar.service";
import { Flow } from "../../../../core/classes/flow";
import { TinyMceEditorComponent } from "../../../../_components/flow-components/editor/tiny-mce-editor.component";
import { SmsEditorComponent } from "../../../shipment/send-flow/step3/sms-editor/sms-editor.component";
import { TinyMceSetupConfiguration } from "../../../../_components/flow-components/editor/tiny-mce.setup-configuration";
import { Validators } from "@angular/forms";
import {
  CtButtonConfiguration,
  MAT_RAISED_ACCENT,
  MAT_RAISED_PRIMARY,
  MAT_RAISED_WARN
} from "@ctsolution/ct-framework";
import {
  VariableFieldsManagerService
} from "../../../../_components/flow-components/editor/variable-fields-manager/variable-fields-manager.service";
import {
  TemplateManagerConfiguration
} from "../../../../_components/flow-components/editor/template-manager.configuration";
import {
  TemplateSaveFormComponent
} from "../../../../_components/flow-components/editor/template-save/template-save-form/template-save-form.component";
import {
  LetterSpecifications,
  LetterSpecificationsComponent
} from "../../../shipment/send-flow/step2/letter-specifications/letter-specifications.component";
import { TemplateController } from "../../../../core/controllers/template.controller";
import { TemplateLiteResponse } from "../../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../../core/classes/template-parameter";
import { TemplateDetailParameter } from "../../../../core/classes/template-detail-parameter";

@Component({
  selector: "app-template-detail",
  templateUrl: "./template-detail.component.html",
  styleUrls: ["./template-detail.component.scss"],
  providers: [TemplateController]
})
export class TemplateDetailComponent {

  @ViewChild("TINY_EDITOR") tinyEditor?: TinyMceEditorComponent;
  @ViewChild("SMS_EDITOR") smsEditor?: SmsEditorComponent;
  @ViewChild("form") templateSaveFormComponent: TemplateSaveFormComponent | null = null;
  @ViewChild("specifications") specifications?: LetterSpecificationsComponent | null = null;

  viewModel: {
    templateList: Array<TemplateLiteResponse>;
    templateSaveFormConfiguration: TemplateManagerConfiguration | null;
    fakeFlow: Flow;
  } = {
    templateList: [],
    templateSaveFormConfiguration: null,
    fakeFlow: Flow.create()
  };

  cancelButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Indietro")
    .setAction(() => this.router.navigate(["/", "amministrazione", "modelli"]))
    .setMatherialOptions(MAT_RAISED_WARN);

  submitButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Salva")
    .setAction(() => this.onSubmit())
    .setMatherialOptions(MAT_RAISED_PRIMARY);

  variableFieldsButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Gestione campi variabili")
    .setIcon("settings")
    .setAction(() => this.manageVariableFields())
    .setMatherialOptions(MAT_RAISED_ACCENT);

  constructor(
    private templateController: TemplateController,
    private route: ActivatedRoute,
    private router: Router,
    private snackbar: SnackbarService,
    private cdr: ChangeDetectorRef,
    private variableFieldsManager: VariableFieldsManagerService) {
  }

  get letterSpecificationsEnabled() {

    return !this.viewModel.fakeFlow.smsStandaloneChannelSetupEnabled;

  }

  ngOnInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        if (qp.oid) {

          this.setup(+qp.oid);
          return;

        }

        this.loadingError();

      });

  }

  private loadingError() {

    this.router
      .navigate(["/", "amministrazione", "modelli"])
      .then(() => {

        this.snackbar.open("Si è verificato un errore durante il caricamento del modello. Riprova più tardi.", "X", 3000);

      });

  }

  async setup(oid: number) {

    const currentTemplate: TemplateParameter | null = await this.getDetail(oid);

    if (!currentTemplate) {

      this.loadingError();
      return;

    }

    this.viewModel
      .templateSaveFormConfiguration = TemplateManagerConfiguration
      .create()
      .setParameter(TemplateParameter.create(currentTemplate));

    const configuration = this.viewModel.templateSaveFormConfiguration;

    this.viewModel
      .fakeFlow
      .setSendTypeConfigurationSendTypeSendCategory(configuration.parameter?.SendCategory!);

    if (this.viewModel.fakeFlow.smsStandaloneChannelSetupEnabled) {

      this.smsEditor
        ?.setTemplateManagementEnabled(false);

    } else {

      if (this.letterSpecificationsEnabled) {

        await this.specifications
          ?.setupGroupDetails();

        this.specifications
          ?.form
          .patchValue({
            SendSubject: configuration.parameter?.Subject ?? null,
            ColorMode: configuration.parameter?.PrintColor ?? null,
            PrintMode: configuration.parameter?.PrintMode ?? null
          });

      }

      this.tinyEditor
        ?.setup(
          TinyMceSetupConfiguration.create(this.viewModel.fakeFlow.SendTypeConfigurationSendTypeSendCategory)
            .setTemplateManagementEnabled(false)
            .setValidators(Validators.required));

    }

    if (configuration.parameter?.Content) {

      setTimeout(() => {

        this.currentComponentRef?.control.setValue(configuration?.parameter?.Content);

      });

    }

    this.cdr.detectChanges();

  }

  private async getDetail(Oid: number): Promise<TemplateParameter | null> {

    return new Promise<TemplateParameter | null>(
      (resolve, reject) => {

        this.templateController
          .templateDetail(
            TemplateDetailParameter
              .create()
              .setOid(Oid)
          )
          .subscribe({
            next: response => resolve(response.Result),
            error: err => {

              this.loadingError();
              reject(err);

            }
          });
      });

  }

  private manageVariableFields() {

    const variableFields = this.variableFieldsManager.extractVariableFields(this.currentComponentRef?.control.value);

    if (!variableFields.length) {

      this.snackbar.open("Nessun campo variabile trovato.");
      return;

    }

    const currentFields = this.viewModel.templateSaveFormConfiguration?.parameter?.Fields ?? [];

    const filteredCurrentFields = currentFields
      .filter((currentField: any) => variableFields.some(variableField => variableField.Name === currentField.Name));

    const newFields = variableFields
      .filter(variableField => !filteredCurrentFields.some((currentField: any) => currentField.Name === variableField.Name));

    const mergedFields = [...filteredCurrentFields, ...newFields];

    this.variableFieldsManager
      .open(mergedFields)
      .afterClosed()
      .subscribe(result => {

        if (result) {

          this.viewModel
            .templateSaveFormConfiguration
            ?.parameter
            ?.setFields(result);

        }

      });

  }

  onSubmit() {

    const form = this.templateSaveFormComponent?.form;

    this.currentComponentRef?.control.markAsTouched();

    if (!form || !this.currentComponentRef?.control.valid || !form.valid) return;

    if (this.viewModel.fakeFlow.emailStandaloneChannelSetupEnabled && !this.specifications?.form.get("SendSubject")?.valid) {

      this.specifications?.form.markAllAsTouched();
      return;

    }

    this.viewModel
      .templateSaveFormConfiguration
      ?.parameter
      ?.setFromTemplateSaveForm(form.value)
      ?.setContent(this.currentComponentRef.control.value);

    if (this.letterSpecificationsEnabled) {

      this.viewModel
        .templateSaveFormConfiguration
        ?.parameter
        ?.setLetterSpecifications(LetterSpecifications.create(this.specifications?.form?.value));

    }

    if (!this.viewModel.templateSaveFormConfiguration?.parameter) return;

    this.templateController
      .save(this.viewModel.templateSaveFormConfiguration?.parameter)
      .subscribe(() => {

        this.snackbar.open("Modello salvato correttamente!", "X");

      });

  }

  get currentComponentRef(): SmsEditorComponent | TinyMceEditorComponent | undefined {

    return (this.viewModel.fakeFlow.smsStandaloneChannelSetupEnabled ? this.smsEditor : this.tinyEditor);

  }

}
