<mat-card class="m-b-15 flow-file-uploader-cntr">
  <mat-card-header>
    <div mat-card-avatar class="d-flex">
      <mat-icon class="m-auto" color="primary"> {{ configuration.icon }}</mat-icon>
    </div>
    <mat-card-title> {{ configuration.title }}</mat-card-title>
    <mat-card-subtitle><p [innerHtml]="configuration.description"></p></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <ct-file-uploader
      #Uploader
      [configuration]="configuration.ctFileUploaderConfiguration"
      (delete)="deleteFile($event)"
    ></ct-file-uploader>
  </mat-card-content>
</mat-card>
