import { Component, EventEmitter, Input, Output } from "@angular/core";
import { CartOrderConfiguration } from "../cart-order.configuration";
import { PriceEstimateController } from "../../../core/controllers/price-estimate.controller";
import { SnackbarService } from "../../../core/lib/snackbar.service";
import { GeneralService } from "../../../core/lib/general.service";
import { FlowController } from "../../../core/controllers/flow.controller";
import { DeleteFlowAlertService } from "../delete-flow-alert/delete-flow-alert.service";
import { FlowPurchaseRequest } from "../../../core/classes/flow-purchase-request";
import { CancelOrderSubmissionService } from "../../../shared/cancel-order-submission/cancel-order-submission.service";
import { FlowService } from "../../../core/lib/flow.service";
import { FLOW_TYPE } from "../../../core/enums/flow-type.enum";
import { FileService } from "../../../core/lib/file.service";
import { Router } from "@angular/router";
import { RecipientFlowPreviewParameter } from "../../../core/classes/base-flow-file-parameter";

@Component({
  selector: "app-cart-order-management",
  templateUrl: "./cart-order-management.component.html",
  styleUrls: ["./cart-order-management.component.scss"],
  providers: [PriceEstimateController, FlowController]
})
export class CartOrderManagementComponent {

  @Input() configuration!: CartOrderConfiguration;
  @Output() update: EventEmitter<any> = new EventEmitter<any>();

  FLOW_TYPE = FLOW_TYPE;

  constructor(
    private priceEstimateController: PriceEstimateController,
    private _snackbar: SnackbarService,
    private _general: GeneralService,
    private flowController: FlowController,
    private DeleteFlowAlertService: DeleteFlowAlertService,
    private cancelOrderSubmissionService: CancelOrderSubmissionService,
    private flowService: FlowService,
    private fileService: FileService,
    private router: Router
  ) {
  }

  download() {

    const parameter: RecipientFlowPreviewParameter = RecipientFlowPreviewParameter
      .create()
      .setFlow(this.configuration.flow);

    this.fileService.manageRecipientFlowPreview(parameter);

  };

  hasAvailableTotalPrice = (): boolean => (this.configuration.flow.TotalAmount ?? 0) > 0;

  cancelButtonLabel() {

    if (this.configuration.hasDialogCallback) {

      return "Chiudi";

    }

    if (this.configuration.estimatedPriceManagementEnabled) {

      return "Rifiuta";

    }

    return "Cancella";

  }

  acceptButtonLabel() {

    if (this.configuration.estimatedPriceManagementEnabled) {

      return "Accetta";

    }

    return "Invia";

  }

  tracking() {

    this.router
      .navigate(["/", "tracking"], {
        queryParams: {
          FlowOid: this.configuration.flow.Oid,
          FlowDescription: this.configuration.flow.Description
        }
      })
      .then(() => this.update.emit());

  }

  cancel() {

    if (this.configuration.hasDialogCallback) {

      this.update.emit();
      return;

    }

    this.DeleteFlowAlertService
      .open()
      .afterClosed()
      .subscribe((result) => {

        if (result) {

          this.flowController
            .delete(this.configuration!.flow.Oid)
            .then((result: boolean) => {

              if (result) {

                this.update.emit();

                this.flowService
                  .getCartCount()
                  .then(() => this._snackbar.open("Ordine cancellato correttamente!", "X"));

              }

            });

        }

      });

  }

  accept() {

    if (this.configuration.estimatedPriceManagementEnabled) {

      this.acceptPriceEstimate();

    } else {

      this.cancelOrderSubmissionService
        .handleCancellation()
        .then((proceed: boolean) => {

          if (proceed) {

            this.sendFlow();

          }

        });

    }

  }

  acceptPriceEstimate() {

    this.priceEstimateController
      .acceptFlowPriceEstimate(this.configuration.flow.Oid!)
      .subscribe(() => {

        this.flowService
          .getCartCount();

        this.update.emit();

        this._snackbar.open(
          "Preventivo approvato correttamente!",
          "Vai al carrello")
          .onAction()
          .subscribe(() => this._general.navigateTo(["/carrello"]));

      });

  }

  sendFlow() {

    const purchaseRequest: FlowPurchaseRequest = new FlowPurchaseRequest(false, [this.configuration.flow.Oid]);
    this.flowController
      .purchase(purchaseRequest)
      .then((success: boolean) => {

        if (success) {

          this.flowService
            .getCartCount().then(() => {

            this._snackbar.open("Ordine inviato!", "X");
            this.update.emit();

          });

        }

      });

  }

}
