import { CtFileUploaderConfiguration, CtFileUploaderFormatExceptionConfig } from "@ctsolution/ct-framework";
import { SendTypeSendCategoryEnum } from "../../core/interfaces/send-type-configuration";
import { Flow } from "../../core/classes/flow";
import { Step3Model } from "../../pages/shipment/send-flow/step3/step3.model";
import { Validators } from "@angular/forms";

const DEFAULT_UPLOAD_SIZE: number = 10; //in MB
const MAX_ZIP_UPLOAD_SIZE: number = 500; //in MB
const FILE_UPLOADER_ACCEPTED_FORMATS: string[] = ["application/pdf"]; // , "image/jpeg", "image/png", "text/html"
const FILE_UPLOADER_ARCHIVE_FORMATS: string[] = ["zip", "application/octet-stream", "application/zip", "application/x-zip", "application/x-zip-compressed"];

const FILE_UPLOADER_DEFAULT_CONFIGURATION: CtFileUploaderConfiguration = CtFileUploaderConfiguration
  .create()
  .setSize(DEFAULT_UPLOAD_SIZE)
  ?.setHint(`N.B. Potrai caricare file fino ad un massimo ${DEFAULT_UPLOAD_SIZE} MB`)
  .setControlValidators(Validators.required)
  .setAcceptedFormats(FILE_UPLOADER_ACCEPTED_FORMATS)
  .setFileUploaderFormatExceptionConfig(CtFileUploaderFormatExceptionConfig.create().setTitle("Attenzione"));

export class FlowComponentCardContainerConfiguration {
  get sendTypeSendCategory(): SendTypeSendCategoryEnum | null {
    return this._sendTypeSendCategory;
  }

  private set sendTypeSendCategory(value: SendTypeSendCategoryEnum | null) {
    this._sendTypeSendCategory = value;
  }

  private _icon: string | null = null;
  get icon() {
    return this._icon;
  }

  private set icon(value: string | null) {
    this._icon = value;
  }

  private _title: string | null = null;
  get title() {
    return this._title;
  }

  private set title(value: string | null) {
    this._title = value;
  }

  private _description: string | null = null;
  get description() {
    return this._description;
  }

  private set description(value: string | null) {
    this._description = value;
  }

  private _CtFileUploaderConfiguration: CtFileUploaderConfiguration | null = null;

  get ctFileUploaderConfiguration() {
    return this._CtFileUploaderConfiguration;
  }

  private set ctFileUploaderConfiguration(value: CtFileUploaderConfiguration | null) {
    this._CtFileUploaderConfiguration = value;
  }

  private _sendTypeSendCategory: SendTypeSendCategoryEnum | null = null;

  constructor() {
  }

  public static create = (): FlowComponentCardContainerConfiguration => new FlowComponentCardContainerConfiguration();

  setTitle(value: string): FlowComponentCardContainerConfiguration {
    this.title = value;
    return this;
  }

  setDescription(value: string): FlowComponentCardContainerConfiguration {
    this.description = value;
    return this;
  }

  setIcon(value: string): FlowComponentCardContainerConfiguration {
    this.icon = value;
    return this;
  }

  setCtFileUploaderConfiguration(value: CtFileUploaderConfiguration): FlowComponentCardContainerConfiguration {

    this.ctFileUploaderConfiguration = value;
    return this;

  }

  setSendTypeSendCategory(value: SendTypeSendCategoryEnum | null): FlowComponentCardContainerConfiguration {

    this.sendTypeSendCategory = value;
    return this;

  }

  setCtFileUploaderDefaultConfiguration() {

    return this.setCtFileUploaderConfiguration(FILE_UPLOADER_DEFAULT_CONFIGURATION);

  }

  setupConfigurationFromFlow(flow: Flow, model?: Step3Model): FlowComponentCardContainerConfiguration {

    this.setSendTypeSendCategory(flow?.SendTypeConfigurationSendTypeSendCategory);

    if (model instanceof Step3Model) {

      let PPFileUploaderCardDescription = `Carica ${(model?.maxAttachmentsCount ?? 1) > 1 ? "i" : "il"} file che vuoi inviare in formato .pdf`;

      let formatExceptionMessage = "Sembra che tu stia provando a caricare un file non compatibile con questa tipologia di invio.";
      let acceptedFormats = FILE_UPLOADER_ACCEPTED_FORMATS;
      let maxUploadSize = DEFAULT_UPLOAD_SIZE;

      if (flow.dynamicRecipientsSetupEnabled) {

        acceptedFormats = FILE_UPLOADER_ARCHIVE_FORMATS;
        maxUploadSize = MAX_ZIP_UPLOAD_SIZE;
        PPFileUploaderCardDescription = "Carica l'archivio che vuoi inviare in formato .zip";
        formatExceptionMessage += `Ricordati che la lista dei destinatari deve essere caricata solo per l'invio multiplo, nella fase precedente. In questo passaggio, è necessario caricare lo zip contenente i file PDF con le comunicazioni da inviare.`;

      } else {

        formatExceptionMessage += `In questo passaggio, è necessario caricare l'allegato PDF contenente la comunicazione da inviare.`;

      }

      this.setDescription(PPFileUploaderCardDescription);

      this.ctFileUploaderConfiguration
        ?.setAcceptedFormats(acceptedFormats)
        ?.setFiles([])
        ?.setLimit(model?.maxAttachmentsCount ?? 1)
        ?.setSize(maxUploadSize)
        ?.setHint(`N.B. Potrai caricare file fino ad un massimo ${maxUploadSize} MB`)
        ?.fileUploaderFormatExceptionConfig
        ?.setDescription(formatExceptionMessage);

      if (flow.digitalSendingStandaloneChannelSetupEnabled) {

        PPFileUploaderCardDescription = `Carica ${(model?.maxAttachmentsCount ?? 1) > 1 ? "i" : "il"} file che vuoi allegare in formato .pdf`;
        this.setDescription(PPFileUploaderCardDescription);

        if (!flow.OTPLetterStandaloneChannelSetupEnabled) {

          this.ctFileUploaderConfiguration
            ?.setControlValidators(null);

        }

      }

    }

    return this;

  }

}
