import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TemplatesManagerComponent } from "./templates-manager.component";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { FlexLayoutModule, FlexModule } from "@angular/flex-layout";
import { MatListModule } from "@angular/material/list";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CtSkeletonModule } from "@ctsolution/ct-framework";
import { TemplateSaveModule } from "../template-save/template-save.module";
import { MatDividerModule } from "@angular/material/divider";


@NgModule({
  declarations: [
    TemplatesManagerComponent
  ],
  imports: [
    CommonModule,
    MatIconModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    FlexModule,
    FlexLayoutModule,
    MatListModule,
    FormsModule,
    ReactiveFormsModule,
    CtSkeletonModule,
    TemplateSaveModule,
    MatDividerModule
  ]
})
export class TemplatesManagerModule {
}
