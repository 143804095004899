<div class="blank-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>
        <form [formGroup]="form" (ngSubmit)="onSubmit()">
          <div class="text-center">
            <img alt="homepage" src="assets/images/logo/logo.svg" height="55" />
            <h4 class="m-t-0">Entra in Polipush</h4>
          </div>

          <div fxLayout="row wrap">
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input matInput placeholder="Email" [formControl]="form.controls['Email']" />
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['Email'].hasError('required') && form.controls['Email'].touched
                "
                class="text-danger support-text"
              >* Campo obbligatorio</small
              >
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <mat-form-field>
                <input
                  matInput
                  type="Password"
                  placeholder="Password"
                  [formControl]="form.controls['Password']"
                />
              </mat-form-field>
              <small
                *ngIf="
                  form.controls['Password'].hasError('required') &&
                  form.controls['Password'].touched
                "
                class="text-danger support-text"
              >* Campo obbligatorio</small
              >
            </div>
            <!-- col half-->
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100">
              <mat-checkbox color="warn" formControlName="RememberMe">Ricordati di me</mat-checkbox>
            </div>
            <!-- col half-->
            <div fxFlex.gt-sm="50" fxFlex.gt-xs="50" fxFlex.xs="100" class="text-right">
              <a [routerLink]="['/autenticazione/recupero-password']" class="link"
              >Password dimenticata?</a
              >
            </div>
            <!-- col full-->
            <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100">
              <button
                mat-raised-button
                color="primary"
                class="btn-block btn-lg m-t-20 m-b-20"
                type="submit"
                [disabled]="!form.valid"
              >
                Login
              </button>
            </div>
            <!-- col full-->
            <!--   <div fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" class="text-center">
                 <span
                   >Non sei ancora registrato?
                   <a [routerLink]="['/autenticazione/registrazione']" class="text-info link"
                     >Registrati ora</a
                   >
                 </span>
               </div>-->
          </div>
        </form>
      </mat-card-content>
    </mat-card>
  </div>
</div>
