import { Component, ViewChild } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GroupSender } from "../../../../../core/classes/group-sender";
import {
  PersonSpecificationsComponent
} from "../../../../shipment/send-flow/step2/person-specifications/person-specifications.component";
import { Subscription } from "rxjs";
import { AddressData } from "../../../../../core/classes/address-data";
import { SnackbarService } from "../../../../../core/lib/snackbar.service";
import {
  PERSON_SPECIFICATION_TYPE,
  PersonSpecificationsConfiguration
} from "../../../../shipment/send-flow/step2/person-specifications/person-specifications.configuration";

@Component({
  selector: "app-group-address",
  templateUrl: "./group-address.component.html",
  styleUrls: ["./group-address.component.scss"]
})
export class GroupAddressComponent {

  @ViewChild("AddressData") addressData!: PersonSpecificationsComponent;
  personSpecificationsComponentConfiguration: PersonSpecificationsConfiguration = new PersonSpecificationsConfiguration()
    .setPersonSpecificationType(PERSON_SPECIFICATION_TYPE.SENDER);

  form: FormGroup;
  subscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private _snackbar: SnackbarService) {

    this.form = this.fb.group({
      AutoFillSenderData: new FormControl(false)
    });

    this.subscription = this.autoFillSenderDataSubscription();

  }

  ngAfterViewInit() {

    this.form.addControl("AddressData", this.addressData!.form);
    this.addressData.form.setParent(this.form);

  }

  /**
   * It sets the form values to the values of the groupSender object
   * @param {GroupSender} groupSender - GroupSender - the object that contains the data to be filled in the form.
   */
  setFormValue(groupSender: GroupSender | null) {

    this.form.get("AutoFillSenderData")?.setValue(groupSender?.AutoFillSenderData ?? false);

    if (this.form.value.AutoFillSenderData && groupSender) {

      this.addressData.setFormValue(groupSender);

    } else {

      this.addressData.form.disable();

    }

    this.form.updateValueAndValidity();

  }

  /* A subscription to the value changes of the AutoFillSenderData form control. */
  autoFillSenderDataSubscription = () => this.form
    .get("AutoFillSenderData")?.valueChanges
    .subscribe((enabled: boolean) => {

      if (enabled) {

        this.addressData.form.enable();

      } else {

        this.addressData.form.disable();

      }

      this.form.updateValueAndValidity();
      this.form.markAllAsTouched();

    });


  ngOnDestroy() {

    this.subscription?.unsubscribe();

  }

}
