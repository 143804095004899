<mat-card class="document-preview-cntr">
  <mat-card-header>
    <mat-icon mat-card-avatar>{{getIcon()}}</mat-icon>
    <mat-card-title>{{getTitle()}}</mat-card-title>
    <mat-card-subtitle>
      Nell'anteprima non vengono mostrati i dati del destinatario reale, che saranno visibili una
      volta inviata la spedizione
    </mat-card-subtitle>
  </mat-card-header>
  <mat-card-content>

    <ng-container
      *ctSkeleton="!previewParameter?.flow?.Oid;
           repeat: 1;
           className: 'preview-skeleton';">

      <div
        class="preview-iframe-cntr"
        [class.smartphone]="previewParameter?.flow?.smsStandaloneChannelSetupEnabled">

        <iframe class="content" toolbar="0" view="FitV" #iframe></iframe>

      </div>

      <ng-container
        *ngIf="previewParameter?.flow?.emailStandaloneChannelSetupEnabled && (previewParameter.flow.flowPdfAttachments() ?? []).length">

        <div class="additional-pdf">

          Allegati:

          <ul>
            <li *ngFor="let element of previewParameter.flow.flowPdfAttachments()"> {{ element.Name }}

              <button mat-icon-button color="primary" (click)="downloadFile(element)">

                <mat-icon> file_download</mat-icon>

              </button>
            </li>
          </ul>

        </div>

      </ng-container>

    </ng-container>

    <mat-card-actions *ngIf="isDialog">
      <button mat-button [mat-dialog-close] color="primary">Chiudi</button>
    </mat-card-actions>

  </mat-card-content>

</mat-card>
