<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<mat-card>
  <mat-card-content>

    <ng-container *ngIf="hasAdministrativeAccess">

      <mat-card-actions align="end">
        <button mat-raised-button color="primary" (click)="newUser()">Nuovo utente
        </button>
      </mat-card-actions>

    </ng-container>

    <ct-datatable
      *ngIf="users"
      [configuration]="users"
      (reload)="fetch($event)"
      (onEdit)="detail($event)"
      (onDelete)="delete($event)"></ct-datatable>
  </mat-card-content>
</mat-card>

<ng-template #cdcCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value ?? '' }} - {{ row.GroupName ?? '' }}</p>
  </div>

</ng-template>

<ng-template #roleCellTemplate let-row="row" let-value="value" let-i="index">

  <div>
    <p> {{ value ? RoleType2LabelMapping[value.toLowerCase()] : '' }}</p>
  </div>

</ng-template>
