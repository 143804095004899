import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartComponent } from './cart.component';
import { CartOrderModule } from '../../_components/cart-order/cart-order.module';
import { CartTotalModule } from './cart-total/cart-total.module';
import { CtSearchFiltersModule } from '@ctsolution/ct-framework';
import { CancelOrderSubmissionModule } from '../../shared/cancel-order-submission/cancel-order-submission.module';
import { PurchaseErrorsDialogComponent } from './purchase-errors-dialog/purchase-errors-dialog.component';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyListModule as MatListModule } from "@angular/material/legacy-list";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";

@NgModule({
  declarations: [CartComponent, PurchaseErrorsDialogComponent],
  imports: [
    CommonModule,
    CartOrderModule,
    CartTotalModule,
    CtSearchFiltersModule,
    CancelOrderSubmissionModule,
    MatDialogModule,
    MatListModule,
    MatIconModule,
    MatButtonModule
  ],
})
export class CartModule {}
