import { CTBase } from "@ctsolution/ct-base";
import { ISendTypeConfiguration } from "../interfaces/send-type-configuration";
import { TemplateLiteResponse } from "../interfaces/template-lite-response";
import { SmartDeliveryRuleRowActivationCondition } from "./smart-delivery-rule-row-activation-condition";

export class SmartDeliveryConfigurationSaveParameter extends CTBase<SmartDeliveryConfigurationSaveParameter> {

  Oid: number | null = null;
  ApiCode: string | null = null;
  Name: string | null = null;
  Description: string | null = null;
  Enabled: boolean = false;
  Rows: Array<SmartDeliveryConfigurationRow> = new Array<SmartDeliveryConfigurationRow>();

  override createListElementClassByProperty(value: any, attribute: string): SmartDeliveryConfigurationRow | null {

    switch (attribute) {

      case "Rows":
        return SmartDeliveryConfigurationRow.create(value);
      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: SmartDeliveryConfigurationSaveParameter);
  private constructor(model?: SmartDeliveryConfigurationSaveParameter) {

    super();

    if (model) {

      this.getClass(model);

      if (!this.Rows) this.Rows = new Array<SmartDeliveryConfigurationRow>();

    }

  }

  public static create = (model?: SmartDeliveryConfigurationSaveParameter): SmartDeliveryConfigurationSaveParameter => new SmartDeliveryConfigurationSaveParameter(model);

  setOid(value: number | null) {

    this.Oid = value;
    return this;

  }

  setRows(value: Array<SmartDeliveryConfigurationRow>) {

    this.Rows = value;
    return this;

  }

}

export interface SmartDeliveryConfigurationRowFile {
  Name: string;
  Oid: number;
  Private: false;
  CreateAt: Date;
  PublicId: string;
}

export class SmartDeliveryConfigurationRow extends CTBase<SmartDeliveryConfigurationRow> {

  Oid: number | null = null;
  Priority: number | null = null;
  SendTypeConfiguration: ISendTypeConfiguration | null = null;
  Template: TemplateLiteResponse | null = null;
  ActivationConditions: Array<SmartDeliveryRuleRowActivationCondition> = new Array<SmartDeliveryRuleRowActivationCondition>();
  Files: Array<SmartDeliveryConfigurationRowFile> = new Array<SmartDeliveryConfigurationRowFile>();

  override createListElementClassByProperty(value: any, attribute: string): SmartDeliveryRuleRowActivationCondition | SmartDeliveryConfigurationRowFile | null {

    switch (attribute) {

      case "Files":
        return value as SmartDeliveryConfigurationRowFile;

      case "ActivationConditions":
        return SmartDeliveryRuleRowActivationCondition.create(value);

      default:
        super.createListElementClassByProperty(value, attribute);
        return null;

    }

  }

  private constructor();
  private constructor(model?: SmartDeliveryConfigurationRow);
  private constructor(model?: SmartDeliveryConfigurationRow) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: SmartDeliveryConfigurationRow): SmartDeliveryConfigurationRow => new SmartDeliveryConfigurationRow(model);

  setPriority(value: number | null) {

    this.Priority = value;
    return this;

  }

}

export class SmartDeliveryConfigurationRowFileParameter {

  FileOid?: number;
  Private: boolean = false;

  constructor(public SmartDeliveryConfigurationRowOid: number, public File: File) {
  }

  public static create = (smartDeliveryConfigurationRowOid: number, file: File): SmartDeliveryConfigurationRowFileParameter => new SmartDeliveryConfigurationRowFileParameter(smartDeliveryConfigurationRowOid, file);

  setPrivate(value: boolean) {

    this.Private = value;
    return this;

  }

}
