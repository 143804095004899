<div class="blank-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>

        <div class="text-center">
          <img alt="homepage" src="assets/images/logo/logo.svg" height="55" />
          <h4 class="m-t-0">Disconnesso con successo</h4>
        </div>

        <p class="text-muted m-t-30 m-b-30">Ti sei disconnesso da Gestione Raccomandate.<br /><br />Puoi accedere nuovamente
          selezionando la procedura dalla intranet aziendale (BLink).</p>

      </mat-card-content>
    </mat-card>
  </div>
</div>
