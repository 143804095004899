import { STEPPER_GLOBAL_OPTIONS } from "@angular/cdk/stepper";
import { ChangeDetectorRef, Component, OnInit, ViewChild } from "@angular/core";
import { Step2Component } from "./step2/step2.component";
import { Step1Component } from "./step1/step1.component";
import { Step3Component } from "./step3/step3.component";
import { Step4Component } from "./step4/step4.component";
import { MatStep, MatStepper } from "@angular/material/stepper";
import { GeneralService } from "../../../core/lib/general.service";
import { FlowService } from "../../../core/lib/flow.service";
import { SnackbarService } from "../../../core/lib/snackbar.service";
import { MediaMatcher } from "@angular/cdk/layout";
import { FLOW_STATE } from "../../../core/enums/flow-state";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { JwtService } from "../../../core/lib/jwt.service";
import { StepSaveResult } from "../../../core/controllers/flow-steps.controller";

@Component({
  selector: "app-send-flow",
  templateUrl: "./send-flow.component.html",
  styleUrls: ["./send-flow.component.scss"],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: false }
    }
  ]
})
export class SendFlowComponent implements OnInit {

  @ViewChild("Stepper") stepper!: MatStepper;

  mobileQuery: MediaQueryList;
  // tslint:disable-next-line - Disables all
  private readonly _mobileQueryListener: () => void;

  //#region Steps

  @ViewChild("STEP_ONE") stepOneComponent!: Step1Component;
  @ViewChild("STEP_TWO") stepTwoComponent!: Step2Component;
  @ViewChild("STEP_THREE") stepThreeComponent!: Step3Component;
  @ViewChild("STEP_FOUR") stepFourComponent!: Step4Component;

  //#endregion Steps

  constructor(
    private _jwt: JwtService,
    private _snackbar: SnackbarService,
    private _general: GeneralService,
    private flowService: FlowService,
    private changeDetectorRef: ChangeDetectorRef,
    private _authentication: CtAuthenticationService,
    media: MediaMatcher
  ) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    // tslint:disable-next-line: deprecation
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngOnInit() {

    this.updateProgress();

  }

  updateProgress(stepSaveResult?: StepSaveResult) {

    const flowProceed = () => {

      if (this.flowService.activeFlow.State > FLOW_STATE.STEP_4) {

        this.conclude();

      } else {

        this.markDoneStepsAsCompleted();

      }

    };

    if (stepSaveResult) {

      this.flowService.updateCurrentFlowValues(stepSaveResult);
      flowProceed();

    } else {

      this._authentication
        .getClaims()
        .then(() => this.flowService
          .setupCurrentFlow()
          .then(() => flowProceed()));

    }

  }

  /**
   * It loops through each step in the stepper and marks it as completed if the step is less than the current step in the
   * flow
   */
  markDoneStepsAsCompleted() {

    const flowState: FLOW_STATE = this.flowService.activeFlow.State;

    if (flowState === FLOW_STATE.STEP_1) return;

    this.stepper
      .steps
      .forEach((item: MatStep, index: number) => item.completed = index < flowState);

    this.moveStepperToFlowState(flowState - 1);

    this.changeDetectorRef.detectChanges();

  }

  back = () => this.moveStepperToFlowState(this.stepper.selectedIndex - 1);

  /* Calling the submit function of the current step. */
  next = () => this.getCurrentStepComponent(this.stepper.selectedIndex).submit();

  private moveStepperToFlowState(nextStepperIndex: number) {

    this.getCurrentStepComponent(nextStepperIndex).fetch();

    this.stepper.selectedIndex = nextStepperIndex; // gli indici dello stepper partono da 0

  }

  /* A function that is called when the user clicks on the close button in the stepper. It unsubscribes from the
  subscription and then calls the resetFlow function of the SendFlowService. */
  private conclude() {

    let nextPath: string = "preventivi";

    this._authentication
      .getClaims()
      .then(async () => {

        const autoAcceptPrintQuote = await this._jwt.getAutoAcceptPrintQuote();

        if (autoAcceptPrintQuote) {

          nextPath = "carrello";
          this.flowService
            .getCartCount();

        }

        this._general
          .navigateTo([`/${nextPath}`])
          .then(() => {

            this.flowService.resetFlow();
            this._snackbar.open("Ordine completato con successo!");

          });

      });

  };

  /* A function that returns the current step component. */

  // @ts-ignore
  private getCurrentStepComponent(stepperIndex: number): Step1Component | Step2Component | Step3Component | Step4Component {

    switch (stepperIndex) {
      case 0:
        return this.stepOneComponent;
      case 1:
        return this.stepTwoComponent;
      case 2:
        return this.stepThreeComponent;
      case 3:
        return this.stepFourComponent;
    }

  };

  ngOnDestroy() {

    // tslint:disable-next-line: deprecation
    this.mobileQuery.removeListener(this._mobileQueryListener);

    this._authentication
      .getClaims();

  }

}
