import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarService } from "../../core/lib/snackbar.service";
import { CompanyUiSettings } from "../../core/lib/company-ui/company.ui-settings";
import { CompanyUiCustomizationService } from "../../core/lib/company-ui/company.ui-customization.service";

@Component({
  selector: "app-newsletter-unsubscribe",
  templateUrl: "./newsletter-unsubscribe.component.html",
  styleUrls: ["./newsletter-unsubscribe.component.scss"]
})
export class NewsletterUnsubscribeComponent {

  viewModel: any = {

    serviceEmail: null,
    logoSrc: "/assets/images/logo/logo.svg",
    mailTo: null

  };

  constructor(private route: ActivatedRoute, private router: Router, private snackbar: SnackbarService, private companyUiService: CompanyUiCustomizationService) {

    this.route
      .queryParams
      .subscribe(qp => {

        if (!qp["service_email"]) {

          this.router
            .navigate(["/"])
            .then(() => this.snackbar.open(`Si è verificato un'errore imprevisto. Riprova più tardi.`));

          return;

        }

        this.viewModel.serviceEmail = qp["service_email"];
        this.viewModel.mailTo = `mailto:${this.viewModel.serviceEmail}`;

        if (qp["company_macro_code"]) {

          this.companyUiService
            .getSettings(qp["company_macro_code"])
            .then((company: CompanyUiSettings | undefined) => {

              if (company) {

                company
                  .getLogo()
                  .then(data => this.viewModel.logoSrc = data.src);

              }

            });

        }

      });

  }

}
