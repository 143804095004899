import { Component, OnInit } from "@angular/core";
import { CtControlConfiguration, CtControlTypes, CtSelectControlValue, CtThemeTypes } from "@ctsolution/ct-framework";
import { ISendTypeConfiguration } from "../../../../core/interfaces/send-type-configuration";
import { SendTypeController } from "../../../../core/controllers/send-type.controller";
import { FormBuilder, FormGroup } from "@angular/forms";

const ProductSelectConfiguration: CtControlConfiguration = {
  name: "SendType",
  label: "Prodotto",
  theme: CtThemeTypes.MATERIAL,
  type: CtControlTypes.ENUMERABLE,
  validators: [
    { name: "required" }
  ]
} as CtControlConfiguration;

@Component({
  selector: "app-product-select",
  templateUrl: "./product-select.component.html",
  styleUrls: ["./product-select.component.css"],
  providers: [SendTypeController]
})
export class ProductSelectComponent implements OnInit {

  form: FormGroup;

  controlConfiguration: CtControlConfiguration = CtControlConfiguration
    .create(ProductSelectConfiguration);

  constructor(private _controller: SendTypeController, private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.controlConfiguration
      .setFormParent(this.form);

  }

  ngOnInit(): void {

    this.getProducts();

  }

  getProducts() {

    this._controller
      .getSendTypeConfigurationList()
      .then((result: ISendTypeConfiguration[]) => {

        const valueOptions: Array<CtSelectControlValue> = result.map(elm => CtSelectControlValue.create()
          .setLabel(elm.SendTypeName)
          .setValue(elm.SendTypeOid.toString()));

        this.controlConfiguration
          .setValueOptions(valueOptions);

      });

  }

}
