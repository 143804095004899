import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import {
  SmartDeliveryConfigurationSaveParameter
} from "../../../core/classes/smart-delivery-configuration-save.parameter";
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CTGeneralService,
  CtSearchFiltersComponent, CtSearchFiltersConfiguration, DeleteConfirmService
} from "@ctsolution/ct-framework";
import { TableColumn } from "@swimlane/ngx-datatable";
import {
  SmartDeliveryRuleListFilters,
  SmartDeliveryConfigurationListParameter
} from "../../../core/classes/smart-delivery-configuration-list.parameter";
import { ListService } from "../../../core/lib/list.service";
import { SmartDeliveryDetailService } from "./smart-delivery-detail/smart-delivery-detail.service";
import {
  SmartDeliveryConfigurationController
} from "../../../core/controllers/smart-delivery-configuration.controller";

@Component({
  selector: "app-smart-delivery",
  templateUrl: "./smart-delivery.component.html",
  providers: [SmartDeliveryConfigurationController, DeleteConfirmService, ListService]
})
export class SmartDeliveryComponent {

  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  rules!: CtDatatableConfiguration;
  filtersConfiguration: CtSearchFiltersConfiguration = CtSearchFiltersConfiguration
    .create()
    .setFilters(SmartDeliveryRuleListFilters);

  private params: SmartDeliveryConfigurationListParameter = SmartDeliveryConfigurationListParameter.create();
  private viewModel: { ruleList: Array<SmartDeliveryConfigurationSaveParameter> } = { ruleList: [] };

  constructor(
    private listService: ListService,
    private general: CTGeneralService,
    private deleteConfirm: DeleteConfirmService,
    private smartDeliveryDetailService: SmartDeliveryDetailService,
    private smartDeliveryConfigurationController: SmartDeliveryConfigurationController,
    private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  private async setup() {

    this.viewModel.ruleList = await this.getData();

    await this.setTable();

  }

  private async setTable() {

    const columns: TableColumn[] = [
      { prop: "ApiCode", name: "Codice", width: 150, minWidth: 150, maxWidth: 150, sortable: false },
      { prop: "Name", name: "Nome", width: 250, minWidth: 250, maxWidth: 250, sortable: false },
      { prop: "Description", name: "Descrizione", sortable: false },
      {
        prop: "Enabled",
        name: "Attivo",
        cellClass: CELL_TYPE.BOOLEAN,
        sortable: false,
        width: 80,
        minWidth: 80,
        maxWidth: 80
      }
    ];

    this.rules = CtDatatableConfiguration
      .create()
      .setScrollbarH(true)
      .setRowHeight(65)
      .setColumns(columns)
      .setController("smartDeliveryRule")
      .setCount(this.viewModel.ruleList.length)
      .setEditingActionEnabled(true)
      .setDeleteActionEnabled(true);

    this.cdr.detectChanges();

  }

  private getData() {

    return new Promise<Array<SmartDeliveryConfigurationSaveParameter>>(
      (resolve, reject) => {

        this.smartDeliveryConfigurationController
          .list()
          .subscribe({
            next: (response: any) => {

              let result = [];

              if (response?.Result && Array.isArray(response.Result)) result = response.Result;

              resolve(result);

            },
            error: (err) => {

              this.rules.setLoadingIndicator(false);
              reject(err);

            }
          });

      });

  }

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter) {

    this.rules.setLoadingIndicator(true);

    const paginatedData = this.listService.getFilteredData<SmartDeliveryConfigurationSaveParameter>(this.viewModel.ruleList, this.params, pagination);

    setTimeout(() => {

      this.rules
        .setRows(paginatedData)
        .setLoadingIndicator(false);

    }, 350);

  }

  manage(configuration: SmartDeliveryConfigurationSaveParameter | null = null) {

    this.smartDeliveryDetailService
      .open(configuration)
      .afterClosed()
      .subscribe((response: number | null) => {

        if (response) {

          this
            .general
            .reloadCurrentRoute();


        }

      });

  }

  async delete(rule: SmartDeliveryConfigurationSaveParameter) {

    if (!rule.Oid) return;

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          this.smartDeliveryConfigurationController
            .delete(rule)
            .subscribe(() => {

              this.general
                .reloadCurrentRoute();

            });

        }

      });

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
