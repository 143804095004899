import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { PurchaseError } from "../../../core/lib/purchase.service";

@Component({
  selector: 'app-purchase-errors-dialog',
  templateUrl: './purchase-errors-dialog.component.html',
  styleUrls: ['./purchase-errors-dialog.component.css'],
})
export class PurchaseErrorsDialogComponent {

  constructor(@Inject(MAT_DIALOG_DATA) public data: PurchaseError[]) {
  }

}
