import { Component, Inject, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { User } from "../../../../../core/classes/user";
import { ROLES, RoleType2LabelMapping } from "../../../../../core/enums/roles.enum";
import {
  CtControlConfiguration,
  CtControlTypes,
  CtSelectControlOptions,
  CtSelectControlValue,
  CtThemeTypes,
  IT_CELLPHONE_PATTERN,
  passwordStrengthValidator
} from "@ctsolution/ct-framework";
import { UserController } from "../../../../../core/controllers/user.controller";
import { SnackbarService } from "../../../../../core/lib/snackbar.service";
import { GeneralService } from "../../../../../core/lib/general.service";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { DataRequest, MethodEnum } from "@ctsolution/ct-webapi";

const GROUP_LOOKUP_CONTROL: CtControlConfiguration = <CtControlConfiguration>{
  name: "Group",
  label: "Gruppo di appartenenza",
  type: CtControlTypes.LOOKUP,
  theme: CtThemeTypes.MATERIAL,
  validators: [
    { name: "required" }
  ],
  materialOptions: {
    appearance: "legacy"
  }
};

@Component({
  selector: "app-user-detail",
  templateUrl: "./user-detail.component.html",
  styleUrls: ["./user-detail.component.scss"],
  providers: [UserController]
})
export class UserDetailComponent implements OnInit {

  form: FormGroup;

  public RoleType2LabelMapping = RoleType2LabelMapping;
  public roleTypes = Object.values(ROLES).filter((elm: string) => elm !== ROLES.COMPANY_GROUP_ADMIN);

  private groupLookupOptions: CtSelectControlOptions = CtSelectControlOptions.create()
    .setLookupResponseMapper(response => {

      return ((response as CtWebapiGenericResponse<any>).Result.Items)
        .map((elm: { Name: string | null; Oid: string | null; Code: string | null; }) => {

          const label: string = `${elm.Code ?? ""} - ${elm.Name ?? ""}`;
          return CtSelectControlValue.create()
            .setLabel(label)
            .setValue(elm.Oid);

        });

    })
    .setLookupFilter((value?: string | number | null) => {
      return {
        GroupCodeName: value,
        Page: 1,
        ElementsForPage: 10
      };
    });

  groupLookupControl: CtControlConfiguration = CtControlConfiguration.create(GROUP_LOOKUP_CONTROL)
    .setOptions(this.groupLookupOptions)
    .setRemoteValueOptionsEndpoint(DataRequest.create()
      .setController(["Group"])
      .setAction("GroupList")
      .setMethod(MethodEnum.GET)
      .setQueryParams({ hideSpinner: true }));

  constructor(
    private fb: FormBuilder,
    private _dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: User,
    private userController: UserController,
    private _snackbar: SnackbarService,
    private general: GeneralService
  ) {

    this.form = this.fb.group({
      Oid: new FormControl(null),
      RoleCode: new FormControl(null, Validators.required),
      Name: new FormControl(null, Validators.required),
      Surname: new FormControl(null, Validators.required),
      UserEmail: new FormControl(null, [Validators.required, Validators.email]),
      PhoneNumber: new FormControl(null, [Validators.pattern(IT_CELLPHONE_PATTERN)]),
      Password: new FormControl(null, [Validators.required, Validators.minLength(6), passwordStrengthValidator])
    });

    this.groupLookupControl.setFormParent(this.form);

  }

  ngOnInit(): void {

    this.setup();

  }

  private setup() {

    if (this.data.Oid) {

      this.form.get("Oid")?.setValue(this.data.Oid);

      // sono in modifica, non devo per forza inserire la password
      this.form.get("Password")?.setValidators([Validators.minLength(6), passwordStrengthValidator]);
      this.form.get("Password")?.updateValueAndValidity();

    }

    if (this.data.RoleCode) {

      this.form.get("RoleCode")?.setValue(this.data.RoleCode.toLowerCase());

    }

    let selectedGroup: CtSelectControlValue | undefined;

    if (this.data.GroupOid) {

      selectedGroup = CtSelectControlValue
        .create()
        .setValue(this.data.GroupOid.toString())
        .setLabel(`${this.data.GroupCode ?? ""} - ${this.data.GroupName ?? ""}`);

      this.groupLookupControl
        //.setValueOptions([selectedGroup])
        .setValue(selectedGroup);

    }

    if (this.data.Name) {

      this.form.get("Name")?.setValue(this.data.Name);

    }

    if (this.data.Surname) {

      this.form.get("Surname")?.setValue(this.data.Surname);

    }

    if (this.data.UserEmail) {

      this.form.get("UserEmail")?.setValue(this.data.UserEmail);
      this.form.get("UserEmail")?.disable();

    }

    if (this.data.PhoneNumber) {

      this.form.get("PhoneNumber")?.setValue(this.data.PhoneNumber);

    }

    if (this.data.Password) {

      this.form.get("Password")?.setValue(this.data.Password);

    }

  }

  submit() {

    this.form.markAllAsTouched();

    if (this.form.valid) {

      const value = this.form.value;

      value.GroupOid = value.Group.value;
      delete value.Group;

      if (this.form.get("UserEmail")?.disabled) {

        value.UserEmail = this.form.get("UserEmail")?.value;

      }

      this.userController
        .save(value as User)
        .then(() => {

          this._snackbar.open("Modifica avvenuta con successo!");

          this._dialogRef
            .close(true);

        });

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

}
