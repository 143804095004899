import { Injectable } from "@angular/core";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  PurchaseErrorsDialogComponent
} from "../../pages/cart/purchase-errors-dialog/purchase-errors-dialog.component";

@Injectable({
  providedIn: "root"
})
export class PurchaseService {

  constructor(private _dialog: MatDialog) {
  }

  purchaseErrorsDialog(errorsList: PurchaseError[]) {

    this._dialog
      .open(PurchaseErrorsDialogComponent, { data: errorsList });

  }

}

export interface PurchaseError {

  FlowOID: number;
  Error: string;

}
