import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import {
  CtButtonConfiguration,
  CtButtonMatherialDesign,
  CtButtonMatherialOptions,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter,
  CtDatatableResult,
  CTGeneralService,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration,
  SnackbarService
} from "@ctsolution/ct-framework";
import { FlowRecipientController } from "../../core/controllers/flow-recipient.controller";
import { FlowRecipientFilters, FlowRecipientListParameter } from "../../core/classes/flow-recipient-list.parameter";
import { TableColumn } from "@swimlane/ngx-datatable";
import { CompanyUiSettings } from "../../core/lib/company-ui/company.ui-settings";
import {
  CompanyUiCustomizationService,
  DEFAULT_LABEL
} from "../../core/lib/company-ui/company.ui-customization.service";
import { FileService } from "../../core/lib/file.service";
import { RecipientData } from "../../core/interfaces/recipient-data";
import { JwtService } from "../../core/lib/jwt.service";
import { ActivatedRoute, Router } from "@angular/router";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import {
  TrackingOrderDetailExitConfirmComponent
} from "./tracking-order-detail-exit-confirm/tracking-order-detail-exit-confirm.component";
import { BaseFlowFileParameter } from "../../core/classes/base-flow-file-parameter";
import { FlowFile } from "../../core/interfaces/flow-file";
import { MatMenuTrigger } from "@angular/material/menu";
import { FLOW_FILE_TYPE } from "../../core/enums/flow-file-type.enum";
import {
  TrackingExportDateFromAlertComponent
} from "./tracking-export-date-from-alert/tracking-export-date-from-alert.component";
import { RoleService } from "../../core/lib/role.service";
import { TrackingService } from "./tracking.service";

@Component({
  selector: "app-tracking",
  templateUrl: "./tracking.component.html",
  styleUrls: ["./tracking.component.scss"],
  providers: [FlowRecipientController, TrackingService]
})
export class TrackingComponent {

  @ViewChild("barcodeCellTemplate") public barcodeCellTemplate!: TemplateRef<any>;
  @ViewChild("recipientCellTemplate") public recipientCellTemplate!: TemplateRef<any>;
  @ViewChild("downloadCellTemplate") public downloadCellTemplate!: TemplateRef<any>;
  @ViewChild("nameCellTemplate") public nameCellTemplate!: TemplateRef<any>;
  @ViewChild("cdcCellTemplate") public cdcCellTemplate!: TemplateRef<any>;
  @ViewChild("createdByCellTemplate") public createdByCellTemplate!: TemplateRef<any>;
  @ViewChild("sendDateTemplate") public sendDateTemplate!: TemplateRef<any>;

  shipments!: CtDatatableConfiguration;

  @ViewChild("searchFilter") public searchFilter!: CtSearchFiltersComponent;

  filtersConfiguration: CtSearchFiltersConfiguration =
    CtSearchFiltersConfiguration
      .create()
      .setFilters(FlowRecipientFilters);

  exportButton: CtButtonConfiguration = CtButtonConfiguration
    .create()
    .setLabel("Esporta Risultati")
    .setClass("")
    .setAction(() => this.export())
    .setIcon("download")
    .setMatherialOptions(CtButtonMatherialOptions
      .create()
      .setDesign(CtButtonMatherialDesign.MatFlatButton));

  private params: FlowRecipientListParameter = new FlowRecipientListParameter();

  orderDetail: any | null = null;  // per gestire la particolarità di ricerca del flowOid utilizzando il campo FlowDescription

  constructor(
    private flowRecipientController: FlowRecipientController,
    private fileService: FileService,
    private companyUiCustomizationService: CompanyUiCustomizationService,
    private cdr: ChangeDetectorRef,
    private _jwt: JwtService,
    private general: CTGeneralService,
    private router: Router,
    private dialog: MatLegacyDialog,
    private _role: RoleService,
    private _snackbar: SnackbarService,
    public trackingService: TrackingService,
    private route: ActivatedRoute) {

  }

  ngAfterViewInit() {

    this.route
      .queryParams
      .subscribe(qp => {

        if (Object.keys(qp).length) {

          this.orderDetail = qp;

        }

        if (this.orderDetail?.FlowDescription) {

          this.searchFilter.form.get("FlowDescription")?.setValue(this.orderDetail.FlowDescription);

        }

        this.setup()
          .then();

      });

  }

  async setup() {

    this.setTable();

    const exportEnabled = await this._jwt.userHasExportEnabled();
    const isCompanyAdminOrCompanyGroupAdmin = await this._role.isCompanyAdminOrCompanyGroupAdmin();

    if (exportEnabled && isCompanyAdminOrCompanyGroupAdmin) this.enableExport();

    if (this.orderDetail?.FlowOid) {

      this.route
        .data
        .subscribe(data => {
          data.title += `: ${this.orderDetail.FlowOid} - ${this.orderDetail.FlowDescription}`;
        });

    }

  }

  /**
   * It creates a new instance of the CtDatatableConfiguration class, sets the externalPaging property to true, sets the
   * scrollbarH property to true, and sets the columns property to the columns array
   * Called in afterViewInit because needs to wait custom cell templates load
   */
  private setTable() {

    this.companyUiCustomizationService
      .getSettings()
      .then((company: CompanyUiSettings | undefined) => {

        const columns: TableColumn[] = [
          {
            prop: "FlowSendDate",
            name: "Data",
            cellTemplate: this.sendDateTemplate
          },
          {
            prop: "TrackingCode",
            name: "Barcode/Stato lavorazione",
            cellTemplate: this.barcodeCellTemplate,
            width: 200
          },
          {
            prop: "FlowDescription",
            name: "Nome ordine/Codice Interno",
            cellTemplate: this.nameCellTemplate,
            width: 250
          },
          { prop: "FlowSendTypeConfigurationSendTypeName", name: "Prodotto" },
          { name: "Downloads", cellTemplate: this.downloadCellTemplate },
          { prop: "Name", name: "Destinatario", cellTemplate: this.recipientCellTemplate, width: 320 },
          { prop: "FlowUserInfoName", name: "Creato Da", cellTemplate: this.createdByCellTemplate },
          {
            prop: "FlowGroupCode",
            name: company?.getGroupLabel() ?? DEFAULT_LABEL.GROUP,
            cellTemplate: this.cdcCellTemplate,
            width: 320
          }
        ];

        this.shipments = CtDatatableConfiguration.create()
          .setExternalPaging(true)
          .setScrollbarH(true)
          .setColumns(columns)
          .setController("shipments");

        this.cdr.detectChanges();

      });

  }

  filter(event: any | null) {

    if (event === null && this.orderDetail?.FlowOid) {

      this.dialog
        .open(TrackingOrderDetailExitConfirmComponent, { maxWidth: "500px" })
        .afterClosed()
        .subscribe(response => {

          if (response) {

            this.router.navigateByUrl(`/`, { skipLocationChange: true })
              .then(() => this.router.navigate([`/`, "tracking"]));

          }

        });

      return;

    }

    this.params = event ?? {};

    this.fetch();

  }

  async getFiles(value: RecipientData, trigger: MatMenuTrigger) {

    if (!value.CachedFlowFiles) {

      const getFlowFileListParam: BaseFlowFileParameter = BaseFlowFileParameter
        .create(value.FlowOid)
        .setRowNumber(value.RowNumber);

      value.CachedFlowFiles = await this.fileService.getFlowFileList(getFlowFileListParam);

    }

    if (!value.CachedFlowFiles?.length) {

      this._snackbar.open("Non ci sono download disponibili. Riprova più tardi");
      return;

    }

    if (value.CachedFlowFiles.length === 1) {

      this.trackingService.manageFile(value.CachedFlowFiles[0], value);
      return;

    }

    trigger.openMenu();

  }

  getDownloadFileIcon(file: FlowFile) {

    if (file?.Extension?.toLowerCase().includes("zip")) return "folder_zip";

    switch (file?.PPFileType) {

      case FLOW_FILE_TYPE.PreviewRecipient:
      case FLOW_FILE_TYPE.OutputRecipientFile:
        return "preview";
      default:
        return "attachment";

    }

  }

  isPreviewFile = (file: FlowFile): boolean => file.PPFileType === FLOW_FILE_TYPE.PreviewRecipient || file.PPFileType === FLOW_FILE_TYPE.OutputRecipientFile;

  enableExport() {

    this.filtersConfiguration
      .setCustomActionButton(this.exportButton);

  }

  export() {

    const dateFromValue = this.searchFilter?.form.get("DateFrom")?.value;
    const dateToValue = this.searchFilter?.form.get("DateTo")?.value ?? new Date();

    let isExportRangeExceeded: boolean = false;

    if (dateFromValue) {

      // la differenza in mesi tra le due date
      const diffInMonths = (dateToValue.getFullYear() - dateFromValue.getFullYear()) * 12 + dateToValue.getMonth() - dateFromValue.getMonth();
      isExportRangeExceeded = diffInMonths > 6;

    }

    // Controlla se la data di inizio è mancante o se l'intervallo è superiore a 6 mesi (non può essere effettuato l'export
    if (!dateFromValue || isExportRangeExceeded) {

      this.dialog
        .open(TrackingExportDateFromAlertComponent);

      return;

    }

    let values = Object.assign({}, this.searchFilter?.form.getRawValue());
    this.general.cleanEmptyValues(values);

    if (this.orderDetail?.FlowOid) {

      values.FlowOid = this.orderDetail.FlowOid;

    }

    this.flowRecipientController
      .export(values);

  }

  fetch(pagination?: CTDatatablePaginationParameter) {

    this.shipments?.setLoadingIndicator(true);

    if (this.orderDetail?.FlowOid) {

      this.params.FlowOid = this.orderDetail.FlowOid;

    }

    if (this.orderDetail?.SmartDeliveryOid) {

      this.params.SmartDeliveryOid = this.orderDetail.SmartDeliveryOid;

    }

    this.flowRecipientController
      .list(this.params, pagination)
      .then((result: CtDatatableResult<RecipientData>) => {

        this.shipments
          ?.setCount(result.ItemTotal)
          ?.setRows(result.Items)
          ?.setLoadingIndicator(false);

      });

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
