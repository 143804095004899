import { Component } from "@angular/core";
import { CtButtonParameters, CtCardParameters } from "@ctsolution/ct-framework";
import { GeneralService } from "../../core/lib/general.service";
import { FlowService } from "../../core/lib/flow.service";
import { JwtService } from "../../core/lib/jwt.service";

@Component({
  selector: "app-shipment",
  templateUrl: "./shipment.component.html",
  styleUrls: ["./shipment.component.scss"]
})
export class ShipmentComponent {
  //===========================
  // #region <CardsParameters>
  singleRecipientButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this.handleNavigation("singola");

  })
    .setMaterialIcon("arrow_forward")
    .setContainerClass("text-right");

  singleRecipientCardParameters: CtCardParameters = CtCardParameters.create(() => {
  })
    .setTitle("Singolo destinatario")
    .setText("Specifica i dati del singolo destinatario e successivamente effettua l'invio")
    .setMaterialIcon("emoji_people")
    .setBackgroundClass("bg-megna")
    .setTextColor("text-white")
    .setCtButton(this.singleRecipientButtonParameters);

  multiRecipientButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this.handleNavigation("multipla");

  })
    .setMaterialIcon("arrow_forward")
    .setContainerClass("text-right");

  multiRecipientCardParameters: CtCardParameters = CtCardParameters.create(() => {
  })
    .setTitle("Più destinatari")
    .setText("Carica una lista di destinatari da file e successivamente effettua l'invio")
    .setMaterialIcon("groups_3")
    .setBackgroundClass("bg-info")
    .setTextColor("text-white")
    .setCtButton(this.multiRecipientButtonParameters);

  // #endregion
  //===========================

  cardsArrayParameters: Array<CtCardParameters> = [
    this.singleRecipientCardParameters,
    this.multiRecipientCardParameters
  ];

  constructor(private _general: GeneralService, private _jwt: JwtService, private flowService: FlowService) {
  }

  /* A function that is called when the user clicks on one of the two cards. */
  async handleNavigation(path: string) {

    const commands: string[] = ["nuova-spedizione", path];

    const currentFlowOid: number | null = await this._jwt.getFlowDraftOid();

    if (currentFlowOid) {

      this.flowService
        .openFlowAlertDialog()
        .afterClosed()
        .subscribe((result) => {

          if (result === true) {

            this.flowService.cancelFlow(commands);

          } else if (result === false) {

            this.flowService.continueFlow();

          }

          //se arrivo qui chiudo la modale e basta, gli if precedenti non mi piacciono, ma per il momento facciamo cosi

        });

    } else {

      this._general.navigateTo(commands);

    }

  };

}
