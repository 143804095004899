<form [formGroup]="form">

  <div fxLayout="row wrap">

    <div
      fxFlex="100"
      fxFlex.gt-sm="100"
      fxFlex.gt-md="25">

      <mat-card-content>
        <mat-card-title class="step-title"> SPECIFICHE</mat-card-title>

        <app-letter-specifications
          [flow]="flowService.activeFlow"
          #specifications></app-letter-specifications>

      </mat-card-content>

    </div>

    <div
      fxFlex="100"
      fxFlex.gt-sm="100"
      fxFlex.gt-md="37.5">

      <app-person-specifications
        #sender
        [configuration]="senderPersonSpecificationsComponentConfiguration"
      ></app-person-specifications>

    </div>

    <div
      fxFlex="100"
      fxFlex.gt-sm="100"
      [fxFlex.gt-md]="37.5">

      <!-- Questo switch temo sia da rivedere, in caso le tipologie di invio aumentino -->
      <ng-container [ngSwitch]="flowService.activeFlow.Type">

        <app-person-specifications
          #recipient
          *ngSwitchCase="FLOW_TYPE.SINGLE_RECIPIENT"
          [configuration]="recipientPersonSpecificationsComponentConfiguration"
        ></app-person-specifications>

        <app-recipient-list-upload
          #multipleRecipients
          (updateFlowFileList)="setFlowRecipientsFile($event)"
          *ngSwitchDefault></app-recipient-list-upload>

      </ng-container>

    </div>

  </div>

</form>
