import { Component } from "@angular/core";
import { CtControlConfiguration, CtControlTypes, CtThemeTypes } from "@ctsolution/ct-framework";
import { FormBuilder, FormGroup } from "@angular/forms";

const FileUploadConfiguration: CtControlConfiguration = {
  name: "File",
  label: "Caricamento File",
  theme: CtThemeTypes.MATERIAL,
  type: CtControlTypes.FILE,
  options: {
    accept: "application/pdf"
  },
  materialOptions: {
    icons: {
      suffixIcon: "attachment"
    }
  },
  validators: [
    { name: "required" }
  ]
} as CtControlConfiguration;

@Component({
  selector: "app-file-upload",
  templateUrl: "./file-upload.component.html",
  styleUrls: ["./file-upload.component.css"]
})
export class FileUploadComponent {

  form: FormGroup;

  controlConfiguration: CtControlConfiguration = CtControlConfiguration
    .create(FileUploadConfiguration);

  constructor(private formBuilder: FormBuilder) {

    this.form = this.formBuilder.group({});

    this.controlConfiguration
      .setFormParent(this.form);

  }

}
