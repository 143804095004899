import { Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { FileService } from "../../../../../core/lib/file.service";
import { FlowStepsController } from "../../../../../core/controllers/flow-steps.controller";
import {
  TemplateManagerConfiguration
} from "../../../../../_components/flow-components/editor/template-manager.configuration";
import {
  TemplatesManagerComponent
} from "../../../../../_components/flow-components/editor/templates-manager/templates-manager.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { SendTypeSendCategoryEnum } from "../../../../../core/interfaces/send-type-configuration";
import {
  TinyMceSetupConfiguration
} from "../../../../../_components/flow-components/editor/tiny-mce.setup-configuration";
import {
  TemplateSaveService
} from "../../../../../_components/flow-components/editor/template-save/template-save.service";
import { TemplateLiteResponse } from "../../../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../../../core/classes/template-parameter";

const MAX_SMS_LENGTH: number = 480;
const SMS_VALIDATORS = [Validators.required, Validators.maxLength(MAX_SMS_LENGTH)];

@Component({
  selector: "app-sms-editor",
  templateUrl: "./sms-editor.component.html",
  styleUrls: ["./sms-editor.component.scss"],
  providers: [FlowStepsController]
})
export class SmsEditorComponent {

  control: FormControl = new FormControl(null, SMS_VALIDATORS);
  dynamicFieldsList: string[] = [];
  tempTemplateRef: TemplateLiteResponse | null = null;

  viewModel = {

    maxLength: MAX_SMS_LENGTH,
    templateManagementEnabled: true

  };

  constructor(
    private fileHelper: FileService,
    private flowSteps: FlowStepsController,
    private dialog: MatLegacyDialog,
    private templateSaveService: TemplateSaveService) {
  }

  getDynamicFields() {

    this.flowSteps
      .getCompositionVariableFields()
      .then((result: string[]) => this.dynamicFieldsList = result);

    return this;

  }

  insertField(value: string) {

    const currentValue: string = this.control.value;

    this.control.setValue((currentValue ?? "") + `{{{${value}}}}`);
    this.control.updateValueAndValidity();

  }

  templatesList() {

    const parameter: TemplateManagerConfiguration = TemplateManagerConfiguration
      .create()
      .setParameter(
        TemplateParameter
          .create()
          .setSendCategory(SendTypeSendCategoryEnum.SMS)
      );

    this.dialog
      .open(TemplatesManagerComponent, { data: parameter, width: "90vw", maxWidth: "900px" })
      .beforeClosed()
      .subscribe((result: { content: string | null, data: TemplateLiteResponse | null }) => {

        if (result) {

          this.control.setValue(result.content);
          this.tempTemplateRef = result.data;

        }

      });

  }

  async templateSave() {

    this.templateSaveService
      .saveAsTemplate(this.control, this.tempTemplateRef, SendTypeSendCategoryEnum.SMS);

  }

  setupContentFromPPFile(configuration: TinyMceSetupConfiguration) {

    this.fileHelper
      .getComponentStringContentFromPPFile(configuration)
      ?.then(result => this.control.setValue(result ?? ""));

  }

  setTemplateManagementEnabled(value: boolean) {

    this.viewModel.templateManagementEnabled = value;

    return this;

  }

}
