import { ChangeDetectorRef, Component } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { SendTypeController } from "../../../../../core/controllers/send-type.controller";
import {
  ISendTypeConfiguration,
  SendTypeSendCategoryEnum
} from "../../../../../core/interfaces/send-type-configuration";
import { FlowService } from "../../../../../core/lib/flow.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-product-list",
  templateUrl: "./product-list.component.html",
  styleUrls: ["./product-list.component.scss"],
  providers: [SendTypeController]
})
export class ProductListComponent {

  SEND_PRODUCTS: ISendTypeConfiguration[] = [];

  control: FormControl = new FormControl(null, Validators.required);

  loadingList: boolean = false;

  constructor(
    private _controller: SendTypeController,
    private cdr: ChangeDetectorRef,
    private flowService: FlowService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  get LETTER_PRODUCTS(): ISendTypeConfiguration[] {

    return this.SEND_PRODUCTS.filter((product: ISendTypeConfiguration) => [SendTypeSendCategoryEnum.Letter].includes(product.SendTypeSendCategory));

  }

  get LETTER_PRODUCT_CARD_MD_FLEX(): number {

    return Math.round(100 / this.LETTER_PRODUCTS.length) ?? 100;

  }

  get DIGITAL_PRODUCTS(): ISendTypeConfiguration[] {

    return this.SEND_PRODUCTS.filter((product: ISendTypeConfiguration) => ![SendTypeSendCategoryEnum.Letter].includes(product.SendTypeSendCategory));

  }

  get DIGITAL_PRODUCT_CARD_MD_FLEX(): number {

    return Math.round(100 / this.DIGITAL_PRODUCTS.length) ?? 100;

  }

  setValue(product: ISendTypeConfiguration) {

    if (this.control.value === product) return;

    if (this.flowService.activeFlow.SendTypeConfigurationSendTypeOid && this.flowService.activeFlow.SendTypeConfigurationSendTypeOid !== product.SendTypeOid) {

      this.flowService
        .openFlowAlertDialog()
        .afterClosed()
        .subscribe((result) => {

          if (result === true) {

            this.flowService
              .cancelFlow()
              .then(() => this.reloadCurrentRoute({ queryParams: { SendTypeOid: product.SendTypeOid } }));

          }

        });

    } else {

      this.control.setValue(product);

    }

  }

  private reloadCurrentRoute(extras = {}) {

    const url = this.router.url.split("?")[0];

    this.router.navigateByUrl(`/`, { skipLocationChange: true })
      .then(() => {

        this.router.navigate([`/${url}`], extras)
          .then(() => {
            console.log(`CT Gen: Reloaded ${this.router.url} route`);
          });

      });

  }

  /* A function that returns a promise. */
  getProducts = (): Promise<boolean> =>

    new Promise<boolean>((resolve) => {

      if (this.SEND_PRODUCTS.length > 0) {

        resolve(true);
        return;

      }

      this.loadingList = true;

      this.cdr.detectChanges();

      this._controller
        .getSendTypeConfigurationList()
        .then((result: ISendTypeConfiguration[]) => {

          this.SEND_PRODUCTS = result;

          this.route
            .queryParams
            .subscribe(qp => {

              if (qp["SendTypeOid"]) {

                const selectedProduct = this.SEND_PRODUCTS.find(product => product.SendTypeOid === +qp["SendTypeOid"]);

                if (selectedProduct) {

                  this.setValue(selectedProduct);

                }

              } else if (this.SEND_PRODUCTS.length === 1) {

                this.setValue(this.SEND_PRODUCTS[0]);

              }

            });

          this.loadingList = false;
          resolve(true);

        });

    });

}
