import { ChangeDetectorRef, Component, ViewChild } from "@angular/core";
import {
  CtDatatableConfiguration, CTDatatablePaginationParameter, CtDatatableResult, CTGeneralService,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration, DeleteConfirmService
} from "@ctsolution/ct-framework";
import { TemplateListFilters, TemplateListParameter } from "../../../core/classes/template-list.parameter";
import { TableColumn } from "@swimlane/ngx-datatable";
import { TemplateSaveService } from "../../../_components/flow-components/editor/template-save/template-save.service";
import {
  TemplateManagerConfiguration
} from "../../../_components/flow-components/editor/template-manager.configuration";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { Router } from "@angular/router";
import { JwtService } from "../../../core/lib/jwt.service";
import { SnackbarService } from "../../../core/lib/snackbar.service";
import {
  GlobalVariableFieldsImportService
} from "../../../_components/flow-components/editor/global-variable-fields-import/global-variable-fields-import.service";
import { TemplateController } from "../../../core/controllers/template.controller";
import { TemplateLiteResponse } from "../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../core/classes/template-parameter";
import { ListService } from "../../../core/lib/list.service";
import { RoleService } from "../../../core/lib/role.service";

@Component({
  selector: "app-template",
  templateUrl: "./template.component.html",
  providers: [TemplateController, TemplateSaveService, DeleteConfirmService, ListService]
})
export class TemplateComponent {

  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  templates!: CtDatatableConfiguration;
  filtersConfiguration: CtSearchFiltersConfiguration = CtSearchFiltersConfiguration
    .create()
    .setFilters(TemplateListFilters);

  private params: TemplateListParameter = TemplateListParameter.create();
  viewModel: {
    templateList: Array<TemplateLiteResponse>;
    isCompanyAdminOrCompanyGroupAdmin: boolean
  } = { templateList: [], isCompanyAdminOrCompanyGroupAdmin: false };

  constructor(
    private templateController: TemplateController,
    private cdr: ChangeDetectorRef,
    private router: Router,
    private deleteConfirm: DeleteConfirmService,
    private general: CTGeneralService,
    private jwt: JwtService,
    private listService: ListService,
    private snackbar: SnackbarService,
    private roleService: RoleService,
    private globalVariableFielsImportService: GlobalVariableFieldsImportService,
    private templateSaveService: TemplateSaveService) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  private async setup() {

    this.viewModel.templateList = await this.getData();
    this.viewModel.isCompanyAdminOrCompanyGroupAdmin = await this.roleService.isCompanyAdminOrCompanyGroupAdmin();

    await this.setTable();

  }

  private async setTable() {

    const columns: TableColumn[] = [
      { prop: "ApiCode", name: "Codice", width: 150, minWidth: 150, maxWidth: 150, sortable: false },
      { prop: "Name", name: "Nome", sortable: false },
      // { prop: "Description", name: "Descrizione", sortable: false },
      { prop: "SendCategory", name: "Prodotto", width: 200, minWidth: 200, maxWidth: 200, sortable: false }
    ];

    this.templates = CtDatatableConfiguration
      .create()
      .setScrollbarH(true)
      .setRowHeight(65)
      .setColumns(columns)
      .setController("templates")
      .setCount(this.viewModel.templateList.length)
      .setEditingActionEnabled(true)
      .setDeleteActionEnabled(true);

    this.cdr.detectChanges();

  }

  private getData() {

    return new Promise<Array<TemplateLiteResponse>>((resolve, reject) => {

      this.templateController
        .templateList()
        .subscribe({
          next: (response: any) => {

            const result = response.Result ?? [];
            resolve(result);

          },
          error: (err) => {

            this.templates.setLoadingIndicator(false);
            reject(err);

          }
        });

    });

  }

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  fetch(pagination?: CTDatatablePaginationParameter) {

    this.templates.setLoadingIndicator(true);

    const paginatedData = this.listService.getFilteredData<TemplateLiteResponse>(this.viewModel.templateList, this.params, pagination);

    setTimeout(() => {

      this.templates
        .setRows(paginatedData)
        .setLoadingIndicator(false);

    }, 350);

  }

  newTemplate() {

    const templateManagerConfiguration: TemplateManagerConfiguration = TemplateManagerConfiguration
      .create()
      .setParameter(
        TemplateParameter
          .create());

    this.templateSaveService
      .create(templateManagerConfiguration)
      .afterClosed()
      .subscribe((response: CtWebapiGenericResponse<number> | null) => {

        if (response) {

          this.manage(response.Result);

        }

      });

  }

  detail(template: TemplateParameter) {

    if (!template.Oid) return;

    this.manage(template.Oid);

  }

  async delete(template: TemplateParameter) {

    if (!template.Oid) return;

    const userInfoOid = await this.jwt.getUserInfoOid();

    if (template.UserInfoOid !== userInfoOid) {

      this.snackbar.open("Verifica di disporre delle autorizzazioni necessarie per apportare questa modifica.", "X", 3000);
      return;

    }

    this.deleteConfirm
      .open()
      .afterClosed()
      .subscribe((result?: boolean) => {

        if (result) {

          this.templateController
            .delete(template)
            .subscribe(() => {

              this
                .general
                .reloadCurrentRoute();

            });

        }

      });


  }

  globalVariableFields() {

    this.globalVariableFielsImportService
      .open();

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

  private manage(oid: number) {

    this.router
      .navigate(["/", "amministrazione", "modelli", "gestione-modello"], { queryParams: { oid } });

  }

}
