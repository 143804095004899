import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SnackbarService } from "../../../core/lib/snackbar.service";
import { TrackingService } from "../tracking.service";
import { FlowFile } from "../../../core/interfaces/flow-file";
import { RecipientData } from "../../../core/interfaces/recipient-data";

@Component({
  selector: "app-get-file",
  templateUrl: "../../../../../node_modules/@ctsolution/ct-authentication/assets/template.html",
  styleUrls: ["../../../../../node_modules/@ctsolution/ct-authentication/assets/template.scss"],
  providers: [TrackingService]
})
export class GetFileComponent {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private trackingService: TrackingService,
    private _snackbar: SnackbarService) {

    this.route
      .queryParams
      .subscribe(
        qp => {

          setTimeout(async () => { // just to simulate delay

            const { FlowFileOid, FlowOid, FileType, RowNumber, Extension } = qp;

            const convertedFlowFileOid = !isNaN(FlowFileOid) ? +FlowFileOid : null;
            const convertedFlowOid = !isNaN(FlowOid) ? +FlowOid : null;
            const convertedRowNumber = !isNaN(RowNumber) ? +RowNumber : null;
            const convertedFileType = !isNaN(FileType) ? +FileType : null;

            try {

              await this.trackingService.manageFile(
                {
                  Oid: convertedFlowFileOid,
                  FlowOid: convertedFlowOid,
                  PPFileType: convertedFileType,
                  Extension
                } as FlowFile,
                {
                  FlowOid: convertedFlowOid,
                  RowNumber: convertedRowNumber
                } as RecipientData
              );

              // Se la finestra non è stata aperta tramite JavaScript, non è possibile chiuderla direttamente utilizzando window.close()
              // a causa delle politiche di sicurezza del browser che impediscono il controllo delle finestre non aperte dallo script.
              // window.close();

              this.router.navigate(["/"]);

            } catch (error) {

              this.errorSnackbar();

            }

          }, 3000);

        });

  }

  errorSnackbar() {

    const reference = this._snackbar.open("An error occurred while file fetching. Try again.", "OK", 3500);

    reference.afterOpened().subscribe(() => this.router.navigate(["/"]));

  }

}
