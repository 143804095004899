import { FullComponent } from "./layouts/full/full.component";
import { AppBlankComponent } from "./layouts/blank/blank.component";
import { CtAuthenticationGuard } from "@ctsolution/ct-authentication";
import { AuthenticationModule } from "./authentication/authentication.module";
import { PagesModule } from "./pages/pages.module";
import { Routes } from "@angular/router";
import { SignOutModule } from "./pages/signout/sign-out.module";
import { NewsletterModule } from "./newsletter/newsletter.module";
import { SsoRoleGuard } from "./core/lib/sso-role-guard.service";
import { GetFileModule } from "./pages/tracking/get-file/get-file.module";
import { ViewContentModule } from "./_components/view-content/view-content.module";
import {
  GetSmartDeliveryFileModule
} from "./pages/admin/smart-delivery/get-smart-delivery-file/get-smart-delivery-file.module";
import { CustomerCareRoleGuard } from "./core/lib/ccare-guard.service";
import { DigitalSignatureModule } from "./digital-signature/digital-signature.module";

export const AppRoutes: Routes = [
  {
    path: "viewcontent",
    loadChildren: () => ViewContentModule
  },
  {
    path: "",
    component: FullComponent,
    canActivate: [CtAuthenticationGuard, SsoRoleGuard, CustomerCareRoleGuard],
    children: [
      {
        path: "",
        loadChildren: () => PagesModule
      }
    ]
  },
  {
    path: "",
    component: AppBlankComponent,
    children: [
      {
        path: "autenticazione",
        loadChildren: () => AuthenticationModule
      }
    ]
  },
  {
    path: "GetFile",
    canActivate: [CtAuthenticationGuard],
    children: [
      {
        path: "",
        loadChildren: () => GetFileModule
      }
    ]
  },
  {
    path: "sd-ext-attach",
    children: [
      {
        path: "",
        loadChildren: () => GetSmartDeliveryFileModule
      }
    ]
  },
  {
    path: "logout",
    loadChildren: () => SignOutModule
  },
  {
    path: "newsletter",
    loadChildren: () => NewsletterModule
  },
  {
    path:"digital-signature",
    loadChildren: () => DigitalSignatureModule
  },
  {
    path: "**",
    redirectTo: "/autenticazione/accesso"
  }
];
