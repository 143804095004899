<div class="barcode-cell-template-container">

  <div class="barcode-container">

    <ng-container
      *ngIf="!flow?.TrackingCode || (flow?.TrackingCode && flow?.TrackingCode === ''); else barcodeTemplate">

      <p
        [class.text-center]="flow?.TrackingState"
        [class.align-tracking-state]="!flow?.TrackingState">
        <mat-icon class="schedule-icon">schedule</mat-icon>
        <span *ngIf="!flow?.TrackingState">In lavorazione</span>
      </p>

    </ng-container>

    <ng-template #barcodeTemplate>

      <p>

        <ng-container
          *ngIf="!flow?.FlowSendTypeConfigurationSendTypeName?.toLowerCase().startsWith('raccomandata'); else barcodeLinkTemplate">

          <span>{{ flow?.TrackingCode }}</span>

        </ng-container>

        <ng-template #barcodeLinkTemplate>

          <a
            [href]="'https://www.poste.it/cerca/index.html#/risultati-spedizioni/' + flow?.TrackingCode"
            target="_blank">
            {{ flow?.TrackingCode ?? '' }}
          </a>

        </ng-template>

      </p>

    </ng-template>

  </div>

  <div class="state-container" *ngIf="flow?.TrackingState">

    <p> {{ flow?.TrackingState ?? ''  }} </p>

  </div>

</div>
