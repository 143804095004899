import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Step1Component } from './step1.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProductListModule } from './product-list/product-list.module';

@NgModule({
  declarations: [Step1Component],
  imports: [
    CommonModule,
    ProductListModule,
    MatCardModule,
    ReactiveFormsModule,
    FormsModule,
  ],
  exports: [Step1Component],
})
export class Step1Module {}
