import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { DeleteFlowAlertComponent } from './delete-flow-alert.component';

@Injectable({
  providedIn: 'root',
})
export class DeleteFlowAlertService {
  constructor(private _dialog: MatDialog) {}

  open = () => this._dialog.open(DeleteFlowAlertComponent, { disableClose: true });
}
