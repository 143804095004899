import { ChangeDetectorRef, Component, Input } from "@angular/core";
import { FLOW_TYPE } from "../../../core/enums/flow-type.enum";
import { Flow } from "../../../core/classes/flow";
import { CartPersonConfiguration } from "./cart-person-specifications/cart-person.configuration";
import {
  PERSON_SPECIFICATION_TYPE
} from "../../../pages/shipment/send-flow/step2/person-specifications/person-specifications.configuration";

@Component({
  selector: "app-cart-order-detail",
  templateUrl: "./cart-order-detail.component.html",
  styleUrls: ["./cart-order-detail.component.scss"]
})
export class CartOrderDetailComponent {

  @Input() flow!: Flow;

  FLOW_TYPE = FLOW_TYPE;
  multiple: boolean = false;

  senderCartPersonConfiguration?: CartPersonConfiguration;
  recipientCartPersonConfiguration?: CartPersonConfiguration;

  constructor(private cdr: ChangeDetectorRef) {
  }

  ngAfterViewInit() {

    this.multiple = [FLOW_TYPE.MULTIPLE_RECIPIENT, FLOW_TYPE.MULTIPLE_RECIPIENT_DYNAMIC].includes(this.flow.Type);

    this.senderCartPersonConfiguration = new CartPersonConfiguration()
      .setPersonType(PERSON_SPECIFICATION_TYPE.SENDER)
      .setFlow(this.flow);

    this.recipientCartPersonConfiguration = new CartPersonConfiguration()
      .setPersonType(PERSON_SPECIFICATION_TYPE.RECIPIENT)
      .setFlow(this.flow);

    this.cdr.detectChanges();

  }

}
