import { Injectable } from "@angular/core";

@Injectable()
export class CheckBraceMatchingService {

  constructor() {
  }

  checkBraceMatching(value: string | null) {

    if (!value) return;

    const openingBraceCount = (value.match(/{/g) || []).length;
    const closingBraceCount = (value.match(/}/g) || []).length;
    const isValid = openingBraceCount === closingBraceCount;
    const invalidCount = Math.abs(openingBraceCount - closingBraceCount);

    return { isValid, invalidCount };

  }

}
