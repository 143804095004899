export const environment = {

  production: false,
  projectConfiguration: {
    name: "POLIPUSH_TEST",
    API_URL: "https://polipush.test.api.testctsolution.it/api"
  },
  keys: {
    TinyMCE: "boqg39ohpzcc8jme5jt2focybafrja1frizyfdnt53fa7i6w"
  }
};
