import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { CtAuthenticationGuard } from "@ctsolution/ct-authentication";

@Injectable({
  providedIn: "root"
})
export class SsoRoleGuard  {

  constructor(private authenticationGuard: CtAuthenticationGuard, private router: Router) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean {

    // Chiamiamo il canActivate del CtAuthenticationGuard
    if (!this.authenticationGuard.canActivate(next, state)) {
      return false;
    }

    const isSSO = this.authenticationGuard.userHasRole(["SSO"]);

    // Se il ruolo è "SSO", reindirizziamo l'utente alla route appropriata
    if (isSSO) {

      this.router.navigate(["/autenticazione/selezione-compagnia"]);
      return false;

    }

    return true;

  }

}
