import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { GroupBudget } from "../../../../../core/classes/group-budget";

@Component({
  selector: "app-group-budget",
  templateUrl: "./group-budget.component.html",
  styleUrls: ["./group-budget.component.scss"]
})
export class GroupBudgetComponent {

  form: FormGroup;
  groupBudgetsPair: GroupBudget[] = [];

  /**
   * It returns the first element of the array of budget pairs, or null if the array is empty
   * @returns The first element of the array of BudgetPair objects.
   */
  get currentBudgetPair(): GroupBudget {

    const currentYear: number = new Date().getFullYear();
    return this.groupBudgetsPair[0] ?? new GroupBudget(0, currentYear, 0);

  }

  /**
   * It returns the next budget pair in the groupBudgetsPair array, or null if there is no next budget pair.
   * @returns The next budget pair in the groupBudgetsPair array.
   */
  get nextBudgetPair(): GroupBudget {

    return this.groupBudgetsPair[1] ?? new GroupBudget(0, this.currentBudgetPair.Year + 1, 0);

  }

  constructor(private fb: FormBuilder) {

    this.form = this.fb.group({
      InfiniteBudget: new FormControl(false),
      BudgetForCurrentYear: new FormControl(null),
      BudgetForNextYear: new FormControl(null)
    });


  }

  ngAfterViewInit() {

    this.form
      .get("InfiniteBudget")
      ?.valueChanges
      .subscribe((value: boolean) => this.handleBudgetValues(value));

  }

  private handleBudgetValues(disable: boolean = false) {

    const BudgetForCurrentYear = this.form.get("BudgetForCurrentYear");
    const BudgetForNextYear = this.form.get("BudgetForNextYear");

    if (disable) {

      BudgetForCurrentYear?.setValue(null);
      BudgetForNextYear?.setValue(null);

      BudgetForCurrentYear?.disable();
      BudgetForNextYear?.disable();


    } else {

      BudgetForCurrentYear?.enable();
      BudgetForNextYear?.enable();

    }

    this.form.updateValueAndValidity();
    this.form.markAsTouched();

  }

  /**
   * If the current budget pair exists, set the current year budget to the current budget pair amount. If the next budget
   * pair exists, set the next year budget to the next budget pair amount
   * @param {GroupBudget[]} result - BudgetPair[] - this is the result of the API call.
   */
  setGroupBudgetsPair(result: GroupBudget[]) {

    this.groupBudgetsPair = result;

    if (this.currentBudgetPair) {

      this.form.get("BudgetForCurrentYear")?.setValue(this.currentBudgetPair.Amount ?? null);

    }

    if (this.nextBudgetPair) {

      this.form.get("BudgetForNextYear")?.setValue(this.nextBudgetPair.Amount ?? null);

    }

  }

  setInfiniteBudget(enable: boolean) {

    this.form.get("InfiniteBudget")?.setValue(enable);
    this.form.get("InfiniteBudget")?.updateValueAndValidity();

  }

}
