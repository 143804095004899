<form [formGroup]="form" fxLayout="row wrap" class="group-data-cntr">
  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <section>
      <mat-checkbox class="checkbox-margin" formControlName="Active"> Attivo</mat-checkbox>
    </section>
  </div>

  <div hidden fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <section>
      <mat-checkbox class="checkbox-margin" formControlName="AutoAcceptPrintQuote">
        Accettazione automatica preventivi
      </mat-checkbox>
    </section>
  </div>

  <!--  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
      <mat-divider></mat-divider>
    </div>-->

  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <mat-form-field>
      <mat-label>Nome</mat-label>
      <input matInput placeholder="Nome" formControlName="Name" />
      <mat-error *ngIf="form.get('Name')?.hasError('required')"> * Campo obbligatorio</mat-error>
      <mat-error *ngIf="form.get('Name')?.hasError('maxlength')"> * Campo non valido</mat-error>
    </mat-form-field>
  </div>

  <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">
    <mat-form-field>
      <mat-label>Codice</mat-label>
      <input matInput placeholder="Codice" formControlName="Code" />
      <mat-error *ngIf="form.get('Code')?.hasError('required')"> * Campo obbligatorio</mat-error>
      <mat-error *ngIf="form.get('Code')?.hasError('maxlength')"> * Campo non valido</mat-error>
    </mat-form-field>
  </div>
</form>
