<h1 mat-dialog-title>Attenzione</h1>
<div mat-dialog-content>

  <ng-container *ngIf="data.length > 1; else singleErrorTemplate">

    <p>
      I seguenti ordini non sono stati presi in carico:
    </p>

    <mat-list>

      <ng-container *ngFor="let error of data">

        <div mat-subheader> Codice Ordine: {{ error.FlowOID }}</div>
        <mat-list-item>
          <mat-icon mat-list-icon>report</mat-icon>
          <div mat-line><strong>{{error.Error}}</strong></div>
        </mat-list-item>

        <mat-divider></mat-divider>

      </ng-container>

    </mat-list>

  </ng-container>

  <ng-template #singleErrorTemplate>

    <div class="text-center">

      <p>Il tuo ordine {{ data[0].FlowOID }} non è stato preso in carico a causa del seguente errore:</p>
      <strong> {{ data[0].Error }}</strong>
    </div>

  </ng-template>

</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial color="warn">Chiudi</button>
</div>
