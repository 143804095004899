import { Component } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Group } from "../../../../../core/interfaces/group";

@Component({
  selector: "app-group-general-data",
  templateUrl: "./group-general-data.component.html",
  styleUrls: ["./group-general-data.component.scss"]
})
export class GroupGeneralDataComponent {

  form: FormGroup;

  constructor(private fb: FormBuilder) {

    this.form = this.fb.group({
      Active: new FormControl(false),
      Name: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
      Code: new FormControl(null, [
        Validators.required,
        Validators.maxLength(50)
      ]),
      AutoAcceptPrintQuote: new FormControl(true)
    });

  }

  /**
   * It sets the form values to the values of the group object passed in
   * @param {Group} group - Group - This is the group object that is passed in from the parent component.
   */
  setFormValue(group: Group) {

    if (group.Active) {

      this.form.get("Active")?.setValue(group.Active);

    }

    if (group.Name) {

      this.form.get("Name")?.setValue(group.Name);

    }

    if (group.Code) {

      this.form.get("Code")?.setValue(group.Code);
      this.form.get("Code")?.disable();

    }

    group.AutoAcceptPrintQuote = true; // abbiamo deciso di settarlo sempre a true di default AB#8087

    if (group.AutoAcceptPrintQuote) {

      this.form.get("AutoAcceptPrintQuote")?.setValue(group.AutoAcceptPrintQuote);

    }

    this.form.updateValueAndValidity();
    this.form.markAllAsTouched();

  }

}
