import { Component } from '@angular/core';

@Component({
  selector: 'app-tracking-export-date-from-alert',
  template: `
    <h1 mat-dialog-title>Attenzione!</h1>

    <div mat-dialog-content>
      <p class="text-center">
        L'export richiede una data di inizio valida. <br /> Ricorda che il periodo massimo consentito è di 6 mesi.
    </div>

    <div mat-dialog-actions class="d-flex space-between">
      <button mat-button [mat-dialog-close] color="primary">Chiudi</button>
    </div>
  `
})
export class TrackingExportDateFromAlertComponent {

}
