import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { GeneralService } from "../../core/lib/general.service";
import { CtResetPasswordService } from "@ctsolution/ct-authentication";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { ForgottenConfirmDialogComponent } from "./forgotten-confirm-dialog.component";

@Component({
  selector: "app-forgotten",
  templateUrl: "./forgotten.component.html",
  styleUrls: ["./forgotten.component.css"]
})
export class ForgottenComponent implements OnInit {

  public form: UntypedFormGroup = Object.create(null);

  constructor(private fb: UntypedFormBuilder, private _general: GeneralService, private ctResetPasswordService: CtResetPasswordService, private dialog: MatLegacyDialog) {
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: [null, Validators.compose([Validators.required, Validators.email])]
    });
  }

  onSubmit(): void {

    if (this.form.valid) {

      this.ctResetPasswordService
        .resetPassword(this.form.value.email)
        .subscribe(() => this.dialog.open(ForgottenConfirmDialogComponent));

    }

  }

}
