import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CTDatatablePaginationParameter, CtDatatableResult } from "@ctsolution/ct-framework";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { UserListParameter } from "../classes/user-list.parameter";
import { User } from "../classes/user";

@Injectable()
export class UserController {

  private controller: string = "/User/";

  constructor(private _webapi: CtWebapiService) {
  }

  list(parameter: UserListParameter, pagination: CTDatatablePaginationParameter = new CTDatatablePaginationParameter()): Promise<CtDatatableResult<User>> {

    return new Promise<CtDatatableResult<User>>((resolve) => {

      this._webapi
        .get(new DataRequest(`${this.controller}UserList`, { ...parameter, ...pagination }))
        .subscribe((r: CtWebapiGenericResponse<CtDatatableResult<User>>) => resolve(r.Result));

    });

  }

  save(user: User): Promise<any> {

    return new Promise<any>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}Save`);

      return this._webapi
        .put(request, user)
        .subscribe((r: CtWebapiGenericResponse<any>) => resolve(r.Result));

    });

  }

  delete(userInfoOID: number): Promise<any> {

    return new Promise<any>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}Delete`, { userInfoOID });

      return this._webapi
        .delete(request)
        .subscribe((r: CtWebapiGenericResponse<any>) => resolve(r.Result));

    });

  }


}
