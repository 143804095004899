import { Injectable } from "@angular/core";
import {
  SmartDeliveryPriorityRuleComponent
} from "./smart-delivery-priority-rule/smart-delivery-priority-rule.component";
import {
  SmartDeliveryConfigurationRow,
  SmartDeliveryConfigurationSaveParameter
} from "../../../../core/classes/smart-delivery-configuration-save.parameter";
import { MatLegacyDialog, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import {
  SmartDeliveryRuleConditionsComponent
} from "./smart-delivery-rule-conditions/smart-delivery-rule-conditions.component";

@Injectable()
export class SmartDeliveryRuleService {

  constructor(private dialog: MatLegacyDialog) {
  }

  openRulePriorityForm(parameter: SmartDeliveryConfigurationSaveParameter | null = null): MatLegacyDialogRef<SmartDeliveryPriorityRuleComponent, SmartDeliveryConfigurationRow> {

    return this.dialog
      .open(SmartDeliveryPriorityRuleComponent, { minWidth: "400px", data: parameter });

  }

  openRuleActivationConditions(rule: SmartDeliveryConfigurationRow) {

    return this.dialog
      .open(SmartDeliveryRuleConditionsComponent, { minWidth: "810px", data: rule });

  }

}
