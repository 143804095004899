import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { DemoMaterialModule } from '../../demo-material-module';
import { OrdersComponent } from './orders.component';
import { CtDatatableModule, CtSearchFiltersModule } from '@ctsolution/ct-framework';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { OrderDetailModule } from './order-detail/order-detail.module';
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  imports: [
    CommonModule,
    DemoMaterialModule,
    FlexLayoutModule,
    MatCardModule,
    CtDatatableModule,
    CtSearchFiltersModule,
    OrderDetailModule,
    MatDialogModule
  ],
  declarations: [OrdersComponent],
})
export class OrdersModule {}
