import { Component, Inject } from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-html-editor",
  templateUrl: "./html-editor.component.html"
})
export class HtmlEditorComponent {

  control: FormControl = new FormControl(null, Validators.required);

  constructor(@Inject(MAT_DIALOG_DATA) public content: string, private dialogRef: MatLegacyDialogRef<HtmlEditorComponent>) {
  }

  ngOnInit() {

    if (this.content) {

      this.control.setValue(this.content);

    }

  }

  submit() {

    if (this.control.valid) {

      this.dialogRef
        .close(this.control.value);

    }

  }

  onUpload(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = () => {

      if (reader.result) {

        this.control.setValue(reader.result as string);

      }

    };

    reader.readAsText(file);

  }

}
