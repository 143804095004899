import { Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { CtFileUploaderConfiguration } from "@ctsolution/ct-framework";
import { Validators } from "@angular/forms";
import {
  FlowComponentCardContainerConfiguration
} from "../../../../_components/flow-components/flow-component-card-container.configuration";
import {
  FlowFileUploaderComponent
} from "../../../../_components/flow-components/file-uploader/flow-file-uploader.component";
import { GroupController } from "../../../../core/controllers/group.controller";
import { MatExpansionPanel } from "@angular/material/expansion";
import { SnackbarService } from "../../../../core/lib/snackbar.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  GroupBudgetMassiveManagementConfirmComponent
} from "./group-budget-massive-management-confirm/group-budget-massive-management-confirm.component";

@Component({
  selector: "app-group-budget-massive-management",
  templateUrl: "./group-budget-massive-management.component.html",
  styleUrls: ["./group-budget-massive-management.component.scss"],
  providers: [GroupController]
})
export class GroupBudgetMassiveManagementComponent {

  @ViewChild("Uploader") fileUploader!: FlowFileUploaderComponent;
  @ViewChild("expansionPanel") expansionPanel!: MatExpansionPanel;

  ctFileUploaderConfiguration: CtFileUploaderConfiguration = CtFileUploaderConfiguration.create()
    .setLimit(1)
    .setControlValidators(Validators.required)
    .setAcceptedFormats([
      ".csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel"
    ]);

  @Output() refresh: EventEmitter<boolean> = new EventEmitter<boolean>();

  currentDate: Date = new Date();

  description: string = `Carica il tuo file in formato .csv o .xlxs per aggiornare massivamente i budget dei tuoi Centri di Costo.`
    + `<small><br/>Ricorda: il file deve avere una riga di intestazione formata da <strong>\"Codice\"</strong>, <strong>\"${this.currentDate.getFullYear()}\"</strong> (anno corrente) e <strong>"${this.currentDate.getFullYear() + 1}"</strong> (anno successivo).`
    + `<br/>In caso di budget illimitato inserisci il valore "<strong>ILLIMITATO</strong>" nell'apposita cella (non viene tenuto in considerazione l'anno).</small>`;

  groupBudgetFileUploaderConfiguration: FlowComponentCardContainerConfiguration =
    FlowComponentCardContainerConfiguration.create()
      .setTitle("Importazione Budget")
      .setDescription(this.description)
      .setIcon("account_balance")
      .setCtFileUploaderConfiguration(this.ctFileUploaderConfiguration);

  constructor(private groupController: GroupController, private snackbar: SnackbarService, private _dialog: MatDialog) {
  }

  import() {

    const control = this.fileUploader.configuration.ctFileUploaderConfiguration?.control;

    control?.markAsTouched();

    if (control?.valid) {

      this.groupController
        .importGroupBudget(control.value[0])
        .then((result: boolean) => {

          if (result) {

            this.expansionPanel.close();

            this.fileUploader.configuration.ctFileUploaderConfiguration!.files = [];
            control.reset();

            this.snackbar.open("File caricato correttamente!");
            this.refresh.emit();

          }

        });

    }

  }

  export() {

    this._dialog
      .open(GroupBudgetMassiveManagementConfirmComponent)
      .afterClosed()
      .subscribe((confirm: boolean) => {

        if (confirm) {

          this.groupController
            .exportGroupBudget();

        }

      });

  }

}
