import { Component, Input } from "@angular/core";
import { Flow } from "../../../core/classes/flow";
import { FLOW_STATE } from "../../../core/enums/flow-state";

@Component({
  selector: "app-cart-order-expiration-notice",
  template: `
    <div *ngIf="remainingDays >= 0" class="cart-order-expiration-notice-container">
      Attenzione: Ordine in scadenza,
      tra {{ remainingDays > 1 ? remainingDays : 1 }} {{ remainingDays > 1 ? 'giorni' : 'giorno' }} verrà
      cancellato automaticamente.
    </div>`,
  styles: [
    `.cart-order-expiration-notice-container {
      display: flex;
      align-items: center;
      color: red;
    }`
  ]
})
export class CartOrderExpirationNoticeComponent {

  @Input() flow: Flow | null = null;

  remainingDays: number = -1;
  expirationThreshold: number = 30;
  daysWarningThreshold: number = 5;

  ngAfterViewInit() {

    this.calculateRemainingDays();

  }

  calculateRemainingDays() {

    if (![FLOW_STATE.PRICE_ESTIMATE, FLOW_STATE.CART].includes(this.flow?.State ?? -1)) return;

    const flowStateDate = new Date(this.flow?.StateDate ?? -1);

    const expirationDate = new Date(flowStateDate);
    expirationDate
      .setDate(expirationDate.getDate() + this.expirationThreshold);

    const currentDate = new Date();

    const daysRemaining: number = Math.floor((expirationDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));

    this.remainingDays = (daysRemaining >= 0 && daysRemaining <= this.daysWarningThreshold) ? daysRemaining : -1;

  }

}
