<h4>Limite spesa (budget) opzionale:</h4>

<form [formGroup]="form" fxLayout="row wrap">

  <div
    fxFlex.gt-sm="100"
    fxFlex.gt-md="100"
    fxFlex="100" class="p-10">

    <section>
      <mat-checkbox class="checkbox-margin" formControlName="InfiniteBudget">
        Budget illimitato
      </mat-checkbox>
    </section>

  </div>

  <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10" *ngIf="currentBudgetPair">
    <mat-form-field>
      <mat-label> Budget {{ currentBudgetPair.Year }}</mat-label>
      <input
        matInput
        [placeholder]="'Budget ' + currentBudgetPair.Year"
        formControlName="BudgetForCurrentYear"
        type="number"
      />
      <mat-icon matSuffix>euro</mat-icon>
    </mat-form-field>
  </div>

  <div fxFlex.gt-sm="100" fxFlex.gt-md="50" fxFlex="100" class="p-10" *ngIf="nextBudgetPair">
    <mat-form-field>
      <mat-label> Budget {{ nextBudgetPair.Year }}</mat-label>
      <input
        matInput
        [placeholder]="'Budget ' + nextBudgetPair.Year"
        formControlName="BudgetForNextYear"
        type="number"
      />
      <mat-icon matSuffix>euro</mat-icon>
    </mat-form-field>
  </div>
</form>
