import { Injectable } from "@angular/core";
import { JwtService } from "./jwt.service";
import { ROLES } from "../enums/roles.enum";

@Injectable({
  providedIn: "root"
})
export class RoleService {

  constructor(private _jwt: JwtService) {
  }

  isCompanyAdminOrCompanyGroupAdmin = async () => await this.hasRoles([ROLES.COMPANY_GROUP_ADMIN, ROLES.COMPANY_ADMIN]);

  isCompanyGroupAdmin = async () => await this.hasRoles([ROLES.COMPANY_GROUP_ADMIN]);

  isCustomerCare = async () => await this.hasRoles([ROLES.CUSTOMER_CARE]);

  hasAdministrativeAccess = async () => await this.hasRoles([ROLES.COMPANY_ADMIN, ROLES.GROUP_ADMIN, ROLES.COMPANY_GROUP_ADMIN]);

  private async hasRoles(roles: ROLES[]) {

    const role: string | Array<string> | null = await this._jwt.getUserRole();

    if (role) {

      if (Array.isArray(role)) {

        return role.some((userRole: string) => roles.includes(<ROLES>userRole.toLowerCase()));

      } else {

        return roles.includes(<ROLES>role.toLowerCase());

      }

    }

    return false;

  }

}
