<div fxLayout="row wrap">
  <div
    fxFlex.gt-lg="50"
    fxFlex.gt-md="50"
    fxFlex.gt-xs="100"
    fxFlex="100"
    *ngFor="let parameters of cardsArrayParameters"
  >
    <ct-card [parameters]="parameters"></ct-card>
  </div>
</div>
