<div class="cart-person-specifications-container">

  <p> {{ user?.Name ?? '' }}</p>

  <div [hidden]="configuration?.flow?.digitalSendingStandaloneChannelSetupEnabled">

    <p> {{ user?.Address ?? '' }}</p>
    <p> {{ user?.ZipCode ?? '' }} {{ user?.City ?? '' }} {{ user?.Province ?? '' }}</p>
    <p> {{ user?.Country ?? '' }}</p>

  </div>

  <p> {{ user?.Phone ?? '' }}</p>
  <p> {{ user?.Email ?? '' }}</p>
  <p> {{ user?.CertifiedEmail ?? '' }}</p>

  <p *ngIf="user?.InternalCode"> Codice interno: {{ user?.InternalCode }}</p>

</div>
