import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlowFileUploaderComponent } from './flow-file-uploader.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatIconModule } from '@angular/material/icon';
import { CtFileUploaderModule } from '@ctsolution/ct-framework';

@NgModule({
  declarations: [FlowFileUploaderComponent],
  imports: [CommonModule, MatCardModule, MatIconModule, CtFileUploaderModule],
  exports: [FlowFileUploaderComponent],
})
export class FlowFileUploaderModule {}
