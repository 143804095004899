<h1 mat-dialog-title>Attenzione</h1>
<div mat-dialog-content>
  <p>
    Entrambe le validazioni non sono andate a buon fine cliccare sull'icona adiacente per validare
    l'indirizzo.
  </p>
</div>
<div mat-dialog-actions>
  <button mat-button mat-dialog-close cdkFocusInitial color="primary">Valida</button>
</div>
