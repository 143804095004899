import { ChangeDetectorRef, Component, Inject, ViewChild } from "@angular/core";
import { Group } from "../../../../core/interfaces/group";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import { GroupGeneralDataComponent } from "./group-general-data/group-general-data.component";
import { FormBuilder, FormGroup } from "@angular/forms";
import { GroupAddressComponent } from "./group-address/group-address.component";
import { GroupBudgetComponent } from "./group-budget/group-budget.component";
import { SnackbarService } from "../../../../core/lib/snackbar.service";
import { GroupController } from "../../../../core/controllers/group.controller";
import { GroupBudget } from "../../../../core/classes/group-budget";
import { AddressData } from "../../../../core/classes/address-data";
import { GroupSender } from "../../../../core/classes/group-sender";
import { GeneralService } from "../../../../core/lib/general.service";
import { JwtService } from "../../../../core/lib/jwt.service";

@Component({
  selector: "app-group-detail",
  templateUrl: "./group-detail.component.html",
  styleUrls: ["./group-detail.component.scss"],
  providers: [GroupController]
})
export class GroupDetailComponent {

  form: FormGroup;

  //#region group forms

  @ViewChild("GeneralData") generalDataComponent!: GroupGeneralDataComponent;

  @ViewChild("Budgets") groupBudget!: GroupBudgetComponent;

  @ViewChild("Address") addressComponent!: GroupAddressComponent;

  //#endregion Steps

  viewModel = {

    insert: false,
    displayTabGroup: false

  };

  constructor(
    private fb: FormBuilder,
    private _dialogRef: MatDialogRef<GroupDetailComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Group,
    private _snackbar: SnackbarService,
    private groupController: GroupController,
    private cdr: ChangeDetectorRef,
    private general: GeneralService,
    private _jwt: JwtService
  ) {

    this.form = this.fb.group({});

    this.viewModel.insert = !this.data.Oid;

    this._dialogRef
      .afterOpened()
      .subscribe(() => this.render());

  }

  // Questa gestione perchè il componente mat-tab viene renderizzato troppo in fretta, non tenendo conto del lasso di tempo di apertura della modale.
  private render() {

    this.viewModel.displayTabGroup = true;

    this.cdr.detectChanges();

    this.init();

  }

  init() {

    if (this.viewModel.insert) {

      this.initForms();

    } else {

      this.groupController
        .detail(this.data.Oid!) // modifica avvenuta dopo l'unificazione dei controlli sui budget da parte di fra. Non ci saranno piu chiamate dedicate, ma una unica
        .then((group: Group) => {

          this.data = group;
          this.initForms();

        });

    }

  }

  private initForms() {

    this.setGeneralDataForm();

    if (!this.viewModel.insert) {

      this.setBudgetsForm();

      this.setAddressForm();

    }

  }

  /* Adding the form of the component to the form of the parent component. */
  private setGeneralDataForm() {

    this.form.addControl("GeneralData", this.generalDataComponent!.form);
    this.generalDataComponent.form.setParent(this.form);
    this.generalDataComponent.setFormValue(this.data);

  };

  /* Adding the form of the component to the form of the parent component. */
  private setBudgetsForm() {

    this.form.addControl("Budgets", this.groupBudget!.form);
    this.groupBudget.form.setParent(this.form);
    this.groupBudget.setGroupBudgetsPair(this.data.BudgetList ?? []);
    this.groupBudget.setInfiniteBudget(this.data.InfiniteBudget ?? false);

  };

  /* Adding the form of the component to the form of the parent component. */
  private setAddressForm() {

    this.form.addControl("Address", this.addressComponent!.form);
    this.addressComponent.form.setParent(this.form);
    this.addressComponent.setFormValue(this.data.SenderData ?? null);

  };

  async submit() {

    if (this.form.valid) {

      if (this.generalDataComponent.form.touched) {

        this.data = this.setGroupGeneralData(this.data);

      }

      if (!this.viewModel.insert) {

        if (this.groupBudget.form.touched) {

          this.data = this.setGroupBudgets(this.data);

        }

        if (this.addressComponent.form.touched) {

          this.data = this.setGroupSender(this.data);

        }

      } else {

        if (!this.data.CompanyCode) {

          this.data.CompanyCode = await this._jwt.getCompanyCode();

        }

        this.data.InfiniteBudget = true;

      }

      this.groupController
        .save(this.data)
        .then(() => {

          this._snackbar.open("Modifica avvenuta con successo!");

          this._dialogRef
            .close(true);

        });

    } else {

      this.general.scrollToFirstInvalidControl();

    }

  }

  private setGroupGeneralData(group: Group): Group {

    const generalData = this.generalDataComponent.form.value;

    return <Group>{ ...group, ...generalData };

  }

  private setGroupBudgets(group: Group): Group {

    const budgetData = this.groupBudget.form.value;

    if (!group.BudgetList) {

      group.BudgetList = [];

    }

    const currentYear: number = new Date().getFullYear();
    const budgetForCurrentYear: GroupBudget = group.BudgetList[0] ?? new GroupBudget(group.Oid, currentYear, 0),
      budgetForNextYear: GroupBudget = group.BudgetList[1] ?? new GroupBudget(group.Oid, currentYear + 1, 0);

    budgetForCurrentYear.Amount = budgetData.BudgetForCurrentYear ?? 0;
    budgetForNextYear.Amount = budgetData.BudgetForNextYear ?? 0;

    group.BudgetList = [
      budgetForCurrentYear,
      budgetForNextYear
    ];

    group.InfiniteBudget = budgetData.InfiniteBudget;

    return group;

  }

  private setGroupSender(group: Group): Group {

    const addressFormValue: {
      AddressData: AddressData,
      AutoFillSenderData: boolean
    } = this.addressComponent.form.value;

    if (addressFormValue.AutoFillSenderData) {

      const groupSender: GroupSender = new GroupSender(
        addressFormValue.AutoFillSenderData,
        addressFormValue.AddressData
      );

      group.SenderData = groupSender;

    } else {

      group.SenderData = <GroupSender>{
        AutoFillSenderData: false
      };

    }

    return group;

  }

}
