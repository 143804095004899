import { Injectable } from "@angular/core";
import { AbstractControl } from "@angular/forms";

@Injectable({
  providedIn: "root"
})
export class FormService {

  constructor() {
  }

  clearControlValidators(control: AbstractControl<any, any> | null) {

    control?.setValidators(null);
    control?.updateValueAndValidity();

  }

}
