export enum FLOW_FILE_TYPE {

  RecipientList = 5,
  Attachment = 10,
  Content = 11,
  Archive = 15,
  Unmanaged = 0,
  OutputRecipientFile = 20,
  PreviewRecipient = 19

}
