<div class="cart-total-cntr">

  <button mat-button (click)="buy()" color="primary"><span
    style="color: #67757c"> Totale {{ totalAmount | currency:'EUR':'symbol':'1.0-3' }} </span> <span style="color: black">&nbsp;|&nbsp;</span>
    <span>Procedi con l'acquisto</span>
    <mat-icon>chevron_right</mat-icon>
  </button>

</div>

