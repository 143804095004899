import { Injectable } from "@angular/core";
import { AddressValidatorComponent } from "./validator/address-validator.component";
import { AddressData } from "../../../../core/classes/address-data";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { IValidationResult } from "../../../../core/interfaces/address-validation";
import { ValidationController } from "../../../../core/controllers/validation.controller";
import {
  AlertBothAddressesInvalidComponent
} from "./alert-both-addresses-invalid/alert-both-addresses-invalid.component";
import { AddressValidatorCache } from "./address-validator-cache";
import {
  AlertMultipleRecipientsInvalidComponent
} from "./alert-multiple-recipients-invalid/alert-multiple-recipients-invalid.component";
import { FlowSendAreaEnum } from "../../../../core/enums/flow-send-area.enum";
import { FlowRecipientValidation } from "../../../../core/classes/flow-recipient-validation";
import { DEFAULT_COUNTRY } from "../../../../core/enums/destination.enum";
import { StepSaveResult } from "../../../../core/controllers/flow-steps.controller";

const FAKE_VALID_ADDRESS: IValidationResult = { IsValid: true } as IValidationResult;

@Injectable()
export class AddressValidatorService {
  validating: boolean = false;
  cache: AddressValidatorCache = new AddressValidatorCache();

  constructor(private _dialog: MatDialog, private _controller: ValidationController) {
  }

  /* A function that opens a dialog. */
  openValidator = (reference: string, parameter: AddressData) =>
    this._dialog.open(AddressValidatorComponent, {
      data: {
        title: reference,
        parameter
      },
      disableClose: true
    });

  openMultipleRecipientsValidation = (data: StepSaveResult) =>
    this._dialog.open(AlertMultipleRecipientsInvalidComponent, {
      data,
      disableClose: true
    });

  /* A function that opens a dialog. */
  invalidAddressesAlert = () =>
    this._dialog.open(AlertBothAddressesInvalidComponent, { maxWidth: "500px" });

  /**
   * It takes an address data object, checks the cache for a matching address validation object, and if it doesn't find
   * one, it calls the controller to validate the address
   * @param {AddressData} parameter - AddressData - this is the address data that you want to validate.
   * @returns A promise that resolves to an AddressValidation object.
   */
  validateAddressData(addressData: AddressData, destination: FlowSendAreaEnum = FlowSendAreaEnum.Local): Promise<IValidationResult | null> {

    const parameter: FlowRecipientValidation = new FlowRecipientValidation(addressData, destination);

    return new Promise<IValidationResult | null>((resolve) => {
      let cachedElement: IValidationResult | undefined = this.cache.get(parameter);

      if (cachedElement) {
        resolve(cachedElement);
        return;
      }

      this.validating = true;

      this._controller
        .validateAddressData(parameter)
        .then((result: IValidationResult | null) => {

          this.validating = false;

          if (result) {
            this.cache.save(parameter, result);
          }

          resolve(result);
        });
    });
  }

  /* It's a function that takes a number and a total length and returns a string with leading zeros. */
  addLeadingZeros = (num: number, totalLength: number): string => String(num).padStart(totalLength, "0");

  /* It's a function that takes an address data object and sets the country to Italy if it's not set. */
  handleDefaultLocalCountryValue(address: AddressData) {

    if (!address.Country) {

      address.Country = DEFAULT_COUNTRY;

    }

    return address;

  };


  checkContactDetailsValidations(senderAddressData: AddressData, recipientAddressData: AddressData, opt?: AddressValidatorOptions): Promise<Step2AddressValidations> {

    return new Promise<Step2AddressValidations>((resolve) => {

      const toReturn: Step2AddressValidations = new Step2AddressValidations();

      const validateSender = () => {

        if (opt?.bypassSenderValidation) {

          toReturn
            .setSenderValidation(FAKE_VALID_ADDRESS);

          resolve(toReturn);

        } else {

          this.validateAddressData(senderAddressData)
            .then((validationResult: IValidationResult | null) => {

              toReturn
                .setSenderValidation(validationResult);

              resolve(toReturn);

            });

        }

      };

      if (opt?.sendArea === FlowSendAreaEnum.External) {

        toReturn
          .setRecipientValidation(FAKE_VALID_ADDRESS); // per le destinazioni estere simulo la validazione a buon fine (perchè non necessaria)

        validateSender();

      } else {

        this.validateAddressData(recipientAddressData)
          .then((validationResult: IValidationResult | null) => {

            toReturn
              .setRecipientValidation(validationResult);

            validateSender();

          });

      }

    });

  }

  getPrintedOption(address: AddressData | any): string {

    const addressFields: string[] = [];

    if (address.Name) addressFields.push(address.Name);
    if (address.Address) addressFields.push(address.Address);
    if (address.ZipCode) addressFields.push(address.ZipCode);
    if (address.City) addressFields.push(address.City);
    if (address.Province) addressFields.push(address.Province);
    if (address.Email) addressFields.push(address.Email);
    if (address.Phone) addressFields.push(address.Phone);

    let toReturn = addressFields.join(", ");

    if (address.userValue) {

      toReturn += ` - DATI INSERITI DALL'UTENTE`;

    }

    return toReturn;

  };

}

export class Step2AddressValidations {

  senderValidation: IValidationResult | null = null;
  recipientValidation: IValidationResult | null = null;

  constructor() {
  }

  setSenderValidation(value: IValidationResult | null): Step2AddressValidations {

    this.senderValidation = value;
    return this;

  }

  setRecipientValidation(value: IValidationResult | null): Step2AddressValidations {

    this.recipientValidation = value;
    return this;

  }

}

export class AddressValidatorOptions {

  sendArea: FlowSendAreaEnum | null = null;
  bypassSenderValidation: boolean = false;

  constructor() {
  }

  setSendArea(value: FlowSendAreaEnum | null): AddressValidatorOptions {

    this.sendArea = value;
    return this;

  }

  setBypassSenderValidation(value: boolean) {

    this.bypassSenderValidation = value;
    return this;

  }

}
