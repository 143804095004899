import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { FlowFile } from "../../../core/interfaces/flow-file";
import { FlowFileController } from "../../../core/controllers/flow-file.controller";
import { CtFileUploaderComponent, CTGeneralService } from "@ctsolution/ct-framework";
import { FlowComponentCardContainerConfiguration } from "../flow-component-card-container.configuration";

@Component({
  selector: "app-flow-file-uploader",
  templateUrl: "./flow-file-uploader.component.html",
  styleUrls: ["./flow-file-uploader.component.scss"]
})
export class FlowFileUploaderComponent implements OnDestroy {

  @ViewChild("Uploader") fileUploader!: CtFileUploaderComponent;
  @Input() configuration!: FlowComponentCardContainerConfiguration;
  @Output() onFlowFilesUpdate: EventEmitter<Array<FlowFile>> = new EventEmitter<Array<FlowFile>>();

  private files: FlowFile[] = [];

  constructor(
    private _controller: FlowFileController,
    private cdr: ChangeDetectorRef,
    private general: CTGeneralService
  ) {
  }

  ngAfterViewInit() {

    if (this.configuration.ctFileUploaderConfiguration) {

      this.configuration
        .ctFileUploaderConfiguration
        .setTitle(null)
        .setLimit(1);

    }

    this.cdr.detectChanges();

  }

  /**
   * It takes a list of FlowFile objects, converts them to File objects, and then passes them to the fileBrowseHandler
   * function of the fileUploader object
   * Poiché questa funzione è condivisa, la variabile "bypassLimits" viene utilizzata per ignorare il controllo che viene effettuato sulla dimensione massima dei file caricati.
   * Il "configuration.limit" dovrebbe essere applicato solo ai file caricati dall'utente attraverso l'uploader, ma NON dovrebbe essere applicato, ad esempio, ai file caricati dal server.
   * Pertanto, questa variabile pilota la gestione del limite (abilitandola o disabilitandola) in base al contesto.
   * @param {FlowFile[]} list - FlowFile[] - The list of FlowFiles to set as the files in the uploader
   * @param {boolean} bypassLimits - boolean - Bypass limit checks
   */
  setFiles(list: FlowFile[], bypassLimits: boolean = false) {

    const tempFiles: File[] = (this.configuration.ctFileUploaderConfiguration?.files ?? []).filter((element: FlowFile | File) => element instanceof File);

    this.reset();
    this.files = list;

    const formattedFileAttributes: File[] = this.files.map((f: FlowFile) => <File>{ name: f.Name });

    tempFiles.forEach((file: File) => {

      if (!formattedFileAttributes.map((flowFile: File) => flowFile.name).includes(file.name)) formattedFileAttributes.push(file);

    });

    this.fileUploader.prepareFilesList(formattedFileAttributes, bypassLimits);

  }

  /**
   * It takes a file object, finds the corresponding file in the files array, deletes it from the database, and then
   * removes it from the files array
   * @param {File} event - File - this is the file that was deleted.
   */
  deleteFile(event: File) {

    const file: FlowFile | undefined = this.files.find((f) => f.Name === event.name);

    if (file) {

      this._controller
        .deleteFlowFileFromDraft(file.Oid)
        .then((result: FlowFile[]) => this.onFlowFilesUpdate.emit(result))
        .catch((err) => this.general.reloadCurrentRoute());

    }

  }

  reset() {

    this.fileUploader.reset();

  }

  ngOnDestroy() {

    this.reset();

  }

}
