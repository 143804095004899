import { Component, EventEmitter, Input, Output } from "@angular/core";
import { FlowService } from "../../../../core/lib/flow.service";

@Component({
  selector: "app-stepper-actions",
  templateUrl: "./stepper-actions.component.html",
  styleUrls: ["./stepper-actions.component.scss"]
})
export class StepperActionsComponent {

  @Input() currentIndex: number = 0;
  @Output() back: EventEmitter<number> = new EventEmitter<number>();
  @Output() next: EventEmitter<number> = new EventEmitter<number>();

  constructor(private flowService: FlowService) {
  }

  /**
   * If the user clicks the cancel button, then open a dialog box asking the user if they're sure they want to cancel. If
   * the user clicks yes, then emit the cancel event
   */
  onCancel() {

    this.flowService
      .openFlowAlertDialog()
      .afterClosed()
      .subscribe((result: any) => {

        if (result) {

          this.flowService.cancelFlow(["/nuova-spedizione"]);

        }

      });

  }

}
