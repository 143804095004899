<app-digital-channel-description
  *ngIf="enableSpecificationDescription"
  [SendTypeSendCategory]="flow?.SendTypeConfigurationSendTypeSendCategory">
</app-digital-channel-description>

<form [formGroup]="form" fxLayout="row wrap">

  <mat-form-field [hidden]="!flow?.emailStandaloneChannelSetupEnabled">
    <mat-label>Oggetto Mail</mat-label>
    <input type="text" matInput formControlName="SendSubject">
    <mat-error *ngIf="form.get('SendSubject')?.hasError('required')"> * Campo obbligatorio</mat-error>
  </mat-form-field>

  <div class="letter-specifications-cntr"
       [hidden]="flow?.digitalSendingChannelSetupEnabled">

    <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10 destination-control-container">

      <p>Destinazione:</p>

      <mat-radio-group class="d-flex flex-column" formControlName="Destination">
        <mat-radio-button [value]="DESTINATIONS.Local"> ITALIA</mat-radio-button>
        <mat-radio-button
          [hidden]="!viewModel.printInternationalEnabled"
          [value]="DESTINATIONS.External"> ESTERO
        </mat-radio-button>
      </mat-radio-group>

    </div>

    <ng-container
      *ctSkeleton="loadingGroupDetails;
           repeat: 3;
           className: 'specifications-letter-card-skeleton';">

      <ng-container *ngIf="(UserGroup?.FlowColorModeList ?? []).length > 0">

        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">

          <p>Colore di stampa:</p>

          <mat-radio-group class="d-flex flex-column" formControlName="ColorMode">
            <mat-radio-button
              *ngFor="let mode of UserGroup.FlowColorModeList"
              [value]="mode.Code">{{ mode.Description }}</mat-radio-button>
          </mat-radio-group>

        </div>

      </ng-container>

      <ng-container *ngIf="(UserGroup?.FlowPrintModeList ?? []).length > 0">

        <div fxFlex.gt-sm="100" fxFlex.gt-md="100" fxFlex="100" class="p-10">

          <p>Modalità di stampa:</p>

          <mat-radio-group class="d-flex flex-column" formControlName="PrintMode">
            <mat-radio-button
              *ngFor="let mode of UserGroup.FlowPrintModeList"
              [value]="mode.Code">{{ mode.Description }}</mat-radio-button>
          </mat-radio-group>

        </div>

      </ng-container>

    </ng-container>

  </div>

</form>
