import { RecipientAddress } from "./recipient-address";
import { FlowSendAreaEnum } from "../enums/flow-send-area.enum";

export class FlowRecipientValidation {

  constructor(
    public Recipient: RecipientAddress,
    public SendArea: FlowSendAreaEnum
  ) {
  }

}
