<h1 mat-dialog-title style="margin: 0px !important">Sorgente HTML Personalizzato</h1>
<p>Qui hai la possibilità di inserire direttamente il codice HTML che verrà impostato nell'editor di testo
</p>
<mat-form-field>
  <mat-label>Sorgente HTML</mat-label>
  <textarea
    matInput
    [formControl]="control"
    cdkTextareaAutosize
    cdkAutosizeMinRows="25"
    cdkAutosizeMaxRows="30"
    style="font-family: 'Courier New', monospace; font-size: 14px; color: #333; background-color: #f5f5f5;"
  ></textarea>
  <mat-error *ngIf="control?.hasError('required')"> * Campo obbligatorio</mat-error>

</mat-form-field>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">

  <div>
    <button mat-raised-button color="warn" [mat-dialog-close]="false">
      Annulla
    </button>
  </div>

  <div>

    <button mat-raised-button color="accent" (click)="fileUploader.click()">
      Carica file...
      <input hidden #fileUploader type="file" accept="text/html" (change)="onUpload($event)">
    </button>
    <button mat-raised-button color="primary" (click)="submit()">
      Salva
    </button>

  </div>
</div>
