import { Injectable } from "@angular/core";
import { BehaviorSubject, Subscription } from "rxjs";

@Injectable({
  providedIn: "root"
})
export class CancelOrderSubmissionService {

  public isLoading: BehaviorSubject<any> = new BehaviorSubject(null);
  private cancelOrder: BehaviorSubject<any> = new BehaviorSubject(null);

  subscription?: Subscription;

  constructor() {
  }

  handleCancellation(): Promise<boolean> {

    this.isLoading.next(true);

    return new Promise<boolean>((resolve) => {

      this.subscription = this.cancelOrder
        .subscribe((value: boolean | null) => {

          if (value !== null) {

            this.isLoading.next(false);
            resolve(value);
            this.reset();

          }

        });

    });

  }

  cancel(value: boolean) {

    this.isLoading.next(false);
    this.cancelOrder.next(!value);

  }

  reset() {

    this.subscription?.unsubscribe();

    this.isLoading.next(null);
    this.cancelOrder.next(null);

  }

}
