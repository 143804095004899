import { Component, Input, OnInit } from "@angular/core";
import { Flow } from "../../../../../core/classes/flow";
import { FlowFile } from "../../../../../core/interfaces/flow-file";
import { CtFileController } from "@ctsolution/ct-webapi";
import { CtFileManagementService } from "@ctsolution/ct-file-management";
import { FileService } from "../../../../../core/lib/file.service";

@Component({
  selector: "app-cart-file-list-download-button",
  templateUrl: "./cart-file-list-download-button.component.html",
  styleUrls: ["./cart-file-list-download-button.component.css"],
  providers: [CtFileController]
})
export class CartFileListDownloadButtonComponent {

  @Input() data!: Flow;

  constructor(private fileService: FileService) {
  }

  downloadFile(file: FlowFile) {

    if (!file) return;

    this.fileService
      .downloadFlowFile(file);

  }

}
