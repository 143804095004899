<div class="stepper-actions-cntr clearfix">

  <div class="stepper-actions-right">

    <button mat-raised-button color="primary" (click)="next.emit()">
      Conferma
      <mat-icon>arrow_forward</mat-icon>
    </button>

  </div>

  <div class="stepper-actions-left">

    <button mat-raised-button color="yellow" class="m-r-15" (click)="back.emit()" *ngIf="currentIndex > 0">
      <mat-icon>arrow_back</mat-icon>
      Indietro
    </button>

    <button mat-raised-button color="warn" (click)="onCancel()">
      <mat-icon>close</mat-icon>
      Annulla spedizione
    </button>

  </div>

</div>
