import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QuotationsComponent } from './quotations.component';
import { CartOrderModule } from '../../_components/cart-order/cart-order.module';
import { CtSearchFiltersModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [QuotationsComponent],
  imports: [CommonModule, CartOrderModule, CtSearchFiltersModule]
})
export class QuotationsModule {}
