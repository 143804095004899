<ng-container *ngIf="items.length > 0; else emptyCartTemplate">

  <div class="cart-cntr">

    <ng-container *ngIf="items.length >= 5">

      <ct-search-filters
        #searchFilter
        [configuration]="filtersConfiguration"
        (filter)="filter($event)"
      ></ct-search-filters>

    </ng-container>

    <div class="cart-orders-container">

      <app-cart-order
        *ngFor="let order of items"
        [configuration]="order"
        (refresh)="fetch()"
      ></app-cart-order>

      <ng-container *ngIf="items.length > 1">

        <app-cart-total [totalAmount]="cartTotalAmount" (refresh)="fetch()"></app-cart-total>

      </ng-container>

    </div>

  </div>

</ng-container>

<ng-template #emptyCartTemplate>
  <p class="text-center">Il tuo carrello è vuoto</p>
</ng-template>
