import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ViewContentComponent } from "./view-content.component";
import { RouterModule } from "@angular/router";
import { MatLegacySnackBarModule } from "@angular/material/legacy-snack-bar";

@NgModule({
  declarations: [
    ViewContentComponent
  ],
  imports: [
    CommonModule,
    MatLegacySnackBarModule,
    RouterModule
      .forChild([
        {
          path: "",
          component: ViewContentComponent
        }
      ])
  ]
})
export class ViewContentModule {
}
