import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { StandaloneSingleFlowComponent } from "./standalone-single-flow.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { Step2Module } from "../send-flow/step2/step2.module";
import { MatDividerModule } from "@angular/material/divider";
import { CtButtonModule, CtCardModule } from "@ctsolution/ct-framework";
import { FlexLayoutModule } from "@angular/flex-layout";
import { ProductSelectModule } from "./product-select/product-select.module";
import { ReactiveFormsModule } from "@angular/forms";
import { FileUploadModule } from "./file-upload/file-upload.module";
import { OrderDescriptionModule } from "./order-description/order-description.module";
import { OneClickPurchaseModule } from "./one-click-purchase/one-click-purchase.module";

@NgModule({
  declarations: [StandaloneSingleFlowComponent],
  imports: [CommonModule, MatCardModule, FileUploadModule, Step2Module, ProductSelectModule, MatDividerModule, CtButtonModule, FlexLayoutModule, ReactiveFormsModule, OrderDescriptionModule, OneClickPurchaseModule, CtCardModule],
  exports: [StandaloneSingleFlowComponent]
})
export class StandaloneSingleFlowModule {
}
