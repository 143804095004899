import { Component, Inject, OnInit } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from "@angular/material/legacy-dialog";
import { StepSaveResult } from "../../../../../core/controllers/flow-steps.controller";
import { IAddressValidation } from "../../../../../core/interfaces/address-validation";
import { AddressValidatorService } from "../address-validator.service";
import { JwtService } from "../../../../../core/lib/jwt.service";
import { CompanyCustomConfiguration } from "../../../../../core/interfaces/company-custom-configuration";

@Component({
  selector: "app-alert-multiple-recipients-invalid",
  templateUrl: "./alert-multiple-recipients-invalid.component.html",
  styleUrls: ["./alert-multiple-recipients-invalid.component.scss"]
})
export class AlertMultipleRecipientsInvalidComponent implements OnInit {

  validationResult: Array<IAddressValidation> = new Array<IAddressValidation>();

  viewModel = {

    autoCorrectRecipientFileEnabled: false

  };

  constructor(
    private _jwt: JwtService,
    public _validator: AddressValidatorService,
    @Inject(MAT_DIALOG_DATA) public data: StepSaveResult
  ) {
  }

  get fileErrorsKeys() {

    return this.data.FileErrors ? Object.keys(this.data.FileErrors) : [];

  };

  get genericError() {

    return Object.keys(this.data.FileErrors ?? {}).includes("0");

  }

  ngOnInit() {

    this.setupConfiguration()
      .then(() => {

        if (this.data.ValidationResult) {

          this.validationResult = this.data.ValidationResult as Array<IAddressValidation>;

        }

      });

  }

  async setupConfiguration() {

    const COMPANY_CONFIGURATION: CompanyCustomConfiguration | null = await this._jwt.getCompanyCustomConfiguration();

    if (!!COMPANY_CONFIGURATION) {

      this.viewModel.autoCorrectRecipientFileEnabled = COMPANY_CONFIGURATION.AutoCorrectRecipientFile ?? false;

    }

  }

}
