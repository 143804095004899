import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent {
  signatureForm: FormGroup;

  yousign: any;
  showFrame = false;
  iFrame = false;
  finish = false;

  constructor(private fb: FormBuilder, private route: Router) {
    this.signatureForm = this.fb.group({
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    });

  }


  viewContract() {
    // Inserire link
    window.open('/', '_blank');
  }

  onSubmit() {
    // Cosa apre dopo
    if (!this.signatureForm.valid) return;
    this.showFrame = true;
    setTimeout(() => {
      this.yousign = new Yousign({
        signatureLink: "https://yousign.app/signatures/5b6ad19f-eeeb-4df8-9491-01133bd203a2?s=35e7cb2d15191086aee01574f4d44202d23cfe0e2906d05b36488da3350772179e63518ed573e0a42e61a8345de04572825443adb7bce166581e17d472a35806&r=88404478adf3bb1a35df3a8440a4ad58&source=email&lang=it&magic_link_id=2046d21e-679b-42eb-b914-4e5f8b57e41f&domain_id=2a7a0d8f59&k=CMcpU6B9HvpoFdM5ZEyLjQ0WvZdyJDt4&sandbox=true",
        iframeContainerId: 'iframe-container',
        isSandbox: true, //To set if you use the sandbox environment
      });
      this.iFrame = true;

      this.yousign.onSignatureDone((data: any) => {

        this.finish = true;

        setTimeout(() => {

          this.route.navigate(['/digital-signature/thank-you']);
          if (this.iFrame) {
            this.showFrame = false;
          }

        }, 15000);

      });

      window.addEventListener('message', function(e) {

        // Check if the origin of the message is really come from Yousign
        if(e.origin === 'https://yousign.app' && e.data.type === 'yousign'){
          
          if(e.data.event === 'declined'){
            setTimeout(() => {
              this.window.location.href = "/digital-signature/error-message";
            }, 15000);
          }

          if(e.data.event === 'error'){
            setTimeout(() => {
              this.window.location.href = "/digital-signature/error-message";
            }, 15000);
          }
        }
      });

    }, 100);

  }

  disableIframe() {

    if (this.finish)
      this.route.navigate(['/digital-signature/thank-you']);


    if (this.iFrame) {
      this.showFrame = false;
    }


  }
}
