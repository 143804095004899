import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SmsEditorComponent } from "./sms-editor.component";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";

@NgModule({
  declarations: [SmsEditorComponent],
  imports: [
    CommonModule,
    TextFieldModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatCardModule,
    MatIconModule,
    MatSelectModule,
    FlexModule,
    MatMenuModule,
    MatButtonModule],
  exports: [SmsEditorComponent]
})
export class SmsEditorModule {
}
