<div fxLayout="row wrap">

  <div
    *ngFor="let card of cards"
    fxFlex.gt-sm="33.33"
    fxFlex.gt-xs="100"
    fxFlex="100">

    <ct-card [parameters]="card"></ct-card>

  </div>

</div>
