import { SendTypeSendCategory2LabelMapping, SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";
import {
  CtControlConfiguration,
  CtControlTypes,
  CtSearchFilterControlConfiguration, CtSelectControlValue,
  CtThemeTypes
} from "@ctsolution/ct-framework";
import { NameFilter } from "./group-list.parameter";

export class TemplateListParameter {

  SendCategory: SendTypeSendCategoryEnum | null = null;

  private constructor() {
  }

  public static create = (): TemplateListParameter => new TemplateListParameter();

  setSendCategory(value: SendTypeSendCategoryEnum | null) {

    this.SendCategory = value;
    return this;

  }

}

export const CodeFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration
    .create("ApiCode")
    .setLabel("Codice");

export const productTemplateTypes = Object
  .values(SendTypeSendCategoryEnum)
  .filter(type => ![SendTypeSendCategoryEnum.OTPLetter].includes(type));

const ProductTemplateValueOptions = (): CtSelectControlValue[] => {

  const values: CtSelectControlValue[] = [];

  productTemplateTypes
    .forEach(type => {

      values
        .push(
          CtSelectControlValue
            .create()
            .setLabel(SendTypeSendCategory2LabelMapping[type])
            .setValue(type));

    });

  return values;

};

const ProductTemplateFilter: CtSearchFilterControlConfiguration =
  CtSearchFilterControlConfiguration
    .create("SendCategory")
    .setLabel("Prodotto")
    .setCTControlConfiguration(
      CtControlConfiguration
        .create({
          name: "SendCategory",
          label: "Prodotto",
          type: CtControlTypes.ENUMERABLE,
          theme: CtThemeTypes.MATERIAL,
          materialOptions: { appearance: "legacy" }
        } as CtControlConfiguration)
        .setValueOptions(ProductTemplateValueOptions()));

export const TemplateListFilters: CtSearchFilterControlConfiguration[] = [CodeFilter, NameFilter, ProductTemplateFilter];
