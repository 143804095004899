import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrackingComponent } from "./tracking.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { CtDatatableModule, CtSearchFiltersModule } from "@ctsolution/ct-framework";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import {
  CartFileListDownloadButtonModule
} from "../../_components/cart-order/cart-order-detail/cart-file-list-download-button/cart-file-list-download-button.module";
import {
  TrackingOrderDetailExitConfirmComponent
} from "./tracking-order-detail-exit-confirm/tracking-order-detail-exit-confirm.component";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatLegacyMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { BarcodeCellTemplateComponent } from "./barcode-cell-template/barcode-cell-template.component";
import { TrackingExportDateFromAlertComponent } from './tracking-export-date-from-alert/tracking-export-date-from-alert.component';

@NgModule({
  imports: [CommonModule, MatCardModule, CtDatatableModule, CtSearchFiltersModule, MatIconModule, MatButtonModule, CartFileListDownloadButtonModule, MatLegacyDialogModule, FlexLayoutModule, MatLegacyMenuModule, MatLegacyProgressSpinnerModule],
  declarations: [TrackingComponent, TrackingOrderDetailExitConfirmComponent, BarcodeCellTemplateComponent, TrackingExportDateFromAlertComponent]
})
export class TrackingModule {
}
