import { Injectable } from "@angular/core";
import { TemplateSaveComponent } from "./template-save.component";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { TemplateManagerConfiguration } from "../template-manager.configuration";
import { SendTypeSendCategoryEnum } from "../../../../core/interfaces/send-type-configuration";
import {
  TemplateEditConfirmComponent,
  TemplateSaveMode
} from "../template-edit-confirm/template-edit-confirm.component";
import { FormControl } from "@angular/forms";
import { SnackbarService } from "@ctsolution/ct-framework";
import { TemplateController } from "../../../../core/controllers/template.controller";
import { TemplateLiteResponse } from "../../../../core/interfaces/template-lite-response";
import { TemplateParameter } from "../../../../core/classes/template-parameter";

@Injectable()
export class TemplateSaveService {

  constructor(
    private dialog: MatDialog,
    private templateController: TemplateController,
    private _snackbar: SnackbarService) {
  }


  create = (data: TemplateManagerConfiguration) => this.dialog
    .open(TemplateSaveComponent, { width: "550px", data });

  async saveAsTemplate(control: FormControl, tempTemplateRef: TemplateLiteResponse | null, sendTypeSendCategoryEnum: SendTypeSendCategoryEnum) {

    control.markAsTouched();

    if (!control.valid) return;

    if (!tempTemplateRef) {

      this.create(
        TemplateManagerConfiguration
          .create()
          .setParameter(
            TemplateParameter
              .create()
              .setSendCategory(sendTypeSendCategoryEnum)
              .setContent(control.value)));
      return;

    }

    const templateParameter = TemplateParameter
      .create(tempTemplateRef as TemplateParameter)
      .setContent(control.value);

    const result = await this.dialog.open(TemplateEditConfirmComponent).afterClosed().toPromise();

    if (!result) return;

    switch (result) {

      case TemplateSaveMode.update:

        await this.templateController
          .save(templateParameter)
          .subscribe(() => this._snackbar.open("Modello aggiornato correttamente!", "X"));

        break;
      case TemplateSaveMode.insert:
      default:

        templateParameter
          .setOid(null)
          .setUserInfoOid(null);

        await this.create(
          TemplateManagerConfiguration
            .create()
            .setParameter(templateParameter));
        tempTemplateRef = null;

        break;
    }

  }

}
