<ct-datatable
  *ngIf="shipments"
  [configuration]="shipments"
  (reload)="fetch($event)"
></ct-datatable>

<ng-template #nameCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value }}</p>
    <p> {{ row.InternalCode }}</p>

  </div>

</ng-template>

<ng-template #recipientCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value }}</p>

    <p> {{ row.Address ?? '' }} {{ row.ZipCode ?? '' }} {{ row.City ?? '' }} {{ row.Province ?? '' }}</p>

    <p> {{ row.Email ?? '' }} {{ row.CertifiedEmail ?? '' }} {{ row.Phone ?? '' }} </p>

  </div>

</ng-template>

<ng-template #downloadCellTemplate let-row="row" let-value="value" let-i="index">

  <div class="text-center">

    <button mat-icon-button color="primary" (click)="download(row)">

      <mat-icon> file_download</mat-icon>

    </button>

  </div>

</ng-template>

<ng-template #cdcCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value ?? '' }} - {{ row.FlowGroupName ?? '' }}</p>
  </div>

</ng-template>
