import { SmartDeliveryActivationConditionOperator } from "../enums/smart-delivery-activation-condition-operator";
import { CTBase } from "@ctsolution/ct-base";

export class SmartDeliveryRuleRowActivationCondition extends CTBase<SmartDeliveryRuleRowActivationCondition> {

  Field: string | null = null;
  Value: any | null = null;
  Operator: SmartDeliveryActivationConditionOperator = SmartDeliveryActivationConditionOperator.EqualTo;

  private constructor();
  private constructor(model?: SmartDeliveryRuleRowActivationCondition);
  private constructor(model?: SmartDeliveryRuleRowActivationCondition) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: SmartDeliveryRuleRowActivationCondition) => new SmartDeliveryRuleRowActivationCondition(model);

  setField(value: string | null) {

    this.Field = value;
    return this;

  }

  setValue(value: any | null) {

    this.Value = value;
    return this;

  }

}

