import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductCardComponent } from './product-card.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatIconModule } from '@angular/material/icon';
import { ProductCardIconComponent } from './product-card-icon/product-card-icon.component';

@NgModule({
  declarations: [ProductCardComponent, ProductCardIconComponent],
  imports: [CommonModule, MatCardModule, MatButtonModule, MatListModule, MatIconModule],
  exports: [ProductCardComponent],
})
export class ProductCardModule {}
