import { Component } from "@angular/core";
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";

function nonNumericValidator(control: AbstractControl): { [key: string]: any } | null {
  const value = control.value;

  if (value && /^\d+$/.test(value.replace(/\s/g, ''))) {
    return { nonNumeric: true }; // Il campo contiene solo numeri
  }

  return null; // Il campo è valido
}



@Component({
  selector: "app-letter-additions",
  templateUrl: "./letter-additions.component.html",
  styleUrls: ["./letter-additions.component.scss"]
})
export class LetterAdditionsComponent {
  form: FormGroup;

  constructor(private _fb: FormBuilder) {
    this.form = this._fb.group({
      name: new FormControl("", [Validators.required, nonNumericValidator])
    });
  }
}
