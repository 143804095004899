import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  Output,
  EventEmitter,
  Input, AfterViewInit
} from "@angular/core";
import { MediaMatcher } from "@angular/cdk/layout";
import { MenuItemsService } from "../../../shared/menu/menu-items.service";

@Component({
  selector: "app-vertical-sidebar",
  templateUrl: "./vertical-sidebar.component.html",
  styleUrls: []
})
export class VerticalAppSidebarComponent implements AfterViewInit, OnDestroy {

  mobileQuery: MediaQueryList;

  @Input() showClass: boolean = false;
  @Output() notify: EventEmitter<boolean> = new EventEmitter<boolean>();

  private readonly _mobileQueryListener: () => void;
  status = true;
  showMenu = "";
  itemSelect: number[] = [];

  addExpandClass = (element: any) => this.showMenu = element === this.showMenu ? "0" : element;

  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    public menuItems: MenuItemsService) {

    this.mobileQuery = media.matchMedia("(min-width: 768px)");
    this._mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this._mobileQueryListener);

  }

  ngAfterViewInit() {

    this.menuItems.setup();

  }

  ngOnDestroy(): void {

    this.mobileQuery.removeListener(this._mobileQueryListener);

  }

  handleNotify() {

    if (window.innerWidth < 1024) {

      this.notify.emit(!this.showClass);

    }

  }

}
