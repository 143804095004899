import { Component, EventEmitter, Input, Output } from "@angular/core";
import { GeneralService } from "../../../core/lib/general.service";
import { FlowController } from "../../../core/controllers/flow.controller";
import { FlowPurchaseRequest } from "../../../core/classes/flow-purchase-request";
import { CancelOrderSubmissionService } from "../../../shared/cancel-order-submission/cancel-order-submission.service";
import { FlowService } from "../../../core/lib/flow.service";

@Component({
  selector: "app-cart-total",
  templateUrl: "./cart-total.component.html",
  styleUrls: ["./cart-total.component.scss"],
  providers: [FlowController]
})
export class CartTotalComponent {

  @Input() totalAmount: number = 0;
  @Output() refresh: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private general: GeneralService,
    private flowController: FlowController,
    private cancelOrderSubmissionService: CancelOrderSubmissionService,
    private flowService: FlowService
  ) {
  }

  /**
   * It calls the buyCart function of the cartController, passing the cart's Oid as a parameter
   */
  buy() {

    this.cancelOrderSubmissionService
      .handleCancellation()
      .then((proceed: boolean) => {

        if (proceed) {

          const request: FlowPurchaseRequest = new FlowPurchaseRequest(true);

          this.flowController
            .purchase(request)
            .then((success: boolean) => {

              this.flowService
                .getCartCount()
                .then(() => {

                  if (success) {

                    this.general.navigateTo(["/tracking"]);

                  } else {

                    this.refresh.emit();

                  }

                });

            });

        }

      });


  }

}
