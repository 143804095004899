<h1 mat-dialog-title style="margin: 0px !important">{{ this.configuration?.parameter?.Oid ? 'Modifica' : 'Creazione' }}
  modello</h1>

<div mat-dialog-content>

  <app-template-save-form
    #form
    *ngIf="configuration"
    [configuration]="configuration"></app-template-save-form>

</div>

<div mat-dialog-actions class="d-flex space-between">

  <button mat-raised-button color="warn" [mat-dialog-close]="false">
    Annulla
  </button>

  <button mat-raised-button color="primary" (click)="submit()">
    Salva
  </button>
</div>
