import { Injectable } from "@angular/core";
import { BaseFlowFileParameter, RecipientFlowPreviewParameter } from "../classes/base-flow-file-parameter";
import { CtFileManagementService } from "@ctsolution/ct-file-management";
import { SnackbarService } from "./snackbar.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import {
  EMAIL_STANDALONE_SEND_CATEGORY,
  SMS_STANDALONE_SEND_CATEGORY
} from "../classes/send-categories.constant";
import { Observable, Subscriber } from "rxjs";
import { FlowFile } from "../interfaces/flow-file";
import { SendTypeSendCategoryEnum } from "../interfaces/send-type-configuration";
import {
  DocumentPreviewComponent
} from "../../pages/shipment/send-flow/step4/document-preview/document-preview.component";
import { FLOW_FILE_TYPE } from "../enums/flow-file-type.enum";
import { FlowFileController } from "../controllers/flow-file.controller";
import { TinyMceSetupConfiguration } from "../../_components/flow-components/editor/tiny-mce.setup-configuration";
import { GetFlowFileParameter } from "../classes/get-flow-file-parameter";

@Injectable({
  providedIn: "root"
})
export class FileService {

  constructor(
    private CTFileManager: CtFileManagementService,
    private _snackbar: SnackbarService,
    private flowFileController: FlowFileController,
    private dialog: MatDialog) {
  }

  getFlowPreviewParameterFromRecipient(parameter: RecipientFlowPreviewParameter): {
    flowPreviewParameter: BaseFlowFileParameter,
    isFlowDraftDigitalChannel: boolean,
    isRecipientDigitalChannel: boolean
  } {

    const flowPreviewParameter: BaseFlowFileParameter = BaseFlowFileParameter
      .create(parameter.flow?.Oid ?? parameter.recipient?.FlowOid!)
      .setRowNumber(parameter.recipient?.RowNumber ?? null);

    const isFlowDraftDigitalChannel: boolean = parameter.flow?.digitalSendingStandaloneChannelSetupEnabled ?? false;
    // Così perchè OTP ha la preview generata da ironpdf
    const isRecipientDigitalChannel: boolean = [...EMAIL_STANDALONE_SEND_CATEGORY, ...SMS_STANDALONE_SEND_CATEGORY].includes(parameter.recipient?.FlowSendTypeConfigurationSendTypeSendCategory as SendTypeSendCategoryEnum);

    return { flowPreviewParameter, isFlowDraftDigitalChannel, isRecipientDigitalChannel };

  }

  manageRecipientFlowPreview(parameter: RecipientFlowPreviewParameter): Promise<boolean | null> {

    return new Promise<boolean | null>((resolve, reject) => {

      if (!parameter.flow?.Oid && !parameter.recipient?.FlowOid) {

        reject(null);
        return;

      }

      const {
        flowPreviewParameter,
        isFlowDraftDigitalChannel,
        isRecipientDigitalChannel
      } = this.getFlowPreviewParameterFromRecipient(parameter);

      if (isFlowDraftDigitalChannel || isRecipientDigitalChannel) {

        this.dialog
          .open(DocumentPreviewComponent, {
            disableClose: true,
            data: parameter,
            minWidth: 350,
            panelClass: "preview-dialog-container"
          })
          .afterClosed()
          .subscribe(() => resolve(true));

      } else {

        this.downloadFlowFilePreview(flowPreviewParameter)
          .then(() => resolve(true))
          .catch((err) => reject(err));

      }

    });

  }

  getFlowPreviewBlobUrl(parameter: BaseFlowFileParameter): Observable<string> {

    return new Observable((observer: Subscriber<any>) => {

      let objectUrl: string | null = null;

      this.getFlowPreviewFile(parameter)
        .then((blob: Blob | null) => {

          if (blob) {

            objectUrl = URL.createObjectURL(blob);
            observer.next(objectUrl);

          }

        });

      return () => {

        if (objectUrl) {

          URL.revokeObjectURL(objectUrl);
          objectUrl = null;

        }

      };

    });

  }

  getFlowFileList(parameter: BaseFlowFileParameter): Promise<FlowFile[]> {

    return this.flowFileController.getFlowFileList(parameter);

  }

  getFlowFile(parameter: GetFlowFileParameter): Promise<Blob | null> {

    return this.flowFileController.getFlowFile(parameter);

  }

  private getFlowPreviewFile(parameter: BaseFlowFileParameter): Promise<Blob | null> {

    const getFlowFileParam: GetFlowFileParameter = GetFlowFileParameter
      .create(parameter.flowOid ?? 0, parameter.rowNumber)
      .setFileType(FLOW_FILE_TYPE.PreviewRecipient);

    return this.getFlowFile(getFlowFileParam);

  }

  private downloadFlowFilePreview(parameter: BaseFlowFileParameter) {

    return new Promise<Blob | null>((resolve, reject) => {

      this.getFlowPreviewFile(parameter)
        .then((result: Blob | null) => {

          if (result) {

            this.CTFileManager.downloadBlobFromAPIResult(result, `PP${new Date().getTime()}${parameter.flowOid}`, ".pdf"); // in teoria entro qui solo se è la preview pdf

          }

          resolve(result);

        })
        .catch(err => reject(err));

    });

  }

  downloadFlowFile(file: FlowFile): Promise<Blob | null> {

    return new Promise<Blob | null>((resolve, reject) => {

      const parameter: GetFlowFileParameter = GetFlowFileParameter
        .create(file.FlowOid)
        .setFlowFileOid(file.Oid);

      this.getFlowFile(parameter)
        .then((result: Blob | any | null) => {

          if (!result) return;

          this.CTFileManager.downloadBlobFromAPIResult(result, file.Name ?? `PP_${new Date().getTime()}`, file?.Extension);
          resolve(result);

        })
        .catch(err => reject(err));

    });

  }

  getStringContentFromFlowFile(parameter: BaseFlowFileParameter) {

    return new Promise<string | ArrayBuffer | null>((resolve) => {

      this.getFlowPreviewFile(parameter)
        .then((result: Blob | null) => {

          this.HTML2String(result)
            .then((result: string | ArrayBuffer | null) => resolve(result));

        });

    });

  }

  string2HTML(value: string | null, fileName: string = "PPDraft"): File | null {

    if (value) {

      return new File([value], `${fileName + new Date().getTime()}.html`, { type: "text/html" });

    }

    return null;

  }

  HTML2String(file: Blob | null): Promise<string | ArrayBuffer | null> {

    return new Promise<string | ArrayBuffer | null>((resolve) => {

      if (file) {

        const reader = new FileReader();

        reader.onload = () => resolve(reader.result);

        reader.readAsText(file);

      } else {

        this._snackbar.open("Si è verificato un errore durante il caricamento del file!");
        resolve(null);

      }

    });

  }

  getComponentStringContentFromPPFile(configuration: TinyMceSetupConfiguration) {

    return new Promise((resolve) => {

      if (!configuration.file) return;

      const parameter: GetFlowFileParameter = GetFlowFileParameter
        .create(configuration.file.FlowOid)
        .setFlowFileOid(configuration.file.Oid);

      this.getFlowFile(parameter)
        .then((result: Blob | any | null) => {

          if (!result) return;

          this.HTML2String(result)
            .then((result: string | ArrayBuffer | null) => resolve(<string>result));

        });


    });

  }

}
