import { Component } from "@angular/core";
import { CtButtonParameters, CtCardParameters } from "@ctsolution/ct-framework";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { ChangePasswordDialogComponent } from "../change-password-dialog/change-password-dialog.component";
import { JwtService } from "../../../../../core/lib/jwt.service";
import { Router } from "@angular/router";
import { RoleService } from "../../../../../core/lib/role.service";

@Component({
  selector: "app-shortcuts-area",
  templateUrl: "./shortcuts-area.component.html",
  styleUrls: ["./shortcuts-area.component.css"]
})
export class ShortcutsAreaComponent {

  private changePasswordButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this.openChangePasswordDialog();

  })
    .setContent("Cambia")
    .setCSS("mat-raised-button ct-widget-button");

  changePasswordCardParameter: CtCardParameters = CtCardParameters.create(() => {
  })
    .setTitle("Cambia password")
    .setMaterialIcon("admin_panel_settings")
    .setBackgroundClass("bg-danger")
    .setTextColor("text-white")
    .setCtButton(this.changePasswordButtonParameters);

  private downloadUserManualButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    window.open("/assets/docs/polipush-user-manual.pdf", "_blank");

  })
    .setContent("Scarica")
    .setCSS("mat-raised-button ct-widget-button");

  downloadUserManualCardParameter: CtCardParameters = CtCardParameters.create(() => {
  })
    .setTitle("Manuale utente")
    .setMaterialIcon("menu_book")
    .setBackgroundClass("bg-megna")
    .setTextColor("text-white")
    .setCtButton(this.downloadUserManualButtonParameters);

  private templateManagementButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this.router
      .navigate(["/", "amministrazione", "modelli"]);

  })
    .setContent("Gestisci")
    .setCSS("mat-raised-button ct-widget-button");

  templateManagementCardParameter: CtCardParameters = CtCardParameters
    .create(() => {
    })
    .setTitle("Gestione modelli")
    .setMaterialIcon("edit_note")
    .setBackgroundClass("bg-success")
    .setTextColor("text-white")
    .setCtButton(this.templateManagementButtonParameters);

  private smartDeliveryManagementButtonParameters: CtButtonParameters = CtButtonParameters.create((event: any) => {

    event.stopPropagation();
    this.router
      .navigate(["/", "amministrazione", "smart-delivery"]);

  })
    .setContent("Gestisci")
    .setCSS("mat-raised-button ct-widget-button");

  smartDeliveryManagementCardParameter: CtCardParameters = CtCardParameters
    .create(() => {
    })
    .setTitle("Regole Smart Delivery")
    .setMaterialIcon("move_down")
    .setBackgroundClass("bg-info")
    .setTextColor("text-white")
    .setCtButton(this.smartDeliveryManagementButtonParameters);

  cards: CtCardParameters[] = [
    this.changePasswordCardParameter,
    this.downloadUserManualCardParameter
  ];

  constructor(private dialog: MatDialog, private jwt: JwtService, private router: Router, private roleService: RoleService) {
  }

  async ngOnInit() {

    const advancedTemplateManagementEnabled = await this.jwt.getAdvancedTemplateManagement();

    if (advancedTemplateManagementEnabled) {

      const advancedTemplateManagementCards = [this.templateManagementCardParameter];

      const isCompanyAdminOrCompanyGroupAdmin = await this.roleService.isCompanyAdminOrCompanyGroupAdmin();

      if (isCompanyAdminOrCompanyGroupAdmin) {

        advancedTemplateManagementCards.push(this.smartDeliveryManagementCardParameter);

      }

      this.cards
        .push(...advancedTemplateManagementCards);

    }

  }

  openChangePasswordDialog() {

    this.dialog
      .open(ChangePasswordDialogComponent, { panelClass: "ct-card-dialog" });

  }

}
