<mat-dialog-content style="min-height: 220px">

  <div>

    <h1 mat-dialog-title> Gestione condizioni di attivazione </h1>

    <div [ngStyle]="!(activationConditions ?? []).length && {'display':'flex'}">

      <ng-container *ngIf="!(activationConditions ?? []).length; else table">

        <p style="margin: auto"> Nessuna condizione di attivazione abilitata per questa regola.</p>

      </ng-container>

      <ng-template #table>

        <p-table
          [value]="activationConditions ?? []"
          editMode="row">

          <ng-template pTemplate="header">

            <tr>

              <th> Campo</th>
              <th> Operatore</th>
              <th> Valore</th>
              <th></th>

            </tr>

          </ng-template>

          <ng-template pTemplate="body" let-condition let-editing="editing" let-ri="rowIndex">

            <tr [pEditableRow]="condition">

              <td>

                <p-cellEditor>

                  <ng-template pTemplate="input">
                    <input style="width: 100%" pInputText type="text" [(ngModel)]="condition.Field">
                  </ng-template>

                  <ng-template pTemplate="output">
                    {{ condition.Field }}
                  </ng-template>

                </p-cellEditor>

              </td>

              <td>

                <p-cellEditor>

                  <ng-template pTemplate="input">

                    <p-dropdown
                      [options]="activationConditionOptions"
                      [(ngModel)]="condition.Operator"
                      appendTo="body"
                      [style]="{'width':'100%'}"></p-dropdown>
                  </ng-template>

                  <ng-template pTemplate="output">
                    {{ SmartDeliveryActivationCondition2LabelMapping[condition.Operator] }}
                  </ng-template>

                </p-cellEditor>

              </td>

              <td>

                <p-cellEditor>

                  <ng-template pTemplate="input">
                    <input style="width: 100%" pInputText type="text" [(ngModel)]="condition.Value">
                  </ng-template>

                  <ng-template pTemplate="output">
                    {{ condition.Value }}
                  </ng-template>

                </p-cellEditor>

              </td>

              <td>

                <div class="flex align-items-center justify-content-center gap-2">

                  <button
                    pButton
                    pRipple
                    type="button"
                    icon="pi pi-trash"
                    (click)="deleteRow( ri)"
                    class="p-button-rounded p-button-text p-button-danger"></button>

                </div>

              </td>

            </tr>

          </ng-template>

        </p-table>

      </ng-template>

    </div>

  </div>

  <ng-container *ngIf="viewModel.fileUploadEnabled">

    <div align="end" class="m-t-20">

      <button mat-raised-button color="accent" (click)="newCondition()">Aggiungi condizione</button>

    </div>

    <hr />

    <div class="row-attachment-file-uploader-container">

      <h1 mat-dialog-title> Gestione allegato </h1>

      <div>

        <ct-file-uploader
          #Uploader
          [configuration]="configuration"
          (delete)="deleteAttachment($event)"></ct-file-uploader>

      </div>

      <div
        style="min-height: 40px; margin-bottom: 20px"
        *ngIf="!!configuration?.control?.value">

        <mat-checkbox
          [(ngModel)]="viewModel.privateFile"> Privato (rendi disponibile questo allegato solo con autenticazione)
        </mat-checkbox>

      </div>

    </div>

  </ng-container>

</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">

  <div>

    <button mat-raised-button color="warn" style="min-width: 176px" (click)="cancel()">
      Annulla
    </button>

  </div>

  <div>

    <button *ngIf="!viewModel.fileUploadEnabled" mat-raised-button color="accent" style="min-width: 176px"
            (click)="newCondition()">Aggiungi
      condizione
    </button>

    <button mat-raised-button color="primary" style="min-width: 176px" (click)="submit()">
      Aggiorna
    </button>

  </div>

</div>
