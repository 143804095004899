import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { VariableFieldsManagerComponent } from "./variable-fields-manager.component";
import { VariableFieldsManagerService } from "./variable-fields-manager.service";
import { FlexModule } from "@angular/flex-layout";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { TextFieldModule } from "@angular/cdk/text-field";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { TableModule } from "primeng/table";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { RippleModule } from "primeng/ripple";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [
    VariableFieldsManagerComponent
  ],
  imports: [
    CommonModule,
    FlexModule,
    MatLegacyButtonModule,
    MatLegacyDialogModule,
    TableModule,
    FormsModule,
    InputTextModule,
    MatLegacyCheckboxModule,
    ButtonModule,
    RippleModule,
    MatIconModule
  ],
  providers: [VariableFieldsManagerService]
})
export class VariableFieldsManagerModule {
}
