import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SmartDeliveryComponent } from "./smart-delivery.component";
import { RouterModule } from "@angular/router";
import { MatLegacyCardModule } from "@angular/material/legacy-card";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { CtDatatableModule, CtSearchFiltersModule } from "@ctsolution/ct-framework";
import { SmartDeliveryDetailModule } from "./smart-delivery-detail/smart-delivery-detail.module";

@NgModule({
  declarations: [
    SmartDeliveryComponent
  ],
  imports: [
    CommonModule,
    SmartDeliveryDetailModule,
    RouterModule
      .forChild([
        {
          path: "",
          component: SmartDeliveryComponent,
          data: {
            title: "Regole Smart Delivery",
            description: "Visualizza e gestisci le tue regole di Smart Delivery",
            urls: [{
              title: "Regole Smart Delivery",
              url: "/amministrazione/smart-delivery"
            }, { title: "Regole Smart Delivery" }]
          }
        }
      ]),
    MatLegacyCardModule,
    MatLegacyButtonModule,
    CtDatatableModule,
    CtSearchFiltersModule
  ]
})
export class SmartDeliveryModule {
}
