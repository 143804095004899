import { Component, EventEmitter, Input, Output } from "@angular/core";
import { ISendTypeConfiguration } from "../../../../../../core/interfaces/send-type-configuration";

@Component({
  selector: "app-product-card",
  templateUrl: "./product-card.component.html",
  styleUrls: ["./product-card.component.scss"]
})
export class ProductCardComponent {

  @Input() product!: ISendTypeConfiguration;
  @Input() selected: boolean = false;
  @Input() invalid: boolean = false;

  // eslint-disable-next-line @angular-eslint/no-output-on-prefix
  @Output() onSelect: EventEmitter<ISendTypeConfiguration> =
    new EventEmitter<ISendTypeConfiguration>();

  constructor() {
  }
}
