import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { ISendTypeConfiguration } from "../interfaces/send-type-configuration";

@Injectable()
export class SendTypeController {

  private controller: string = "/SendType/";
  private sendTypeListCache: ISendTypeConfiguration[] | null = null;

  constructor(private _webapi: CtWebapiService) {
  }

  getSendTypeConfigurationList(): Promise<ISendTypeConfiguration[]> {

    if (this.sendTypeListCache) return Promise.resolve(this.sendTypeListCache);

    return new Promise<ISendTypeConfiguration[]>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}GetSendTypeConfigurationList`, {});

      this._webapi
        .get(request)
        .subscribe((r: CtWebapiGenericResponse<ISendTypeConfiguration[]>) => {

          this.sendTypeListCache = r.Result;
          resolve(this.sendTypeListCache);

        });

    });

  }

}
