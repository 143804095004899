import { Routes } from "@angular/router";
import { AdminModule } from "./admin/admin.module";
import { CartComponent } from "./cart/cart.component";
import { HomeComponent } from "./home/home.component";
import { OrdersComponent } from "./orders/orders.component";
import { QuotationsComponent } from "./quotations/quotations.component";
import { ShipmentModule } from "./shipment/shipment.module";
import { TrackingComponent } from "./tracking/tracking.component";

export const PagesRoutes: Routes = [
  {
    path: "",
    children: [
      {
        path: "dashboard",
        component: HomeComponent,
        data: {
          title: "Dashboard",
          description: "Visualizza un riepilogo dei servizi che usi",
          urls: [{ title: "Dashboard", url: "/dashboard" }, { title: "Dashboard" }]
        }
      },
      {
        path: "ordini",
        component: OrdersComponent,
        data: {
          title: "Ordini",
          description: "Visualizza gli ordini confermati.",
          urls: [{ title: "Ordini", url: "/ordini" }, { title: "Ordini" }]
        }
      },
      {
        path: "tracking",
        component: TrackingComponent,
        data: {
          title: "Stato delle spedizioni",
          description:
            "Segui lo stato delle tue spedizioni e clicca sul barcode per avere maggiori informazioni.",
          urls: [
            { title: "Stato delle spedizioni", url: "/tracking" },
            { title: "Stato delle spedizioni" }
          ]
        }
      },
      {
        path: "nuova-spedizione",
        loadChildren: () => ShipmentModule
      },
      {
        path: "preventivi",
        component: QuotationsComponent,
        data: {
          title: "Preventivi",
          description:
            "I preventivi non potranno più essere accettati dopo 30 giorni dalla loro creazione.",
          urls: [{ title: "Preventivi", url: "/cart" }, { title: "Preventivi" }]
        }
      },
      {
        path: "carrello",
        component: CartComponent,
        data: {
          title: "Carrello",
          description: "Gli ordini nel carrello sono validi fino a 30 giorni dalla loro creazione.",
          urls: [{ title: "Carrello", url: "/cart" }, { title: "Carrello" }]
        }
      },
      {
        path: "amministrazione",
        loadChildren: () => AdminModule
      },
      {
        path: "Tracking",
        redirectTo: "tracking",
        pathMatch: "full"
      },
      {
        path: "",
        pathMatch: "full",
        redirectTo: "dashboard"
      }
    ]
  }
];
