import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { IAddressValidation, IValidationResult } from "../interfaces/address-validation";
import { FlowRecipientValidation } from "../classes/flow-recipient-validation";

@Injectable()
export class ValidationController {

  private controller: string = "/Validation/";

  constructor(private _webapi: CtWebapiService) {
  }

  validateAddressData(parameter: FlowRecipientValidation): Promise<IValidationResult | null> {

    return new Promise<IValidationResult | null>((resolve) => {

      const request: DataRequest = new DataRequest(`${this.controller}ValidateAddressData`);

      this._webapi
        .post(request, parameter)
        .subscribe((r: CtWebapiGenericResponse<IAddressValidation[] | null>) => {

          const listResult: IAddressValidation[] = r.Result ?? [];

          let singleValidation: IValidationResult | null = null;

          if (listResult.length > 0) {

            singleValidation = listResult[0].ValidationResult;

          }

          resolve(singleValidation);

        });

    });

  }

}
