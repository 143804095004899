import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NewsletterUnsubscribeModule } from "./newsletter-unsubscribe/newsletter-unsubscribe.module";


@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        children: [
          {
            path: "unsubscribe",
            loadChildren: () => NewsletterUnsubscribeModule
          },
          {
            path: "",
            pathMatch: "full",
            redirectTo: "/"
          }
        ]
      }
    ])
  ]
})
export class NewsletterModule {
}
