import { Component } from "@angular/core";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-signout",
  templateUrl: "./logout.component.html"
})
export class LogoutComponent {

  constructor(
    private _authentication: CtAuthenticationService,
    private _dialog: MatDialog
  ) {

    this.logout();

  }

  logout() {

    this._dialog.closeAll();
    this._authentication.signOut();

  }

}
