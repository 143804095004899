<h1 mat-dialog-title>Attenzione</h1>

<div mat-dialog-content>
  <p class="text-center">
    Stai per cancellare il gruppo: <strong> {{ data.Code }} - {{ data.Name }}</strong>.<br />
    Dopo la conferma non sarà più possibile annullare l'operazione. <br />
    Sei sicuro di voler proseguire?
  </p>
</div>

<div mat-dialog-actions class="d-flex space-between">
  <button mat-raised-button color="warn" [mat-dialog-close]="false">
    <mat-icon>close</mat-icon>
    Annulla
  </button>

  <button mat-raised-button color="primary" [mat-dialog-close]="true">
    Cancella gruppo
  </button>
</div>
