import { Injectable } from "@angular/core";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { FlowFile } from "../interfaces/flow-file";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { BaseFlowFileParameter } from "../classes/base-flow-file-parameter";

@Injectable({
  providedIn: "root"
})
export class FlowFileController {

  private controller: string = "/FlowFile/";

  constructor(private _webapi: CtWebapiService) {
  }

  deleteFlowFileFromDraft(flowFileOid: number): Promise<FlowFile[]> {

    return new Promise<FlowFile[]>((resolve, reject) => {

      this._webapi
        .delete(new DataRequest(`${this.controller}DeleteFlowFileFromDraft`, { flowFileOid }))
        .subscribe({
          next: (response: CtWebapiGenericResponse<FlowFile[]>) => resolve(response.Result),
          error: (err) => reject(err)
        });

    });

  }

  deleteFlowFilesFromDraft(list: FlowFile[]): Promise<FlowFile[]> {

    return new Promise<FlowFile[]>((resolve) => {

      list
        .forEach((element: FlowFile, index: number) => {

          this._webapi
            .delete(new DataRequest(`${this.controller}DeleteFlowFileFromDraft`, { flowFileOid: element.Oid }))
            .subscribe((response: CtWebapiGenericResponse<FlowFile[]>) => {

              if (index === list.length - 1) {

                resolve(response.Result);

              }

            });

        });

    });

  }


  getFlowFile(parameter: BaseFlowFileParameter): Promise<Blob | null> {

    return new Promise<Blob | null>((resolve, reject) => {

      const request: DataRequest = new DataRequest(`/FlowFile/GetFlowFile`, parameter);
      request.setResponseType("blob");

      this._webapi.get(request)
        .subscribe({
          next: (blob: Blob | null) => resolve(blob), error: err => reject(err)
        });

    });

  }

  getFlowFileList(parameter: BaseFlowFileParameter): Promise<FlowFile[]> {

    return new Promise<FlowFile[]>((resolve) => {

      const request: DataRequest = new DataRequest(`/FlowFile/FlowFileList`, parameter);

      this._webapi.get(request)
        .subscribe((response: CtWebapiGenericResponse<FlowFile[]>) => resolve(response?.Result ?? []));

    });

  }

}
