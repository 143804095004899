<div class="blank-container smart-delivery-file-container">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>

        <div class="text-center">
          <img alt="homepage" src="assets/images/logo/logo.svg" height="55" />
          <h4 class="m-t-0">Il download sta per iniziare...</h4>
        </div>

        <div style="flex-direction:column">

          <p class="text-muted m-t-30 m-b-30">

            Hai selezionato un file da scaricare.

            <br />

            Il download dovrebbe partire a breve, se così non fosse puoi cliccare a questo <a class="link"
                                                                                              style="cursor: pointer"
                                                                                              (click)="download()"><u>link</u></a>.
            <br />
            <br />

            Ricordati di controllare la cartella download del tuo dispositivo.

          </p>

          <p class="text-center text-muted m-t-30 m-b-30">

            Grazie per aver usato <a class="link" style="cursor: pointer" [href]="'https://polipush.it'"
                                     target="_blank"><u>Polipush</u></a>!

          </p>

          <div class="text-center" fxFlex.gt-sm="100" fxFlex.gt-xs="100" fxFlex="100" *ngIf="_auth.userValue">
              <span
              > &nbsp;
                <a [routerLink]="['/']" class="link text-info"> Torna alla home </a>
              </span>
          </div>

        </div>

      </mat-card-content>
    </mat-card>
  </div>
</div>
