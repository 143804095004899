<ct-search-filters
  #searchFilter
  [configuration]="filtersConfiguration"
  (filter)="filter($event)"
></ct-search-filters>

<mat-card class="tracking-container">

  <mat-card-content>

    <ct-datatable
      *ngIf="shipments"
      [configuration]="shipments"
      (reload)="fetch($event)"
    ></ct-datatable>

  </mat-card-content>

</mat-card>

<ng-template #sendDateTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ (row.FlowSendDate ?? row.FlowEstimateAcceptDate ?? row.FlowCreationDate) | date }}</p>

  </div>

</ng-template>

<ng-template #nameCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value }}</p>
    <p> {{ row.InternalCode }}</p>

  </div>

</ng-template>

<ng-template #createdByCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ row.FlowUserInfoName ?? '' }} {{ row.FlowUserInfoSurname ?? '' }}</p>

  </div>

</ng-template>

<ng-template #barcodeCellTemplate let-row="row" let-value="value" let-i="index">

  <app-barcode-cell-template [flow]="row"></app-barcode-cell-template>

</ng-template>

<ng-template #recipientCellTemplate let-row="row" let-value="value" let-i="index">

  <div>

    <p> {{ value }}</p>

    <p> {{ row.Address ?? '' }} {{ row.ZipCode ?? '' }} {{ row.City ?? '' }} {{ row.Province ?? '' }}</p>

    <p> {{ row.Phone ?? '' }} {{ row.Email ?? '' }} {{ row.CertifiedEmail ?? '' }} </p>

  </div>

</ng-template>

<ng-template #downloadCellTemplate let-row="row" let-value="value" let-i="index">

  <div class="text-center">

    <span #menuTrigger="matMenuTrigger" [matMenuTriggerFor]="menu"></span>

    <button mat-icon-button color="primary" (click)="getFiles(row, menuTrigger)">

      <mat-icon> file_download</mat-icon>

    </button>

    <mat-menu #menu="matMenu" xPosition="after" yPosition="below">

      <button
        mat-menu-item
        *ngFor="let file of row?.CachedFlowFiles ?? []; let i = index;"
        [disabled]="file.loading"
        (click)="trackingService.manageFile(file, row); $event.stopPropagation();">
        <mat-icon
          [color]="isPreviewFile(file) ? 'accent' : 'primary'"> {{ getDownloadFileIcon(file) }}</mat-icon>
        {{file.Name}}
        <mat-icon *ngIf="file.loading">
          <mat-spinner color="accent" diameter="15">
          </mat-spinner>
        </mat-icon>
      </button>

    </mat-menu>

  </div>

</ng-template>

<ng-template #cdcCellTemplate let-row="row" let-value="value" let-i="index">

  <div *ngIf="value">

    <p> {{ value ?? '' }} - {{ row.FlowGroupName ?? '' }}</p>

  </div>

</ng-template>

