export class TemplateDetailParameter {

  Oid: number | null = null;
  loadContent: boolean = true;
  loadFields: boolean = true;

  private constructor() {
  }

  public static create = () => new TemplateDetailParameter();

  setOid(value: number | null) {

    this.Oid = value;
    return this;

  }

}
