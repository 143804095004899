import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { Table } from "primeng/table";
import { TemplateVariableField } from "../../../../core/classes/template-variable-field";

@Component({
  selector: "app-variable-fields-manager",
  templateUrl: "./variable-fields-manager.component.html",
  styles: [`
    :host ::ng-deep tbody tr td.centered {
      text-align: center !important;
      vertical-align: middle !important;
    }
  `]
})
export class VariableFieldsManagerComponent {

  @ViewChild(Table) private dataTable: Table | null = null;

  constructor(private dialogRef: MatLegacyDialogRef<VariableFieldsManagerComponent>, @Inject(MAT_DIALOG_DATA) public data: TemplateVariableField[]) {
  }

  ngAfterViewInit() {

    this.setup();

  }


  setup() {

    const data = this.dataTable!.value;

    // Impostare il flag di editing per ogni riga
    data.forEach(row => this.dataTable!.initRowEdit(row));

  }

  cancel() {

    this.dialogRef
      .close(null);

  }

  submit() {

    this.dialogRef
      .close(this.data);
  }

}
