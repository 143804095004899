import {
  Component,
  Input,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef
} from "@angular/core";
import { Subscription, takeWhile, timer } from "rxjs";
import { map } from "rxjs/operators";
import { CancelOrderSubmissionService } from "./cancel-order-submission.service";
import { environment } from "../../../environments/environment";

@Component({
  selector: "app-cancel-order-submission",
  template: `
    <div class="preloader fade-in" *ngIf="visible">

      <div class="spinner-inner-container">

        <img src="/assets/images/logo/logo.svg" class="spinner bounce-top" />

        <p style="color: white; text-align: center; margin-bottom: 5px"> Stiamo procedendo all'evasione del tuo
          ordine </p>

        <mat-progress-bar mode="determinate" style="margin-top: 15px; margin-bottom: 15px;" color="warn"
                          [value]="progressbarValue"></mat-progress-bar>

        <button mat-raised-button color="primary" (click)="onCancel()"> Annulla</button>

      </div>

    </div>`,
  encapsulation: ViewEncapsulation.None
})
export class CancelOrderSubmissionComponent implements OnDestroy {

  visible: boolean = false;
  @Input() seconds: number = environment.production ? 3 : 1;

  progressbarValue: number = 0;
  subscription?: Subscription;
  cancelOrderSubmissionServiceSubscription?: Subscription;

  constructor(private cdr: ChangeDetectorRef, private cancelOrderSubmissionService: CancelOrderSubmissionService) {

    this.cancelOrderSubmissionServiceSubscription = this.cancelOrderSubmissionService
      .isLoading
      .subscribe((visible) => {

        this.visible = visible;

        if (visible) {

          this.subscription = this.interval(this.seconds * 1000)
            .subscribe((value: number) => {

              this.progressbarValue = value;

              if (this.progressbarValue >= 100) {

                this.cancelOrderSubmissionService.cancel(false);

              }

            });

        } else {

          this.subscription?.unsubscribe();

        }

      });
  }

  onCancel() {

    this.cancelOrderSubmissionService.cancel(true);

  }

  interval(ms: number) {

    const initial = new Date().getTime();

    return timer(0, 100)
      .pipe(
        map(() => new Date().getTime()),
        takeWhile((res: number) => res <= initial + ms, true),
        map(now => {
          const percentage = (100 * (now - initial)) / ms;
          return percentage < 100 ? Math.round(percentage) : 100;
        })
      );

  }

  ngOnDestroy(): void {

    if (this.cancelOrderSubmissionServiceSubscription) {

      this.cancelOrderSubmissionServiceSubscription.unsubscribe();

    }

    if (this.subscription) {

      this.subscription.unsubscribe();

    }

  }

}
