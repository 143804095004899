export interface ISendTypeConfiguration {
  Oid: number;
  SendTypeOid: number;
  SendTypeName: string;
  SendTypeDescription: string;
  BasePrice: string;
  SendTypeSendCategory: SendTypeSendCategoryEnum;
}

export enum SendTypeSendCategoryEnum {
  Letter = "Letter",
  SMS = "SMS",
  Email = "Email",
  PEC = "PEC",
  OTPLetter = "OTPLetter"
}

export const SendTypeSendCategory2LabelMapping: Record<SendTypeSendCategoryEnum, string> = {
  [SendTypeSendCategoryEnum.Letter]: "Lettera",
  [SendTypeSendCategoryEnum.SMS]: "SMS",
  [SendTypeSendCategoryEnum.Email]: "Email",
  [SendTypeSendCategoryEnum.PEC]: "PEC",
  [SendTypeSendCategoryEnum.OTPLetter]: "Raccomandata Digitale"
};
