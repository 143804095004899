import { Component, Input } from "@angular/core";
import { ISendTypeConfiguration } from "../../../../../../../core/interfaces/send-type-configuration";

@Component({
  selector: "app-product-card-icon",
  templateUrl: "./product-card-icon.component.html",
  styleUrls: ["./product-card-icon.component.scss"]
})
export class ProductCardIconComponent {

  @Input() productCode!: ISendTypeConfiguration;

  constructor() {
  }

  /* A function that returns a string. */
  getIcon = (product: ISendTypeConfiguration = this.productCode): string => {

    const code: string = product.SendTypeName.toLowerCase().replace(/ /g, "");

    if (code.includes("sms")) {

      return "sms";

    }

    if (code.includes("mail")) {

      return "alternate_email";

    }

    if (code.includes("lettera")) {

      return "local_post_office";

    }

    if (code.includes("raccomandata")) {

      if (code.includes("elettronica")) {

        return "mark_email_read";

      }

      if (code.includes("a/r")) {

        return "assignment_return";

      }

      return "mail_lock";

    }

    return "local_post_office";

  };

}
