<h1 mat-dialog-title>Validatore</h1>

<div mat-dialog-content class="address-validator-cntr">
  <div>
    <ng-container *ngIf="_validator.validating; else addressesList">
      <p class="text-center">
        Stiamo verificando i dati del <span class="text-lowercase"> {{ data.title }}</span> ..
      </p>

      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </ng-container>

    <ng-template #addressesList>
      <p>
        Il sistema ha corretto l'indirizzo del
        <span class="text-lowercase">{{ data.title }}</span
        >, confermare quello corretto:
      </p>

      <mat-selection-list [multiple]="false" class="address-validation-list">
        <mat-list-option
          *ngFor="let address of addresses"
          [value]="address"
          (click)="select(address)"
        >
          <mat-icon color="primary">verified</mat-icon>
          {{ _validator.getPrintedOption(address) }}
        </mat-list-option>
      </mat-selection-list>
    </ng-template>
  </div>
</div>
