import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TinyMceEditorComponent } from "./tiny-mce-editor.component";
import { EditorModule as TinyEditorModule, TINYMCE_SCRIPT_SRC } from "@tinymce/tinymce-angular";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatIconModule } from "@angular/material/icon";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { CtSkeletonModule } from "@ctsolution/ct-framework";
import { TemplatesManagerModule } from "./templates-manager/templates-manager.module";
import { TemplateSaveModule } from "./template-save/template-save.module";
import { HtmlEditorComponent } from "./html-editor/html-editor.component";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { MatLegacyCheckboxModule } from "@angular/material/legacy-checkbox";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { TextFieldModule } from "@angular/cdk/text-field";
import { FlexModule } from "@angular/flex-layout";
import { TemplateEditConfirmComponent } from "./template-edit-confirm/template-edit-confirm.component";
import { HtmlWarningModalComponent } from "./html-editor/html-warning-modal.component";

@NgModule({
  declarations: [TinyMceEditorComponent, HtmlEditorComponent, TemplateEditConfirmComponent, HtmlWarningModalComponent],
  exports: [TinyMceEditorComponent],
  imports: [
    CommonModule,
    TinyEditorModule,
    MatCardModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    CtSkeletonModule,
    TemplatesManagerModule,
    TemplateSaveModule,
    MatLegacyButtonModule,
    MatLegacyCheckboxModule,
    MatLegacyDialogModule,
    MatLegacyInputModule,
    TextFieldModule,
    FlexModule
  ],
  providers: [
    { provide: TINYMCE_SCRIPT_SRC, useValue: "tinymce/tinymce.min.js" }
  ]
})
export class EditorModule {
}
