import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FileUploadComponent } from "./file-upload.component";
import { CtControlModule } from "@ctsolution/ct-framework";
import { ReactiveFormsModule } from "@angular/forms";

@NgModule({
  declarations: [FileUploadComponent],
  imports: [CommonModule, CtControlModule, ReactiveFormsModule],
  exports: [FileUploadComponent]
})
export class FileUploadModule {
}
