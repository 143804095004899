import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { JwtService } from "../../core/lib/jwt.service";
import { Menu } from "./menu.interfaces";
import {
  ADMINISTRATION_MENU,
  cartMenuItem,
  DASHBOARD_MENU,
  dashboardMenuItem,
  estimatedPriceMenuItem, groupMenuItem,
  logoutMenuItem, settingsMenuItem,
  SHIPMENT_MENU, shipmentSeparator,
  trackingMenuItem, userMenuItem
} from "./menu.items";
import { RoleService } from "../../core/lib/role.service";
import { CompanyCustomConfiguration } from "../../core/interfaces/company-custom-configuration";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";

@Injectable()
export class MenuItemsService {

  constructor(
    private _jwt: JwtService,
    private roleService: RoleService,
    private authenticationService: CtAuthenticationService
  ) {
  }

  setup() {

    this.setupUserName();

    this.authenticationService
      .user
      .subscribe(async () => {

        const isCustomerCare = await this.roleService.isCustomerCare();

        if (isCustomerCare) {

          this.setupCustomerCareMenu();
          return;

        }

        this.setupAdministrationItems()
          .then(() => this.setupEstimatedPriceItem()
            .then(() => this.updateMenu()));

      });

  }

  /* Setting the badge of the cart item to the cart count. */
  setupCartCount(count: number) {

    if (!count) {

      cartMenuItem.badge = [];
      return;

    }

    cartMenuItem.badge = [{ type: "warning", value: count }];

  };

  private async setupUserName() {

    const userName = await this._jwt.getUserName() ?? "User";
    dashboardMenuItem.name = `Ciao, ${userName}`;

  }

  private async setupEstimatedPriceItem() {

    const autoAcceptPrintQuote: boolean = await this._jwt.getAutoAcceptPrintQuote();

    this.handleItemInListByMacroType(SHIPMENT_MENU, estimatedPriceMenuItem, !autoAcceptPrintQuote);

    this.updateMenu();

  };

  private handleItemInListByMacroType(list: Array<any>, item: any, condition: boolean) {

    const existingItem: any = list.filter((element: any) => !!element.macroType).find((element: any) => element.macroType === item.macroType);

    if (condition && !existingItem) {

      list.push(item);

    } else if (!condition && existingItem) {

      list.splice(list.indexOf(existingItem), 1);

    }

  }

  /**
   * It finds the administration item with the state of `groups` and then updates the name and state of that item to the
   * values in the company settings
   */
  private async setupAdministrationItems() {

    const companyConfiguration: CompanyCustomConfiguration | null = await this._jwt.getCompanyCustomConfiguration();

    const hasAdministrativeAccess: boolean = await this.roleService.hasAdministrativeAccess();

    this.handleItemInListByMacroType(ADMINISTRATION_MENU, groupMenuItem, hasAdministrativeAccess);

    this.handleItemInListByMacroType(ADMINISTRATION_MENU, userMenuItem, hasAdministrativeAccess && (companyConfiguration?.UserManagementEnabled ?? false)); // #AB4546

    this.handleItemInListByMacroType(ADMINISTRATION_MENU, settingsMenuItem, companyConfiguration?.SettingsManagementEnabled ?? false);

  }

  list$: BehaviorSubject<Array<Menu>> = new BehaviorSubject([] as Array<Menu>);

  private updateMenu() {

    return this.list$.next([
      ...DASHBOARD_MENU,
      ...SHIPMENT_MENU,
      ...ADMINISTRATION_MENU,
      logoutMenuItem
    ]);

  }

  private setupCustomerCareMenu() {

    return this.list$.next([shipmentSeparator, trackingMenuItem, logoutMenuItem]);

  }

}
