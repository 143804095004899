import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { OrderDetailComponent } from './order-detail.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { CtDatatableModule } from '@ctsolution/ct-framework';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatLegacyProgressBarModule as MatProgressBarModule } from "@angular/material/legacy-progress-bar";
import { CartOrderModule } from "../../../_components/cart-order/cart-order.module";

@NgModule({
  declarations: [OrderDetailComponent],
  imports: [CommonModule, MatDialogModule, CtDatatableModule, MatButtonModule, CartOrderModule],
  exports: [OrderDetailComponent],
})
export class OrderDetailModule {}
