import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { AbstractControl } from "@angular/forms";

const JSON_PATH: string = "/assets/json/replacementDict.json";

interface ReplacementDictionary {
  [specialChar: string]: string;
}

@Injectable()
export class SpecialCharsService {

  private replacementDict: ReplacementDictionary | undefined;

  constructor(private http: HttpClient) {
  }

  async loadConfig(): Promise<void> {

    this.replacementDict = await this.http.get<ReplacementDictionary>(JSON_PATH).toPromise();

  }

  private replaceSpecialChars(inputString: string): string {

    let outputString = inputString;

    for (const specialChar in this.replacementDict) {

      const replacementChar = this.replacementDict[specialChar];
      const regex = new RegExp(`\\${specialChar}`, "g");
      outputString = outputString.replace(regex, replacementChar);

    }

    if (!environment.production) {

      console.warn(`ATTENTION: Normalizing ${inputString} into ${outputString}`);

    }

    return outputString;

  }

  replaceControlSpecialChars(control: AbstractControl | null) {

    if (!control?.value) return;

    const normalizedValue: string = this.replaceSpecialChars(control.value);

    control.setValue(normalizedValue);
    control.updateValueAndValidity();

  }

}
