<h1 mat-dialog-title> Nuova priorità </h1>

<mat-dialog-content>

  <form [formGroup]="form">

    <mat-form-field class="m-b-10">

      <mat-label>Prodotto</mat-label>
      <mat-select formControlName="SendTypeConfiguration">
        <mat-option *ngFor="let sendType of sendTypeList" [value]="sendType">
          {{ sendType.SendTypeName }}
        </mat-option>
      </mat-select>

      <mat-error *ngIf="form.get('SendTypeConfiguration')?.hasError('required')"> * Campo obbligatorio</mat-error>

    </mat-form-field>

    <div class="m-b-15">

      <ct-control [configuration]="templateLookupControl"></ct-control>

    </div>

  </form>

</mat-dialog-content>

<div mat-dialog-actions fxLayout="row wrap" fxLayoutAlign="space-between">

  <div>

    <button mat-raised-button color="warn" (click)="cancel()">
      Annulla
    </button>

  </div>

  <div>

    <button mat-raised-button color="primary" (click)="submit()">
      Aggiungi
    </button>

  </div>

</div>
