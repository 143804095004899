import { ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { CtDatatableConfiguration, CTDatatablePaginationParameter, CtDatatableResult } from "@ctsolution/ct-framework";
import { TableColumn } from "@swimlane/ngx-datatable";
import { FlowRecipientController } from "../../../../core/controllers/flow-recipient.controller";
import { FlowRecipientListParameter } from "../../../../core/classes/flow-recipient-list.parameter";
import { Flow } from "../../../../core/classes/flow";
import { RecipientData } from "../../../../core/interfaces/recipient-data";
import { CompanyUiSettings } from "../../../../core/lib/company-ui/company.ui-settings";
import {
  CompanyUiCustomizationService,
  DEFAULT_LABEL
} from "../../../../core/lib/company-ui/company.ui-customization.service";
import { FileService } from "../../../../core/lib/file.service";
import { RecipientFlowPreviewParameter } from "../../../../core/classes/base-flow-file-parameter";

@Component({
  selector: "app-cart-multiple-recipients-list",
  templateUrl: "./cart-multiple-recipients-list.component.html",
  styleUrls: ["./cart-multiple-recipients-list.component.css"],
  providers: [FlowRecipientController]
})
export class CartMultipleRecipientsListComponent {

  @Input() data!: Flow;

  @ViewChild("recipientCellTemplate") public recipientCellTemplate!: TemplateRef<any>;
  @ViewChild("downloadCellTemplate") public downloadCellTemplate!: TemplateRef<any>;
  @ViewChild("nameCellTemplate") public nameCellTemplate!: TemplateRef<any>;
  @ViewChild("cdcCellTemplate") public cdcCellTemplate!: TemplateRef<any>;

  shipments!: CtDatatableConfiguration;

  constructor(
    private flowRecipientController: FlowRecipientController,
    private fileService: FileService,
    private cdr: ChangeDetectorRef,
    private companyUiCustomizationService: CompanyUiCustomizationService) {
  }

  ngAfterViewInit() {

    this.setTable();

  }

  /**
   * It creates a new instance of the CtDatatableConfiguration class, sets the externalPaging property to true, sets the
   * scrollbarH property to true, sets the count property to 5, and sets the columns property to the columns array
   * Called in afterViewInit because needs to wait custom cell templates load
   */
  setTable() {

    this.companyUiCustomizationService
      .getSettings()
      .then((company: CompanyUiSettings | undefined) => {

        const columns: TableColumn[] = [
          {
            prop: "FlowDescription",
            name: "Nome ordine/Codice Interno",
            cellTemplate: this.nameCellTemplate,
            width: 250
          },
          { prop: "FlowSendTypeConfigurationSendTypeName", name: "Prodotto" },
          { name: "Anteprima", cellTemplate: this.downloadCellTemplate },
          { prop: "Name", name: "Destinatario", cellTemplate: this.recipientCellTemplate, width: 320 },
          { prop: "FlowUserInfoName", name: "Creato Da" },
          {
            prop: "FlowGroupCode",
            name: company?.getGroupLabel() ?? DEFAULT_LABEL.GROUP,
            cellTemplate: this.cdcCellTemplate,
            width: 320
          }
        ];

        this.shipments = CtDatatableConfiguration.create()
          .setExternalPaging(true)
          .setScrollbarH(true)
          .setLimit(2)
          .setColumns(columns)
          .setController("multipleRecipientsShipment");

        this.cdr.detectChanges();

      });

  }

  fetch(pagination?: CTDatatablePaginationParameter) {

    const params: FlowRecipientListParameter = new FlowRecipientListParameter();
    params.FlowOid = this.data.Oid;

    this.shipments.setLoadingIndicator(true);

    this.flowRecipientController
      .list(params, pagination)
      .then((result: CtDatatableResult<RecipientData>) => {

        this.shipments
          .setCount(result.ItemTotal)
          .setRows(<Array<RecipientData>>result.Items)
          .setLoadingIndicator(false);

      });

  }

  download(value: RecipientData) {

    const parameter: RecipientFlowPreviewParameter = RecipientFlowPreviewParameter
      .create()
      .setRecipient(value);

    this.fileService.manageRecipientFlowPreview(parameter);

  }

}
