import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CtDatatableResult,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration
} from "@ctsolution/ct-framework";
import { FlowListFilters, FlowListParameter } from "../../core/classes/flow-list.parameter";
import { FlowController } from "../../core/controllers/flow.controller";
import { FLOW_STATE } from "../../core/enums/flow-state";
import { Flow } from "../../core/classes/flow";
import { TableColumn } from "@swimlane/ngx-datatable";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { OrderDetailComponent } from "./order-detail/order-detail.component";
import { CompanyUiSettings } from "../../core/lib/company-ui/company.ui-settings";
import {
  CompanyUiCustomizationService,
  DEFAULT_LABEL
} from "../../core/lib/company-ui/company.ui-customization.service";

@Component({
  selector: "app-orders",
  templateUrl: "./orders.component.html",
  styleUrls: ["./orders.component.scss"],
  providers: [FlowController]
})
export class OrdersComponent {

  @ViewChild("cdcCellTemplate") public cdcCellTemplate!: TemplateRef<any>;
  @ViewChild("createdByCellTemplate") public createdByCellTemplate!: TemplateRef<any>;
  @ViewChild("sendDateTemplate") public sendDateTemplate!: TemplateRef<any>;

  orders!: CtDatatableConfiguration;

  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  filtersConfiguration: CtSearchFiltersConfiguration =
    CtSearchFiltersConfiguration.create()
      .setFilters(FlowListFilters);

  private params: FlowListParameter = new FlowListParameter();

  constructor(
    private flowController: FlowController,
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private companyUiCustomizationService: CompanyUiCustomizationService) {
  }

  ngAfterViewInit() {

    this.setTable();

  }

  /**
   * It creates a new instance of the CtDatatableConfiguration class, sets the externalPaging property to true, the
   * detailActionEnabled property to true, the scrollbarH property to true and the columns property to the columns array
   * Called in afterViewInit because needs to wait custom cell templates load
   */
  private setTable() {

    this.companyUiCustomizationService
      .getSettings()
      .then((company: CompanyUiSettings | undefined) => {

        const columns: TableColumn[] = [
          {
            prop: "SendDate",
            name: "Data",
            cellTemplate: this.sendDateTemplate
          },
          { prop: "SendTypeConfigurationSendTypeName", name: "Prodotto", width: 200 },
          { prop: "Description", name: "Descrizione", width: 250 },
          { prop: "UserName", name: "Creato Da", cellTemplate: this.createdByCellTemplate },
          {
            prop: "GroupCode",
            name: company?.getGroupLabel() ?? DEFAULT_LABEL.GROUP,
            cellTemplate: this.cdcCellTemplate,
            width: 320
          },
          { prop: "TotalAmount", name: "Totale ordine", cellClass: CELL_TYPE.PRICE }
        ];

        this.orders = CtDatatableConfiguration.create()
          .setExternalPaging(true)
          .setDetailActionEnabled(true)
          .setScrollbarH(true)
          .setRowHeight(75)
          .setColumns(columns)
          .setController('orders');

        this.cdr.detectChanges();

      });

  }

  /**
   * The filter function is called when the user changes the filter options. It sets the optFilters variable to the new
   * filter options and then calls the fetch function
   * @param {any} event - any - this is the event that is emitted from the child component.
   */
  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  /**
   * The function is called when the user clicks on the pagination buttons. It calls the controller's cartList function,
   * which returns a promise. When the promise is resolved, the function sets the count and rows properties of the orders
   * object
   * @param {CTDatatablePaginationParameter} [event] - CTDatatablePaginationParameter
   */
  fetch(pagination?: CTDatatablePaginationParameter) {

    this.params.FlowStates = [FLOW_STATE.PROCESSING, FLOW_STATE.SENT_WITH_ERROR, FLOW_STATE.SENT];

    this.orders.setLoadingIndicator(true);

    this.flowController
      .list(this.params, pagination)
      .then((result: CtDatatableResult<Flow>) => {

        this.orders
          .setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

  detail = (flow: Flow) => this.dialog.open(OrderDetailComponent, {
    data: flow,
    disableClose: true,
    panelClass: "order-detail-dialog-cntr",
    maxHeight: "90vh"
  });


  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
