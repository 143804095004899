import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { GetFileComponent } from "./get-file.component";
import { RouterModule } from "@angular/router";

@NgModule({
  declarations: [
    GetFileComponent
  ],
  imports: [
    CommonModule,
    RouterModule
      .forChild([
        {
          path: "",
          component: GetFileComponent
        }
      ])
  ]
})
export class GetFileModule {
}
