import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { LetterSpecificationsComponent } from "./letter-specifications.component";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from "@angular/material/legacy-progress-spinner";
import { DigitalChannelDescriptionModule } from "../digital-channel-description/digital-channel-description.module";
import { CtSkeletonModule } from "@ctsolution/ct-framework";

@NgModule({
  declarations: [LetterSpecificationsComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    DigitalChannelDescriptionModule,
    CtSkeletonModule
  ],
  exports: [LetterSpecificationsComponent]
})
export class LetterSpecificationsModule {
}
