import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartOrderComponent } from './cart-order.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { CartOrderManagementComponent } from './cart-order-management/cart-order-management.component';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { EstimatedPriceComponent } from './estimated-price/estimated-price.component';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { DeleteFlowAlertModule } from './delete-flow-alert/delete-flow-alert.module';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { CartOrderDetailModule } from "./cart-order-detail/cart-order-detail.module";
import { FlexLayoutModule } from "@angular/flex-layout";
import {
  CartFileListDownloadButtonModule
} from "./cart-order-detail/cart-file-list-download-button/cart-file-list-download-button.module";
import { CartOrderExpirationNoticeComponent } from './cart-order-expiration-notice/cart-order-expiration-notice.component';

@NgModule({
  declarations: [
    CartOrderComponent,
    CartOrderManagementComponent,
    EstimatedPriceComponent,
    CartOrderExpirationNoticeComponent,
  ],
  imports: [
    CommonModule,
    MatExpansionModule,
    MatIconModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    DeleteFlowAlertModule,
    MatProgressBarModule,
    CartOrderDetailModule,
    FlexLayoutModule,
    CartFileListDownloadButtonModule
  ],
  exports: [CartOrderComponent],
})
export class CartOrderModule {}
