<div class="blank-container unsubscribe">
  <div class="blank-container-box">
    <mat-card>
      <mat-card-content>

        <div class="text-center">
          <img alt="homepage" [src]="viewModel.logoSrc" height="55" />
          <!--                    <h4 class="m-t-0">Disconnesso con successo</h4>-->
        </div>

        <p class="text-muted m-t-30 m-b-30">
          Ciao! Siamo felici di avervi nella nostra lista mail e di fornirvi regolarmente aggiornamenti e informazioni
          sui nostri prodotti e servizi.<br />Tuttavia, siamo consapevoli che potreste desiderare di non ricevere più le
          nostre email.
        </p>

        <p class="text-muted m-t-30 m-b-30">
          Se desiderate disiscrivervi dalla nostra lista mail, potete farlo in qualsiasi momento scrivendo alla mail
          specifica: <a [href]="viewModel.mailTo">{{viewModel.serviceEmail}}</a>.
          <br />
          Vi preghiamo di
          includere il vostro nome e l'indirizzo email che
          desiderate rimuovere dalla lista, in modo che possiamo identificare e processare la vostra richiesta il più
          rapidamente possibile.
        </p>

        <p class="text-muted m-t-30 m-b-30">
          Ci dispiacerebbe perderVi come parte della nostra comunità, ma rispetteremo la vostra decisione e vi
          rimuoveremo dalla nostra lista mail il più presto possibile.
        </p>

        <p class="text-muted m-t-30 m-b-30">
          Grazie per averci scelto come parte delle vostre fonti di informazione e speriamo di poter continuare ad
          offrirvi contenuti interessanti in futuro.
        </p>

      </mat-card-content>
    </mat-card>
  </div>
</div>
