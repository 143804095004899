import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProductListComponent } from './product-list.component';
import { MatLegacyRadioModule as MatRadioModule } from '@angular/material/legacy-radio';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { ProductCardModule } from './product-card/product-card.module';
import { CtSkeletonModule } from "@ctsolution/ct-framework";
import { MatDividerModule } from "@angular/material/divider";

@NgModule({
  declarations: [ProductListComponent],
  imports: [
    CommonModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    MatExpansionModule,
    FlexLayoutModule,
    MatCardModule,
    ProductCardModule,
    CtSkeletonModule,
    MatDividerModule
  ],
  exports: [ProductListComponent],
})
export class ProductListModule {}
