import { CtSearchFilterControlConfiguration } from "@ctsolution/ct-framework";
import { ActiveFilter, NameFilter } from "./group-list.parameter";
import { CodeFilter } from "./template-list.parameter";

export class SmartDeliveryConfigurationListParameter {

  private constructor() {
  }

  public static create = (): SmartDeliveryConfigurationListParameter => new SmartDeliveryConfigurationListParameter();

}

export const SmartDeliveryRuleListFilters: CtSearchFilterControlConfiguration[] = [CodeFilter, NameFilter, ActiveFilter];
