import { ChangeDetectorRef, Component, TemplateRef, ViewChild } from "@angular/core";
import {
  CELL_TYPE,
  CtDatatableConfiguration,
  CTDatatablePaginationParameter, CtDatatableResult,
  CtSearchFiltersComponent,
  CtSearchFiltersConfiguration, CtSortOrderDescriptorParameter
} from "@ctsolution/ct-framework";
import { GroupController } from "../../../core/controllers/group.controller";
import { CtAuthenticationService } from "@ctsolution/ct-authentication";
import { TableColumn } from "@swimlane/ngx-datatable";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { Group } from "../../../core/interfaces/group";
import { GroupDetailComponent } from "./group-detail/group-detail.component";
import { ActiveFilter, GroupListFilters, GroupListParameter } from "../../../core/classes/group-list.parameter";
import { RoleService } from "../../../core/lib/role.service";
import { JwtService } from "../../../core/lib/jwt.service";
import {
  CompanyUiCustomizationService, DEFAULT_LABEL
} from "../../../core/lib/company-ui/company.ui-customization.service";
import { CompanyCodeFilter } from "../../../core/classes/flow-common.parameter";
import { CompanyUiSettings } from "../../../core/lib/company-ui/company.ui-settings";
import { GroupService } from "../../../core/lib/group.service";
import { GroupDeleteComponent } from "./group-detail/group-delete/group-delete.component";
import { CtDatatableSortEvent } from "@ctsolution/ct-framework/lib/ct-datatable/ct-datatable-sort.event";

@Component({
  selector: "app-group",
  templateUrl: "./group.component.html",
  styleUrls: ["./group.component.scss"],
  providers: [GroupController]
})
export class GroupComponent {

  @ViewChild("budgetCellTemplate") public budgetCellTemplate!: TemplateRef<any>;
  @ViewChild("searchFilter") public searchFilter?: CtSearchFiltersComponent;

  group!: CtDatatableConfiguration;

  filtersConfiguration: CtSearchFiltersConfiguration = CtSearchFiltersConfiguration.create().setFilters(GroupListFilters);

  private params: GroupListParameter = new GroupListParameter();

  hasAdministrativeAccess: boolean = false;

  constructor(
    private groupController: GroupController,
    private _authentication: CtAuthenticationService,
    private _dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    private roleService: RoleService,
    private _jwt: JwtService,
    private companyUiCustomizationService: CompanyUiCustomizationService,
    private groupService: GroupService
  ) {
  }

  ngAfterViewInit() {

    this.setup();

  }

  private async setup() {

    const hasDefaultAdministrativeAccess = await this.roleService.hasAdministrativeAccess();

    const BPERGroupUser = await this.companyUiCustomizationService.BPERGroupUser();
    const isCompanyAdminOrCompanyGroupAdmin = await this.roleService.isCompanyAdminOrCompanyGroupAdmin();

    this.hasAdministrativeAccess = (!BPERGroupUser && hasDefaultAdministrativeAccess) || (BPERGroupUser && isCompanyAdminOrCompanyGroupAdmin);

    this.setDefaultValues()
      .then(() => this.setTable());

  }

  private async setDefaultValues() {

    const BPERGroupUser = await this.companyUiCustomizationService.BPERGroupUser();

    if (BPERGroupUser) {

      if (!ActiveFilter.control.touched) {

        ActiveFilter.control.setValue(true);
        ActiveFilter.control.markAsTouched();

      }

      if (!CompanyCodeFilter.control.touched) {

        const companyCode: string | null = await this._jwt.getCompanyCode();

        if (companyCode) {

          CompanyCodeFilter.control.setValue(companyCode);
          CompanyCodeFilter.control.markAsTouched();

        }

      }

      // sta roba dovrebbe farla nel pacchetto, o meglio, la fa gia. vedere come renderla configurabile per queste customizzazioni
      this.params = this.searchFilter?.form.value;
      this.cleanEmptyValues(this.params);


    }

    this.cdr.detectChanges();

  }

  private cleanEmptyValues = (values: any) => Object.keys(values).forEach((k) => values[k] == null && delete values[k]);

  /**
   * It creates a new instance of the CtDatatableConfiguration class, sets the externalPaging, editingActionEnabled,
   * scrollbarH, externalSorting and columns properties and returns the instance
   * Called in afterViewInit because needs to wait custom cell templates load
   */
  setTable() {

    if (this.group) return;

    this.companyUiCustomizationService
      .getSettings()
      .then(async (company: CompanyUiSettings | undefined) => {

        const isCompanyGroupAdmin = await this.roleService.isCompanyGroupAdmin();

        let columns: TableColumn[] = [
          {
            prop: "Code",
            name: company?.getGroupCodeLabel() ?? DEFAULT_LABEL.GROUP_CODE,
            sortable: true,
            width: 150,
            minWidth: 150,
            maxWidth: 150
          },
          { prop: "Name", name: "Nome", width: isCompanyGroupAdmin ? 320 : 500, sortable: true },
          { prop: "CompanyCode", name: company?.getCompanyCodeLabel() ?? DEFAULT_LABEL.COMPANY_CODE, sortable: false },
          {
            prop: "Active",
            name: "Attivo",
            cellClass: CELL_TYPE.BOOLEAN,
            sortable: false,
            width: 80,
            minWidth: 80,
            maxWidth: 80
          },
          {
            prop: "CurrentBudget",
            name: "Budget residuo",
            width: isCompanyGroupAdmin ? 320 : 500,
            cellTemplate: this.budgetCellTemplate,
            sortable: true
          }
        ];

        if (!isCompanyGroupAdmin) {

          columns = columns.filter((column: TableColumn) => column.prop !== "CompanyCode");

        }

        this.group = CtDatatableConfiguration
          .create()
          .setExternalPaging(true)
          .setScrollbarH(true)
          .setExternalSorting(true)
          .setRowHeight(65)
          .setColumns(columns);

        if (this.hasAdministrativeAccess) {

          this.group
            .setEditingActionEnabled(true)
            .setDeleteActionEnabled(true);

        }

        this.cdr.detectChanges();

      });

  }

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  onSort(event: CtDatatableSortEvent) {

    const sortParams: CtSortOrderDescriptorParameter | null = event.sorting ?? null;

    if (sortParams?.Field) {

      this.params.OrderByField = sortParams?.Field === "CurrentBudget" ? "Expense" : sortParams.Field;

    } else if (this.params.OrderByField) {

      delete this.params.OrderByField;

    }

    if (!!sortParams?.IsDescending) {

      this.params.OrderByDescending = sortParams.IsDescending;

    } else if (this.params.OrderByDescending) {

      delete this.params.OrderByDescending;

    }

    this.fetch();

  }

  getBudgetLabel(group: Group): string {

    if (group.CurrentBudget) {

      group.BudgetList = [group.CurrentBudget];

    }

    const residue: string | number = this.groupService.getBudgetResidueLabel(group);

    return isNaN(+residue) ? residue : `${residue} / ${group.CurrentBudget?.Amount ?? 0} €`;

  }

  /**
   * The function calls the controller's list function, which returns a promise. When the promise is resolved, the
   * group's setCount and setRows functions are called, and the loading indicator is set to false
   * @param {CTDatatablePaginationParameter} [Pagination] - This is the pagination parameter that is passed to the
   * controller.
   */
  fetch(pagination?: CTDatatablePaginationParameter) {

    this.group.setLoadingIndicator(true);

    this.groupController
      .list(this.params, pagination)
      .then((result: CtDatatableResult<Group>) => {

        this.group
          .setCount(result.ItemTotal)
          .setRows(result.Items)
          .setLoadingIndicator(false);

      });

  }

  newGroup() {

    this.detail(<Group>{ Oid: null });

  }

  delete(group: Group) {

    if (!group.Oid) return;

    this._dialog
      .open(GroupDeleteComponent, {
        data: group,
        disableClose: true,
        maxWidth: 650
      }).afterClosed()
      .subscribe((result: boolean | undefined) => {

        if (result) {

          this.groupController
            .delete(group.Oid!)
            .then(() => {

              this.fetch();

            });

        }

      });

  }

  /**
   * It opens a dialog, and when the dialog is closed, it fetches the data and updates the claims
   * @param {Group} group - Group - The group object that will be passed to the dialog component.
   */
  detail(group: Group) {

    this._dialog
      .open(GroupDetailComponent, {
        data: group,
        disableClose: true,
        maxWidth: 650
      }).afterClosed()
      .subscribe(async (result: boolean | undefined) => {

        if (result) {

          const userGroupOid: number = await this._jwt.getGroupOid();

          if (userGroupOid === group.Oid) {

            this._authentication
              .getClaims()
              .then(() => this.fetch());

          } else {

            this.fetch();

          }

        }

      });

  }

  ngOnDestroy() {

    this.searchFilter?.reset();

  }

}
