import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserComponent } from './user.component';
import { CtDatatableModule, CtSearchFiltersModule } from '@ctsolution/ct-framework';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { UserDetailModule } from './user-detail/user-detail.module';

@NgModule({
  declarations: [UserComponent],
  imports: [
    CommonModule,
    MatCardModule,
    CtDatatableModule,
    CtSearchFiltersModule,
    MatButtonModule,
    MatIconModule,
    UserDetailModule,
  ],
})
export class UserModule {}
