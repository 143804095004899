import { Component, Inject, ViewChild } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef } from "@angular/material/legacy-dialog";
import { TemplateManagerConfiguration } from "../template-manager.configuration";
import { TemplateController } from "../../../../core/controllers/template.controller";
import { SnackbarService } from "../../../../core/lib/snackbar.service";
import { CtWebapiGenericResponse } from "@ctsolution/ct-webapi/core/response.interface";
import { TemplateSaveFormComponent } from "./template-save-form/template-save-form.component";

@Component({
  selector: "app-template-save",
  templateUrl: "./template-save.component.html",
  styleUrls: ["./template-save.component.scss"],
  providers: [TemplateController]
})
export class TemplateSaveComponent {

  @ViewChild("form") templateSaveFormComponent: TemplateSaveFormComponent | null = null;

  constructor(
    private dialogRef: MatLegacyDialogRef<TemplateSaveComponent>,
    private templateController: TemplateController,
    private _snackbar: SnackbarService,
    @Inject(MAT_DIALOG_DATA) public configuration: TemplateManagerConfiguration) {

  }

  close(result: CtWebapiGenericResponse<number> | null = null) {

    this.dialogRef
      .close(result);

  }

  submit() {

    const form = this.templateSaveFormComponent?.form;

    if (!form) return;

    form.markAllAsTouched();

    if (form.valid && this.configuration.parameter) {

      this.configuration
        .parameter
        .setFromTemplateSaveForm(form.value);

      this.templateController
        .save(this.configuration.parameter)
        .subscribe((result: CtWebapiGenericResponse<number>) => {

          this._snackbar.open("Modello salvato correttamente!", "X");
          this.close(result);

        });

    }

  }

}
