import { BrowserModule } from "@angular/platform-browser";
import { LOCALE_ID, NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HttpClient } from "@angular/common/http";
import { DatePipe, registerLocaleData } from "@angular/common";
import { AppRoutes } from "./app.routing";
import { AppComponent } from "./app.component";

import { FlexLayoutModule } from "@angular/flex-layout";
import { FullComponent } from "./layouts/full/full.component";
import { AppBlankComponent } from "./layouts/blank/blank.component";

import { VerticalAppSidebarComponent } from "./layouts/full/vertical-sidebar/vertical-sidebar.component";

import { AppBreadcrumbComponent } from "./layouts/full/breadcrumb/breadcrumb.component";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { DemoMaterialModule } from "./demo-material-module";

import { SpinnerComponent } from "./shared/spinner.component";

import { TranslateLoader, TranslateModule } from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";

import {
  CtAuthenticationModule,
  CtAuthenticationSetup
} from "@ctsolution/ct-authentication";
import { CtFileController, CtWebapiModule, CtWebapiSetup } from "@ctsolution/ct-webapi";
import { environment } from "../environments/environment";
import { MenuItemsService } from "./shared/menu/menu-items.service";

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, "./assets/i18n/", ".json");
}

import localeIT from "@angular/common/locales/it";
import { CancelOrderSubmissionModule } from "./shared/cancel-order-submission/cancel-order-submission.module";
import { CtFileManagementModule } from "@ctsolution/ct-file-management";
import { NgxWebstorageModule } from "ngx-webstorage";
import { CtFrameworkModule, CtFrameworkSetup } from "@ctsolution/ct-framework";

registerLocaleData(localeIT);

export const AUTHENTICATION_CONFIG = CtAuthenticationSetup
  .create(environment.projectConfiguration.name, environment.projectConfiguration.API_URL)
  .setRedirectUrl("/autenticazione/accesso");
// .setInactivityThresholdConfiguration(
//   CtInactivityThresholdConfiguration
//     .create()
//     .setIdle(1800)
//     .setTimeOut(10)
// );

@NgModule({
  declarations: [
    AppComponent,
    FullComponent,
    SpinnerComponent,
    AppBlankComponent,
    VerticalAppSidebarComponent,
    AppBreadcrumbComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    DemoMaterialModule,
    FlexLayoutModule,
    FormsModule,
    HttpClientModule,
    RouterModule.forRoot(AppRoutes), //, { relativeLinkResolution: "legacy" }
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CtAuthenticationModule.setup(AUTHENTICATION_CONFIG),
    CtWebapiModule.setup(
      CtWebapiSetup
        .create(environment.projectConfiguration.API_URL)
    ),
    CancelOrderSubmissionModule,
    CtFileManagementModule,
    NgxWebstorageModule.forRoot(),
    CtFrameworkModule.forRoot(CtFrameworkSetup.create(environment.projectConfiguration.name))
  ],
  providers: [
    DatePipe,
    MenuItemsService,
    { provide: LOCALE_ID, useValue: "it-IT" },
    CtFileController
  ],
  bootstrap: [AppComponent],
  exports: [SpinnerComponent]
})
export class AppModule {
}
