import { Injectable } from "@angular/core";
import { CompanyUiSettings } from "./company.ui-settings";
import { HttpClient } from "@angular/common/http";
import { JwtService } from "../jwt.service";
import { AUTHENTICATION_CONFIG } from "../../../app.module";

export const DEFAULT_LABEL = {

  GROUP: "Gruppo",
  GROUPS: "Gruppi",
  COMPANY_CODE: "Codice Cliente",
  GROUP_CODE: "Codice Gruppo"

};

export const COMPANY_MACRO_CODE = {

  BPER: "BPER",
  BANCODISARDEGNA: "BANCODISARDEGNA",
  CESAREPONTI: "CESAREPONTI",
  AXPOITALIA: "AXPOITALIA",
  COLLINO: "COLLINO",
  ASPMENERGIA: "ASPMENERGIA",
  BANCAPASSADORE: "BANCAPASSADORE",
  FESTINA: "FESTINA",
  ENERGYSELLER: "ENERGYSELLER",
  PULSEE: "PULSEE",
  AUTOGRILL: "AUTOGRILL",
  HUB799: "HUB799"

};

@Injectable({
  providedIn: "root"
})
export class CompanyUiCustomizationService {

  private companies: CompanyUiSettings[] = [];
  private loadingData: boolean = false;

  constructor(private http: HttpClient, private _jwt: JwtService) {
  }

  /* A function that returns a promise. */
  getSettings = (code: string | null = null, attempt: number = 3): Promise<CompanyUiSettings | undefined> =>

    new Promise<CompanyUiSettings | undefined>(async (resolve) => {

      if (!code) code = await this._jwt.getCompanyMacroCode();

      if (this.companies.length > 0 || attempt < 0) {

        resolve(this.findCompany(code));
        return;

      }

      if (!this.loadingData) {

        this.loadingData = true;

        this.getJSON()
          .then((result: any) => {

            this.companies = result.map((e: CompanyUiSettings) => new CompanyUiSettings(e.MacroCode, e.Assets, e.Dictionary));
            resolve(this.findCompany(code));

            this.loadingData = false;

          });

      } else {

        setTimeout(() => resolve(this.getSettings(code, --attempt)), 300);

      }

    });

  private getJSON = (): Promise<CompanyUiSettings[]> =>
    new Promise<CompanyUiSettings[]>((resolve) => {
      this.http
        .get("./assets/companies/companies.json")
        .subscribe((result: any) => resolve(result as CompanyUiSettings[]));
    });

  private findCompany = (code: string | null): CompanyUiSettings | undefined => this.companies.find((c) => c.MacroCode === code);

  async BPERGroupUser() {

    const companyMacroCode = await this._jwt.getCompanyMacroCode() ?? "";

    return [COMPANY_MACRO_CODE.BPER, COMPANY_MACRO_CODE.BANCODISARDEGNA, COMPANY_MACRO_CODE.CESAREPONTI].includes(companyMacroCode);

  };

  async AxpoGroupUser() {

    const companyMacroCode = await this._jwt.getCompanyMacroCode() ?? "";

    return [COMPANY_MACRO_CODE.ASPMENERGIA, COMPANY_MACRO_CODE.COLLINO, COMPANY_MACRO_CODE.AXPOITALIA, COMPANY_MACRO_CODE.PULSEE].includes(companyMacroCode);

  };

  setBPERGroupLogoutRedirect = () => AUTHENTICATION_CONFIG.redirectUrl = `/logout/bper-group`;

}
