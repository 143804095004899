import { Component, OnInit } from "@angular/core";
import { Flow } from "../../core/classes/flow";
import { FlowController } from "../../core/controllers/flow.controller";
import { FLOW_STATE } from "../../core/enums/flow-state";
import { CartOrderConfiguration } from "../../_components/cart-order/cart-order.configuration";
import { FlowListFilters, FlowListParameter } from "../../core/classes/flow-list.parameter";
import { GeneralService } from "../../core/lib/general.service";
import { CtDatatableResult, CtSearchFiltersConfiguration } from "@ctsolution/ct-framework";
import { JwtService } from "../../core/lib/jwt.service";

@Component({
  selector: "app-quotations",
  templateUrl: "./quotations.component.html",
  styleUrls: ["./quotations.component.scss"],
  providers: [FlowController]
})
export class QuotationsComponent implements OnInit {

  filtersConfiguration: CtSearchFiltersConfiguration = CtSearchFiltersConfiguration.create().setFilters(FlowListFilters);

  items: CartOrderConfiguration[] = [];

  params: FlowListParameter = new FlowListParameter();

  filter(event: any) {

    this.params = event ?? {};

    this.fetch();

  }

  createEstimatedPriceOrder = (flow: Flow) =>
    CartOrderConfiguration.create()
      .manageEstimatedPrice(true)
      .setFlow(flow);

  constructor(
    private flowController: FlowController,
    private _general: GeneralService,
    private _jwt: JwtService
  ) {
  }

  ngOnInit() {

    this.setup();

  }

  async setup() {

    const autoAcceptPrintQuote = await this._jwt.getAutoAcceptPrintQuote();

    if (autoAcceptPrintQuote) {

      this._general.navigateTo(["/carrello"]);

    } else {

      this.fetch();

    }

  }

  /**
   * The function fetches a list of flows from the server, and then maps the flows to a list of estimated price orders
   */
  fetch() {

    this.params.FlowStates = [FLOW_STATE.PRICE_ESTIMATE];

    this.flowController
      .list(this.params)
      .then((result: CtDatatableResult<Flow>) => {

        this.items = result.Items.map((flow: Flow) => this.createEstimatedPriceOrder(flow));

      });

  }

}
