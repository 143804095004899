import { ChangeDetectorRef, Component, EventEmitter, Output, ViewChild } from "@angular/core";
import { Validators } from "@angular/forms";
import {
  FlowFileUploaderComponent
} from "../../../../_components/flow-components/file-uploader/flow-file-uploader.component";
import { FlowStepsController, StepSaveResult } from "../../../../core/controllers/flow-steps.controller";
import { FlowStep3 } from "../../../../core/classes/flow-steps/flow-step3";
import { FlowService } from "../../../../core/lib/flow.service";
import {
  FlowComponentCardContainerConfiguration
} from "../../../../_components/flow-components/flow-component-card-container.configuration";
import { FlowFile } from "../../../../core/interfaces/flow-file";
import { FLOW_FILE_TYPE } from "../../../../core/enums/flow-file-type.enum";
import { TinyMceEditorComponent } from "../../../../_components/flow-components/editor/tiny-mce-editor.component";
import { CompanyCustomConfiguration } from "../../../../core/interfaces/company-custom-configuration";
import { JwtService } from "../../../../core/lib/jwt.service";
import { FlowFileController } from "../../../../core/controllers/flow-file.controller";
import { FileService } from "../../../../core/lib/file.service";
import { SmsEditorComponent } from "./sms-editor/sms-editor.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import { CheckBraceMatchingAlertComponent } from "./check-brace-matching-alert/check-brace-matching-alert.component";
import { TinyMceSetupConfiguration } from "../../../../_components/flow-components/editor/tiny-mce.setup-configuration";
import { CheckBraceMatchingService } from "./check-brace-matching-alert/check-brace-matching.service";
import { editorTabIndex, fileUploaderTabIndex, Step3Model } from "./step3.model";
import { animate, state, style, transition, trigger } from "@angular/animations";

@Component({
  selector: "app-step3",
  templateUrl: "./step3.component.html",
  styleUrls: ["./step3.component.scss"],
  providers: [FlowStepsController, CheckBraceMatchingService],
  animations: [
    trigger("expandCollapse", [
      state(
        "expanded",
        style({
          height: "*",
          opacity: 1
        })
      ),
      state(
        "collapsed",
        style({
          height: "0",
          opacity: 0
        })
      ),
      transition("expanded <=> collapsed", [animate("300ms ease-in-out")])
    ])
  ]
})
export class Step3Component {

  @Output() onSuccess: EventEmitter<StepSaveResult> = new EventEmitter<StepSaveResult>();
  @ViewChild("FILE_UPLOADER") fileUploader?: FlowFileUploaderComponent;
  @ViewChild("TINY_EDITOR") tinyEditor?: TinyMceEditorComponent;
  @ViewChild("SMS_EDITOR") smsEditor?: SmsEditorComponent;

  viewModel: Step3Model = Step3Model.create();
  fileUploaderContainerConfiguration: FlowComponentCardContainerConfiguration | null = FlowComponentCardContainerConfiguration
    .create()
    .setIcon("upload")
    .setCtFileUploaderDefaultConfiguration();

  get isEditorTabActive(): boolean {

    return this.viewModel.selectedTabIndex === editorTabIndex;

  }

  constructor(
    public flowService: FlowService,
    private flowFileController: FlowFileController,
    private _jwt: JwtService,
    private _controller: FlowStepsController,
    private _file: FileService,
    private cdr: ChangeDetectorRef,
    private dialog: MatLegacyDialog,
    private checkBraceMatchingService: CheckBraceMatchingService
  ) {
  }

  /**
   * The function fetches a list of files from the server and then sets the files on the file uploader
   */
  fetch() {

    this.viewModel = Step3Model.create(this.flowService.activeFlow) // carico i default
      .setSelectedTabIndex(this.isEditorTabActive ? editorTabIndex : fileUploaderTabIndex); // serve ad evitare glitch sgradevoli se ha già renderizzato l'editor

    this.setup()
      .then(() => this.loadFiles());

  }

  private async setup() {

    //#region init editor sms
    if (this.flowService.activeFlow.smsStandaloneChannelSetupEnabled) {

      setTimeout(() => this.smsEditor?.getDynamicFields());
      this.fileUploaderContainerConfiguration = null;
      return;

    }
    //#endregion init editor sms

    //#region setup configurations
    const COMPANY_CONFIGURATION: CompanyCustomConfiguration | null = await this._jwt.getCompanyCustomConfiguration();

    // se esiste la configurazione e non rientro nelle casistische di destinatari multipli e/o raccomandata digitale
    if (!!COMPANY_CONFIGURATION && !(this.flowService.activeFlow.dynamicRecipientsSetupEnabled || this.flowService.activeFlow.OTPLetterStandaloneChannelSetupEnabled)) {

      this.viewModel
        .setupByConfiguration(COMPANY_CONFIGURATION);

    }
    //#endregion setup configurations

    //#region init file uploader
    this.fileUploaderContainerConfiguration
      ?.setupConfigurationFromFlow(this.flowService.activeFlow, this.viewModel);

    //setTimeout(() => this.fileUploader?.setFiles([]));
    //#endregion init file uploader

    //#region init editor
    this.tinyEditor
      ?.setup(
        TinyMceSetupConfiguration
          .create(this.flowService.activeFlow.SendTypeConfigurationSendTypeSendCategory)
          .setValidators(Validators.required)
      );

    //#endregion init editor

    this.cdr.detectChanges();

  }

  refreshUploaderFilesAfterFileDelete(files: FlowFile[] | null) {

    // mi vengono passati dal server dopo che ho cancellato un file, quindi è la lista aggiornata
    this.flowService.activeFlow.FlowFiles = files ?? [];
    this.loadFiles(true);

  }

  loadFiles(bypassHtmlLoad: boolean = false) {

    const flowFiles: FlowFile[] = this.flowService.activeFlow.FlowFiles ?? [];

    const existingHtml: FlowFile | undefined = flowFiles.find((file: FlowFile) => file.PPFileType == FLOW_FILE_TYPE.Content);

    if (existingHtml && !bypassHtmlLoad) {

      const editorConfiguration: TinyMceSetupConfiguration = TinyMceSetupConfiguration
        .create()
        .setFile(existingHtml);

      if (this.flowService.activeFlow.smsStandaloneChannelSetupEnabled) {

        this.smsEditor?.setupContentFromPPFile(editorConfiguration);
        return;

      }

      if (!this.isEditorTabActive) {

        this.viewModel
          .setSelectedTabIndex(editorTabIndex);

      }

      this.tinyEditor
        ?.setupContentFromPPFile(editorConfiguration);

    }

    const currentFileType: FLOW_FILE_TYPE = this.flowService.activeFlow.dynamicRecipientsSetupEnabled ? FLOW_FILE_TYPE.Archive : FLOW_FILE_TYPE.Attachment;
    const attachments: FlowFile[] = flowFiles.filter((file: FlowFile) => file.PPFileType === currentFileType && !file.Extension.toLowerCase().includes("htm"));

    setTimeout(() => this.fileUploader?.setFiles(attachments, true));

    this.cdr.detectChanges();

  }

  submit() {

    // triggero le validazioni in caso non venissero percepite sul submit
    if (this.flowService.activeFlow.smsStandaloneChannelSetupEnabled && this.smsEditor?.control.invalid) {

      this.smsEditor?.control.markAsTouched();
      return;

    }

    // triggero le validazioni in caso non venissero percepite sul submit
    if (this.isEditorTabActive && this.tinyEditor?.control.invalid) {

      this.tinyEditor?.control.markAsTouched();
      return;

    }

    if (this.fileUploaderContainerConfiguration?.ctFileUploaderConfiguration?.control.invalid) { // se c'è anche upload file e non è valido

      this.fileUploaderContainerConfiguration?.ctFileUploaderConfiguration.control.markAsTouched();
      return;

    }

    this.getCleanedFileList()
      .then((paramDTO: Step3_SaveRequestDTO) => {

        const parameter: FlowStep3 = new FlowStep3(paramDTO.Attachments, paramDTO.Content);

        this._controller
          .step3Save(parameter)
          .then((result: StepSaveResult) => this.onSuccess.emit(result));

      });

  }

  onTabChange() {

    this.tinyEditor
      ?.setControlValidators(this.isEditorTabActive ? Validators.required : null);

    this.fileUploaderContainerConfiguration
      ?.ctFileUploaderConfiguration
      ?.setControlValidators(this.isEditorTabActive ? null : Validators.required);

  }

  private getCleanedFileList(): Promise<Step3_SaveRequestDTO> {

    return new Promise<Step3_SaveRequestDTO>(async (resolve) => {

      let Attachments: Array<FlowFile | File> = [];
      let Content : FlowFile | File | null = null;

      if (this.flowService.activeFlow.smsStandaloneChannelSetupEnabled || this.isEditorTabActive) {

        const stringValue: string | null = (this.flowService.activeFlow.smsStandaloneChannelSetupEnabled ? this.smsEditor : this.tinyEditor)?.control.value;
        const { isValid, invalidCount }: any = this.checkBraceMatchingService.checkBraceMatching(stringValue);

        if (!isValid) {

          this.dialog.open(CheckBraceMatchingAlertComponent, { data: invalidCount, maxWidth: 500 });
          return; // contenuto editor non valido, interrompo

        }

        const file: File | null = this._file.string2HTML(stringValue);

        if (file) {

          Content = file;

        }

      }

      // prendo solo i file 'nuovi' che sono stati inseriti, se ci sono
      const attachmentFiles: File[] = (this.fileUploaderContainerConfiguration?.ctFileUploaderConfiguration?.control?.value ?? []).filter((f: File) => !!f.lastModified);

      if (attachmentFiles) {

        Attachments = Attachments.concat(attachmentFiles);

      }

      const flowFiles: FlowFile[] = this.flowService.activeFlow.FlowFiles ?? [];
      const ERASABLE_DATA: FlowFile[] = flowFiles.filter((elm: FlowFile) => elm.Extension?.toLowerCase().includes("htm")); // ho deciso di non caricare contenuto editor, cancello eventuali file html precedenti

      const TO_LOAD : Step3_SaveRequestDTO = new Step3_SaveRequestDTO(Attachments, Content);
      
      if (ERASABLE_DATA.length === 0) {

        // non ci sono file da cancellare, restituisco la lista di file caricabili
        resolve(TO_LOAD);
        return;

      }
      
      // se arrivo qui, devo prima cancellare qualche file che non è più necessario
      this.flowFileController
        .deleteFlowFilesFromDraft(ERASABLE_DATA)
        .then(() => resolve(TO_LOAD));

    });

  }

  //#endregion

  ngOnDestroy() {

    this.fileUploader?.setFiles([]);

  }

}

export class Step3_SaveRequestDTO
{
      constructor(public Attachments : Array<FlowFile | File>, public Content : FlowFile | File | null){}
}