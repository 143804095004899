import { Injectable } from "@angular/core";
import { SmartDeliveryDetailComponent } from "./smart-delivery-detail.component";
import { MatLegacyDialog } from "@angular/material/legacy-dialog";
import {
  SmartDeliveryConfigurationSaveParameter
} from "../../../../core/classes/smart-delivery-configuration-save.parameter";

@Injectable()
export class SmartDeliveryDetailService {

  constructor(private dialog: MatLegacyDialog) {
  }

  open(param: SmartDeliveryConfigurationSaveParameter | null = null) {

    if (param) {

      param = SmartDeliveryConfigurationSaveParameter.create(param);

    }

    return this.dialog
      .open(SmartDeliveryDetailComponent, { data: param, width: "700px" });

  }

}
