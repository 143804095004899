import { Flow } from "../../../../../core/classes/flow";
import { FlowSendAreaEnum } from "../../../../../core/enums/flow-send-area.enum";

export enum PERSON_SPECIFICATION_TYPE {

  SENDER = 1,
  RECIPIENT = 2

}

export class PersonSpecificationsConfiguration {

  get emailProvider(): string | null {
    return this._emailProvider;
  }

  private set emailProvider(value: string | null) {
    this._emailProvider = value;
  }

  get sendArea(): FlowSendAreaEnum {
    return this._sendArea;
  }

  private set sendArea(value: FlowSendAreaEnum) {
    this._sendArea = value;
  }

  get personSpecificationType(): PERSON_SPECIFICATION_TYPE | null {

    return this._personSpecificationType;

  }

  private set personSpecificationType(value: PERSON_SPECIFICATION_TYPE | null) {

    this._personSpecificationType = value;

  }

  get errors(): { [p: string]: string } | null {

    return this._errors;

  }

  private set errors(value: { [p: string]: string } | null) {

    this._errors = value;

  }

  get verified(): boolean | null {

    return this._verified;

  }

  private set verified(value: boolean | null) {

    this._verified = value;

  }

  get title(): string | null {

    return this._title;

  }

  private set title(value: string | null) {

    this._title = value;

  }

  get flow(): Flow | null {

    return this._flow;

  }

  private set flow(value: Flow | null) {

    this._flow = value;

  }

  private _title: string | null = null;
  private _verified: boolean | null = null;
  private _errors: { [key: string]: string } | null = null;
  private _personSpecificationType: PERSON_SPECIFICATION_TYPE | null = null;
  private _flow: Flow | null = null;
  private _sendArea: FlowSendAreaEnum = FlowSendAreaEnum.Local;
  private _emailProvider: string | null = null;

  constructor(title?: string) {

    if (title) this.setTitle(title);

  }

  setTitle(value: string): PersonSpecificationsConfiguration {

    this.title = value;
    return this;
  }

  setVerified(value: boolean | null): PersonSpecificationsConfiguration {

    this.verified = value;
    return this;

  }

  setErrors(value: { [key: string]: string }): PersonSpecificationsConfiguration {

    this.errors = value;
    return this;

  }

  setPersonSpecificationType(value: PERSON_SPECIFICATION_TYPE): PersonSpecificationsConfiguration {

    this.personSpecificationType = value;
    return this;

  }

  get recipientContainer(): boolean {

    return this.personSpecificationType === PERSON_SPECIFICATION_TYPE.RECIPIENT;

  }

  get senderContainer(): boolean {

    return this.personSpecificationType === PERSON_SPECIFICATION_TYPE.SENDER;

  }

  get externalAreaConfiguration(): boolean {

    return this.sendArea === FlowSendAreaEnum.External;

  }

  get localAreaConfiguration(): boolean {

    return this.sendArea === FlowSendAreaEnum.Local;

  }

  setFlow(value: Flow) {

    this.flow = value;
    return this;

  }

  setSendArea(value: FlowSendAreaEnum) {

    this.sendArea = value;
    return this;

  }

  setEmailProvider(value: string) {

    this.emailProvider = value;
    return this;
    
  }

}
