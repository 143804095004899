import { Component } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CtWebapiService, DataRequest } from "@ctsolution/ct-webapi";
import { MatLegacySnackBar as MatSnackBar } from "@angular/material/legacy-snack-bar";

@Component({
  selector: "app-view-content",
  templateUrl: "./view-content.component.html",
  styles: [`.view-content-container {
    background-image: none;
    background-color: white;
    box-shadow: none;
  }

  #fileIframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: none; /* Rimuove il bordo dell'iframe */
  }`]
})
export class ViewContentComponent {

  constructor(private route: ActivatedRoute, private router: Router, private _snackbar: MatSnackBar, private _webapi: CtWebapiService) {

    this.route
      .queryParams
      .subscribe(
        p => {

          const id: string | null = p["id"];

          if (id) {

            this.getFile(id);

          } else {

            this.errorSnackbar();

          }

        });

  }

  errorSnackbar() {

    const reference = this._snackbar.open("An error occurred while loading file. Try again.", "OK", { duration: 3500 });

    reference.afterOpened().subscribe(() => this.router.navigate(["/"]));

  }

  getFile(id: string) {

    const request: DataRequest = new DataRequest(`/FlowFile/RemoteViewByID`, { id });
    request.setResponseType("blob");

    this._webapi.get(request)
      .subscribe({
        next: (blob: Blob | null) => {

          if (blob) {

            const url = window.URL.createObjectURL(blob);

            const iframe = document.getElementById("fileIframe") as HTMLIFrameElement;

            if (iframe) {

              iframe.src = url;

            } else {

              console.error("Errore: iframe non trovato");
              this.errorSnackbar();

            }

            iframe.onload = () => {

              window.URL.revokeObjectURL(url);

            };

          } else {

            console.error("Errore: Il blob è null");
            this.errorSnackbar();

          }

        },
        error: (err: any) => {

          console.error("Errore durante il recupero del file", err);
          this.errorSnackbar();

        }

      });
  }

}
