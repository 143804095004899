import { Injectable } from "@angular/core";
import { Flow } from "./core/classes/flow";
import { SendCategorySender } from "./core/interfaces/company-custom-configuration";
import { SendTypeSendCategoryEnum } from "./core/interfaces/send-type-configuration";
import {
  EMAIL_STANDALONE_SEND_CATEGORY,
  OTP_STANDALONE_SEND_CATEGORY,
  SMS_STANDALONE_SEND_CATEGORY
} from "./core/classes/send-categories.constant";

@Injectable({
  providedIn: "root"
})
export class SendCategoryService {

  constructor() {
  }

  getCategorySenderByFlow(flow: Flow | null, sendCategorySenders: SendCategorySender[]): SendCategorySender | null {

    if (flow) {

      const sender: SendCategorySender | undefined = sendCategorySenders.find((elm: SendCategorySender) => this.getChannelBySendCategory(flow).includes(elm.SendCategory!));

      if (sender) return sender;

    }

    return null;

  }

  getChannelBySendCategory(flow: Flow): SendTypeSendCategoryEnum[] {

    if (flow.emailStandaloneChannelSetupEnabled) return EMAIL_STANDALONE_SEND_CATEGORY;

    if (flow.smsStandaloneChannelSetupEnabled) return SMS_STANDALONE_SEND_CATEGORY;

    if (flow.OTPLetterStandaloneChannelSetupEnabled) return OTP_STANDALONE_SEND_CATEGORY;

    return [];

  }

}
