import { Component } from '@angular/core';

@Component({
  selector: 'app-forgotten-confirm-dialog',
  template: `

    <h1 mat-dialog-title>Recupero password effettuato con successo!</h1>

    <div mat-dialog-content>
      <p>Ti verrà inviato un messaggio di posta contenente un link per modificare la password di accesso.</p>
    </div>

    <div mat-dialog-actions align="end">
      <button [routerLink]="['/autenticazione/accesso']" mat-raised-button color="warn" mat-dialog-close="">
        Chiudi
      </button>
    </div>`
})
export class ForgottenConfirmDialogComponent {

}
