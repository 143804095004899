<mat-expansion-panel
  class="cart-order-cntr"
  [disabled]="configuration.disabled"
  [expanded]="configuration.expanded"
  (opened)="onOpen()"
>
  <mat-expansion-panel-header>
    <mat-panel-title>

      <strong> {{ configuration.flow.Description }}  </strong>

    </mat-panel-title>

    <mat-panel-description>

      <div>
        <app-cart-order-expiration-notice [flow]="configuration.flow"></app-cart-order-expiration-notice>
      </div>

      <div>

        <app-estimated-price
          *ngIf="configuration.estimatedPriceManagementEnabled || configuration.flow.TotalAmount"
          [configuration]="configuration"
        ></app-estimated-price>

      </div>

    </mat-panel-description>

  </mat-expansion-panel-header>

  <ng-container *ngIf="configuration.flowData; else loadingDataTemplate">

    <app-cart-order-detail [flow]="configuration.flowData"></app-cart-order-detail>

    <app-cart-order-management
      [configuration]="configuration"
      (update)="refresh.emit()"
    ></app-cart-order-management>

  </ng-container>

  <ng-template #loadingDataTemplate>

    <mat-progress-bar mode="buffer"></mat-progress-bar>

  </ng-template>

</mat-expansion-panel>
