import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import {
  CartFileListDownloadButtonComponent
} from "./cart-file-list-download-button/cart-file-list-download-button.component";
import { MatLegacyMenuModule as MatMenuModule } from "@angular/material/legacy-menu";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatIconModule } from "@angular/material/icon";

@NgModule({
  declarations: [CartFileListDownloadButtonComponent],
  imports: [CommonModule, MatMenuModule, MatButtonModule, MatIconModule],
  exports: [CartFileListDownloadButtonComponent]
})
export class CartFileListDownloadButtonModule {
}
