import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-validation-mark',
  templateUrl: './validation-mark.component.html',
  styleUrls: ['./validation-mark.component.scss'],
})
export class ValidationMarkComponent {
  @Input() verified: boolean = false;
  @Output() verify: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}
}
