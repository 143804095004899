import { Flow } from "../../core/classes/flow";

export class CartOrderConfiguration {

  private _trackingRedirectEnabled: boolean | null = false;
  get trackingRedirectEnabled(): boolean | null {
    return this._trackingRedirectEnabled;
  }

  private set trackingRedirectEnabled(value: boolean | null) {
    this._trackingRedirectEnabled = value;
  }

  private _estimatedPriceManagementEnabled: boolean = false;
  get estimatedPriceManagementEnabled(): boolean {
    return this._estimatedPriceManagementEnabled;
  }

  private set estimatedPriceManagementEnabled(value: boolean) {
    this._estimatedPriceManagementEnabled = value;
  }

  private _flow: Flow = Flow.create();
  get flow(): Flow {
    return this._flow;
  }

  set flow(value: Flow) {
    this._flow = value;
  }

  private _flowData: Flow | null = null;
  get flowData(): Flow | null {
    return this._flowData;
  }

  set flowData(value: Flow | null) {
    this._flowData = value;
  }

  private _expanded: boolean = false;
  get expanded(): boolean {
    return this._expanded;
  }

  set expanded(value: boolean) {
    this._expanded = value;
  }

  private _disabled: boolean = false;
  get disabled(): boolean {
    return this._disabled;
  }

  set disabled(value: boolean) {
    this._disabled = value;
  }

  private _dialogCallBack: boolean = false;

  get hasDialogCallback(): boolean {
    return this._dialogCallBack;
  }

  set hasDialogCallback(value: boolean) {
    this._dialogCallBack = value;
  }

  constructor() {
  }

  public static create = (): CartOrderConfiguration => new CartOrderConfiguration();

  manageEstimatedPrice(value: boolean): CartOrderConfiguration {
    this.estimatedPriceManagementEnabled = value;
    return this;
  }

  setFlow(value: Flow): CartOrderConfiguration {

    this.flow = Flow.create(value);
    return this;

  }

  setFlowData(value: Flow): CartOrderConfiguration {

    this.flowData = Flow.create(value);
    return this;

  }

  setExpanded(value: boolean): CartOrderConfiguration {

    this.expanded = value;
    return this;

  }

  setDisable(value: boolean): CartOrderConfiguration {

    this.disabled = value;
    return this;

  }

  setHasDialogCallback(value: boolean): CartOrderConfiguration {

    this.hasDialogCallback = value;
    return this;

  }

  setTrackingRedirectEnabled(value: boolean | null): CartOrderConfiguration {

    this.trackingRedirectEnabled = value;
    return this;

  }

}
