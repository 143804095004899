import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NewsletterUnsubscribeComponent } from "./newsletter-unsubscribe.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";

@NgModule({
  declarations: [NewsletterUnsubscribeComponent],
  imports: [
    CommonModule,
    RouterModule.forChild([
      {
        path: "",
        component: NewsletterUnsubscribeComponent
      }
    ]),
    MatCardModule
  ]
})
export class NewsletterUnsubscribeModule {
}
