import { Component, Input, OnChanges, SimpleChanges } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PRINT_MODE } from "../../../../../core/enums/print-mode.enum";
import { COLOR_MODE } from "../../../../../core/enums/color-mode.enum";
import { GroupController } from "../../../../../core/controllers/group.controller";
import { Group } from "../../../../../core/interfaces/group";
import { FlowSendAreaEnum } from "../../../../../core/enums/flow-send-area.enum";
import { Flow } from "../../../../../core/classes/flow";
import { personSpecificationsDefaultValidators } from "../person-specifications/person-specifications.component";
import { CTBase } from "@ctsolution/ct-base";
import { JwtService } from "../../../../../core/lib/jwt.service";

@Component({
  selector: "app-letter-specifications",
  templateUrl: "./letter-specifications.component.html",
  styleUrls: ["./letter-specifications.component.scss"],
  providers: [GroupController]
})
export class LetterSpecificationsComponent {

  @Input() flow?: Flow;

  DESTINATIONS = FlowSendAreaEnum;

  UserGroup?: Group;
  form: FormGroup;

  loadingGroupDetails: boolean = false;

  viewModel: any = {

    printInternationalEnabled: true

  };

  constructor(
    private _fb: FormBuilder,
    private _controller: GroupController,
    private _jwt: JwtService
  ) {

    this.form = this._fb.group({
      Destination: new FormControl(FlowSendAreaEnum.Local, Validators.required),
      SendSubject: new FormControl(null)
    });

  }

  ngAfterViewInit() {

    this.setup();

  }

  async setup() {

    const printInternationalEnabled = await this._jwt.userHasPrintInternationalEnabled();

    if (printInternationalEnabled === null) return; // se nullo è abilitato

    this.viewModel.printInternationalEnabled = printInternationalEnabled;

  }

  get enableSpecificationDescription(): boolean {

    return (
      (this.flow?.digitalSendingChannelSetupEnabled ?? false)
      &&
      !(this.flow?.emailStandaloneChannelSetupEnabled ?? false));

  }

  setupGroupDetails(): Promise<Group> {

    this.loadingGroupDetails = true;

    return new Promise<Group>((resolve) => {

      this._controller
        .userGroup()
        .then((result: Group) => {

          this.loadingGroupDetails = false;

          this.UserGroup = result;

          if (this.UserGroup.FlowColorModeList && this.UserGroup.FlowColorModeList.length > 0) this.setColorModeControl();
          if (this.UserGroup.FlowPrintModeList && this.UserGroup.FlowPrintModeList.length > 0) this.setPrintModeControl();

          this.form.get("SendSubject")?.setValidators(this.flow?.emailStandaloneChannelSetupEnabled ? personSpecificationsDefaultValidators() : null);

          this.form.updateValueAndValidity();

          resolve(result);

        });

    });

  }

  /* Adding a control to the form. */
  private setColorModeControl() {

    const defaultValue: any = this.UserGroup!.FlowColorModeList![0].Code;
    this.form.addControl("ColorMode", new FormControl(defaultValue, Validators.required));

  };

  /* Adding a control to the form. */
  private setPrintModeControl() {

    const defaultValue: any = this.UserGroup!.FlowPrintModeList![0].Code;
    this.form.addControl("PrintMode", new FormControl(defaultValue, Validators.required));

  };

}

export class LetterSpecifications extends CTBase<LetterSpecifications> {

  Destination: FlowSendAreaEnum = FlowSendAreaEnum.Local;
  ColorMode: COLOR_MODE | null = null;
  PrintMode: PRINT_MODE | null = null;
  SendSubject: string | null = null;

  constructor();
  constructor(model?: LetterSpecifications);
  constructor(model?: LetterSpecifications) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: LetterSpecifications) => new LetterSpecifications(model);

}
