import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GroupDetailComponent } from './group-detail.component';
import { DialogModule } from '@angular/cdk/dialog';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { GroupGeneralDataComponent } from './group-general-data/group-general-data.component';
import { GroupAddressComponent } from './group-address/group-address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import { MatLegacyFormFieldModule as MatFormFieldModule } from '@angular/material/legacy-form-field';
import { MatLegacyInputModule as MatInputModule } from '@angular/material/legacy-input';
import { MatDividerModule } from '@angular/material/divider';
import { GroupBudgetComponent } from './group-budget/group-budget.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatIconModule } from '@angular/material/icon';
import { PersonSpecificationsModule } from '../../../shipment/send-flow/step2/person-specifications/person-specifications.module';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacySnackBarModule as MatSnackBarModule } from '@angular/material/legacy-snack-bar';
import { CtMultiCheckboxFormModule } from '@ctsolution/ct-framework';
import { GroupDeleteComponent } from './group-delete/group-delete.component';

@NgModule({
  declarations: [
    GroupDetailComponent,
    GroupGeneralDataComponent,
    GroupAddressComponent,
    GroupBudgetComponent,
    GroupDeleteComponent,
  ],
  imports: [
    CommonModule,
    DialogModule,
    MatTabsModule,
    MatButtonModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    FlexLayoutModule,
    MatIconModule,
    PersonSpecificationsModule,
    MatDialogModule,
    CtMultiCheckboxFormModule,
    MatSnackBarModule,
  ],
  exports: [GroupDetailComponent],
})
export class GroupDetailModule {}
