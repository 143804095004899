<h1 mat-dialog-title>Attenzione</h1>

<div mat-dialog-content>
  <p class="text-center">
    Proseguendo cancellerai quest'ordine. <br />
    Sei sicuro di voler proseguire?
  </p>
</div>

<div mat-dialog-actions class="d-flex space-between">
  <button mat-raised-button color="warn" [mat-dialog-close]="true">
    <mat-icon>close</mat-icon>
    Cancella ordine
  </button>

  <button mat-raised-button color="primary" [mat-dialog-close]="false">
    Annulla
    <mat-icon>arrow_forward</mat-icon>
  </button>
</div>
