import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { DocumentPreviewComponent } from "./document-preview.component";
import { MatLegacyCardModule as MatCardModule } from "@angular/material/legacy-card";
import { MatIconModule } from "@angular/material/icon";
import { SecurePipeModule } from "../../../../../core/secure/secure-pipe.module";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { CtSkeletonModule } from "@ctsolution/ct-framework";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule } from "@angular/material/legacy-dialog";

@NgModule({
  declarations: [DocumentPreviewComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatCardModule,
    MatIconModule,
    MatLegacyDialogModule,
    SecurePipeModule,
    MatFormFieldModule,
    MatSelectModule,
    CtSkeletonModule],
  exports: [DocumentPreviewComponent]
})
export class DocumentPreviewModule {
}
