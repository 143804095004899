import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { MatIconModule } from '@angular/material/icon';
import { MatStepperModule } from '@angular/material/stepper';
import { StepperActionsComponent } from './stepper-actions.component';
import { SendFlowAlertModule } from '../send-flow-alert/send-flow-alert.module';
import { CdkStepper } from "@angular/cdk/stepper";

@NgModule({
  declarations: [StepperActionsComponent],
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    SendFlowAlertModule,
  ],
  exports: [StepperActionsComponent]
})
export class StepperActionsModule {}
