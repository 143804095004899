<h1 mat-dialog-title>{{ data.Oid ? 'Modifica' : 'Creazione' }} gruppo</h1>

<div mat-dialog-content class="group-detail-cntr">

  <ng-container *ngIf="this.viewModel.insert; else updateTemplate">

    <app-group-general-data #GeneralData></app-group-general-data>

  </ng-container>

  <ng-template #updateTemplate>

    <mat-tab-group *ngIf="viewModel.displayTabGroup">

      <mat-tab>

        <ng-template mat-tab-label>
          Dati generali
          <mat-icon class="tab-icon" *ngIf="GeneralData.form.touched && GeneralData.form.invalid">error
          </mat-icon>
        </ng-template>

        <app-group-general-data #GeneralData></app-group-general-data>

        <app-group-budget #Budgets></app-group-budget>

      </mat-tab>

      <mat-tab>

        <ng-template mat-tab-label>
          Indirizzo
          <mat-icon class="tab-icon" *ngIf="Address.form.touched && Address.form.invalid">error</mat-icon>
        </ng-template>

        <app-group-address #Address></app-group-address>
      </mat-tab>

    </mat-tab-group>

  </ng-template>

</div>

<div mat-dialog-actions class="d-flex space-between">
  <button mat-button mat-dialog-close color="primary">Annulla</button>
  <button mat-button cdkFocusInitial color="accent" (click)="submit()">Salva</button>
</div>
