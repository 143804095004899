import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AddressValidatorComponent } from './validator/address-validator.component';
import { AddressValidatorService } from './address-validator.service';
import { MatLegacyListModule as MatListModule } from '@angular/material/legacy-list';
import { MatLegacyProgressBarModule as MatProgressBarModule } from '@angular/material/legacy-progress-bar';
import { MatIconModule } from '@angular/material/icon';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { AlertBothAddressesInvalidComponent } from './alert-both-addresses-invalid/alert-both-addresses-invalid.component';
import { ValidationController } from '../../../../core/controllers/validation.controller';
import { MatLegacyButtonModule as MatButtonModule } from '@angular/material/legacy-button';
import { AlertMultipleRecipientsInvalidComponent } from './alert-multiple-recipients-invalid/alert-multiple-recipients-invalid.component';
import { MatExpansionModule } from "@angular/material/expansion";

@NgModule({
  declarations: [
    AddressValidatorComponent,
    AlertBothAddressesInvalidComponent,
    AlertMultipleRecipientsInvalidComponent,
  ],
  imports: [
    CommonModule,
    MatDialogModule,
    MatListModule,
    MatProgressBarModule,
    MatIconModule,
    MatButtonModule,
    MatExpansionModule
  ],
  providers: [AddressValidatorService, ValidationController],
})
export class AddressValidatorModule {}
