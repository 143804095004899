import { Component, Inject, OnInit } from "@angular/core";
import { AddressValidatorService } from "../address-validator.service";
import {
  IValidationResult,
  ISuggestedAddressCorrection
} from "../../../../../core/interfaces/address-validation";
import { AddressData } from "../../../../../core/classes/address-data";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";

@Component({
  selector: "app-address-validator",
  templateUrl: "./address-validator.component.html",
  styleUrls: ["./address-validator.component.scss"]
})
export class AddressValidatorComponent implements OnInit {
  addresses: AddressData[] = [];

  constructor(
    private _dialogRef: MatDialogRef<AddressValidatorComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      parameter: AddressData;
    },
    public _validator: AddressValidatorService
  ) {
    this.validate();
  }

  ngOnInit() {
    this.addresses.push({ ...this.data.parameter, userValue: true } as any);
  }

  /**
   * We're using the `validate()` function to validate the address that the user has entered.
   * If the address is valid, we'll add it to the list of addresses.
   * If the address is not valid, we'll show the user a list of suggested corrections.
   * The user can then select one of the suggested corrections, and we'll add that to the list of addresses.
   */
  validate() {
    this._validator
      .validateAddressData(this.data.parameter)
      .then((result: IValidationResult | null) => {
        const suggestedCorrections: ISuggestedAddressCorrection[] =
          result?.SuggestedCorrections ?? [];
        const mapped = suggestedCorrections.map((correction: ISuggestedAddressCorrection) =>
          this._formatToAddressData(correction)
        );

        if (mapped.length > 0) this.addresses = [...mapped, ...this.addresses];
      });
  }

  select = (address: AddressData) => this._dialogRef.close(address);

  private _formatToAddressData = (correction: ISuggestedAddressCorrection) =>
    new AddressData(
      correction.Name,
      correction.Address,
      correction.ZipCode,
      correction.City,
      correction.Province,
      correction.Country,
      correction.Email,
      correction.Phone
    );

}
