import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RecipientListUploadComponent } from './recipient-list-upload.component';
import { MatLegacyCardModule as MatCardModule } from '@angular/material/legacy-card';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { MatIconModule } from '@angular/material/icon';
import { FlowFileUploaderModule } from '../../../../../_components/flow-components/file-uploader/flow-file-uploader.module';

@NgModule({
  declarations: [RecipientListUploadComponent],
  imports: [CommonModule, MatCardModule, MatTabsModule, MatIconModule, FlowFileUploaderModule],
  exports: [RecipientListUploadComponent],
})
export class RecipientListUploadModule {}
