import { CTBase } from "@ctsolution/ct-base";
import { FlowFile } from "./flow-file";

export class RecipientData extends CTBase<RecipientData> {

  FlowGroupCode: string | null = null;
  FlowGroupName: string | null = null;
  FlowSendTypeConfigurationSendTypeName: string | null = null;
  FlowSendTypeConfigurationSendTypeSendCategory: string | null = null;
  FlowDescription: string | null = null;
  FlowCreationDate: Date | null = null;
  InternalCode: string | null = null;
  FlowUserInfoUserUsername: string | null = null;
  FlowUserInfoEmail?: any;
  FlowUserInfoName: string | null = null;
  FlowUserInfoSurname: string | null = null;
  TrackingCode?: any;
  TrackingState?: any;
  TrackingDate?: any;
  Oid: number | null = null;
  FlowOid!: number;
  RowNumber: number | null = null;
  Name: string | null = null;
  Address: string | null = null;
  ZipCode: string | null = null;
  City: string | null = null;
  Province: string | null = null;
  Country: string | null = null;
  Phone: string | null | null = null;
  Email: string | null | null = null;
  CachedFlowFiles: FlowFile[] | null = null;
  CertifiedEmail?: string;

  private constructor();
  private constructor(model?: RecipientData);
  private constructor(model?: RecipientData) {

    super();

    if (model) this.getClass(model);

  }

  public static create = (model?: RecipientData): RecipientData => new RecipientData(model);

}
